import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getDataFromApi, baseUrl } from '../../api/providers/providers';

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const TicketOrderRma = () => {
    const {idOrder} = useParams();

    const date = new Date()

    const [companyData, setCompanyData] = useState({
        idTaxRegime: null,
        idRegisterUser: null,
        sBuinessName: '',
        sCompanyName: '',
        sRfc: '',
        sBusinessAddress: '',
        sPrincipalPhone: '',
        sAlternatePhone: '',
        sWebsite: '',
        sEmail: '',
        sImagePath: '',
        sCsd: '',
        sKey: '',
        sSatpassword: '',
        sExtNumber: '',
        sIntNumber: '',
        idState: null,
        idCity: null,
        idColony: 1,
        sCp: '',
        sColony: '',
    })

    const [orderData, setOrderData] = useState({
        customer: {},
        address: {},
        items: [],
    })

    const getData = async () => {
        const company = await getDataFromApi(`Company/findCompany?idCompany=${localStorage.getItem('idCompany')}`, localStorage.getItem('idCompany'), companyData, headers);

        if(company.status === 'success') {
            setCompanyData(company.companyData)
        }

        const order = await getDataFromApi(`Order/findOrder?idOrder=${idOrder}`, idOrder, orderData, headers)
        if(order.status === 'success'){
            const customer = await getDataFromApi(`Customer/findCustomer?idCustomer=${order.orderData.idCustomer}`, order.orderData.idCustomer, {}, headers)

            const details = await getDataFromApi(`/OrderDetail/getOrderDetailList?idOrder=${idOrder}`, idOrder, {}, headers)

            const addresses = await getDataFromApi(`CustomerAddresses/getCustomerAddressesList?idCustomer=${order.orderData.idCustomer}`, order.orderData.idCustomer, [], headers)
            if(customer.status === 'success' && addresses.status === 'success') {
                
                const customerAddress = addresses.listCustomerAddresses.find( address => address.idAddress === order.orderData.idCustomerAddress )

                const address = await getDataFromApi(`CustomerAddresses/findCustomerAddress?idCustomerAddress=${order.orderData.idCustomerAddress}`, order.orderData.idCustomerAddress, [], headers)

                const customerBilling = await getDataFromApi(`CustomerBilling/getBillingData?idCustomer=${order.orderData.idCustomer}`, order.orderData.idCustomer, [], headers)

                if(customerAddress && address.status === 'success' && customerBilling.status === 'success') {
                    customerAddress.sColony = address.addressData.sColony
                    customerAddress.sZipCode = address.addressData.sZipCode
                    customer.customerData.sRfc = customerBilling.billData.sRfc

                    setOrderData({
                        idOrder: order.orderData.idOrder,
                        deliveryDate: order.orderData.dDeliveryDate.substring(0,10),
                        deliveryHour: order.orderData.dDeliveryDate.substring(11, 19),
                        customer: customer.customerData,
                        address: customerAddress,
                        items: details.orderDetailList,
                        dSubtotal: order.orderData.dSubtotal.toFixed(2),
                        dDiscount: 0,
                        dTax: order.orderData.dTax.toFixed(2),
                        dTotal: order.orderData.dTotal.toFixed(2),
                        registerDate: order.orderData.dRegisterDate.substring(0,10),
                        registerHour: order.orderData.dRegisterDate.substring(11, 19),
                        sDriverName: order.orderData.sDriverName
                    })

                    setTimeout(() => {
                        window.print()
                    }, 200);
                }
            }
        }
    }

    useEffect(() => {
        getData()
    }, [])
    
    return (
        <div style={ { 'width' : '300px', 'marginTop' : '20px', 'fontSize': '11px', 'marginLeft': '5px' } }>
            <div style={ { 'textAlign': 'center' } }>
            {
                companyData.sImagePath !== ''
                ? (
                    <img src={ `${baseUrl}/${companyData.sImagePath}` } width="100" style={ { 'display': 'inline-block' } }></img>
                )
                : ''
            }
                <p>{ companyData.sCompanyName }<br/>{ companyData.sRfc }</p>
            </div>
            <div style={ { 'textAlign': 'right' } }>
                <span>Fecha Ticket: { `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` } </span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span>Empresa: { companyData.sBuinessName } </span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Calle: { companyData.sBusinessAddress }</span>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Num: { `${companyData.sExtNumber} ${companyData.sIntNumber ? companyData.sIntNumber : ''}` } </span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Col: { companyData.sColony }</span>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>CP: { companyData.sCp } </span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Del: { orderData.address.sCity }</span>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Edo: { orderData.address.sState } </span>
            </div>
            <div style={ { 'textAlign': 'center', 'borderBottom': '1px solid black' } }>
                <span>Tel: { companyData.sPrincipalPhone }</span>
            </div>
            <div style={ { 'textAlign': 'center' } }>
                <span style={ { 'fontSize': '15px' } }>NOTAS DE MOSTRADOR</span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>No. Pedido: {orderData.idOrder} </span>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Ticket: </span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Fecha: { orderData.registerDate } </span>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Hora: { orderData.registerHour } </span>
            </div>
            <div style={ { 'textAlign': 'center' } }>
                <span>Chofer: { orderData.sDriverName }</span>
            </div>
            <div style={ { 'textAlign': 'center' } }>
                <span>Entrega de pedido</span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Fecha: { orderData.deliveryDate } </span>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Hora: { orderData.deliveryHour } </span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span>Cliente: { orderData.customer.sBusinessName } </span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span>RFC: { orderData.customer.sRfc } </span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Calle: { orderData.address.sBusinessAddress }</span>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Num: { `${orderData.address.sExtNumber} ${orderData.address.sIntNumber}` } </span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Col: { orderData.address.sColony }</span>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>CP: { orderData.address.sZipCode } </span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Del: { orderData.address.sCity }</span>
                <span style={ { 'display': 'inline-block', 'width': '49%', 'verticalAlign': 'top' } }>Edo: { orderData.address.sState } </span>
            </div>
            <div style={ { 'textAlign': 'center', 'borderBottom': '2px solid black', 'marginBottom': '2px' } }>
                <span>Tel: { orderData.customer.sPhoneNumber }</span>
            </div>
            {/* <div style={ { 'textAlign': 'center', 'borderTop': '2px solid black' } }>
                <span style={ { 'fontSize': '15px' } }>DETALLE NOTA</span>
            </div>
            <div style={ { 'textAlign': 'left', 'borderBottom': '1px solid black' } }>
                <span style={ { 'display': 'inline-block', 'width': '15%', 'verticalAlign': 'top', 'textAlign': 'center' } }>Cant.</span>
                <span style={ { 'display': 'inline-block', 'width': '45%', 'verticalAlign': 'top' } }>Descripción</span>
                <span style={ { 'display': 'inline-block', 'width': '15%', 'verticalAlign': 'top', 'textAlign': 'center' } }>P. Unit</span>
                <span style={ { 'display': 'inline-block', 'width': '25%', 'verticalAlign': 'top', 'textAlign': 'center' } }>P. Total</span>
            </div>
            {
                orderData.items.map( element => (
                    <div style={ { 'textAlign': 'left' } } key={ element.idOrderDetail }>
                        <span style={ { 'display': 'inline-block', 'width': '15%', 'verticalAlign': 'top', 'textAlign': 'center' } }>{ element.iQuantity }</span>
                        <span style={ { 'display': 'inline-block', 'width': '45%', 'verticalAlign': 'top' } }>{ element.sProductService }</span>
                        <span style={ { 'display': 'inline-block', 'width': '15%', 'verticalAlign': 'top', 'textAlign': 'right' } }>${ element.dPrice.toFixed(2) }</span>
                        <span style={ { 'display': 'inline-block', 'width': '25%', 'verticalAlign': 'top', 'textAlign': 'right' } }>${ element.dTotal.toFixed(2) }</span>
                    </div>
                ) )
            }
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '60%', 'verticalAlign': 'top', 'textAlign': 'right' } }>Subtotal</span>
                <span style={ { 'display': 'inline-block', 'width': '40%', 'verticalAlign': 'top', 'textAlign': 'right' } }>${ orderData.dSubtotal }</span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '60%', 'verticalAlign': 'top', 'textAlign': 'right' } }>Descuento</span>
                <span style={ { 'display': 'inline-block', 'width': '40%', 'verticalAlign': 'top', 'textAlign': 'right' } }>${ orderData.dDiscount }</span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '60%', 'verticalAlign': 'top', 'textAlign': 'right' } }>I.V.A</span>
                <span style={ { 'display': 'inline-block', 'width': '40%', 'verticalAlign': 'top', 'textAlign': 'right' } }>${ orderData.dTax }</span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '60%', 'verticalAlign': 'top', 'textAlign': 'right' } }>Total</span>
                <span style={ { 'display': 'inline-block', 'width': '40%', 'verticalAlign': 'top', 'textAlign': 'right' } }>${ orderData.dTotal }</span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '50%', 'verticalAlign': 'top' } }>Efectivo: </span>
                <span style={ { 'display': 'inline-block', 'width': '50%', 'verticalAlign': 'top' } }>Saldo: </span>
            </div>
            <div style={ { 'textAlign': 'left' } }>
                <span style={ { 'display': 'inline-block', 'width': '50%', 'verticalAlign': 'top' } }>Tarjeta: </span>
                <span style={ { 'display': 'inline-block', 'width': '50%', 'verticalAlign': 'top' } }>Cambio: </span>
            </div>
            <div style={ { 'textAlign': 'left', 'marginTop': '10px' } }>
                <span>Página web: https://aqcuatronix.com</span><br/>
                <span>Email: ventas@aqcuatronix.com</span><br/>
                <span>Teléfono: </span><br/>
                <span>Descripción nota: </span>
            </div>
            <div style={ { 'textAlign': 'center', 'marginTop': '50px' } }>
                <span>FIRMA DE CONFORMIDAD</span>
            </div> */}
        </div>
    )
}

import { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import { Form, Input } from '@availity/form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Label, TabContent, TabPane, CardHeader, Nav, NavItem, NavLink } from "reactstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import {QRCodeSVG} from 'qrcode.react';
import { lavakleenApi } from '../../api/lavakleenApi';
import { deleteDataFromApi, getCompanyById, getDataFromApi, saveData } from '../../api/providers/providers';
import { PageTitle } from '../../layout/components/PageTitle';
import { startProcessing, stopProcessing } from '../../store/auth/authSlice';
import { TabHeaderComponent } from '../components/TabHeaderComponent';
import { columnsAddress } from '../providers/clientsProviders';
import { ModalComponent } from '../components/ModalComponent';
import { columnsProductsListToAssign, Taxes } from '../providers/inventoryProviders';

const formData = {
    idCustomer: 0,
    idServiceType: 0,
    idCustomerAddress: 0,
    idCurrency: 0,
    idRegisterUser: 0,
    dDeliveryDate: '',
    sNotificationMail: '',
    sComments: '',
    dSubtotal: 0,
    dDiscount: 0,
    dTax: 0,
    dTotal: 0,
    idDriver: 0,
    idOrderProcess: 0,
    dTotalWeigth: 0,
    idOrder: 0,
    dTotalWeight: 0,
    dCreationDate: '',
};

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const OrderPage = () => {

    const {idOrder} = useParams();

    const orderDetailsRef = useRef([]);
    const productsDetailsRef = useRef([]);
    const clientsRef = useRef([]);
    const servicesListRef = useRef([]);
    const billingAddressesRef = useRef([]);
    const orderDataRef = useRef(formData);
    const chofersRef = useRef([]);
    const productsOfList = useRef([]);
    const discountRef = useRef(0);

    const { id, isProcessing, role } = useSelector( state => state.auth );

    const [state, setState] = useState({
        activeTab: 'items'
    })

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
    })

    const [modalStatePayments, setModalStatePayments] = useState({
        isOpen: false,
        backDrop: true,
    })

    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [creationDate, setCreationDate] = useState(new Date());
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [closeMessage, setCloseMessage] = useState(false);

    const [formItemData, setFormItemData] = useState({
        idOrder: parseFloat(idOrder),
        idProduct: null,
        iQuantity: 1,
        iKGWeight: 0,
        sComments: '',
        dPrice: '',
        dTaxPercen: '',
        id: 0,
        dDiscount: 0,
    });

    const [formPaymentData, setFormPaymentData] = useState({
        idOrder: parseFloat(idOrder),
        idPaymentType: 0,
        dAmount: 0,
        dPaymentDate: new Date(),
        id: 0,
      });

    const [columnsProductsList, setColumnsProductsList] = useState([
        {
            name: "Cantidad",
            selector: row => row.iQuantity,
            sortable: true,
            width: '150px',
        },
        {
            name: "Producto",
            selector: row => row.sProductService,
            sortable: true,
        },
        {
            name: "Unidad",
            selector: row => row.sCustomerMeasurement,
            sortable: true,
        },
        {
            name: "P.Unitario",
            selector: row => row.dPrice,
            sortable: true,
        },
        // {
        //     name: "Tasa IVA",
        //     selector: row => row.dTaxPercent,
        //     sortable: true,
        // },
        {
            name: "Importe",
            selector: row => row.dSubtotal,
            sortable: true,
        },
        {
            name: "IVA",
            selector: row => row.dTaxAmount,
            sortable: true,
        },
        {
            name: "Comentarios",
            selector: row => row.sComments,
            width: '200px'
        },
        {
            name: "Acciones",
            cell: (row) => (
                <>
                    <Button color="primary" onClick={ () => editOrderDetail(row) } title="Editar"><i className="lnr-pencil"></i></Button>

                    <Button color="danger" onClick={ () => deleteOrderDetail(row) } title="Eliminar"><i className="lnr-trash"></i></Button>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            width: '100px',
        }
    ]);

    const [columnsPaymentsList, setColumnsPaymentsList] = useState([
        {
            name: "Forma de pago",
            selector: row => row.sPaymentType,
            sortable: false,
        },
        {
            name: "Importe",
            selector: row => row.dAmount,
            sortable: false,
        },
        {
            name: "Acciones",
            cell: (row) => (
                <>
                    <Button color="danger" onClick={ () => deleteOrderPayment(row) } title="Eliminar"><i className="lnr-trash"></i></Button>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            width: '100px'
        }
    ]);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedOptionCurrency, setSelectedOptionCurrency] = useState({
        value: 1, label: 'MXN - Pesos Mexicanos'
    });
    const [selectedOptionProductList, setSelectedOptionProductList] = useState(null);
    const [selectedOptionProcess, setSelectedOptionProcess] = useState(null);
    const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
    const [selectedOptionChofer, setSelectedOptionChofer] = useState(null);
    const [selectedOptionBillingAddress, setSelectedOptionBillingAddress] = useState(null);
    const [selectedOptionTax, setSelectedOptionTax] = useState(null);
    const [orderData, setOrderData] = useState(formData);
    const [selectedPaymentForm, setSelectedPaymentForm] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [notificationEmail, setNotificationEmail] = useState('');
    const [dTotalWeigth, setDTotalWeigth] = useState(0);

    const [optionsProducts, setOptionsProducts] = useState([]);
    const [optionsChofer, setOptionsChofer] = useState([]);
    const [optionsCurrency, setOptionsCurrency] = useState([
        {value: 1, label: 'MXN - Pesos Mexicanos'},
        {value: 2, label: 'USD - Dólares americanos'}
    ]);
    const [optionsPaymentsForm, setOptionsPaymentsForm] = useState([
        {value: 1, label: '01 - Efectivo'},
        {value: 2, label: '02 - Cheque nominativo'},
        {value: 3, label: '03 - Transferencia electrónica'},
        {value: 4, label: '04 - Tarjeta de crédito'},
        {value: 5, label: '28 - Tarjeta de débito'}
    ]);
    const [optionsTaxes, setOptionsTaxes] = useState(Taxes);
    const [optionsProductList, setOptionsProductList] = useState([]);
    const [optionsCustomers, setOptionsCustomers] = useState([]);
    const [optionsProcess, setOptionsProcess] = useState([
        { value: 1, label: 'RECOLECCION' },
        { value: 2, label: 'EN ESPERA' },
        { value: 3, label: 'EN PROCESO' },
        { value: 4, label: 'PROCESADO' },
        { value: 5, label: 'PARA ENTREGA' },
        { value: 6, label: 'FINALIZADO' },
        { value: 7, label: 'PREFACTURADO' },
    ]);
    const [finisRegistering, setFinisRegistering] = useState(false);
    const [optionsBillingAddress, setOptionsBillingAddress] = useState([]);
    const [sComments, setSComments] = useState('');

    //validators
    const [invalidCustomer, setInvalidCustomer] = useState(true);
    const [invalidProduct, setInvalidProduct] = useState(true);
    const [invalidTax, setInvalidTax] = useState(true);
    const [invalidChofer, setInvalidChofer] = useState(true);
    const [invalidBillingAddress, setInvalidBillingAddress] = useState(true);
    const [invalidPaymentForm, setInvalidPaymentForm] = useState(true);

    //datatables
    const [dataPaymentsOrder, setDataPaymentsOrder] = useState([]);
    const [dataProductsOrder, setDataProductsOrder] = useState([]);
    const [dataProductsCustomer, setDataProductsCustomer] = useState([]);

    const [dataProductsListCustomer, setDataProductsListCustomer] = useState([]);

    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        selectedOptionCustomer == null ? setInvalidCustomer(true) : setInvalidCustomer(false)
        selectedOptionBillingAddress == null ? setInvalidBillingAddress(true) : setInvalidBillingAddress(false)

        if(idOrder === undefined) {
            const alertConfig = {
                title: 'Exito',
                text: 'Pedido creado exitosamente.',
                icon: 'success',
                willClose: () => {
                    onFinishRegister()
                }
            };

            values.idCustomer = selectedOptionCustomer.value
            values.idCurrency = selectedOptionCurrency.value
            values.idCustomerAddress = selectedOptionBillingAddress.value
            values.idRegisterUser = id
            values.dDeliveryDate = deliveryDate
            values.idServiceType = selectedOptionProductList.value
            values.idDriver = selectedOptionChofer.value
            values.sNotificationMail = notificationEmail
            values.dTotalWeight = dTotalWeigth
            values.dTotalWeigth = dTotalWeigth
            values.sComments = sComments

            dispatch( startProcessing() )

            //save the order to get the idOrder
            await lavakleenApi.post('Order/addOrder', values, {
                headers
            }).then( response => {
                if(response.data.sErrors.length === 0) {
                    orderDetailsRef.current.map( async element => {
                        element.idOrder = response.data.idOrder
                        await lavakleenApi.post('/OrderDetail/addOrderDetail', element, { headers })
                            .then( responseDetail => {
                            } )
                    })

                    setTimeout(() => {
                        Swal.fire(alertConfig)
                    }, 200);
                } else {
                    setTimeout(() => {
                        Swal.fire({
                            title: 'Error',
                            text: response.data.sErrors[0],
                            icon: 'error',
                            willClose: () => {
                            }
                        })
                    }, 200);
                }
            })

            dispatch( stopProcessing() )

        } else {
            const alertConfig = {
                title: 'Exito',
                text: 'Pedido actualizado exitosamente.',
                icon: 'success',
                willClose: () => {
                    onFinishRegister()
                }
            };

            values.idCustomer = selectedOptionCustomer.value
            values.idCurrency = selectedOptionCurrency.value
            values.idCustomerAddress = selectedOptionBillingAddress.value
            values.idRegisterUser = id
            values.dDeliveryDate = deliveryDate
            values.dRegisterDate = creationDate
            values.idServiceType = selectedOptionProductList.value
            values.idOrder = idOrder
            values.dSubtotal = orderData.dSubtotal
            values.dTax = orderData.dTax
            values.dTotal = orderData.dTotal
            values.dDiscount = orderData.dDiscount
            values.idOrderProcess = selectedOptionProcess.value
            values.sNotificationMail = notificationEmail
            values.dTotalWeight = dTotalWeigth
            values.dTotalWeigth = dTotalWeigth

            calculateTotal(orderDetailsRef.current)
            values.dSubtotal = orderData.dSubtotal
            values.dTax = orderData.dTax
            values.dTotal = orderData.dTotal
            values.dDiscount = orderData.dDiscount
            values.sComments = sComments
            
            dispatch( startProcessing() )

            //save the order to get the idOrder
            await lavakleenApi.post('Order/editOrder', values, {
                headers
            }).then( response => {
                if(response.data.sErrors.length === 0) {
                    setTimeout(() => {
                        Swal.fire(alertConfig)
                    }, 200);
                } else {
                    setTimeout(() => {
                        Swal.fire({
                            title: 'Error',
                            text: response.data.sErrors[0],
                            icon: 'error',
                            willClose: () => {
                            }
                        })
                    }, 200);
                }
            })

            dispatch( stopProcessing() )
        }
    }

    const getCustomerLists = async (customer) => {
        productsOfList.current = []
        const lists = await getDataFromApi(`Customer/getCustomerProdList?idCustomer=${customer.value}`, customer.value, [], headers)
        if(lists.status === 'success') {
            setDataProductsListCustomer(lists.customerListsList)
            lists.customerListsList.map( async (list) => {
                const listOfProducts = await getDataFromApi(`ProductList/geProductsOfList?idListProduct=${list.idProductList}`, list.idProductList, [], headers)

                if(listOfProducts.status === 'success') {
                    productsOfList.current = [...productsOfList.current, ...listOfProducts.productosOfList] ;
                }
            } )
        }
    }

    const getCustomerProducts = async (customer) => {
        const lists = await getDataFromApi(`Customer/getCustomerProducts?idCustomer=${customer.value}`, customer.value, [], headers)
        if(lists.status === 'success') {
            setDataProductsCustomer(lists.customerProductList)
        }
    }

    const getDropdownData = async () => {
        await lavakleenApi.get('Customer/getCustomerList?filterIdStatusCustomer=1', {
            headers
        }).then( response => {
            let optionsArray = []
            response.data.customerList.map( option => {
                optionsArray = [...optionsArray, {value: option.idCustomer, label: `${option.sCompanyName}`, discount: option.dPercent, sEmail: option.sEmail}]
            } )
            setOptionsCustomers( optionsArray );
            clientsRef.current = optionsArray;
        } )

        await lavakleenApi.get('ServiceType/getServiceList', {
            headers
        }).then( response => {
            let optionsArray = []
            response.data.serviceTypeList.map( option => {
                if(option.sStatus === 'Activo')
                    optionsArray = [...optionsArray, {value: option.idServiceType, label: option.sServiceType}]
            } )
            setOptionsProductList( optionsArray );
            servicesListRef.current = optionsArray
        } )

        await lavakleenApi.get('Product/getProductList', {
            headers
        }).then( response => {
            let optionsArray = []
            response.data.productList.map( option => {
                optionsArray = [...optionsArray, {value: option.idProduct, label: option.sProductService, sServiceType: option.sServiceType}]
            } )
            productsDetailsRef.current = optionsArray
        } )

        let filter = ''
        if(role !== 1)
            filter = `&idCompany=${localStorage.getItem('idCompany')}`

        const chofers = await getDataFromApi(`User/getUsersList?idRol=6&idStatusUser=1${filter}`, 0, [], headers)
        if(chofers.status === 'success') {
            let optionsArray = []
            chofers.userList.map( option => {
                optionsArray = [...optionsArray, {value: option.idUser, label: `${option.sName}`}]
            } )
            setOptionsChofer( optionsArray )
            chofersRef.current = optionsArray
        }
    }

    const getClientAddresses = async (customer) => {
        const address = await getDataFromApi(`CustomerAddresses/getCustomerAddressesList?idCustomer=${customer.value}`, customer.value, [], headers)
        if(address.status === 'success') {
            let optionsArray = []
            address.listCustomerAddresses.map( option => {
                optionsArray = [...optionsArray, {value: option.idAddress, label: `${option.sAddressAlias} ${option.sBusinessAddress} ${option.sExtNumber} ${option.sIntNumber} ${option.sCity} ${option.sState}`}]
            } )
            setOptionsBillingAddress( optionsArray );
            billingAddressesRef.current = optionsArray
            setDiscount(customer.discount)
            if(idOrder == undefined) {
                orderDataRef.current.sNotificationMail = customer.sEmail
                setNotificationEmail(customer.sEmail)
            }
            
            // setOrderData(orderDataRef.current)
        }
    }

    const getData = async () => {
        await getDropdownData();

        // await getProductsDetails();
        

        const order = await getDataFromApi(`Order/findOrder?idOrder=${idOrder}`, idOrder, orderData, headers);
        if(order.status === 'success'){
            const customer = clientsRef.current.find( element => element.value === order.orderData.idCustomer )
            setSelectedOptionCustomer(customer)
            setDiscount(customer.discount)
            setInvalidCustomer(false)

            setSComments(order.orderData.sComments)

            discountRef.current = customer.discount

            await getClientAddresses(customer)
            await getCustomerProducts(customer)
            await getCustomerLists(customer)

            const address = billingAddressesRef.current.find( element => element.value === order.orderData.idCustomerAddress )

            setSelectedOptionBillingAddress(address)
            setInvalidBillingAddress(false)

            // await getProductsList(customer)
            const serviceType = servicesListRef.current.find( element => element.value === order.orderData.idServiceType )
            setSelectedOptionProductList(serviceType)

            const chofer = chofersRef.current.find(element => element.value === order.orderData.idDriver)
            setSelectedOptionChofer(chofer)
            setInvalidChofer(false)

            const orderStatus = optionsProcess.find(element => element.value === order.orderData.idOrderProcess)
            setSelectedOptionProcess(orderStatus)

            setDeliveryDate(new Date(order.orderData.dDeliveryDate))
            setCreationDate(new Date(order.orderData.dRegisterDate))

            await getOrderDetails(idOrder)
            await getOrderPayments(idOrder)
            await getProducts(serviceType)

            orderDataRef.current = {...orderDataRef.current, sNotificationMail: order.orderData.sNotificationMail, dTotalWeigth: order.orderData.dTotalWeight}
            setNotificationEmail(order.orderData.sNotificationMail)
            setOrderData(orderDataRef.current)
            
            calculateTotal(orderDetailsRef.current)
            setDTotalWeigth(order.orderData.dTotalWeight)
        }
    }

    const getOrderDetails = async(orderId) => {
        const details = await getDataFromApi(`/OrderDetail/getOrderDetailList?idOrder=${orderId}`, orderId, {}, headers)
        if(details.orderDetailList !== null) {
            let optionsArray = []
            details.orderDetailList.map( element => {
                optionsArray = [...optionsArray, {iQuantity: element.iQuantity, sProductService: element.sProductService, dSubtotal: element.dTotal + element.dDiscount, dPrice: element.dPrice, dTaxAmount: (((element.iQuantity * element.dPrice) - element.dDiscount) * (parseFloat(element.dTaxPercent) / 100)).toFixed(6), idOrderDetail: element.idOrderDetail, dDiscount: element.dDiscount, sComments: element.sComments, iKGWeight: 0, sCustomerMeasurement: element.sCustomerMeasurement}]
            } )
            
            setDataProductsOrder(optionsArray)
            orderDetailsRef.current = optionsArray
        }
    }

    const getOrderPayments = async(orderId) => {
        if(orderId === undefined)
            orderId = idOrder
        const details = await getDataFromApi(`Payment/getPaymentList?idOrder=${orderId}`, orderId, {}, headers)
        if(details.status === 'success') {
            
            
            setDataPaymentsOrder(details.paymentList)
        }
    }

    const getProducts = async (list) => {
        const products = productsDetailsRef.current.filter( element => element.sServiceType === list.label )
        let optionsArray = []

        dataProductsCustomer.map( productCustomer => {
            const product = products.find( element => element.value === productCustomer.idProductService )
            if(product !== undefined)
                optionsArray = [...optionsArray, {value: product.value, label: product.label}]
        } )

        productsOfList.current.map( productCustomer => {
            const product = optionsArray.find(element => element.value == productCustomer.idProduct)
            if(productCustomer.idServiceType == list.value && product == undefined)
                optionsArray = [...optionsArray, {value: productCustomer.idProduct, label: productCustomer.sProductName}]
        } )
        
        setOptionsProducts( optionsArray );
    }

    const editOrderDetail = async(row) => {
        console.log(row)
        if(idOrder === undefined) {
            setSelectedProduct({ value: row.idProduct, label: row.sProductService })
            setInvalidProduct(false)

            const tax = Taxes.find( element => element.value === row.dTaxPercent )
            setSelectedOptionTax(tax)
            setInvalidTax(false)

            setFormItemData(row)
            toggleModal('OrderDetail/editOrderDetail')
        } else {
            const detail = await getDataFromApi(`OrderDetail/findOrderDetail?idOrderDetail=${row.idOrderDetail}`, row.idOrderDetail, {}, headers);
            // orderDetailData
            if(detail.status === 'success') {
                setSelectedProduct({ value: detail.orderDetailData.idProduct, label: detail.orderDetailData.sProduct })
                setInvalidProduct(false)

                const tax = Taxes.find( element => element.value === detail.orderDetailData.dTaxPercen.toString() )
                setSelectedOptionTax(tax)
                setInvalidTax(false)

                setFormItemData({
                    idOrder: idOrder,
                    idProduct: detail.orderDetailData.idProduct,
                    iQuantity: detail.orderDetailData.iQuantity,
                    sComments: detail.orderDetailData.sComments,
                    dPrice: detail.orderDetailData.dPrice,
                    dTaxPercen: detail.orderDetailData.dTaxPercen.toString(),
                    id: detail.orderDetailData.idOrderDetail,
                    idOrderDetail: detail.orderDetailData.idOrderDetail,
                    iKGWeight: 0,
                    dDiscount: detail.orderDetailData.dDiscount,
                })

                toggleModal('OrderDetail/editOrderDetail')
            }
            
        }
    }

    const deleteOrderDetail = async (row) => {

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                if(idOrder === undefined) {
                    let tempArray = dataProductsOrder
                    const el = tempArray.findIndex(element => element === row.id)
                    tempArray.splice(el, 1)
    
                    setDataProductsOrder(tempArray)
                    orderDetailsRef.current = tempArray
                    calculateTotal(orderDetailsRef.current)
                } else {
                    dispatch( startProcessing() )
                    const result = await deleteDataFromApi(`OrderDetail/deleteOrderDetail?idOrderDetail=${row.idOrderDetail}`, headers)
                    if(result.sErrors.length === 0) {
                        setTimeout(() => {
                            Swal.fire({
                                title: 'Exito',
                                text: 'Concepto eliminado correctamente. Para terminar la actualización por favor da clic en "Actualizar" en la parte inferior derecha.',
                                icon: 'success',
                                willClose: async () => {
                                    await getOrderDetails(idOrder)
                                    calculateTotal(orderDetailsRef.current)
                                }
                            })
                        }, 200)
                    } else {
                        setTimeout(() => {
                            Swal.fire({
                                title: 'Error',
                                text: response.data.sErrors[0],
                                icon: 'error',
                                willClose: async () => {
                                    
                                }
                            })
                        }, 200)
                    }
                    dispatch( stopProcessing() )
                }
            }
        })
    }

    const deleteOrderPayment = async (row) => {
        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                dispatch( startProcessing() )
                const result = await deleteDataFromApi(`Payment/removePayment?idPayment=${row.idPayment}`, headers)
                if(result.sErrors.length === 0) {
                    setTimeout(() => {
                        Swal.fire({
                            title: 'Exito',
                            text: 'Pago eliminado correctamente.',
                            icon: 'success',
                            willClose: async () => {
                                await getOrderPayments(idOrder)
                            }
                        })
                    }, 200)
                } else {
                    setTimeout(() => {
                        Swal.fire({
                            title: 'Error',
                            text: response.data.sErrors[0],
                            icon: 'error',
                            willClose: async () => {
                                
                            }
                        })
                    }, 200)
                }
                dispatch( stopProcessing() )
            }
        })
    }

    const onFinishRegister = () => {
        setFinisRegistering(true)
    }

    const toggle = (tab) => {
        if(state.activeTab !== tab) {
            setState({
                ...state,
                activeTab: tab
            })
        }
    }

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const toggleModalPayment = (saveDataToEndpoint) => {
        setModalStatePayments({
            ...modalStatePayments,
            isOpen: !modalStatePayments.isOpen,
            saveDataToEndpoint,
        })
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Pedidos')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Pedidos'))
        if(userPerm.bCreate !== true && idOrder === undefined) {
            setCloseMessage(false)
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            navigate(`/pages/dashboard`)
            return
        }

        if(userPerm.bEdit !== true && idOrder !== undefined) {
            setCloseMessage(false)
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            navigate(`/pages/dashboard`)
            return
        }
        setCloseMessage(true)
        getData();
    }

    const onAddItemClick = () => {
        setFormItemData({
            idOrder: parseFloat(idOrder),
            idProduct: null,
            iQuantity: 1,
            sComments: '',
            dPrice: '',
            dTaxPercen: '',
            iKGWeight: 0,
            id: 0,
            dDiscount: 0,
        })
        setSelectedProduct(null)
        setInvalidProduct(true)
        setSelectedOptionTax(null)
        setInvalidTax(true)
        toggleModal('OrderDetail/addOrderDetail')
    }

    const onAddPaymentClick = () => {
        setFormPaymentData({
            idOrder: parseFloat(idOrder),
            idPaymentType: 0,
            dAmount: 0,
            dPaymentDate: new Date(),
            id: 0,
        })
        toggleModalPayment('Payment/addPayment')
    }

    const saveTemporalData = async (values) => {
        values.dTaxPercen = selectedOptionTax.value
        if(idOrder === undefined) {
            const tempArray = dataProductsOrder
            const num = orderDetailsRef.current.length
    
            setDataProductsOrder([...tempArray, {iQuantity: values.iQuantity, sProductService: values.sProductService, dPrice: values.dPrice, dTaxPercent: values.dTaxPercen, dSubtotal: (values.iQuantity * values.dPrice), dTaxAmount: ((values.iQuantity * values.dPrice) * (parseFloat(values.dTaxPercen) / 100)).toFixed(6), idOrder: 0, idProduct: values.idProduct, sComments: values.sComments, id: num + 1, dTaxPercen: values.dTaxPercen, dDiscount: values.dDiscount, iKGWeight: (values.iQuantity * values.iKGWeight), sCustomerMeasurement: values.sCustomerMeasurement, unitKGWeight: values.iKGWeight}])
            toggleModal('')
    
            orderDetailsRef.current.push({iQuantity: values.iQuantity, sProductService: values.sProductService, dPrice: values.dPrice, dTaxPercent: values.dTaxPercen, dSubtotal: (values.iQuantity * values.dPrice), dTaxAmount: (((values.iQuantity * values.dPrice) - values.dDiscount) * (parseFloat(values.dTaxPercen) / 100)).toFixed(6), idOrder: 0, idProduct: values.idProduct, sComments: values.sComments, id: num + 1, dTaxPercen: values.dTaxPercen, dDiscount: values.dDiscount, iKGWeight: (values.iQuantity * values.iKGWeight), sCustomerMeasurement: values.sCustomerMeasurement, unitKGWeight: values.iKGWeight})
    
            //calculate total
            calculateTotal(orderDetailsRef.current)
        } else {
            
            dispatch( startProcessing() )
            const alertConfig = {
                title: 'Exito',
                text: 'Concepto agregado corretamente. Para terminar la actualización por favor da clic en "Actualizar" en la parte inferior derecha.',
                icon: 'success',
                willClose: async () => {
                    await getOrderDetails(idOrder)
                    calculateTotal(orderDetailsRef.current)
                }
            };

            await saveData( 'OrderDetail/addOrderDetail', values, headers, alertConfig );

            dispatch( stopProcessing() )

            toggleModal('')
        }
        
    }

    const editTemporalData = async (values) => {
        if(idOrder === undefined) {
            const tempArray = dataProductsOrder.map( element => {
                if(element.id === values.id)
                    return {iQuantity: values.iQuantity, sProductService: values.sProductService, dPrice: values.dPrice, dTaxPercent: values.dTaxPercen, dSubtotal: (values.iQuantity * values.dPrice), dTaxAmount: (((values.iQuantity * values.dPrice) - values.dDiscount) * (parseFloat(values.dTaxPercen) / 100)).toFixed(6), idOrder: 0, idProduct: values.idProduct, sComments: values.sComments, id: values.id, dTaxPercen: values.dTaxPercen, dDiscount: values.dDiscount, iKGWeight: (values.iQuantity * values.unitKGWeight), sCustomerMeasurement: values.sCustomerMeasurement, unitKGWeight: values.unitKGWeight}
    
                return element
            } )
            
            setDataProductsOrder(tempArray)
            orderDetailsRef.current = tempArray
    
            calculateTotal(orderDetailsRef.current)
        } else {
            values.dTaxPercen = selectedOptionTax.value
            dispatch( startProcessing() )
            const alertConfig = {
                title: 'Exito',
                text: 'Concepto actualizado corretamente. Para terminar la actualización por favor da clic en "Actualizar" en la parte inferior derecha.',
                icon: 'success',
                willClose: async () => {
                    await getOrderDetails(idOrder)
                    calculateTotal(orderDetailsRef.current)
                }
            };

            await saveData( 'OrderDetail/editOrderDetail', values, headers, alertConfig );
            dispatch( stopProcessing() )
        }

        toggleModal('')
    }

    const calculateTotal = (items) => {
        let subTotal = 0
        let tax = 0
        let total = 0
        let amountDiscount = 0
        let totalWeight = 0
        items.map( item => {
            subTotal += parseFloat(item.dSubtotal)
            tax += parseFloat(item.dTaxAmount)
            amountDiscount += parseFloat(item.dDiscount)
            totalWeight += parseFloat(item.iKGWeight)
        } )

        total = subTotal - amountDiscount + tax

        orderData.dSubtotal = subTotal.toFixed(2)
        orderData.dDiscount = amountDiscount.toFixed(2)
        orderData.dTax = tax.toFixed(2)
        orderData.dTotal = total.toFixed(2)
        orderData.dTotalWeigth = totalWeight
        setDTotalWeigth(totalWeight)

        console.log(subTotal)
    }

    const calculateDiscount = (value) => {
        orderData.dDiscount = parseFloat(value)
    }

    const setItemData = async (item) => {
        const product = await getDataFromApi(`Product/findProduct?idProduct=${item.value}`, item.value, [], headers)
        if(product.status === 'success'){
            const tax = Taxes.find( element => element.value === product.productData.sTaxId )
            let dPrice = 0
            if(selectedOptionCurrency.value == 1)
                dPrice = product.productData.dMXNPrice
            if(selectedOptionCurrency.value == 2)
                dPrice = product.productData.dUSDPrice
            
            setFormItemData( {
                idOrder: parseFloat(idOrder),
                idProduct: product.productData.idProduct,
                iQuantity: formItemData.iQuantity,
                sComments: formItemData.sComments,
                dPrice: dPrice,
                dTaxPercen: product.productData.sTaxId,
                iKGWeight: product.productData.iKGWeight,
                id: 0,
                sProductService: product.productData.sProductService,
                sCustomerMeasurement: product.productData.sCustomerMeasurement,
                dDiscount: (formItemData.iQuantity * dPrice) * (discount / 100),
            } );

            setSelectedOptionTax(tax)
            setInvalidTax(false)
        }
    }

    const convertToInvoice = (idOrder) => {
        navigate(`/pages/new-invoice/${idOrder}`)
    }

    const onDiscountChange = (event) => {
        setFormItemData({...formItemData, iQuantity: event.target.value, dDiscount: (event.target.value * formItemData.dPrice) * (discount / 100)})
    }

    useEffect(() => {
        checkPermissions();
        
    }, [])

    useEffect(() => {
        if(finisRegistering == true) {
            navigate(`/pages/orders`)
        }
    }, [finisRegistering])
    

    useEffect(() => {
        if(isProcessing == true) {

            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }
        if(closeMessage == true)
            if(!isProcessing) Swal.close()
    }, [isProcessing, closeMessage])

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Crear/editar pedido"
                                subheading="Pantalla para creación/edición de pedidos"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <Form
                                initialValues={orderData}
                                onSubmit={ (values) => onSubmit(values) }
                            >
                                <Row>
                                    <Col md="12">
                                        <Card className="main-card mb-3">
                                            <CardBody>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Cliente</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCustomer}
                                                                options={optionsCustomers}
                                                                isClearable
                                                                name="idCustomerType"
                                                                key={ selectedOptionCustomer }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidCustomer ? 'is-invalid form-control' : '' }
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidCustomer(true) : setInvalidCustomer(false)
                                                                    setSelectedOptionCustomer(option)
                                                                    if(option){
                                                                        // getProducts(option)
                                                                        getClientAddresses(option)
                                                                        getCustomerProducts(option)
                                                                        getCustomerLists(option)
                                                                    }
                                                                }}
                                                                isDisabled={ idOrder !== undefined ? true : false }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="2">
                                                        { 
                                                            idOrder !== undefined
                                                            ? 
                                                            <FormGroup>
                                                                <Label for="idCustomerType">Fecha registro</Label>
                                                                <DatePicker
                                                                    className="form-control"
                                                                    name="dCreationDate"
                                                                    dateFormat="yyyy-MM-dd"
                                                                    selected={ creationDate }
                                                                    onChange={(date) => setCreationDate(date)}
                                                                    minDate={new Date()}
                                                                />
                                                            </FormGroup>
                                                            : "" 
                                                        
                                                        }
                                                    </Col>
                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Fecha entrega</Label>
                                                            <DatePicker
                                                                className="form-control"
                                                                name="dDeliveryDate"
                                                                dateFormat="yyyy-MM-dd"
                                                                selected={ deliveryDate }
                                                                onChange={(date) => setDeliveryDate(date)}
                                                                minDate={new Date()}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {
                                                        idOrder !== undefined
                                                        ? (
                                                            <Col md="2">
                                                                <FormGroup>
                                                                    <Label for="idCustomerType">No. Pedido</Label>
                                                                    <Input type="text" name="idOrder" placeholder="Pedido" disabled value={idOrder}/>
                                                                </FormGroup>
                                                            </Col>
                                                        )
                                                        : ''
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Moneda</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCurrency}
                                                                options={optionsCurrency}
                                                                name="idCustomerType"
                                                                key={ selectedOptionCurrency }
                                                                placeholder="Selecciona una opción"
                                                                isDisabled={ idOrder !== undefined ? true : false }
                                                                onChange={ (option) => {
                                                                    setSelectedOptionCurrency(option)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Tipo de servicio</Label>
                                                            <Select
                                                                defaultValue={selectedOptionProductList}
                                                                options={optionsProductList}
                                                                name="idCustomerType"
                                                                key={ selectedOptionProductList }
                                                                placeholder="Selecciona una opción"
                                                                onChange={ (option) => {
                                                                    setSelectedOptionProductList(option)
                                                                    if(option){
                                                                        getProducts(option)
                                                                    }
                                                                }}
                                                                isDisabled={ idOrder !== undefined ? true : false }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Proceso</Label>
                                                            <Select
                                                                defaultValue={selectedOptionProcess}
                                                                options={optionsProcess}
                                                                name="idCustomerType"
                                                                key={ selectedOptionProcess }
                                                                placeholder="Selecciona una opción"
                                                                onChange={ (option) => {
                                                                    setSelectedOptionProcess(option)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Noficiar a</Label>
                                                            <Input type="email" name="sNotificationMail" placeholder="Email..." value={notificationEmail}
                                                            onChange={ (value) => setNotificationEmail(value.target.value) }/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup>
                                                        <Label for="idCustomerType">Chofer</Label>
                                                        <Select
                                                            defaultValue={selectedOptionChofer}
                                                            options={optionsChofer}
                                                            isClearable
                                                            name="idCustomerType"
                                                            key={ selectedOptionChofer }
                                                            placeholder="Selecciona una opción"
                                                            className={ invalidChofer ? 'is-invalid form-control' : '' }
                                                            onChange={ (option) => {
                                                                option == null ? setInvalidChofer(true) : setInvalidChofer(false)
                                                                setSelectedOptionChofer(option)
                                                            }}
                                                        />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="3">
                                                        <FormGroup>
                                                            <Label for="dTotalWeigth">Peso total (KG)</Label>
                                                            <Input type="number" name="dTotalWeigth" placeholder="Peso" value={ dTotalWeigth } disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <Card className="main-card mb-3 mt-3">
                                                            <CardHeader>
                                                                <Nav justified>
                                                                    <TabHeaderComponent id="items" label="Conceptos de venta" state={state} toggle={ toggle }/>
                                                                    {
                                                                        idOrder !== undefined
                                                                        ? <TabHeaderComponent id="payments" label="Pagos" state={state} toggle={ toggle }/>
                                                                        : ''
                                                                    }
                                                                    
                                                                </Nav>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <TabContent activeTab={ state.activeTab }>
                                                                    <TabPane tabId="items">
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                                                                    <Button
                                                                                        className="mb-2 me-2"
                                                                                        color="primary"
                                                                                        onClick={ onAddItemClick }
                                                                                    >
                                                                                        Agregar
                                                                                    </Button>
                                                                                </ul>
                                                                            </Col>
                                                                            <Col md="12">
                                                                                <DataTable data={dataProductsOrder}
                                                                                    columns={columnsProductsList}
                                                                                    pagination
                                                                                    fixedHeader
                                                                                    fixedHeaderScrollHeight="500px"
                                                                                />
                                                                            </Col>
                                                                            <ModalComponent
                                                                                isOpen={modalState.isOpen}
                                                                                toggle={ toggleModal }
                                                                                backdrop={ modalState.backDrop } modalTitle="Agregar/Editar concepto"
                                                                                size="md"
                                                                                initialValues={ formItemData }
                                                                                validationSchema={ {
                                                                                    iQuantity: yup.string().required(),
                                                                                    dPrice: yup.string().required()
                                                                                } }
                                                                                saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                                                                alertMessages={ { addMessage: 'Concepto agregado exitosamente', updateMessage: 'Concepto actualizado exitosamente' } }
                                                                                getDataFunction={ getClientAddresses }
                                                                                dropDownsToValidate={ [  ] }
                                                                                saveTemporalData={ saveTemporalData }
                                                                                editTemporalData= { editTemporalData }
                                                                            >
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="iQuantity">Cantidad</Label>
                                                                                            <Input type="number" name="iQuantity" placeholder="" onChange={ onDiscountChange }/>
                                                                                        </FormGroup>
                                                                                        {/* <FormGroup className="mb-2">
                                                                                            <Label for="sUnit">Unidad</Label>
                                                                                            <Input type="text" name="sUnit" placeholder=""/>
                                                                                        </FormGroup> */}
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="idCustomerType">Servicio/Producto</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedProduct}
                                                                                                options={optionsProducts}
                                                                                                isClearable
                                                                                                name="idProduct"
                                                                                                key={ selectedProduct }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidProduct ? 'is-invalid form-control' : '' }
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidProduct(true) : setInvalidProduct(false)
                                                                                                    setSelectedProduct(option)

                                                                                                    if(option)
                                                                                                        setItemData(option)
                                                                                                }}
                                                                                            />
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="dPrice">Precio</Label>
                                                                                            <Input type="number" name="dPrice" placeholder=""/>
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="dTaxPercen">I.V.A</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedOptionTax}
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidTax(true) : setInvalidTax(false)
                                                                                                    setSelectedOptionTax(option)
                                                                                                }}
                                                                                                options={optionsTaxes}
                                                                                                name="dTaxPercen"
                                                                                                key={ selectedOptionTax }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidTax ? 'is-invalid form-control' : '' }
                                                                                            />
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="idCustomerType">Comentarios</Label>
                                                                                            <Input type="text" name="sComments" placeholder=""/>
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="dDiscount">Descuento { discount } %</Label>
                                                                                            <Input type="number" name="dDiscount" placeholder=""/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                            </ModalComponent>
                                                                        </Row>
                                                                    </TabPane>
                                                                    <TabPane tabId="payments">
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                                                                    <Button
                                                                                        className="mb-2 me-2"
                                                                                        color="primary"
                                                                                        onClick={ onAddPaymentClick }
                                                                                    >
                                                                                        Agregar
                                                                                    </Button>
                                                                                </ul>
                                                                            </Col>
                                                                            <Col md="12">
                                                                                <DataTable data={dataPaymentsOrder}
                                                                                    columns={columnsPaymentsList}
                                                                                    pagination
                                                                                    fixedHeader
                                                                                    fixedHeaderScrollHeight="500px"
                                                                                />
                                                                            </Col>
                                                                            <ModalComponent
                                                                                isOpen={modalStatePayments.isOpen}
                                                                                toggle={ toggleModalPayment }
                                                                                backdrop={ modalStatePayments.backDrop } modalTitle="Agregar Pago"
                                                                                size="md"
                                                                                initialValues={ formPaymentData }
                                                                                validationSchema={ {
                                                                                } }
                                                                                saveDataToEndpoint={ modalStatePayments.saveDataToEndpoint }
                                                                                alertMessages={ { addMessage: 'Pago agregado exitosamente', updateMessage: 'Pago actualizado exitosamente' } }
                                                                                getDataFunction={ getOrderPayments }
                                                                                dropDownsToValidate={ [ { field: 'idPaymentType', value: selectedPaymentForm?.value} ] }
                                                                                saveTemporalData={ saveTemporalData }
                                                                                editTemporalData= { editTemporalData }
                                                                            >
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="idCustomerType">Forma de pago</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedPaymentForm}
                                                                                                options={optionsPaymentsForm}
                                                                                                isClearable
                                                                                                name="idProduct"
                                                                                                key={ selectedPaymentForm }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidPaymentForm ? 'is-invalid form-control' : '' }
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidPaymentForm(true) : setInvalidPaymentForm(false)
                                                                                                    setSelectedPaymentForm(option)
                                                                                                }}
                                                                                            />
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="dAmount">Monto</Label>
                                                                                            <Input type="number" name="dAmount" placeholder=""/>
                                                                                        </FormGroup>
                                                                                        <FormGroup>
                                                                                            <Label for="idCustomerType">Fecha de pago</Label>
                                                                                            <DatePicker
                                                                                                className="form-control"
                                                                                                name="dPaymentDate"
                                                                                                dateFormat="yyyy-MM-dd"
                                                                                                selected={ paymentDate }
                                                                                                onChange={(date) => setPaymentDate(date)}
                                                                                                maxDate={new Date()}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                            </ModalComponent>
                                                                        </Row>
                                                                    </TabPane>
                                                                </TabContent>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="8">
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Dirección de entrega</Label>
                                                            <Select
                                                                defaultValue={selectedOptionBillingAddress}
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidBillingAddress(true) : setInvalidBillingAddress(false)
                                                                    setSelectedOptionBillingAddress(option)
                                                                }}
                                                                options={optionsBillingAddress}
                                                                name="idState"
                                                                key={ selectedOptionBillingAddress }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidBillingAddress ? 'is-invalid form-control' : '' }
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Comentarios</Label>
                                                            <Input type="textarea" name="sComments" value={ sComments } onChange={ (event) => setSComments(event.target.value) } />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup className="mb-3">
                                                            <Label for="discount">% Descuento</Label>
                                                            <Input type="number" name="discount"
                                                                onChange={ (event) => {
                                                                    calculateDiscount(event.target.value)
                                                                } }
                                                                value={ discount }
                                                                readOnly
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Subtotal</Label>
                                                            <Input type="number" name="dSubtotal" readOnly/>
                                                        </FormGroup>
                                                        <FormGroup className="mb-3">
                                                            <Label for="dDiscount">Descuento</Label>
                                                            <Input type="number" name="dDiscount" readOnly/>
                                                        </FormGroup>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">I.V.A</Label>
                                                            <Input type="number" name="dTax" readOnly/>
                                                        </FormGroup>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Total</Label>
                                                            <Input type="number" name="dTotal" readOnly/>
                                                        </FormGroup>
                                                        <FormGroup className="mb-3" style={ { 'textAlign': 'end' } }>
                                                            <Link 
                                                                className="mt-2 btn btn-danger"
                                                                to="/pages/orders"
                                                            >
                                                                Cancelar
                                                            </Link>{ "  " }
                                                            {
                                                                (idOrder != undefined && selectedOptionProcess && selectedOptionProcess.value == 6)
                                                                ? <Button color="success" className="mt-2" onClick={ () => convertToInvoice(idOrder) }>Facturar</Button>
                                                                : ''
                                                            }
                                                            { "  " }
                                                            <Button color="primary" className="mt-2 mr-2" type="submit">
                                                                {
                                                                    (idOrder === undefined)
                                                                    ? 'Guardar'
                                                                    : 'Actualizar'
                                                                }
                                                            </Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form> 
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

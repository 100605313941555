import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, FormGroup, Label } from "reactstrap";
import { Input } from '@availity/form';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageTitle } from '../../layout/components/PageTitle';
import { useEffect, useImperativeHandle, useState } from 'react';
import { lavakleenApi } from '../../api/lavakleenApi';
import { ModalComponent } from '../components/ModalComponent';
import { forwardRef } from 'react';
import * as yup from "yup";
import { convertArrayOfObjectsToCSV, filterDataFromApi, getDataFromApi, saveData, baseUrl } from '../../api/providers/providers';
import { faCheckCircle, faClock, faCloudUpload, faDollarSign, faDolly, faMoneyBill, faTruck, faUpload, faWrench } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { startProcessing, stopProcessing } from '../../store/auth/authSlice';
import { cfdiCancelationMotives } from '../providers/salesProviders';
import Select from "react-select";

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany'),
};

export const PaymentsPage = forwardRef( (props, ref) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useImperativeHandle( ref, () => ({
        async filterData(values){
            dispatch( startProcessing() )
            const result = await filterDataFromApi('Pay/getPayList', values, headers);
            setData(result.payListData)
            dispatch( stopProcessing() )
        },

        async restore() {
            await getPayments()
        }
    }) )

    const { id, isProcessing } = useSelector( state => state.auth )

    const [data, setData] = useState([]);
    const [optionsCancelation, setOptionsCancelation] = useState(cfdiCancelationMotives);
    const [dataSendInvoice, setDataSendInvoice] = useState({
        sEmail: '',
        idInvoice: 0,
    });
    const [dataCancelInvoice, setDataCancelInvoice] = useState({
        idInvoice: 0,
        sReason: '',
    });
    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)
    const [selectedCancelationMotive, setSelectedCancelationMotive] = useState(null);

    const [invalidCancelationMotive, setInvalidCancelationMotive] = useState(true);
    const [closeMessage, setCloseMessage] = useState(false);

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
    })

    const [modalStateCancelation, setModalStateCancelation] = useState({
        isOpen: false,
        backDrop: true,
    })

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const toggleModalCancelation = (saveDataToEndpoint) => {
        setModalStateCancelation({
            ...modalStateCancelation,
            isOpen: !modalStateCancelation.isOpen,
            saveDataToEndpoint,
        })
    }

    const columns = [
        {
            name: "Opciones",
            cell: (row) => (
                <>
                    {
                        row.bIsTibrada
                        ? ' '
                        : <>
                            {/* <Link to={ `/pages/edit-invoice/${row.idPrePayment}` } className="btn btn-primary" title="Editar">
                                <i className="lnr-pencil"></i>
                            </Link> */}
                            {/* <Button color="secondary" onClick={ () => sendPreInvoice(row) } title="Enviar prefactura por correo"><i className="lnr-envelope"></i></Button> */}
                            <Button color="danger" onClick={ () => deletePrePayment(row.idPrePayment) } title="Eliminar"><i className="lnr-trash"></i></Button>
                            <Button color="warning" onClick={ () => sendInvoiceToSat(row.idPrePayment) } title="Timbrar complemento"><i className="lnr-code"></i></Button>
                        </>
                    }
                    
                    {
                        row.bIsTibrada
                        ? <>
                            <Button color="primary" onClick={ () => sendInvoice(row) } title="Enviar por correo"><i className="lnr-envelope"></i></Button>
                            <Button color="success" onClick={ () => downloadInvoice(row.invoiceData.idInvoice) } title="Descargar archivos"><i className="lnr-cloud-download"></i></Button>
                            <Button color="danger" onClick={ () => cancelInvoice(row.invoiceData.idInvoice) } title="Cancelar complemento"><i className="lnr-cross"></i></Button>
                        </>
                        : ' '
                    }
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            button: true,
            width: '200px'
        },
        {
            name: "Pre comp.",
            selector: row => row.idPrePayment,
            sortable: true,
            width: '110px',
        },
        {
            name: "Folio",
            selector: row => row.invoiceData.idInvoice,
            sortable: true,
            width: '80px',
        },
        {
            name: "Serie",
            selector: row => row.invoiceData.sSerie,
            sortable: true,
            width: '80px',
        },
        {
            name: "Razón social o cliente",
            selector: row => row.invoiceData.sBusinessName,
            sortable: true,
            width: '300px',
            wrap: true,
        },
        {
            name: "Fecha alta",
            selector: row => row.invoiceData.dRegisterDate.substring(0, 10),
            sortable: true,
            width: '110px',
        },
        {
            name: "Subtotal",
            selector: row => row.invoiceData.dSubtotal.toFixed(2),
            sortable: false,
        },
        {
            name: "IVA",
            selector: row => row.invoiceData.dIva.toFixed(2),
            sortable: false,
        },
        {
            name: "Total",
            selector: row => row.invoiceData.dTotal.toFixed(2),
            sortable: false,
        },
        {
            name: "Tipo CFDI",
            selector: row => row.invoiceData.sReceiptType,
            sortable: false,
            width: '190px',
        },
        {
            name: "Folio Fiscal",
            selector: row => row.invoiceData.sFolioFiscal,
            sortable: false,
            width: '280px',
        },
        {
            name: "Estatus",
            selector: row => row.invoiceData.sStatusSistema,
            sortable: false,
        },
        {
            name: "Estatus SAT",
            selector: row => row.invoiceData.sEstatus,
            sortable: false,
        },
        // {
        //     name: "Opciones",
        //     cell: (row) => (
        //         <>
        //             <Button color="primary" onClick={ () => sendInvoice(row) } title="Enviar por correo"><i className="lnr-envelope"></i></Button>
        //             <Button color="success" onClick={ () => downloadInvoice(row.invoiceData.idInvoice) } title="Descargar archivos"><i className="lnr-cloud-download"></i></Button>
        //             <Button color="danger" onClick={ () => cancelInvoice(row.invoiceData.idInvoice) } title="Cancelar factura"><i className="lnr-cross"></i></Button>
        //         </>
        //     ),
        //     sortable: false,
        //     ignoreRowClick: true,
        //     button: true,
        //     width: '150px'
        // },
        // {
        //     name: "Folio",
        //     selector: row => row.invoiceData.idInvoice,
        //     sortable: true,
        //     width: '80px',
        // },
        // {
        //     name: "Serie",
        //     selector: row => row.invoiceData.sSerie,
        //     sortable: true,
        //     width: '80px',
        // },
        // {
        //     name: "Razón social o cliente",
        //     selector: row => row.invoiceData.sBusinessName,
        //     sortable: true,
        //     width: '300px',
        //     wrap: true,
        // },
        
        // {
        //     name: "Fecha alta",
        //     selector: row => row.invoiceData.dRegisterDate,
        //     sortable: true,
        //     width: '190px',
        // },
        // {
        //     name: "Subtotal",
        //     selector: row => (row.invoiceData.dTotal - row.invoiceData.dIva).toFixed(2),
        //     sortable: false,
        // },
        // {
        //     name: "Descuento",
        //     selector: row => row.invoiceData.dDescuento.toFixed(2),
        //     sortable: false,
        // },
        // {
        //     name: "IVA",
        //     selector: row => row.invoiceData.dIva.toFixed(2),
        //     sortable: false,
        // },
        // {
        //     name: "Total",
        //     selector: row => row.invoiceData.dTotal.toFixed(2),
        //     sortable: false,
        // },
        // {
        //     name: "Tipo CFDI",
        //     selector: row => row.invoiceData.sReceiptType,
        //     sortable: false,
        //     width: '190px',
        // },
        // {
        //     name: "Folio Fiscal",
        //     selector: row => row.invoiceData.sFolioFiscal,
        //     sortable: false,
        //     width: '280px',
        // },
        // {
        //     name: "Estatus",
        //     selector: row => row.invoiceData.sStatusSistema,
        //     sortable: false,
        // },
    ];

    const columnsHistory = [
        {
            name: "Estatus",
            selector: row => row.sOrderProcess,
            cell: (row) => (
                <>
                    {
                        (row.idOrderProcess == 1)
                        ? <FontAwesomeIcon icon={faCloudUpload} size="2x" color='gray'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 2)
                        ? <FontAwesomeIcon icon={faClock} size="2x" color='red'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 3)
                        ? <FontAwesomeIcon icon={faWrench} size="2x" color='black'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 4)
                        ? <FontAwesomeIcon icon={faDolly} size="2x" color='orange'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 5)
                        ? <FontAwesomeIcon icon={faTruck} size="2x" color='blue'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 6)
                        ? <FontAwesomeIcon icon={faCheckCircle} size="2x" color='green'/> 
                        : ''
                    }
                    {row.sOrderProcess}
                </>
            ),
            sortable: false,
            width: '200px',
        },
        {
            name: "Usuario",
            selector: row => row.usuario,
            sortable: true,
        },
        {
            name: "Fecha",
            selector: row => row.dRegisterDate,
            sortable: true,
            width: '180px',
        },
    ];

    const onAddClick = () => {
        navigate('/pages/new-payment', {})
    }

    const getPayments = async () => {
        dispatch( startProcessing() )
        await lavakleenApi.get('Pay/getPayList', {
            headers
        }).then( response => {
            setData( response.data.payListData );
        } )
        dispatch( stopProcessing() )
    }

    const deletePrePayment = (idPrePayment) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Complementos de pago')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }
        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                await lavakleenApi.post(`Pay/removePrePayment?idPrePayment=${idPrePayment}`, {}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                        getPayments()
                    else {
                        Swal.fire({
                            title: 'Error',
                            text: response.data.sErrors[0],
                            icon: 'error',
                        })
                    }
                } )
            }
        })
    }

    const sendInvoice = async (row) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Complementos de pago')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        setDataSendInvoice({
            sEmail: row.sEmail,
            idInvoice: row.invoiceData.idInvoice,
            id: 0,
        });
        
        toggleModal('Invoice/sendEmail')
    }

    const cancelInvoice = async (idInvoice) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Complementos de pago')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }
        
        setDataCancelInvoice({
            idInvoice,
            sReason: '',
            id: 0,
        });
        
        toggleModalCancelation('Pay/cancelPay')
    }

    const downloadInvoice = async (idInvoice) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Complementos de pago')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        dispatch( startProcessing() )

        await lavakleenApi.get(`Invoice/getDownloadLinks?idInvoice=${idInvoice}`, {
            headers
        }).then( response => {
            if(response.data.sErrors.length == 0) {
                if(response.data.sPFDLink !== '') {
                    const downloadPdf = document.createElement("a")
                    downloadPdf.href = `${baseUrl}${response.data.sPFDLink}`
                    downloadPdf.download = `Factura_${idInvoice}.pdf`
                    downloadPdf.target= '_blank'
                    document.body.appendChild(downloadPdf)
                    downloadPdf.click()
                    downloadPdf.remove()
                }

                if(response.data.sXMLLink !== '') {
                    const downloadXml = document.createElement("a")
                    downloadXml.href = `${baseUrl}${response.data.sXMLLink}`
                    downloadXml.download = `Factura_${idInvoice}.xml`
                    downloadXml.target= '_blank'
                    document.body.appendChild(downloadXml)
                    downloadXml.click()
                    downloadXml.remove();
                }
            }
        } )

        dispatch( stopProcessing() )
    }

    const sendInvoiceToSat = async (idPrePayment) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Complementos de pago')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }
        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                dispatch( startProcessing() )
                await lavakleenApi.post(`Pay/createPay?idPrePayment=${idPrePayment}`, {}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0) {
                        dispatch( stopProcessing() )
                        setTimeout(() => {
                            Swal.fire({
                                title: 'Exito',
                                text: 'Factura timbrada correctamente.',
                                icon: 'success',
                            })

                            getPayments()
                            
                        }, 200);
                    }
                    else {
                        dispatch( stopProcessing() )
                        setTimeout(() => {
                            Swal.fire({
                                title: 'Error',
                                text: response.data.sErrors[0],
                                icon: 'error',
                            })
                        }, 200)
                    }
                } )
            }
        })
    }

    const nothingToDo = () => {
        
    }

    const printTicket = (idOrder) => {
        window.open(`/ticket-order/${idOrder}`, '_blank')
    }

    const printTicketRma = (idOrder) => {
        window.open(`/ticket-order-rma/${idOrder}`, '_blank')
    }

    const sendTicket = async (idOrder) => {
        dispatch( startProcessing() )
        await lavakleenApi.post(`Order/sendEmail?idOrder=${idOrder}`, {}, {
            headers
        }).then( response => {
            if(response.data.bResult == true && response.data.sErrors.length == 0) {
                setTimeout(() => {
                    Swal.fire({
                        title: 'Exito',
                        text: 'Ticket enviado correctamente',
                        icon: 'success',
                    })
                }, 200);
            }
            else {
                setTimeout(() => {
                    Swal.fire({
                        title: 'Error',
                        text: response.data.sErrors[0],
                        icon: 'error',
                    })
                }, 200);
            }
        } )

        dispatch( stopProcessing() )
    }

    const changeOrderProcess = async (idOrder, status) => {

        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Complementos de pago')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const alertConfig = {
                    title: 'Exito',
                    text: 'Estatus actualizado exitosamente.',
                    icon: 'success',
                    willClose: () => {
                        getPayments()
                    }
                }
                const result = await saveData(`Order/changeProcessStatus`, { idOrder, idOrderProcess: status, idRegisterUser: id }, headers, alertConfig)
            }
        })
    }

    const activeInactiveOrder = async (order) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Complementos de pago')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const bBlock = order.sStatus === 'Activo' ? true : false
                await lavakleenApi.post(`Order/blockUnblockOrder`, {idOrder: order.idOrder, bBlock}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                        getPayments()
                } )
            }
        })
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Complementos de pago')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Complementos de pago'))
        
        if(userPerm.bList !== true) {
            setCloseMessage(false)
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })

            navigate(`/pages/dashboard`)
        }
        setCloseMessage(true)
        getPayments()
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        const filename = 'complementos_pago.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    useEffect(() => {
        checkPermissions();
    }, [])

    useEffect(() => {
        if(isProcessing == true) {

            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }

        if(closeMessage == true)
            if(!isProcessing) Swal.close()
    }, [isProcessing, closeMessage])
    

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Complementos de pago"
                                subheading="Gestion de complementos de pago para tu negocio"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                {
                                    permission !== null && permission.bCreate === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="primary"
                                                onClick={ onAddClick }
                                            >
                                                Agregar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }

                                {
                                    permission !== null && permission.bExport === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="danger"
                                                onClick={ () => downloadCsv()}
                                            >
                                                Exportar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }
                            </ul>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                        <DataTable data={data}
                                            columns={columns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight="500px"
                                        />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <ModalComponent
                                    isOpen={modalState.isOpen}
                                    toggle={ toggleModal }
                                    backdrop={ modalState.backDrop }
                                    modalTitle={ 'Enviar factura' }
                                    size="md"
                                    initialValues={ dataSendInvoice }
                                    validationSchema={ {
                                        sEmail: yup.string().required(),
                                    } }
                                    saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                    alertMessages={ { } }
                                    getDataFunction={ nothingToDo }
                                    dropDownsToValidate={ [ ] }
                                    showSaveButton={true}
                                    textSaveButton="Enviar"
                                >
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup className="mb-2">
                                                <Label for="sEmail">Email</Label>
                                                <Input type="text" name="sEmail" placeholder="Captura email para enviar la factura"/>
                                            </FormGroup>
                                        </Col>
                                        
                                    </Row>
                                </ModalComponent>
                                <ModalComponent
                                    isOpen={modalStateCancelation.isOpen}
                                    toggle={ toggleModalCancelation }
                                    backdrop={ modalStateCancelation.backDrop }
                                    modalTitle={ 'Cancelar factura' }
                                    size="md"
                                    initialValues={ dataCancelInvoice }
                                    validationSchema={ {} }
                                    saveDataToEndpoint={ modalStateCancelation.saveDataToEndpoint }
                                    alertMessages={ { } }
                                    getDataFunction={ nothingToDo }
                                    dropDownsToValidate={ [ 
                                        { field: 'sReason', value: selectedCancelationMotive?.value }
                                    ] }
                                    showSaveButton={true}
                                    textSaveButton="Cancelar factura"
                                >
                                    <Row>
                                        <Col md={12}>
                                        <Select
                                            defaultValue={selectedCancelationMotive}
                                            options={optionsCancelation}
                                            name="idProduct"
                                            key={ selectedCancelationMotive }
                                            placeholder="Selecciona una opción"
                                            className={ invalidCancelationMotive ? 'is-invalid form-control' : '' }
                                            onChange={ (option) => {
                                                option == null ? setInvalidCancelationMotive(true) : setInvalidCancelationMotive(false)
                                                setSelectedCancelationMotive(option)
                                            }}
                                        />
                                        </Col>
                                    </Row>
                                </ModalComponent>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
})
import { useCallback, useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Form, Input } from '@availity/form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Label, TabContent, TabPane, CardHeader, Nav, NavItem, NavLink } from "reactstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import {QRCodeSVG} from 'qrcode.react';
import { lavakleenApi } from '../../api/lavakleenApi';
import { convertArrayOfObjectsToCSV, deleteDataFromApi, getCompanyById, getDataFromApi, saveData } from '../../api/providers/providers';
import { PageTitle } from '../../layout/components/PageTitle';
import { startProcessing, stopProcessing } from '../../store/auth/authSlice';
import { TabHeaderComponent } from '../components/TabHeaderComponent';
import { columnsAddress } from '../providers/clientsProviders';
import { ModalComponent } from '../components/ModalComponent';
import { columnsProductsListToAssign, columnsProductsListAssignedClient } from '../providers/inventoryProviders';

const formData = {
    idCustomer: 0,
    idBranchOffice: 1,
    idCustomerType: null,
    sBusinessName: '',
    sEmail: '',
    dPercent: 0,
    sContactName: '',
    sPhoneNumber: '',
    sCellNumber: '',
    sContactEmail: '',
    sAlternativeContactEmail: '',
    sZone: '',
    sExternalID: '',
    idCustomerStatus: null,
    sBusinessNameBilling: '',
    idTaxRegime: null,
    sRfc: '',
    idCustomerAddress: null,
    sCustomerGUID: '',
    dCreationDate: '',
    sCreateUser: '',
};

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const ClientPage = () => {

    const {idCustomer} = useParams();

    const listAssignedRef = useRef(null);
    const productsAssignedRef = useRef(null);

    const { id } = useSelector( state => state.auth );

    const [state, setState] = useState({
        activeTab: 'details'
    })

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
    })

    const [formAddressData, setFormAddressData] = useState({
        idAddress: 0,
        idCustomer: parseFloat(idCustomer),
        idState: null,
        idCity: null,
        sAddressAlias: '',
        sBusinessAddress: '',
        sIntNumber: '',
        sExtNumber: '',
        sColony: '',
        sZipCode: '',
        sReferences: '',
        sPrincipalPhone: '',
        sCellPhone: '',
        id: 0,
    });

    const [columnsAddresses, setColumnsAddresses] = useState([...columnsAddress, {
        name: "Opciones",
        cell: (row) => (
            <>
                <Button onClick={ () => editRow(row.idAddress) } color="primary">
                    <i className="lnr-pencil"></i>
                </Button>
                <Button color="danger" onClick={ () => deleteRow(row.idAddress) }><i className="lnr-trash"></i></Button>
            </>
        ),
        sortable: false,
        ignoreRowClick: true,
        button: true,
    }]);

    const [columnsProductsList, setColumnsProductsList] = useState([
        {
            name: "ID",
            selector: row => row.idListProduct,
            sortable: true,
            width: '80px',
        },
        {
            name: "Nombre lista",
            selector: row => row.sListName,
            sortable: true,
        },
    ]);

    const [columnsProducts, setColumnsProducts] = useState([...columnsProductsListToAssign]);

    const [columnsProductsListCustomer, setColumnsProductsListCustomer] = useState([
        {
            name: "ID",
            selector: row => row.idProductList,
            sortable: true,
            width: '80px',
        },
        {
            name: "Nombre lista",
            selector: row => row.sListName,
            sortable: true,
        },
    ]);

    const [columnsProductsCustomer, setColumnsProductsCustomer] = useState([...columnsProductsListAssignedClient]);

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionTaxRegime, setSelectedOptionTaxRegime] = useState(null);
    const [selectedOptionState, setSelectedOptionState] = useState(null);
    const [selectedOptionCity, setSelectedOptionCity] = useState(null);
    const [selectedOptionClientType, setSelectedOptionClientType] = useState(null);
    const [selectedOptionClientStatus, setSelectedOptionClientStatus] = useState(null);
    const [selectedOptionBillingAddress, setSelectedOptionBillingAddress] = useState(null);
    const [clientData, setClientData] = useState(formData);
    const [idCustomerRegister, setIdCustomerRegister] = useState(idCustomer);

    const [optionsTaxRegime, setOptionsTaxRegime] = useState([]);
    const [optionsState, setOptionsState] = useState([]);
    const [optionsCities, setOptionsCities] = useState([]);
    const [optionsClientTypes, setOptionsClientTypes] = useState([]);
    const [optionsClientStatus, setOptionsClientStatus] = useState([]);
    const [finisRegistering, setFinisRegistering] = useState(false);
    const [optionsBillingAddress, setOptionsBillingAddress] = useState([]);

    //validators
    const [invalidCustomerType, setInvalidCustomerType] = useState(true);
    const [invalidClientStatus, setInvalidClientStatus] = useState(true);
    const [invalidState, setInvalidState] = useState(true);
    const [invalidCity, setInvalidCity] = useState(true);
    const [invalidBillingAddress, setInvalidBillingAddress] = useState(true);
    const [invalidTaxRegime, setInvalidTaxRegime] = useState(true);
    const [hasBillingData, setHasBillingData] = useState(false);

    //datatables
    const [dataAddress, setDataAddress] = useState([]);
    const [dataProductsList, setDataProductsList] = useState([]);
    const [dataProducts, setDataProducts] = useState([]);
    const [dataProductsListCustomer, setDataProductsListCustomer] = useState([]);
    const [dataProductsCustomer, setDataProductsCustomer] = useState([]);

    const [customerProductListsList, setCustomerProductListsList] = useState([]);
    const [customerProducts, setCustomerProducts] = useState([]);

    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async (values) => {

        if(state.activeTab === 'details') {
            selectedOptionClientType == null ? setInvalidCustomerType(true) : setInvalidCustomerType(false)
            selectedOptionClientStatus == null ? setInvalidClientStatus(true) : setInvalidClientStatus(false)
            if(invalidClientStatus || invalidCustomerType)
                return
            
            values.idCustomerStatus = selectedOptionClientStatus.value
            values.idCustomerType = selectedOptionClientType.value
            values.idCreateUser = id

            if(idCustomer !== undefined)
                values.idCustomer = parseFloat(idCustomer);

            dispatch( startProcessing() )

            if(idCustomer === undefined) {
                const alertConfig = {
                    title: 'Exito',
                    text: 'Cliente creado exitosamente.',
                    icon: 'success',
                    willClose: () => {
                        onFinishRegister()
                    }
                };

                const result = await saveData( 'Customer/addCustomer', values, headers, alertConfig );
                if(result.status === 'success') {
                    setIdCustomerRegister(result.response.idCustomer)
                    onFinishRegister()
                }
            }

            if(idCustomer !== undefined) {
                const alertConfig = {
                    title: 'Exito',
                    text: 'Cliente actualizado exitosamente',
                    icon: 'success',
                    willClose: () => {
                        onFinishRegister()
                    }
                };

                await saveData( 'Customer/editCustomer', values, headers, alertConfig );
            }

            dispatch( stopProcessing() )
        }
        
        if(state.activeTab === 'invoicing') {
            selectedOptionTaxRegime == null ? setInvalidTaxRegime(true) : setInvalidTaxRegime(false)
            selectedOptionBillingAddress == null ? setInvalidBillingAddress(true) : setInvalidBillingAddress(false)
            if(invalidTaxRegime || invalidBillingAddress)
                return

            values.idTaxRegime = selectedOptionTaxRegime.value
            values.idCustomerAddress = selectedOptionBillingAddress.value
            values.sBusinessName = values.sBusinessNameBilling

            dispatch( startProcessing() )

            const alertConfig = {
                title: 'Exito',
                text: 'Cliente actualizado exitosamente',
                icon: 'success',
                willClose: () => {
                    onFinishRegister()
                }
            };

            if(hasBillingData === false)
                await saveData( 'CustomerBilling/addBilling', values, headers, alertConfig );
            if(hasBillingData === true)
                await saveData( 'CustomerBilling/editBillingData', values, headers, alertConfig );

            dispatch( stopProcessing() )
        }
    }

    const reloadPage = () => {
        location.href = `/pages/new-client/${idCustomerRegister}`
    }

    const addListToClient = (list, checkId) => {
        let productsId = listAssignedRef.current
        const check = document.getElementById(checkId)
        if(check.checked) {
            const el = productsId.find(element => element === list.idListProduct)
            if(el === undefined)
                productsId.push(list.idListProduct)
        } else {
            const el = productsId.findIndex(element => element === list.idListProduct)
            productsId.splice(el, 1)
        }

        listAssignedRef.current = productsId

        setTimeout(() => {
            productsId.map( element => {
                document.getElementById(`check_list_${element}`).checked = true
            } )
        }, 100);

        setCustomerProductListsList(productsId)
    }

    const addProductToCustomer = (product, checkId) => {
        let productsId = productsAssignedRef.current
        const check = document.getElementById(checkId)
        if(check.checked) {
            const el = productsId.find(element => element === product.idProduct)
            if(el === undefined)
                productsId.push(product.idProduct)
        } else {
            const el = productsId.findIndex(element => element === product.idProduct)
            productsId.splice(el, 1)
        }

        productsAssignedRef.current = productsId

        setTimeout(() => {
            productsId.map( element => {
                document.getElementById(`check_${element}`).checked = true
            } )
        }, 100);

        setCustomerProducts(productsId)
    }

    const getCustomerLists = async () => {
        const lists = await getDataFromApi(`Customer/getCustomerProdList?idCustomer=${idCustomer}`, idCustomer, [], headers)
        if(lists.status === 'success') {
            setDataProductsListCustomer(lists.customerListsList)
        }
    }

    const getCustomerProducts = async () => {
        const lists = await getDataFromApi(`Customer/getCustomerProducts?idCustomer=${idCustomer}`, idCustomer, [], headers)
        if(lists.status === 'success') {
            setDataProductsCustomer(lists.customerProductList)
        }
    }

    const onAddListClick = async () => {
        dispatch( startProcessing() )
        const alertConfig = {
            title: 'Exito',
            text: 'Listas asignadas correctamente.',
            icon: 'success',
            willClose: () => {
                onFinishRegister()
            }
        };

        const result = await saveData('Customer/updateListsOfCustomer', { idCustomer, customerProductListsList: listAssignedRef.current }, headers, alertConfig)
        if(result.status === 'success') {
            await getCustomerLists()
        }

        dispatch( stopProcessing() )
    }

    const onAddProductClick = async () => {
        dispatch( startProcessing() )
        const alertConfig = {
            title: 'Exito',
            text: 'Producto-servicios asignados correctamente.',
            icon: 'success',
            willClose: () => {
                onFinishRegister()
            }
        };

        const result = await saveData('Customer/updateProductsOfCustomer', { idCustomer, customerProducts: productsAssignedRef.current }, headers, alertConfig)
        if(result.status === 'success') {
            await getCustomerProducts()
        }

        dispatch( stopProcessing() )
    }

    const getDropdownData = async () => {
        await lavakleenApi.get('TaxRegime/getTaxRegimeList', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.listTaxRegime.map( option => {
                optionsArray.push({value: option.idTaxRegime, label: `${option.sClaveSAT} ${option.sTaxRegime}`})
            } )
            setOptionsTaxRegime( optionsArray );
        } )

        await lavakleenApi.get('CustomerType/getCustomerTypeList', {
            headers: { ...headers, private: localStorage.getItem('idCompany') }
        }).then( response => {
            const optionsArray = []
            response.data.listCustomerType.map( option => {
                optionsArray.push({value: option.idCustomerType, label: option.sCustomerType})
            } )
            setOptionsClientTypes( optionsArray );
        } )

        await lavakleenApi.get('CustomerStatus/getCustomerStatusList', {
            headers: { ...headers, private: localStorage.getItem('idCompany') }
        }).then( response => {
            const optionsArray = []
            response.data.listCustomerStatus.map( option => {
                optionsArray.push({value: option.idCustomerStatus, label: option.sCustomerStatus})
            } )
            setOptionsClientStatus( optionsArray );
        } )

        await lavakleenApi.get('CommonMaster/getStates', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.options.map( option => {
                optionsArray.push({value: option.idOpcion, label: option.sOpcion})
            } )
            setOptionsState( optionsArray );
        } )
    }

    const getClientAddresses = async () => {
        const address = await getDataFromApi(`CustomerAddresses/getCustomerAddressesList?idCustomer=${idCustomer}`, idCustomer, [], headers)
        if(address.status === 'success') {
            setDataAddress(address.listCustomerAddresses)
            const optionsArray = []
            address.listCustomerAddresses.map( option => {
                optionsArray.push({value: option.idAddress, label: `${option.sAddressAlias} ${option.sBusinessAddress} ${option.sExtNumber} ${option.sIntNumber} ${option.sCity} ${option.sState}`})
            } )
            setOptionsBillingAddress( optionsArray );
        }
    }

    const getProductsList = async () => {
        const lists = await getDataFromApi(`ProductList/getListProductList?filterIdStatusList=1`, 0, [], headers)
        if(lists.status === 'success') {
            setDataProductsList(lists.listPorducts)
        }
    }

    const getProductsDetails = async () => {
        const productsDetails = await getDataFromApi(`Product/getProductList`, 4, [], headers)
        if(productsDetails.status === 'success') {
            setDataProducts(productsDetails.productList)
        }
    }

    const getData = async () => {
        await getDropdownData();
        await getClientAddresses();
        await getProductsList();
        await getProductsDetails();
        await getCustomerLists();
        await getCustomerProducts();

        const client = await getDataFromApi(`Customer/findCustomer?idCustomer=${idCustomer}`, idCustomer, clientData, headers);
        if(client.status === 'success'){
            const billingAddress = await getDataFromApi(`CustomerBilling/getBillingData?idCustomer=${idCustomer}`, idCustomer, {}, headers)
            if(billingAddress.billData !== null) {
                setHasBillingData(true)
                setClientData( {...client.customerData, sBusinessNameBilling: billingAddress.billData.sBusinessName, idTaxRegime: billingAddress.billData.idTaxRegime, sRfc: billingAddress.billData.sRfc, idCustomerBilling: billingAddress.billData.idCustomerBilling, idCustomerAddress: billingAddress.billData.idCustomerAddress} );
            } else {
                setClientData( {...client.customerData, sBusinessNameBilling: '', idTaxRegime: null, sRfc: '',} );
            }
        }
    }

    const getCities = async (state) => {
        await lavakleenApi.get(`CommonMaster/getCities?idState=${state.value}`, {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.options.map( option => {
                optionsArray.push({value: option.idOpcion, label: option.sOpcion})
            } )
            setOptionsCities( optionsArray );
        } )
    }

    const editRow = async(idCustomerAddress) => {
        setSelectedOptionState(null)
        setInvalidState(true)
        const address = await getDataFromApi(`CustomerAddresses/findCustomerAddress?idCustomerAddress=${idCustomerAddress}`, idCustomerAddress, {}, headers);
        if(address.status === 'success'){
            setFormAddressData( {...address.addressData, id: address.addressData.idAddress} );
            toggleModal('CustomerAddresses/editCustomerAddress')
        }
    }

    const deleteRow = async (idCustomerAddress) => {

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const result = await deleteDataFromApi(`CustomerAddresses/deleteCustomerAddress?idCustomerAddress=${idCustomerAddress}`, headers)
                if(result.bResult === true)
                    await getClientAddresses()
            }
        })
    }

    const onFinishRegister = () => {
        setFinisRegistering(true)
    }

    const toggle = (tab) => {
        if(state.activeTab !== tab) {
            setState({
                ...state,
                activeTab: tab
            })
        }
    }

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const onAddAddressClick = () => {
        setFormAddressData({
            idAddress: 0,
            idCustomer: idCustomer,
            idState: null,
            idCity: null,
            sAddressAlias: '',
            sBusinessAddress: '',
            sIntNumber: '',
            sExtNumber: '',
            sColony: '',
            sZipCode: '',
            sReferences: '',
            sPrincipalPhone: '',
            sCellPhone: '',
            id: 0,
        })
        setSelectedOptionState(null)
        setInvalidState(true)
        setSelectedOptionCity(null)
        setInvalidCity(true)
        toggleModal('CustomerAddresses/addCustomerAddress')
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Clientes')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Clientes'))
        
        if(userPerm.bCreate !== true && idCustomer === undefined) {
            navigate(`/pages/clients`)
        }

        if(userPerm.bEdit !== true && idCustomer !== undefined) {
            navigate(`/pages/clients`)
        }
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(dataAddress);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        const filename = 'direcciones.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const handleRowChange = useCallback( state => {
        let listToAssign = []
        state.selectedRows.map( row => {
            listToAssign = [...listToAssign, row.idListProduct]
        } )
        listAssignedRef.current = listToAssign
    } )

    const listOfProductsAssigned = (rows) => {
        return listAssignedRef.current.indexOf(rows.idListProduct) > -1
    }
    
    const handleRowChangeProducts = useCallback( state => {
        let listToAssign = []
        state.selectedRows.map( row => {
            listToAssign = [...listToAssign, row.idProduct]
        } )
        productsAssignedRef.current = listToAssign
    } )

    const productsAssigned = (rows) => {
        return productsAssignedRef.current.indexOf(rows.idProduct) > -1
    }

    useEffect(() => {
        checkPermissions();
        getData();
    }, [])

    useEffect(() => {
        if(finisRegistering == true) {
            if(state.activeTab === 'details') {
                if(idCustomerRegister > 0 && idCustomer === undefined) {
                    setTimeout(() => {
                        location.href = `/pages/new-client/${idCustomerRegister}`
                    }, 800);
                }
    
                if(idCustomer !== undefined) {
                    navigate(`/pages/clients`)
                }
            }
        }
    }, [finisRegistering])

    useEffect(() => {
        if(optionsClientTypes.length > 0 && idCustomer !== undefined) {
            if(clientData.sBusinessName !== '') {
                const clientType = optionsClientTypes.find( (option) => option.value === clientData.idCustomerType )
                setSelectedOptionClientType(clientType)
                setInvalidCustomerType(false)
            }
        }
    }, [optionsClientTypes, clientData])

    useEffect(() => {
        if(optionsState.length > 0 && idCustomer !== undefined && formAddressData.idAddress > 0) {
            const state = optionsState.find( (option) => option.value === formAddressData.idState )
            setSelectedOptionState(state)
            setInvalidState(false)
            if(state)
                getCities(state)
        }
    }, [optionsState, formAddressData])
    
    useEffect(() => {
        if(optionsCities.length > 0 && idCustomer !== undefined && formAddressData.idAddress > 0) {
            const city = optionsCities.find( (option) => option.value === formAddressData.idCity )
            setSelectedOptionCity(city)
            setInvalidCity(false)
        }
    }, [optionsCities, formAddressData, selectedOptionState])

    useEffect(() => {
        if(clientData.idCustomer > 0 && optionsBillingAddress.length > 0) {
            const billingAddress = optionsBillingAddress.find( (option) => option.value === clientData.idCustomerAddress )
            setSelectedOptionBillingAddress(billingAddress)
            setInvalidBillingAddress(false)

            const taxRegime = optionsTaxRegime.find( (option) => option.value === clientData.idTaxRegime )
            setSelectedOptionTaxRegime(taxRegime)
            setInvalidTaxRegime(false)
        }
    }, [clientData, optionsBillingAddress])

    useEffect(() => {
        if(clientData.idCustomer > 0 && optionsClientStatus.length > 0) {
            const customerStatus = optionsClientStatus.find( (option) => option.value === clientData.idCustomerStatus )
            setSelectedOptionClientStatus(customerStatus)
            setInvalidClientStatus(false)
        }
    }, [clientData, optionsClientStatus])

    useEffect(() => {
        if(state.activeTab === 'products') {
            let productsListId = []
            let productsId = []
            listAssignedRef.current = []
            productsAssignedRef.current = []
            setTimeout(() => {
                dataProductsListCustomer.map( element => {
                    productsListId.push(element.idProductList)
                    listAssignedRef.current = [...listAssignedRef.current, element.idProductList]
                } )

                dataProductsCustomer.map( element => {
                    productsId.push(element.idProductService)
                    productsAssignedRef.current = [...productsAssignedRef.current, element.idProductService]
                } )
                // listAssignedRef.current = productsListId
                // productsAssignedRef.current = productsId
                setCustomerProductListsList(productsListId)
                setCustomerProducts(productsId)
            }, 200);
        }
    }, [state.activeTab])

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Crear cliente"
                                subheading="Pantalla para creación de clientes nuevos"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardHeader>
                                            <Nav justified>
                                                <TabHeaderComponent id="details" label="Datos generales" state={state} toggle={ toggle }/>
                                                {
                                                    idCustomer && (
                                                        <>
                                                            <TabHeaderComponent id="addresses" label="Direcciones" state={state} toggle={ toggle }/>
                                                            <TabHeaderComponent id="invoicing" label="Facturación" state={state} toggle={ toggle }/>
                                                            <TabHeaderComponent id="products" label="Productos" state={state} toggle={ toggle }/>
                                                        </>
                                                    )
                                                }
                                                
                                            </Nav>
                                        </CardHeader>
                                        <CardBody>
                                            <Form
                                                    initialValues={clientData}
                                                    onSubmit={ (values) => onSubmit(values) }
                                                    validationSchema={
                                                        yup.object({
                                                            sBusinessName: state.activeTab === 'details' ? yup.string().required() : yup.string(),
                                                            sEmail: state.activeTab === 'details' ? yup.string().email().required() : yup.string(),
                                                            sContactEmail: state.activeTab === 'details' ? yup.string().email().required() : yup.string(),
                                                            sContactName: state.activeTab === 'details' ? yup.string().required() : yup.string(),
                                                            sPhoneNumber: state.activeTab === 'details' ? yup.string().matches(/^[0-9]+$/).min(10).max(12).required() : yup.string(),
                                                            sCellNumber: state.activeTab === 'details' ? yup.string().matches(/^[0-9]+$/).min(10).max(12) : yup.string(),
                                                            sBusinessNameBilling: state.activeTab === 'invoicing' ? yup.string().required() : yup.string(),
                                                            sRfc: state.activeTab === 'invoicing' ? yup.string().required() : yup.string(),
                                                        })
                                                    }
                                                    enableReinitialize
                                                >
                                                    <TabContent activeTab={ state.activeTab }>
                                                        <TabPane tabId="details">
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup className="mb-3">
                                                                        <Label for="sBusinessName">Nombre comercial</Label>
                                                                        <Input type="text" name="sBusinessName" placeholder="Ingresa el nombre comercial del cliente"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="sEmail">Correo del cliente</Label>
                                                                        <Input type="text" name="sEmail" placeholder="Ingresa el correo del cliente"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup className="mb-3">
                                                                        <Label for="dPercent">Porcentaje de descuento</Label>
                                                                        <Input type="number" name="dPercent" placeholder="Ingresa el porcentaje"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        {
                                                                            clientData.sCustomerGUID !== ''
                                                                            ? (
                                                                                <QRCodeSVG
                                                                                    value={ clientData.sCustomerGUID }
                                                                                    size={ 100 }
                                                                                />
                                                                            )
                                                                            : ''
                                                                        }
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="idCustomerType">Tipo cliente</Label>
                                                                        <Select
                                                                            defaultValue={selectedOptionClientType}
                                                                            options={optionsClientTypes}
                                                                            name="idCustomerType"
                                                                            key={ selectedOptionClientType }
                                                                            placeholder="Selecciona una opción"
                                                                            className={ invalidCustomerType ? 'is-invalid form-control' : '' }
                                                                            onChange={ (option) => {
                                                                                option == null ? setInvalidCustomerType(true) : setInvalidCustomerType(false)
                                                                                setSelectedOptionClientType(option)
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup className="mb-3">
                                                                        <Label for="sContactName">Nombre de contacto</Label>
                                                                        <Input type="text" name="sContactName" placeholder="Ingresa el contacto del cliente"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="sPhoneNumber">Teléfono</Label>
                                                                        <Input type="text" name="sPhoneNumber" placeholder="Ingresa el teléfono del cliente"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup className="mb-3">
                                                                        <Label for="sCellNumber">Celular</Label>
                                                                        <Input type="text" name="sCellNumber" placeholder="Ingresa el celular del cliente"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="sContactEmail">Correo del contacto</Label>
                                                                        <Input type="text" name="sContactEmail" placeholder="Ingresa el correo del contacto del cliente"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup className="mb-3">
                                                                        <Label for="sAlternativeContactEmail">Correo alternativo del contacto</Label>
                                                                        <Input type="text" name="sAlternativeContactEmail" placeholder="Ingresa un correo alternativo"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="sZone">Zona</Label>
                                                                        <Input type="text" name="sZone" placeholder="Ingresa la zona"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup className="mb-3">
                                                                        <Label for="sExternalID">ID Externo</Label>
                                                                        <Input type="text" name="sExternalID" placeholder="Ingresa un ID Externo"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup className="mb-3">
                                                                        <Label for="sBuinessName">Status</Label>
                                                                        <Select
                                                                            defaultValue={selectedOptionClientStatus}
                                                                            options={optionsClientStatus}
                                                                            name="idCustomerStatus"
                                                                            key={ selectedOptionClientStatus }
                                                                            placeholder="Selecciona una opción"
                                                                            className={ invalidClientStatus ? 'is-invalid form-control' : '' }
                                                                            onChange={ (option) => {
                                                                                option == null ? setInvalidClientStatus(true) : setInvalidClientStatus(false)
                                                                                setSelectedOptionClientStatus(option)
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    {
                                                                        clientData.dCreationDate !== ''
                                                                        ? (
                                                                            <FormGroup className="mb-3">
                                                                                <Label for="dCreationDate">Fecha y hora de alta</Label>
                                                                                <Input type="text" name="dCreationDate" placeholder="Ingresa un ID Externo" readOnly/>
                                                                            </FormGroup>
                                                                        )
                                                                        : ''
                                                                    }
                                                                </Col>
                                                                <Col md={6}>
                                                                    {
                                                                        clientData.sCreateUser !== ''
                                                                        ? (
                                                                            <FormGroup className="mb-3">
                                                                                <Label for="sCreateUser">Creado por</Label>
                                                                                <Input type="text" name="sCreateUser" placeholder="Ingresa un ID Externo" readOnly/>
                                                                            </FormGroup>
                                                                        )
                                                                        : ''
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tabId="invoicing">
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup className="mb-3">
                                                                        <Label for="sBusinessNameBilling">Razón social</Label>
                                                                        <Input type="text" name="sBusinessNameBilling" placeholder="Ingresa la razón social del cliente"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="sRfc">RFC</Label>
                                                                        <Input type="text" name="sRfc" placeholder="Ingresa el RFC del cliente"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup className="mb-3">
                                                                        <Label for="sBuinessName">Dirección de facturación</Label>
                                                                        <Select
                                                                            defaultValue={selectedOptionBillingAddress}
                                                                            onChange={ (option) => {
                                                                                option == null ? setInvalidBillingAddress(true) : setInvalidBillingAddress(false)
                                                                                setSelectedOptionBillingAddress(option)
                                                                            }}
                                                                            options={optionsBillingAddress}
                                                                            name="idState"
                                                                            key={ selectedOptionBillingAddress }
                                                                            placeholder="Selecciona una opción"
                                                                            className={ invalidBillingAddress ? 'is-invalid form-control' : '' }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="sCompanyName">Régimen Fiscal</Label>
                                                                        <Select
                                                                            defaultValue={selectedOptionTaxRegime}
                                                                            options={optionsTaxRegime}
                                                                            name="idState"
                                                                            key={ selectedOptionTaxRegime }
                                                                            placeholder="Selecciona una opción"
                                                                            onChange={ (option) => {
                                                                                option == null ? setInvalidTaxRegime(true) : setInvalidTaxRegime(false)
                                                                                setSelectedOptionTaxRegime(option)
                                                                            }}
                                                                            className={ invalidTaxRegime ? 'is-invalid form-control' : '' }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tabId="products">
                                                            <Row>
                                                                <Col md={6}>
                                                                    <h3>Para asignar</h3>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <h3>Asignados al cliente</h3>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <Card className="main-card mb-3">
                                                                        <CardBody>
                                                                            <CardTitle>Lista</CardTitle>
                                                                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                                                                <li className="nav-item">
                                                                                    <Button className="mb-2 me-2" color="success"
                                                                                        onClick={ onAddListClick }
                                                                                    >
                                                                                        Asignar
                                                                                    </Button>
                                                                                </li>
                                                                            </ul>
                                                                            <DataTable data={dataProductsList}
                                                                                columns={columnsProductsList}
                                                                                pagination
                                                                                fixedHeader
                                                                                selectableRows
                                                                                onSelectedRowsChange={handleRowChange}
                                                                                selectableRowSelected={listOfProductsAssigned}
                                                                                fixedHeaderScrollHeight="500px"
                                                                            />
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Card className="main-card mb-3">
                                                                        <CardBody>
                                                                            <CardTitle>Lista</CardTitle>
                                                                            <DataTable data={dataProductsListCustomer}
                                                                                columns={columnsProductsListCustomer}
                                                                                pagination
                                                                                fixedHeader
                                                                                fixedHeaderScrollHeight="500px"
                                                                            />
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <Card className="main-card mb-3">
                                                                        <CardBody>
                                                                            <CardTitle>Servicio - Producto</CardTitle>
                                                                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                                                                <li className="nav-item">
                                                                                    <Button className="mb-2 me-2" color="success"
                                                                                        onClick={ onAddProductClick }
                                                                                    >
                                                                                        Asignar
                                                                                    </Button>
                                                                                </li>
                                                                            </ul>
                                                                            <DataTable data={dataProducts}
                                                                                columns={columnsProducts}
                                                                                pagination
                                                                                fixedHeader
                                                                                fixedHeaderScrollHeight="500px"
                                                                                selectableRows
                                                                                onSelectedRowsChange={handleRowChangeProducts}
                                                                                selectableRowSelected={productsAssigned}
                                                                            />
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Card className="main-card mb-3">
                                                                        <CardBody>
                                                                            <CardTitle>Servicio - Producto</CardTitle>
                                                                            <DataTable data={dataProductsCustomer}
                                                                                columns={columnsProductsCustomer}
                                                                                pagination
                                                                                fixedHeader
                                                                                fixedHeaderScrollHeight="500px"
                                                                            />
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tabId="addresses">
                                                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                                                <li className="nav-item">
                                                                    <Button className="mb-2 me-2" color="primary"
                                                                        onClick={ onAddAddressClick }
                                                                    >
                                                                        Agregar
                                                                    </Button>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <Button className="mb-2 me-2" color="danger"
                                                                        onClick={ () => downloadCsv()}
                                                                    >
                                                                        Exportar
                                                                    </Button>
                                                                </li>
                                                            </ul>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Card className="main-card mb-3">
                                                                        <CardBody>
                                                                            <DataTable data={dataAddress}
                                                                                columns={columnsAddresses}
                                                                                pagination
                                                                                fixedHeader
                                                                                fixedHeaderScrollHeight="500px"
                                                                            />
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                            <ModalComponent isOpen={modalState.isOpen} toggle={ toggleModal } backdrop={ modalState.backDrop } modalTitle="Agregar dirección" size="lg"
                                                                initialValues={ formAddressData }
                                                                validationSchema={ {
                                                                    sAddressAlias: yup.string().required(),
                                                                    sBusinessAddress: yup.string().required(),
                                                                    sExtNumber: yup.string().required(),
                                                                    sColony: yup.string().required(),
                                                                    sZipCode: yup.string().min(5).max(5).matches(/^[0-9]+$/).required(),
                                                                    sPrincipalPhone: yup.string().matches(/^[0-9]+$/).min(10).max(12),
                                                                    sCellPhone: yup.string().matches(/^[0-9]+$/).min(10).max(12),
                                                                } }
                                                                saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                                                alertMessages={ { addMessage: 'Dirección creada exitosamente', updateMessage: 'Dirección actualizada exitosamente' } }
                                                                getDataFunction={ getClientAddresses }
                                                                dropDownsToValidate={ [ { field: 'idState', value: selectedOptionState?.value}, { field: 'idCity', value:  selectedOptionCity?.value} ] }
                                                            >
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <FormGroup className="mb-3">
                                                                            <Label for="sAddressAlias">Nombre de sucursal</Label>
                                                                            <Input type="text" name="sAddressAlias" placeholder=""/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="sBusinessAddress">Calle</Label>
                                                                            <Input type="text" name="sBusinessAddress" placeholder=""/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <FormGroup>
                                                                            <Label for="sExtNumber">No. Exterior</Label>
                                                                            <Input type="text" name="sExtNumber" placeholder=""/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <FormGroup>
                                                                            <Label for="sIntNumber">No. Interior</Label>
                                                                            <Input type="text" name="sIntNumber" placeholder=""/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <FormGroup className="mb-3">
                                                                            <Label for="idState">Estado</Label>
                                                                            <Select
                                                                                defaultValue={selectedOptionState}
                                                                                onChange={ (option) => {
                                                                                    option == null ? setInvalidState(true) : setInvalidState(false)
                                                                                    setSelectedOptionState(option)
                                                                                    if(option)
                                                                                        getCities(option)
                                                                                }}
                                                                                options={optionsState}
                                                                                name="idState"
                                                                                key={ selectedOptionState }
                                                                                placeholder="Selecciona una opción"
                                                                                className={ invalidState ? 'is-invalid form-control' : '' }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup className="mb-3">
                                                                            <Label for="idCity">Ciudad</Label>
                                                                            <Select
                                                                                defaultValue={selectedOptionCity}
                                                                                onChange={ (option) => {
                                                                                    option == null ? setInvalidCity(true) : setInvalidCity(false)
                                                                                    setSelectedOptionCity(option)

                                                                                }}
                                                                                options={optionsCities}
                                                                                name="idCity"
                                                                                key={ selectedOptionCity }
                                                                                placeholder="Selecciona una opción"
                                                                                className={ invalidCity ? 'is-invalid form-control' : '' }
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup className="mb-3">
                                                                            <Label for="sColony">Colonia</Label>
                                                                            <Input type="text" name="sColony" placeholder="Ingresa la colonia del cliente"/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="sZipCode">Código postal</Label>
                                                                            <Input type="text" name="sZipCode" placeholder=""/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <FormGroup>
                                                                            <Label for="sReferences">Referencias</Label>
                                                                            <Input type="text" name="sReferences" placeholder=""/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <FormGroup>
                                                                            <Label for="sPrincipalPhone">Teléfono fijo</Label>
                                                                            <Input type="text" name="sPrincipalPhone" placeholder=""/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <FormGroup>
                                                                            <Label for="sCellPhone">Celular</Label>
                                                                            <Input type="text" name="sCellPhone" placeholder=""/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </ModalComponent>
                                                        </TabPane>
                                                    </TabContent>
                                                    {
                                                        (state.activeTab === 'details' || state.activeTab === 'invoicing')
                                                        ? (
                                                            <Button color="primary" className="mt-2 mr-2" type="submit">
                                                                {
                                                                    (idCustomer === undefined)
                                                                    ? 'Guardar'
                                                                    : 'Actualizar'
                                                                }
                                                            </Button>
                                                        )
                                                        : ''
                                                    }
                                                    { "  " }
                                                    <Link 
                                                        className="mt-2 btn btn-danger"
                                                        to="/pages/clients"
                                                    >
                                                        Cancelar
                                                    </Link>
                                                </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                            <CardTitle>Datos de empresa</CardTitle>
                                            <Form
                                                initialValues={companyData}
                                                onSubmit={ (values) => onSubmit(values) }
                                                validationSchema={
                                                    yup.object({
                                                        sBuinessName: yup.string().required(),
                                                        sBusinessAddress: yup.string().required(),
                                                        sRfc: yup.string().required(),
                                                        sExtNumber: yup.string().required(),
                                                        sCp: yup.string().min(5).required(),
                                                        sPrincipalPhone: yup.string().min(10).max(12).required(),
                                                        sAlternatePhone: yup.string().min(10).max(12)
                                                    })
                                                }
                                                enableReinitialize
                                            >
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Razón social*</Label>
                                                            <Input type="text" name="sBuinessName" placeholder="Ingresa la razón social del negocio"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="sCompanyName">Nombre comercial</Label>
                                                            <Input type="text" name="sCompanyName" placeholder="Ingresa el nombre comercial del negocio"/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBusinessAddress">Calle*</Label>
                                                            <Input type="text" name="sBusinessAddress" placeholder="Ingresa la calle"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sExtNumber">No. Exterior*</Label>
                                                            <Input type="text" name="sExtNumber" placeholder="Ingresa el número exterior"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sIntNumber">No. Interior</Label>
                                                            <Input type="text" name="sIntNumber" placeholder="Ingresa el número interior"/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="idState">Estado</Label>
                                                            <Select
                                                                defaultValue={selectedOptionState}
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidState(true) : setInvalidState(false)
                                                                    setSelectedOptionState(option)
                                                                    if(option)
                                                                        getCities(option)
                                                                }}
                                                                options={optionsState}
                                                                name="idState"
                                                                key={ selectedOptionState }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidState ? 'is-invalid form-control' : '' }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="idCity">Ciudad</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCity}
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidCity(true) : setInvalidCity(false)
                                                                    setSelectedOptionCity(option)
                                                                }}
                                                                options={optionsCities}
                                                                name="idCity"
                                                                key={ selectedOptionCity }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidCity ? 'is-invalid form-control' : '' }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="idColony">Colonia</Label>
                                                            <AsyncSelect
                                                                // cacheOptions
                                                                defaultValue={selectedOptionColony}
                                                                defaultOptions
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidColony(true) : setInvalidColony(false)
                                                                    setSelectedOptionColony(option)
                                                                }}
                                                                loadOptions={loadColonies}
                                                                placeholder="Escribe para buscar..."
                                                                className={ invalidColony ? 'is-invalid form-control' : '' }
                                                                key={ selectedOptionColony }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="sCp">Código postal</Label>
                                                            <Input type="text" name="sCp" placeholder="Ingresa el código postal"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="sRfc">RFC</Label>
                                                            <Input type="text" name="sRfc" placeholder="Ingresa el RFC para facturar"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="idTaxRegime">Régimen fiscal</Label>
                                                            <Select
                                                                defaultValue={selectedOption}
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalid(true) : setInvalid(false)
                                                                    setSelectedOption(option)
                                                                }}
                                                                options={options}
                                                                name="idTaxRegime"
                                                                key={ selectedOption }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalid ? 'is-invalid form-control' : '' }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sPrincipalPhone">Teléfono principal</Label>
                                                            <Input type="text" name="sPrincipalPhone" placeholder="Ingresa el teléfono principal del negocio"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="sAlternatePhone">Teléfono alterno</Label>
                                                            <Input type="text" name="sAlternatePhone" placeholder="Ingresa un teléfono secundario"/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sWebsite">Sitio web</Label>
                                                            <Input type="text" name="sWebsite" placeholder="Ingresa la dirección web del negocio"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="sEmail">Correo electrónico</Label>
                                                            <Input type="text" name="sEmail" placeholder="Ingresa un correo electrónico del negocio"/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    
                                                </Row>
                                                <Button color="primary" className="mt-2 mr-2" type="submit">
                                                    {
                                                        (idCompany === undefined)
                                                        ? 'Guardar'
                                                        : 'Actualizar'
                                                    }
                                                </Button>{ "  " }
                                                <Link 
                                                    className="mt-2 btn btn-danger"
                                                    to="/pages/companies"
                                                >
                                                    Cancelar
                                                </Link>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row> */}
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Form, Input } from '@availity/form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Label, TabContent, TabPane, CardHeader, Nav, NavItem, NavLink } from "reactstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import { lavakleenApi } from '../../api/lavakleenApi';
import { convertArrayOfObjectsToCSV, deleteDataFromApi, getDataFromApi, saveData } from '../../api/providers/providers';
import { PageTitle } from '../../layout/components/PageTitle';
import { startProcessing, stopProcessing } from '../../store/auth/authSlice';
import { TabHeaderComponent } from '../components/TabHeaderComponent';
import { TaxObjects, Taxes, columnsProductDetails } from '../providers/inventoryProviders';
import { ModalComponent } from '../components/ModalComponent';

const formData = {
    sMasterProduct: '',
};

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const ProductPage = () => {
    const {idMasterProduct} = useParams();

    const [state, setState] = useState({
        activeTab: 'details'
    })

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
    })

    const [columnsProducts, setColumnsProducts] = useState([...columnsProductDetails, {
        name: "Acciones",
        cell: (row) => (
            <>
                <Button onClick={ () => editRow(row.idProduct) } color="primary">
                    <i className="lnr-pencil"></i>
                </Button>
                <Button color="danger" onClick={ () => deleteRow(row.idProduct) }><i className="lnr-trash"></i></Button>
            </>
        ),
        sortable: false,
        ignoreRowClick: true,
        button: true,
    }]);

    const [selectedOptionService, setSelectedOptionService] = useState(null);
    const [selectedOptionTax, setSelectedOptionTax] = useState(null);
    const [selectedOptionMeasurementUnit, setSelectedOptionMeasurementUnit] = useState(null);
    const [selectedOptionSatProduct, setSelectedOptionSatProduct] = useState(null);
    const [selectedOptionTaxObject, setSelectedOptionTaxObject] = useState(null);
    const [productData, setProductData] = useState(formData);
    const { id, isProcessing } = useSelector( state => state.auth )
    const [idProductRegister, setIdProductRegister] = useState(idMasterProduct);

    const [optionsServices, setOptionsServices] = useState([]);
    const [optionsUnitMeasurements, setOptionsUnitMeasurements] = useState([]);
    const [optionsTaxes, setOptionsTaxes] = useState(Taxes);
    const [optionsSatProducts, setOptionsSatProducts] = useState([]);
    const [optionsTaxObjects, setOptionsTaxObjects] = useState(TaxObjects);
    const [finisRegistering, setFinisRegistering] = useState(false);

    //validators
    const [invalidService, setInvalidService] = useState(true);
    const [invalidMeasurementUnit, setInvalidMeasurementUnit] = useState(true);
    const [invalidTax, setInvalidTax] = useState(true);
    const [invalidSatProduct, setInvalidSatProduct] = useState(true);
    const [invalidTaxObject, setInvalidTaxObject] = useState(true);

    //datatables
    const [dataProducts, setDataProducts] = useState([]);
    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formProductDetail, setFormProductDetail] = useState({
        idServiceType: null,
        idMasterProduct: parseFloat(idMasterProduct),
        idCustomerMeasurement: null,
        idProductServiceSAT: null,
        sTaxObjectId: '',
        sTaxId: '',
        sProductService: '',
        dMXNPrice: 0,
        dUSDPrice: 0,
        iKGWeight: 0,
        sFamily: '',
        sBarCode: '',
        id: 0,
        idRegisterUser: id,
    });

    const onSubmit = async (values) => {

        if(state.activeTab === 'details') {
            values.idRegisterUser = id
            dispatch( startProcessing() )

            if(idMasterProduct === undefined) {
                const alertConfig = {
                    title: 'Exito',
                    text: 'Producto creado exitosamente',
                    icon: 'success',
                    willClose: () => {
                        onFinishRegister()
                    }
                };

                const result = await saveData( 'MasterProduct/addMasterProduct', values, headers, alertConfig );
                console.log(result)
                // if(result.status === 'success') {
                //     setIdProductRegister(result.response.idCustomer)
                //     onFinishRegister()
                // }
            }

            if(idMasterProduct !== undefined) {
                const alertConfig = {
                    title: 'Exito',
                    text: 'Producto actualizado exitosamente',
                    icon: 'success',
                    willClose: () => {
                        onFinishRegister()
                    }
                };

                await saveData( 'MasterProduct/editMasterProduct', values, headers, alertConfig );
            }

            dispatch( stopProcessing() )
        }
    }

    const getDropdownData = async () => {
        await lavakleenApi.get('ServiceType/getServiceList?filterActive=true', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.serviceTypeList.map( option => {
                optionsArray.push({value: option.idServiceType, label: option.sServiceType})
            } )
            setOptionsServices( optionsArray );
        } )

        await lavakleenApi.get('CustomerMeasurement/getCustomerMeasurementList', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.customerMeasurementList.map( option => {
                optionsArray.push({value: option.idCustomerMeasurement, label: option.sCustomerMeasurement})
            } )
            setOptionsUnitMeasurements( optionsArray );
        } )
    }

    const getData = async () => {
        await getDropdownData();
        await getProductsDetails();

        const product = await getDataFromApi(`MasterProduct/findMasterProduct?idMasterProduct=${idMasterProduct}`, idMasterProduct, productData, headers);
        console.log(product)
        if(product.status === 'success'){
            setProductData( {...product.masterProductData} )
        }
    }

    const getProductsDetails = async () => {
        const productsDetails = await getDataFromApi(`Product/getProductList?idMasterProduct=${idMasterProduct}`, idMasterProduct, [], headers)
        if(productsDetails.status === 'success') {
            setDataProducts(productsDetails.productList)
        }
    }

    const getSatProducts = async (inputValue) => {
        const optionsArray = []
        await lavakleenApi.get(`ServiceProduct/getServicesProductsList?filterProdServ=${inputValue}`, {
            headers
        }).then( response => {
            
            response.data.serviceProductData.map( option => {
                optionsArray.push({value: option.idProductService, label: `${option.sClaveSAT} ${option.sProductService}`})
            } )
        } )

        setOptionsSatProducts(optionsArray)

        return optionsArray;
    }

    const loadSatProducts = (inputValue) => {
        if(inputValue.length > 4) {
            return new Promise((resolve) => {
                resolve(getSatProducts(inputValue))
            })
        }
    };

    const onFinishRegister = () => {
        setFinisRegistering(true)
    }

    const toggle = (tab) => {
        if(state.activeTab !== tab) {
            setState({
                ...state,
                activeTab: tab
            })
        }
    }

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const onAddProductClick = () => {
        setFormProductDetail({
            idServiceType: null,
            idMasterProduct: parseFloat(idMasterProduct),
            idCustomerMeasurement: null,
            idProductServiceSAT: null,
            sTaxObjectId: '',
            sTaxId: '',
            sProductService: '',
            dMXNPrice: 0,
            dUSDPrice: 0,
            iKGWeight: 0,
            sFamily: '',
            sBarCode: '',
            id: 0,
            idRegisterUser: id,
        })
        setSelectedOptionService(null)
        setInvalidService(true)
        setSelectedOptionMeasurementUnit(null)
        setInvalidMeasurementUnit(true)
        setSelectedOptionSatProduct(null)
        setInvalidSatProduct(true)
        setSelectedOptionTaxObject(null)
        setInvalidTaxObject(true)
        setSelectedOptionTax(null)
        setInvalidTax(true)
        toggleModal('Product/addProduct')
    }

    const editRow = async(idProduct) => {
        setSelectedOptionService(null)
        setInvalidService(true)
        setSelectedOptionMeasurementUnit(null)
        setInvalidMeasurementUnit(true)
        setSelectedOptionSatProduct(null)
        setInvalidSatProduct(true)
        setSelectedOptionTaxObject(null)
        setInvalidTaxObject(true)
        setSelectedOptionTax(null)
        setInvalidTax(true)
        const product = await getDataFromApi(`Product/findProduct?idProduct=${idProduct}`, idProduct, {}, headers);
        if(product.status === 'success'){
            setFormProductDetail( {...product.productData, id: product.productData.idProduct} );
            toggleModal('Product/editProduct')
        }
    }

    const deleteRow = async (idProduct) => {

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const result = await deleteDataFromApi(`Product/deleteProduct?idProduct=${idProduct}`, headers)
                if(result.bResult === true)
                    await getProductsDetails()
            }
        })
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Productos')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Productos'))
        
        if(userPerm.bCreate !== true && idMasterProduct === undefined) {
            navigate(`/pages/products`)
            return
        }

        if(userPerm.bEdit !== true && idMasterProduct !== undefined) {
            navigate(`/pages/products`)
            return
        }

        getData();
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(dataProducts);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        const filename = 'productos-servicios.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    useEffect(() => {
        checkPermissions();
        
    }, [])

    const getSatProduct = async (idProdServ) => {
        const satProduct = await getDataFromApi(`ServiceProduct/findProdServ?idProdServ=${idProdServ}`, idProdServ, {}, headers);

        if(satProduct.status === 'success') {
            setSelectedOptionSatProduct({
                value: satProduct.prodServ.idProductService,
                label: `${satProduct.prodServ.sClaveSAT} ${satProduct.prodServ.sProductService}`
            })
            setInvalidSatProduct(false)
        }
    }

    useEffect(() => {
        if(finisRegistering == true) {
            // if(idProductRegister > 0 && idMasterProduct === undefined) {
            //     setTimeout(() => {
            //         location.href = `/pages/new-product/${idProductRegister}`
            //     }, 800);
            // }
    
            // if(idMasterProduct !== undefined) {
            //     navigate(`/pages/products`)
            // }
            navigate(`/pages/products`)
        }
    }, [finisRegistering])

    useEffect(() => {
        if(optionsServices.length > 0 && formProductDetail.idProduct !== undefined) {
            const serviceType = optionsServices.find( (option) => option.value === formProductDetail.idServiceType )
            setSelectedOptionService(serviceType)
            setInvalidService(false)

            const measurementUnit = optionsUnitMeasurements.find( (option) => option.value === formProductDetail.idCustomerMeasurement )
            setSelectedOptionMeasurementUnit(measurementUnit)
            setInvalidMeasurementUnit(false)

            const taxObject = optionsTaxObjects.find( (option) => option.value === formProductDetail.sTaxObjectId )
            setSelectedOptionTaxObject(taxObject)
            setInvalidTaxObject(false)

            const tax = optionsTaxes.find( (option) => option.value === formProductDetail.sTaxId )
            setSelectedOptionTax(tax)
            setInvalidTax(false)

            getSatProduct(formProductDetail.idProductServiceSAT)
        }
    }, [optionsServices, formProductDetail])

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Crear producto"
                                subheading="Pantalla para creación de productos nuevos"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardHeader>
                                            <Nav justified>
                                                <TabHeaderComponent id="details" label="Generales" state={state} toggle={ toggle }/>
                                                {
                                                    idMasterProduct && (
                                                        <TabHeaderComponent id="products" label="Servicio - Productos" state={state} toggle={ toggle }/>
                                                    )
                                                }
                                            </Nav>
                                        </CardHeader>
                                        <CardBody>
                                            <Form
                                                    initialValues={productData}
                                                    onSubmit={ (values) => onSubmit(values) }
                                                    validationSchema={
                                                        yup.object({
                                                            sMasterProduct: yup.string().required()
                                                        })
                                                    }
                                                    enableReinitialize
                                                >
                                                    <TabContent activeTab={ state.activeTab }>
                                                        <TabPane tabId="details">
                                                            <Row>
                                                                <Col md={12}>
                                                                    <FormGroup className="mb-3">
                                                                        <Label for="sMasterProduct">Nombre del producto</Label>
                                                                        <Input type="text" name="sMasterProduct" placeholder=""/>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tabId="products">
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Card className="main-card mb-3">
                                                                        <CardBody>
                                                                            <CardTitle>Productos a asignar</CardTitle>
                                                                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                                                                <li className="nav-item">
                                                                                    <Button className="mb-2 me-2" color="primary"
                                                                                        onClick={ onAddProductClick }
                                                                                    >
                                                                                        Agregar
                                                                                    </Button>
                                                                                </li>
                                                                                <li className="nav-item">
                                                                                    <Button className="mb-2 me-2" color="danger"
                                                                                        onClick={ downloadCsv }
                                                                                    >
                                                                                        Exportar
                                                                                    </Button>
                                                                                </li>
                                                                            </ul>
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <Card className="main-card mb-3">
                                                                                        <CardBody>
                                                                                            <DataTable data={dataProducts}
                                                                                                columns={columnsProducts}
                                                                                                pagination
                                                                                                fixedHeader
                                                                                                fixedHeaderScrollHeight="500px"
                                                                                            />
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Row>
                                                                            <ModalComponent
                                                                                isOpen={modalState.isOpen}
                                                                                toggle={ toggleModal }
                                                                                backdrop={ modalState.backDrop } modalTitle="Agregar producto"
                                                                                size="lg"
                                                                                initialValues={ formProductDetail }
                                                                                validationSchema={ {
                                                                                    sProductService: yup.string().required(),
                                                                                    dMXNPrice: yup.number().required(),
                                                                                } }
                                                                                saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                                                                alertMessages={ { addMessage: 'Producto/servicio asignado exitosamente', updateMessage: 'Producto/servicio actualizado exitosamente' } }
                                                                                getDataFunction={ getProductsDetails }
                                                                                dropDownsToValidate={ [ { field: 'idServiceType', value: selectedOptionService?.value}, { field: 'idCustomerMeasurement', value:  selectedOptionMeasurementUnit?.value}, { field: 'idProductServiceSAT', value:  selectedOptionSatProduct?.value}, { field: 'sTaxId', value:  selectedOptionTax?.value}, { field: 'sTaxObjectId', value:  selectedOptionTaxObject?.value} ] }
                                                                            >
                                                                                <Row>
                                                                                    <Col md={6}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="sProductService">Nombre de producto o servicio</Label>
                                                                                            <Input type="text" name="sProductService" placeholder=""/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="idState">Tipo de servicio</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedOptionService}
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidService(true) : setInvalidService(false)
                                                                                                    setSelectedOptionService(option)
                                                                                                }}
                                                                                                options={optionsServices}
                                                                                                name="idState"
                                                                                                key={ selectedOptionService }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidService ? 'is-invalid form-control' : '' }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={3}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="dMXNPrice">Precio en pesos (MXN)</Label>
                                                                                            <Input type="number" name="dMXNPrice" placeholder=""/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={3}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="dUSDPrice">Precio en dólares (USD)</Label>
                                                                                            <Input type="number" name="dUSDPrice" placeholder=""/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="idState">Unidad</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedOptionMeasurementUnit}
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidMeasurementUnit(true) : setInvalidMeasurementUnit(false)
                                                                                                    setSelectedOptionMeasurementUnit(option)
                                                                                                }}
                                                                                                options={optionsUnitMeasurements}
                                                                                                name="idState"
                                                                                                key={ selectedOptionMeasurementUnit }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidMeasurementUnit ? 'is-invalid form-control' : '' }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={6}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="idState">Clave producto SAT</Label>
                                                                                            <AsyncSelect
                                                                                                // cacheOptions
                                                                                                defaultValue={selectedOptionSatProduct}
                                                                                                defaultOptions
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidSatProduct(true) : setInvalidSatProduct(false)
                                                                                                    setSelectedOptionSatProduct(option)
                                                                                                }}
                                                                                                loadOptions={loadSatProducts}
                                                                                                placeholder="Escribe para buscar..."
                                                                                                className={ invalidSatProduct ? 'is-invalid form-control' : '' }
                                                                                                key={ selectedOptionSatProduct }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={3}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="iKGWeight">Peso en KG</Label>
                                                                                            <Input type="number" name="iKGWeight" placeholder=""/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={3}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="sFamily">Familia</Label>
                                                                                            <Input type="text" name="sFamily" placeholder=""/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col md={4}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="sBarCode">Código de barras</Label>
                                                                                            <Input type="text" name="sBarCode" placeholder=""/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={4}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="idState">Objeto de impuesto</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedOptionTaxObject}
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidTaxObject(true) : setInvalidTaxObject(false)
                                                                                                    setSelectedOptionTaxObject(option)
                                                                                                }}
                                                                                                options={optionsTaxObjects}
                                                                                                name="idState"
                                                                                                key={ selectedOptionTaxObject }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidTaxObject ? 'is-invalid form-control' : '' }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={4}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="idTax">Impuestos</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedOptionTax}
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidTax(true) : setInvalidTax(false)
                                                                                                    setSelectedOptionTax(option)
                                                                                                }}
                                                                                                options={optionsTaxes}
                                                                                                name="idTax"
                                                                                                key={ selectedOptionTax }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidTax ? 'is-invalid form-control' : '' }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                            </ModalComponent>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                    </TabContent>
                                                    {
                                                        state.activeTab === 'details'
                                                        ? (
                                                            <Button color="primary" className="mt-2 mr-2" type="submit">
                                                                {
                                                                    (idMasterProduct === undefined)
                                                                    ? 'Guardar'
                                                                    : 'Actualizar'
                                                                }
                                                            </Button>
                                                        )
                                                        : ''
                                                    }
                                                    
                                                    { "  " }<Link 
                                                        className="mt-2 btn btn-danger"
                                                        to="/pages/products"
                                                    >
                                                        Cancelar
                                                    </Link>
                                                </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

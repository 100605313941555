import { Form, Input } from '@availity/form';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import {useDropzone} from 'react-dropzone';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Label } from "reactstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import { lavakleenApi } from '../../api/lavakleenApi';
import { getCompanyById, saveData, baseUrl } from '../../api/providers/providers';
import { PageTitle } from '../../layout/components/PageTitle';
import { startProcessing, stopProcessing } from '../../store/auth/authSlice';
import { Callbacks } from 'jquery';

const formData = {
    idTaxRegime: null,
    idRegisterUser: null,
    sBuinessName: '',
    sCompanyName: '',
    sRfc: '',
    sBusinessAddress: '',
    sPrincipalPhone: '',
    sAlternatePhone: '',
    sWebsite: '',
    sEmail: '',
    sImagePath: '',
    sCsd: '',
    sKey: '',
    sSatpassword: '',
    sExtNumber: '',
    sIntNumber: '',
    idState: null,
    idCity: null,
    idColony: 1,
    sCp: '',
    sColony: '',
    sCertificateNumber: '',
    sPassword: '',
    certificateFile: '',
    keyFile: '',
    sFacturaExpressApiKey: '',
    sPrivateKeyFile: '',
    sCertificateFile: '',
};

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken')
}

export const NewCompanyPage = () => {

    const {idCompany} = useParams();

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        saveFiles(acceptedFiles);
    }, [])

    const saveFiles = async (files) => {
        const formData = new FormData() // pass data as a form
        formData.append(`file`, files[0])

        const alertConfig = {
            title: 'Exito',
            text: 'Logo subido exitosamente.',
            icon: 'success',
            willClose: () => {
                // onFinishRegister()
            }
        };

        const result = await saveData(`/Company/uploadLogo?idCompany=${idCompany}`, formData, headers, alertConfig)

        if(result.status === 'success') {
            setTimeout(() => {
                location.reload()
            }, 500);
        }
    }

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: {
          'image/jpeg': [],
          'image/png': []
        }, onDrop});

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionState, setSelectedOptionState] = useState(null);
    const [selectedOptionCity, setSelectedOptionCity] = useState(null);
    const [selectedOptionColony, setSelectedOptionColony] = useState(null);
    const [companyData, setCompanyData] = useState(formData);
    const { id, isProcessing } = useSelector( state => state.auth )

    const [options, setOptions] = useState([]);
    const [optionsState, setOptionsState] = useState([]);
    const [optionsCities, setOptionsCities] = useState([]);
    const [optionsColonies, setOptionsColonies] = useState([]);
    const [finisRegistering, setFinisRegistering] = useState(false);
    const [formCsd, setFormCsd] = useState({
        sCertificateNumber: '',
        certificateFile: null,
        sPassword: '',
        keyFile: null,
    });

    //validators
    const [invalid, setInvalid] = useState(true);
    const [invalidState, setInvalidState] = useState(true);
    const [invalidCity, setInvalidCity] = useState(true);
    const [invalidColony, setInvalidColony] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)

    const onSubmit = async (values) => {
        selectedOption == null ? setInvalid(true) : setInvalid(false)
        selectedOptionState == null ? setInvalidState(true) : setInvalidState(false)
        selectedOptionCity == null ? setInvalidCity(true) : setInvalidCity(false)
        selectedOptionColony == null ? setInvalidColony(true) : setInvalidColony(false)

        if(invalid || invalidState || invalidCity)
            return

        values.idTaxRegime = selectedOption.value
        values.idRegisterUser = id;
        values.sBuinessName = values.sBuinessName.toUpperCase()
        values.idState = selectedOptionState.value
        values.idCity = selectedOptionCity.value
        // values.idColony = selectedOptionColony.value
        if(idCompany !== undefined)
            values.idCompany = parseFloat(idCompany);

        dispatch( startProcessing() )

        if(idCompany === undefined) {
            const alertConfig = {
                title: 'Exito',
                text: 'Empresa creada exitosamente!!',
                icon: 'success',
                willClose: () => {
                    onFinishRegister()
                }
            };

            await saveData( 'Company/addCompany', values, headers, alertConfig );
        }

        if(idCompany !== undefined) {
            const alertConfig = {
                title: 'Exito',
                text: 'Empresa actualizada exitosamente!!',
                icon: 'success',
                willClose: () => {
                    onFinishRegister()
                }
            };

            await saveData( 'Company/editCompany', values, headers, alertConfig );
        }

        dispatch( stopProcessing() )
    }

    const getDropdownData = async () => {
        await lavakleenApi.get('CommonMaster/getTaxRegime', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.options.map( option => {
                optionsArray.push({value: option.idOpcion, label: option.sOpcion})
            } )
            setOptions( optionsArray );
        } )

        await lavakleenApi.get('CommonMaster/getStates', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.options.map( option => {
                optionsArray.push({value: option.idOpcion, label: option.sOpcion})
            } )
            setOptionsState( optionsArray );
        } )
    }

    const getData = async () => {
        await getDropdownData();

        const company = await getCompanyById(idCompany, headers);
        if(company.status === 'success'){
            setCompanyData({...company.companyData, sCertificateNumber: '', sPassword: '', certificateFile: '', keyFile: ''});
        }
    }

    const getCities = async (state) => {
        await lavakleenApi.get(`CommonMaster/getCities?idState=${state.value}`, {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.options.map( option => {
                optionsArray.push({value: option.idOpcion, label: option.sOpcion})
            } )
            setOptionsCities( optionsArray );
        } )
    }

    const getColonies = async (inputValue) => {
        const optionsArray = []
        if(selectedOptionState && selectedOptionCity) {
            await lavakleenApi.get(`CommonMaster/getColony?idState=${selectedOptionState.value}&idCity=${selectedOptionCity.value}&filter=${inputValue}`, {
                headers
            }).then( response => {
                
                response.data.options.map( option => {
                    optionsArray.push({value: option.idOpcion, label: option.sOpcion})
                } )
            } )
        }

        setOptionsColonies(optionsArray)

        return optionsArray;
    }

    const loadColonies = (inputValue) => {
        if(inputValue.length > 5 && selectedOptionState && selectedOptionCity) {
            return new Promise((resolve) => {
                resolve(getColonies(inputValue))
            })
        }
    }

    const onFinishRegister = () => {
        setFinisRegistering(true)
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Empresas')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Empresas'))
        
        if(userPerm.bCreate !== true && idCompany === undefined) {
            navigate(`/pages/companies`)
        }

        if(userPerm.bEdit !== true && idCompany !== undefined) {
            navigate(`/pages/companies`)
        }
    }

    const onCsdChange = (event) => {
        setFormCsd({...formCsd, certificateFile: event.target.files[0]})
    }

    const onKeyChange = (event) => {
        setFormCsd({...formCsd, keyFile: event.target.files[0]})
    }

    const uploadFiles = async () => {
        const formData = new FormData() // pass data as a form
        const formDataKey = new FormData()
        formData.append(`file`, formCsd.certificateFile)
        formDataKey.append(`file`, formCsd.keyFile)
        const alertConfig = {
            title: 'Exito',
            text: 'Archivos cargados correctamente.',
            icon: 'success',
            willClose: () => {
                // onFinishRegister()
            }
        };
        dispatch( startProcessing() )
        await saveData(`/Certificates/processCertificate?sCertificateNumber=${formCsd.sCertificateNumber}&idCompany=${idCompany}`, formData, headers, alertConfig );

        await saveData(`Certificates/processPrivateKey?sPassword=${formCsd.sPassword}&idCompany=${idCompany}`, formDataKey, headers, alertConfig );
        dispatch( stopProcessing() )
    }

    const setCertificateNumber = (event) => {
        setFormCsd({ ...formCsd, sCertificateNumber: event.target.value })
    }

    const setKeyPassword = (event) => {
        setFormCsd({ ...formCsd, sPassword: event.target.value })
    }

    useEffect(() => {
        checkPermissions();
        getData();
    }, [])

    useEffect(() => {
        if(isProcessing == true) {

            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }

        if(!isProcessing) Swal.close()
    }, [isProcessing])

    useEffect(() => {
        if(finisRegistering == true) {

            navigate('/pages/companies')
        }
    }, [finisRegistering])

    useEffect(() => {
        if(options.length > 0 && idCompany !== undefined) {
            if(companyData.sBuinessName !== '') {
                const taxRegime = options.find( (option) => option.value === companyData.idTaxRegime )
                setSelectedOption(taxRegime)
                setInvalid(false)
            }
        }
    }, [options, companyData])

    useEffect(() => {
        if(optionsState.length > 0 && idCompany !== undefined) {
            const state = optionsState.find( (option) => option.value === companyData.idState )
            setSelectedOptionState(state)
            setInvalidState(false)
            if(state)
                getCities(state)
        }
    }, [optionsState, companyData])
    
    useEffect(() => {
        if(optionsCities.length > 0 && idCompany !== undefined) {
            const city = optionsCities.find( (option) => option.value === companyData.idCity )
            setSelectedOptionCity(city)
            setInvalidCity(false)
            if(city)
                getColonies()
        }
    }, [optionsCities, companyData, selectedOptionCity])

    useEffect(() => {
        if(optionsColonies.length > 0 && idCompany !== undefined) {
            const colony = optionsColonies.find( (option) => option.value === companyData.idColony )
            setSelectedOptionColony(colony)
            setInvalidColony(false)
        }
    }, [optionsColonies, companyData])

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Crear empresa"
                                subheading="Pantalla para creación de empresas nuevas"
                                icon="lnr-apartment icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                
                            </ul>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                            <CardTitle>Datos de empresa</CardTitle>
                                            <Form
                                                initialValues={companyData}
                                                onSubmit={ (values) => onSubmit(values) }
                                                validationSchema={
                                                    yup.object({
                                                        sBuinessName: yup.string().required(),
                                                        sBusinessAddress: yup.string().required(),
                                                        sRfc: yup.string().required(),
                                                        sExtNumber: yup.string().required(),
                                                        sColony: yup.string().required(),
                                                        sCp: yup.string().min(5).required(),
                                                        sPrincipalPhone: yup.string().min(10).max(12).required(),
                                                        sAlternatePhone: yup.string().min(10).max(12)
                                                    })
                                                }
                                                enableReinitialize
                                            >
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Razón social*</Label>
                                                            <Input type="text" name="sBuinessName" placeholder="Ingresa la razón social del negocio"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="sCompanyName">Nombre comercial</Label>
                                                            <Input type="text" name="sCompanyName" placeholder="Ingresa el nombre comercial del negocio"/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBusinessAddress">Calle*</Label>
                                                            <Input type="text" name="sBusinessAddress" placeholder="Ingresa la calle"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sExtNumber">No. Exterior*</Label>
                                                            <Input type="text" name="sExtNumber" placeholder="Ingresa el número exterior"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sIntNumber">No. Interior</Label>
                                                            <Input type="text" name="sIntNumber" placeholder="Ingresa el número interior"/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="idState">Estado</Label>
                                                            <Select
                                                                defaultValue={selectedOptionState}
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidState(true) : setInvalidState(false)
                                                                    setSelectedOptionState(option)
                                                                    if(option)
                                                                        getCities(option)
                                                                }}
                                                                options={optionsState}
                                                                name="idState"
                                                                key={ selectedOptionState }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidState ? 'is-invalid form-control' : '' }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="idCity">Ciudad</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCity}
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidCity(true) : setInvalidCity(false)
                                                                    setSelectedOptionCity(option)
                                                                }}
                                                                options={optionsCities}
                                                                name="idCity"
                                                                key={ selectedOptionCity }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidCity ? 'is-invalid form-control' : '' }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="idColony">Colonia</Label>
                                                            <Input type="text" name="sColony" placeholder="Ingresa la colonia del negocio"/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="sCp">Código postal</Label>
                                                            <Input type="text" name="sCp" placeholder="Ingresa el código postal"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="sRfc">RFC</Label>
                                                            <Input type="text" name="sRfc" placeholder="Ingresa el RFC para facturar"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="idTaxRegime">Régimen fiscal</Label>
                                                            <Select
                                                                defaultValue={selectedOption}
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalid(true) : setInvalid(false)
                                                                    setSelectedOption(option)
                                                                }}
                                                                options={options}
                                                                name="idTaxRegime"
                                                                key={ selectedOption }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalid ? 'is-invalid form-control' : '' }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sPrincipalPhone">Teléfono principal</Label>
                                                            <Input type="text" name="sPrincipalPhone" placeholder="Ingresa el teléfono principal del negocio"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="sAlternatePhone">Teléfono alterno</Label>
                                                            <Input type="text" name="sAlternatePhone" placeholder="Ingresa un teléfono secundario"/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sWebsite">Sitio web</Label>
                                                            <Input type="text" name="sWebsite" placeholder="Ingresa la dirección web del negocio"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="sEmail">Correo electrónico</Label>
                                                            <Input type="text" name="sEmail" placeholder="Ingresa un correo electrónico del negocio"/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        {
                                                            idCompany !== undefined
                                                            ? (
                                                                <div {...getRootProps({className: 'dropzone'})}>
                                                                    <input {...getInputProps()} />
                                                                    <p>Da clic aquí para subir el logotipo</p>
                                                                </div>
                                                            )
                                                            : ''
                                                        }
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            {
                                                                companyData.sImagePath !== ''
                                                                ? (
                                                                    <img src={ `${baseUrl}/${companyData.sImagePath}` } width="500"></img>
                                                                )
                                                                : ''
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        {
                                                            idCompany !== undefined
                                                            ? (
                                                                <FormGroup>
                                                                    <Label>Certificado CSD</Label>
                                                                    <Input type="file" name='certificateFile' onChange={onCsdChange} accept=".cer" />
                                                                </FormGroup>
                                                            )
                                                            : ''
                                                        }
                                                    </Col>
                                                    <Col md={6}>
                                                        {
                                                            idCompany !== undefined
                                                            ? (
                                                                <FormGroup>
                                                                    <Label>Llave privada</Label>
                                                                    <Input type="file" name='keyFile' onChange={onKeyChange} accept=".key" />
                                                                </FormGroup>
                                                            )
                                                            : ''
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sCertificateNumber">No. Certificado</Label>
                                                            <Input type="number" name="sCertificateNumber" onChange={ setCertificateNumber }/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="sPassword">Contraseña llave privada</Label>
                                                            <Input type="password" name="sPassword" onChange={setKeyPassword}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        {
                                                            idCompany !== undefined
                                                            ? (
                                                                <FormGroup className="mb-3">
                                                                    <Button color="primary" className="mt-2 mr-2" type="button" onClick={uploadFiles}>
                                                                        Subir archivos para factura electrónica
                                                                    </Button>
                                                                </FormGroup>
                                                            )
                                                            : ''
                                                        }
                                                    </Col>
                                                    <Col md={6}>
                                                        {
                                                            idCompany !== undefined
                                                            ? (
                                                                <FormGroup className="mb-3">
                                                                    <Label for="sFacturaExpressApiKey">API Key Facturación</Label>
                                                                    <Input type="text" name="sFacturaExpressApiKey"/>
                                                                </FormGroup>
                                                            )
                                                            : ''
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        {
                                                            companyData.sCsd !== '' && companyData.sKey !== ''
                                                            ? (
                                                                <FormGroup>
                                                                    <Label>Actualmente la empresa cuenta con los archivos para emitir facturas electrónicas, si deseas cambiar estos archivos puedes hacerlo en la sección de arriba<br/><br/></Label>
                                                                </FormGroup>
                                                            )
                                                            : (
                                                                <FormGroup>
                                                                    <Label>Actualmente la empresa no cuenta con los archivos para emitir facturas electrónicas, si deseas subir estos archivos puedes hacerlo en la sección de arriba<br/><br/></Label>
                                                                </FormGroup>
                                                            )
                                                        }
                                                    </Col>
                                                    <Col md={6}>
                                                        {
                                                            idCompany !== undefined
                                                            ? (
                                                                <FormGroup className="mb-3">
                                                                    <Label for="sCertificateFile">Certificado</Label>
                                                                    <Input type="text" name="sCertificateFile" disabled/>
                                                                </FormGroup>
                                                            )
                                                            : ''
                                                        }
                                                    </Col>
                                                    <Col md={6}>
                                                        {
                                                            idCompany !== undefined
                                                            ? (
                                                                <FormGroup className="mb-3">
                                                                    <Label for="sPrivateKeyFile">Llave privada</Label>
                                                                    <Input type="text" name="sPrivateKeyFile" disabled/>
                                                                </FormGroup>
                                                            )
                                                            : ''
                                                        }
                                                    </Col>
                                                </Row>
                                                <Button color="primary" className="mt-2 mr-2" type="submit">
                                                    {
                                                        (idCompany === undefined)
                                                        ? 'Guardar'
                                                        : 'Actualizar'
                                                    }
                                                </Button>{ "  " }
                                                <Link 
                                                    className="mt-2 btn btn-danger"
                                                    to="/pages/companies"
                                                >
                                                    Cancelar
                                                </Link>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

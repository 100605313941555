import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css'
import {QRCodeCanvas} from 'qrcode.react';
import { PageTitle } from '../../layout/components/PageTitle';
import { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { lavakleenApi } from '../../api/lavakleenApi';
import { ModalComponent } from '../components/ModalComponent';
import { forwardRef } from 'react';
import {useDropzone} from 'react-dropzone';
import { convertArrayOfObjectsToCSV, filterDataFromApi, saveData } from '../../api/providers/providers';
import { useSelector } from 'react-redux';

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany'),
};

export const ClientsPage = forwardRef( (props, ref) => {

    useImperativeHandle( ref, () => ({
        async filterData(values){
            const result = await filterDataFromApi('Customer/getCustomerList', values, headers);
            setData(result.customerList)
        },

        async restore() {
            await getClients()
        }
    }) )

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [qrClient, setQrClient] = useState('');
    const [sBusinessName, setSBusinessName] = useState('');
    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)
    const { id, isProcessing } = useSelector( state => state.auth )

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
    })
    const [modalStateCustomers, setModalStateCustomers] = useState({
        isOpen: false,
        backDrop: true,
    })

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const toggleModalCustomers = (saveDataToEndpoint) => {
        setModalStateCustomers({
            ...modalStateCustomers,
            isOpen: !modalStateCustomers.isOpen,
            saveDataToEndpoint,
        })
    }

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        saveFiles(acceptedFiles);
    }, [])

    const saveFiles = async (files) => {
        const formData = new FormData() // pass data as a form
        formData.append(`file`, files[0])

        const alertConfig = {
            title: 'Exito',
            text: 'Archivo procesado exitosamente.',
            icon: 'success',
            willClose: () => {
                getClients()
            }
        };

        const result = await saveData(`Customer/customerMasiveLoad?idRegisterUser=${id}`, formData, headers, alertConfig)
    }

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: {
          'application/vnd.ms-excel': [],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
        }, onDrop});

    const columns = [
        {
            name: "Opciones",
            cell: (row) => (
                <>
                    {/* <Link to={ `/pages/new-client/${row.idCustomer}` } className="btn btn-primary" title="Editar">
                        <i className="lnr-pencil"></i>
                    </Link> */}
                    <Button color="primary" onClick={ () => editCustomer(row) } title="Editar"><i className="lnr-pencil"></i></Button>
                    <Button color="success" onClick={ () => activeInactiveCustomer(row) } title="Bloquear/Desbloquear"><i className="lnr-lock"></i></Button>
                    <Button color="danger" onClick={ () => deleteClient(row.idCustomer) } title="Eliminar"><i className="lnr-trash"></i></Button>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            button: true,
            width: '150px'
        },
        {
            name: "ID",
            selector: row => row.idCustomer,
            sortable: true,
            width: '80px',
        },
        // {
        //     name: "Matrícula",
        //     selector: row => row.sCustomerGuid,
        //     sortable: true,
        // },
        {
            name: "Nombre comercial",
            selector: row => row.sCompanyName,
            sortable: true,
            width: '250px',
            wrap: true,
        },
        {
            name: "Correo",
            selector: row => row.sEmail,
            sortable: true,
            width: '250px',
        },
        
        {
            name: "Celular",
            selector: row => row.sCellNumber,
            sortable: true,
            width: '120px',
        },
        {
            name: "Razón Social",
            selector: row => row.sBusinessName,
            sortable: true,
            width: '250px',
            wrap: true,
        },
        {
            name: "QR",
            selector: (row) => (
                <Button color="primary" onClick={ () => showQr(row.sCustomerGuid, row.sBusinessName) } title="Mostrar QR"><i className="lnr-dice"></i></Button>
            ),
            sortable: false,
        },
        {
            name: "% Descuento",
            selector: row => row.dPercent,
            sortable: false,
            width: '120px',
        },
        {
            name: "Estatus",
            selector: row => row.sCustomerStatus,
            sortable: false,
        },
        {
            name: "Zona",
            selector: row => row.sZone,
            sortable: true,
        },
        {
            name: "Fecha y hora alta",
            selector: row => row.dCreationDate,
            sortable: true,
            width: '200px',
        },
    ];

    const onAddClick = () => {
        navigate('/pages/new-client', {})
    }

    const importCustomers = () => {
        toggleModalCustomers('Visit/addVisit')
    }

    const editCustomer = (customer) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Clientes')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        if(customer.idCustomerStatus !== 1) {
            Swal.fire({
                title: 'Error',
                text: 'El cliente no está activo. Antes de editar favor de activarlo.',
                icon: 'error',
            })
            return
        }

        navigate(`/pages/new-client/${customer.idCustomer}`, {})
    }

    const getClients = async () => {
        await lavakleenApi.get('Customer/getCustomerList?filterIdStatusCustomer=1', {
            headers
        }).then( response => {
            setData( response.data.customerList );
        } )
    }

    const deleteClient = (idCustomer) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Clientes')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }
        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                await lavakleenApi.post(`Customer/deleteCustomer?idCustomer=${idCustomer}`, {}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                    getClients();
                } )
            }
        })
    }

    const showQr = (qrValue, sBuinessName) => {
        setQrClient(qrValue)
        setSBusinessName(sBuinessName)
        toggleModal('')
    }

    const nothingToDo = () => {

    }

    const downloadQr = () => {
        // Generate download with use canvas and stream
        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${qrClient}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const activeInactiveCustomer = async (customer) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Clientes')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const idCustomerStatus = customer.sCustomerStatus === 'Activo' ? 2 : 1
                await lavakleenApi.post(`Customer/changeCustomerStatus`, {idCustomer: customer.idCustomer, idCustomerStatus}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                        getClients()
                } )
            }
        })
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Clientes')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Clientes'))
        
        if(userPerm.bList !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })

            navigate(`/pages/dashboard`)
        }
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        const filename = 'clientes.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    useEffect(() => {
        checkPermissions();
        getClients();
    }, [])
    

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Clientes"
                                subheading="Gestion de clientes para tu negocio"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                {
                                    permission !== null && permission.bCreate === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="primary"
                                                onClick={ onAddClick }
                                            >
                                                Agregar
                                            </Button>
                                            <Button className="mb-2 me-2" color="success"
                                                onClick={ importCustomers }
                                            >
                                                Importar de Excel
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }

                                {
                                    permission !== null && permission.bExport === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="danger"
                                                onClick={ () => downloadCsv()}
                                            >
                                                Exportar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }
                            </ul>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                        <DataTable data={data}
                                            columns={columns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight="500px"
                                        />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <ModalComponent
                                    isOpen={modalState.isOpen}
                                    toggle={ toggleModal }
                                    backdrop={ modalState.backDrop }
                                    modalTitle={ sBusinessName }
                                    size="md"
                                    initialValues={ {} }
                                    validationSchema={ {} }
                                    saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                    alertMessages={ { } }
                                    getDataFunction={ nothingToDo }
                                    dropDownsToValidate={ [ ] }
                                    showSaveButton={false}
                                >
                                    <Row>
                                        <Col md={12}>
                                            <QRCodeCanvas
                                                value={ qrClient }
                                                size={ 300 }
                                                id="qr-gen"
                                            />
                                        </Col>
                                        <Col md={12}>
                                        <Button color="primary" onClick={ downloadQr }>
                                            Descargar QR
                                        </Button>
                                        </Col>
                                    </Row>
                                </ModalComponent>

                                <ModalComponent
                                    isOpen={modalStateCustomers.isOpen}
                                    toggle={ toggleModalCustomers }
                                    backdrop={ modalStateCustomers.backDrop }
                                    modalTitle="Importar clientes"
                                    size="md"
                                    initialValues={ {} }
                                    validationSchema={ {} }
                                    saveDataToEndpoint={ modalStateCustomers.saveDataToEndpoint }
                                    alertMessages={ { } }
                                    getDataFunction={ nothingToDo }
                                    dropDownsToValidate={ [ ] }
                                    showSaveButton={false}
                                >
                                    <Row>
                                        <Col md={12}>
                                        <div {...getRootProps({className: 'dropzone'})}>
                                            <input {...getInputProps()} />
                                            <p>Da clic aquí para subir a tus clientes</p>
                                        </div>
                                        </Col>
                                    </Row>
                                </ModalComponent>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
})
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Label, CardHeader, Nav, TabContent, TabPane } from "reactstrap";
import { Form, Input } from '@availity/form';
import * as yup from "yup";
import { PageTitle } from '../../layout/components/PageTitle';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import { lavakleenApi } from '../../api/lavakleenApi';
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import DeniReactTreeView from "deni-react-treeview"
import { setFinishRegister, startProcessing, stopFinishRegister, stopProcessing } from '../../store/auth/authSlice';
import { getDataFromApi, getUserById, saveData, updateData } from '../../api/providers/providers';
import { TabHeaderComponent } from '../components/TabHeaderComponent';

const formData = {
    idUserRegister: null,
    idRol: null,
    idCompany: null,
    sName: '',
    sLastName: '',
    sUser: '',
    sPassword: '',
    sEmail: '',
};

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken')
}

export const UserPage = () => {

    const {idUser} = useParams();

    const optionsRolesRef = useRef();
    const optionsCompaniesRef = useRef();
    
    const [userData, setUserData] = useState(formData);

    const [options, setOptions] = useState([]);
    const [optionsCompany, setOptionsCompany] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [permissionsToAssign, setPermissionsToAssign] = useState([]);

    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionCompany, setSelectedOptionCompany] = useState(null);
    const [finisRegistering, setFinisRegistering] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [invalidCompany, setInvalidCompany] = useState(false);

    const [state, setState] = useState({
        activeTab: 'details'
    })

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isProcessing, id, role } = useSelector( state => state.auth );

    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)
    

    const onSubmit = async (values) => {
        if(state.activeTab === 'details') {
            selectedOption == null ? setInvalid(true) : setInvalid(false)
            selectedOptionCompany == null ? setInvalidCompany(true) : setInvalidCompany(false)
            if(invalid || invalidCompany)
                return
            
            values.idRol = selectedOption.value
            values.idUserRegister = id
            values.idCompany = selectedOptionCompany.value
    
            
            
            dispatch( startProcessing() )
    
            if(idUser === undefined) {
                const alertConfig = {
                    title: 'Exito',
                    text: 'Usuario agregado exitosamente!!',
                    icon: 'success',
                    willClose: () => {
                        onFinishRegister()
                    }
                };
    
                await saveData( 'User/AddUser', values, headers, alertConfig );
            }
    
            if(idUser !== undefined) {
                const alertConfig = {
                    title: 'Exito',
                    text: 'Usuario actualizado exitosamente!!',
                    icon: 'success',
                    willClose: () => {
                        onFinishRegister()
                    }
                };
    
                await saveData( 'User/editUser', values, headers, alertConfig );
            }
    
            dispatch( stopProcessing() )
        }

        if(state.activeTab === 'permissions') {
            let dataToSave = {
                idUser,
                permisionsList: []
            }

            permissionsToAssign.map( permission => {
                permission.children.map( child => {
                    dataToSave.permisionsList.push({
                        idModule: child.id,
                        bCreate: child.children[1].state === 1 ? true : false,
                        bFind: false,
                        bList: child.children[0].state === 1 ? true : false,
                        bEdit: child.children[2].state === 1 ? true : false,
                        bDelete: child.children[3].state === 1 ? true : false,
                        bChangeStatus: false,
                        bGrantPrivilege: false,
                        bExport: child.children[4].state === 1 ? true : false
                    })
                } )
            } )

            dispatch( startProcessing() )

            const alertConfig = {
                title: 'Exito',
                text: 'Permisos asignados exitosamente.',
                icon: 'success',
                willClose: () => {
                    onFinishRegister()
                }
            };

            await saveData( 'GrantPrivileges/grantPrivileges', dataToSave, headers, alertConfig );

            dispatch( stopProcessing() )
        }

    }

    const toggle = (tab) => {
        if(state.activeTab !== tab) {
            setState({
                ...state,
                activeTab: tab
            })
        }
    }

    const onFinishRegister = () => {
        setFinisRegistering(true)
    }

    const getDropdownData = async () => {
        dispatch( startProcessing() )

        await lavakleenApi.get('CommonMaster/getRols', {
            headers
        }).then( response => {
            let optionsArray = []
            response.data.options.map( option => {
                optionsArray = [...optionsArray, {value: option.idOpcion, label: option.sOpcion}]
            } )
            setOptions( optionsArray );
            optionsRolesRef.current = optionsArray
        } )

        await lavakleenApi.get('CommonMaster/getCompanies', {
            headers
        }).then( response => {
            let optionsArray = []
            response.data.options.map( option => {
                optionsArray = [...optionsArray, {value: option.idOpcion, label: option.sOpcion} ]
            } )
            setOptionsCompany( optionsArray );
            optionsCompaniesRef.current = optionsArray
        } )

        if(role != 1) {
            const company = optionsCompaniesRef.current.find( (option) => option.value == localStorage.idCompany )
            setSelectedOptionCompany(company)
        }

        optionsRolesRef.current.map( roleOption => {
            if(roleOption.value < role)
                roleOption.isdisabled = true
            else
                roleOption.isdisabled = false
        } )

        setOptions( optionsRolesRef.current )

        dispatch( stopProcessing() )
    }

    const showPermissions = () => {
        let permissionsTree = []
        let stateChild = 0
        permissions.map( permission => {
            if(!permissionsTree.find( category => category.text === permission.sCategory )) {
                permissionsTree.push({ id: permission.idModule, text: permission.sCategory, children: [], state: 2 })
            }
        } )

        permissionsTree.map( permission => {
            let children = permissions.filter( child => child.sCategory === permission.text )
            children.map( child => {
                stateChild = 2
                if(child.bList === true && child.bCreate === true && child.bEdit === true && child.bDelete === true && child.bExport === true) {
                    stateChild = 1
                }
                permission.children.push({ id: child.idModule, text: child.sModuleDetail, children: [
                    { id: 1, text: 'Ver', value: child.bList, state: child.bList ? 1 : 2 },
                    { id: 2, text: 'Crear', value: child.bCreate, state: child.bCreate ? 1 : 2 },
                    { id: 3, text: 'Editar', value: child.bEdit, state: child.bEdit ? 1 : 2 },
                    { id: 4, text: 'Eliminar/Cancelar', value: child.bDelete, state: child.bDelete ? 1 : 2 },
                    { id: 5, text: 'Exportar', value: child.bExport, state: child.bExport ? 1 : 2 },
                ], state: stateChild })
            } )
        } )

        setPermissionsToAssign(permissionsTree)
    }

    const getData = async () => {
        await getDropdownData();

        const user = await getUserById(idUser, headers);
        if(user.status === 'success'){
            const idCompany = user.userData.companies[0]
            setUserData({...user.userData, idCompany: idCompany} );

            const userRole = optionsRolesRef.current.find( (option) => option.value === user.userData.idRol )
            setSelectedOption(userRole)

            const company = optionsCompaniesRef.current.find( (option) => option.value === idCompany )
            setSelectedOptionCompany(company)

            const userPermissions = await getDataFromApi(`GrantPrivileges/getPermisionList?idUser=${idUser}`, idUser, [], headers);
            if(userPermissions.status === 'success') {
                setPermissions(userPermissions.permisionsList)
            }
        }
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Usuarios')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Usuarios'))
        
        if(userPerm.bCreate !== true && idUser === undefined) {
            navigate(`/pages/users`)
        }

        if(userPerm.bEdit !== true && idUser !== undefined) {
            navigate(`/pages/users`)
        }
    }

    useEffect(() => {
        checkPermissions();
        getData();
    }, [])

    useEffect(() => {
        if(permissions.length > 0)
            showPermissions()
    }, [permissions])
    

    useEffect(() => {
        if(isProcessing == true) {

            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }

        if(!isProcessing) Swal.close()
    }, [isProcessing])

    useEffect(() => {
        if(finisRegistering == true) {

            navigate('/pages/users')
        }
    }, [finisRegistering])

    useEffect(() => {
        if(options.length > 0 && idUser !== undefined) {
            if(userData.sName !== '') {
                //TODO: cambiar por los datos que vengan en la api
                setSelectedOption({ value: 1, label: 'Administrador'})
                setSelectedOptionCompany({ value: 1, label: 'Lavanderia'})
            }
        }
    }, [options, userData])
    
    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Crear usuario"
                                subheading="Pantalla para creación de usuarios nuevos"
                                icon="lnr-apartment icon-gradient bg-ripe-malin"
                            />
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardHeader>
                                            <Nav justified>
                                                <TabHeaderComponent id="details" label="Datos de usuario" state={state} toggle={ toggle }/>
                                                {
                                                    idUser && (
                                                        <TabHeaderComponent id="permissions" label="Permisos" state={state} toggle={ toggle }/>
                                                    )
                                                }
                                            </Nav>
                                        </CardHeader>
                                        <CardBody>
                                            <Form
                                                initialValues={userData}
                                                onSubmit={ (values) => onSubmit(values) }
                                                validationSchema={
                                                    yup.object({
                                                        sName: yup.string().required(),
                                                        sLastName: yup.string().required(),
                                                        sUser: yup.string().min(10).matches(/^[A-Z]/i).required(),
                                                        sPassword: idUser === undefined ? yup.string().min(6).required() : yup.string().min(6),
                                                        sEmail: yup.string().email().required(),
                                                    })
                                                }
                                                enableReinitialize
                                            >
                                                <TabContent activeTab={ state.activeTab }>
                                                    <TabPane tabId="details">
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup className="mb-3">
                                                                    <Label for="sName">Nombre(s) del usuario*</Label>
                                                                    <Input type="text" name="sName" placeholder="Ingresa el nombre del usuario a crear"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="sLastName">Apellidos*</Label>
                                                                    <Input type="text" name="sLastName" placeholder="Ingresa los apellidos del usuario"/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup className="mb-3">
                                                                    <Label for="sUser">Cuenta de usuario*</Label>
                                                                    <Input type="text" name="sUser" placeholder="Ingresa la cuenta del usuario"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="sPassword">Contraseña*</Label>
                                                                    <Input type="password" name="sPassword" placeholder="Ingresa la contraseña del usuario"/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="sEmail">Correo electrónico*</Label>
                                                                    <Input type="email" name="sEmail" placeholder="Ingresa el correo electrónico del usuario"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                            <FormGroup className="mb-3">
                                                                    <Label for="idRol">Rol*</Label>
                                                                    <Select
                                                                        defaultValue={selectedOption}
                                                                        onChange={(selectedOption) => {
                                                                            selectedOption == null ? setInvalid(true) : setInvalid(false)
                                                                            setSelectedOption(selectedOption)
                                                                        }}
                                                                        options={options}
                                                                        name="idRol"
                                                                        key={ selectedOption }
                                                                        placeholder="Selecciona un rol"
                                                                        className={ invalid ? 'is-invalid form-control' : '' }
                                                                        isOptionDisabled={(option) => option.isdisabled}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="idCompany">Empresa a la que pertenece*</Label>
                                                                    <Select
                                                                        defaultValue={selectedOptionCompany}
                                                                        onChange={ (selectedCompany) => {
                                                                            selectedCompany == null ? setInvalidCompany(true) : setInvalidCompany(false)
                                                                            setSelectedOptionCompany(selectedCompany)
                                                                        }}
                                                                        options={optionsCompany}
                                                                        name="idCompany"
                                                                        key={ selectedOptionCompany }
                                                                        isClearable
                                                                        isDisabled={ role == 1 ? false : true }
                                                                        placeholder="Selecciona una empresa"
                                                                        className={ invalidCompany ? 'is-invalid form-control' : '' }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tabId="permissions">
                                                        <Row>
                                                            <Col md={ 12 }>
                                                                <Card className="main-card mb-3">
                                                                    <CardBody>
                                                                        <CardTitle>Asignación de permisos</CardTitle>
                                                                        <DeniReactTreeView
                                                                            items={ permissionsToAssign }
                                                                            theme="classic"
                                                                            showCheckbox={true}
                                                                            showIcon={ false }
                                                                        />
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                </TabContent>
                                                <Button color="primary" className="mt-2 mr-2" type="submit">
                                                    {
                                                        (idUser === undefined)
                                                        ? 'Guardar'
                                                        : 'Actualizar'
                                                    }
                                                </Button>{ "  " }
                                                <Link 
                                                    className="mt-2 btn btn-danger"
                                                    to="/pages/users"
                                                >
                                                    Cancelar
                                                </Link>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

import { createRef } from "react";
import { useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { BranchOfficePage } from "../../pages/branch-offices/BranchOfficePage";
import { BranchOfficesPage } from "../../pages/branch-offices/BranchOfficesPage";
import { ClientStatus } from "../../pages/catalogs/ClientStatus";
import { ClientTypes } from "../../pages/catalogs/ClientTypes";
import { Modules } from "../../pages/catalogs/Modules";
import { SatGeneralCatalogs } from "../../pages/catalogs/SatGeneralCatalogs";
import { UnitMeasures } from "../../pages/catalogs/UnitMeasures";
import { ClientPage } from "../../pages/clients/ClientPage";
import { ClientsPage } from "../../pages/clients/ClientsPage";
import { CompanyPage } from "../../pages/companies/CompanyPage";
import { NewCompanyPage } from "../../pages/companies/NewCompanyPage";
import { CreditNotePage } from "../../pages/credit-notes/CreditNotePage";
import { CreditNotesPage } from "../../pages/credit-notes/CreditNotesPage";
import { Dashboard } from "../../pages/dashboard/Dashboard";
import { EditInvoicePage } from "../../pages/invoices/EditInvoicePage";
import { InvoicePage } from "../../pages/invoices/InvoicePage";
import { InvoicesPage } from "../../pages/invoices/InvoicesPage";
import { IssuesPage } from "../../pages/issues/IssuesPage";
import { OrderPage } from "../../pages/orders/OrderPage";
import { OrdersPage } from "../../pages/orders/OrdersPage";
import { PaymentPage } from "../../pages/payments/PaymentPage";
import { PaymentsPage } from "../../pages/payments/PaymentsPage";
import { EditProductionPage } from "../../pages/production/EditProductionPage";
import { ProductionPage } from "../../pages/production/ProductionPage";
import { ProductListPage } from "../../pages/products/ProductListPage";
import { ProductPage } from "../../pages/products/ProductPage";
import { ProductsListPage } from "../../pages/products/ProductsListPage";
import { ProductsPage } from "../../pages/products/ProductsPage";
import { ServicesPage } from "../../pages/products/ServicesPage";
import { RolesPage } from "../../pages/roles/RolesPage";
import { UserPage } from "../../pages/users/UserPage";
import { UsersPage } from "../../pages/users/UsersPage";
import { VisitsPage } from "../../pages/visits/VisitsPage";
import { AppFilters } from "../filters/AppFilters";
import { AppHeader } from "../header/AppHeader"
import { AppSideBar } from "../sidebar/AppSideBar"
import { Colors } from "../../pages/catalogs/Colors";
import { SalesDashboard } from "../../pages/dashboard/SalesDashboard";

export const AppContent = () => {
    const location = useLocation();
    const clientStatusRef = createRef();
    const clientTypesRef = useRef();
    const modulesRef = useRef();
    const clientsRef = useRef();
    const servicesRef = useRef();
    const productListsRef = useRef();
    const productsRef = useRef();
    const rolesRef = useRef();
    const branchOfficesRef = useRef();
    const usersRef = useRef();
    const companiesRef = useRef();
    const ordersRef = useRef();
    const visitsRef = useRef();
    const issuesRef = useRef();
    const satCatalogRef = useRef();
    const productionRef = useRef();
    const paymentsRef = useRef();
    const invoicesRef = useRef();
    const creditNotesRef = useRef();
    const unitMeasurementsRef = useRef();
    const colorsRef = useRef();

    const applyFilter = (values) => {
        if(location.pathname === '/pages/client-status')
            clientStatusRef.current.filterData(values)
        if(location.pathname === '/pages/client-types')
            clientTypesRef.current.filterData(values)
        if(location.pathname === '/pages/clients')
            clientsRef.current.filterData(values)
        if(location.pathname === '/pages/services')
            servicesRef.current.filterData(values)
        if(location.pathname === '/pages/products-list')
            productListsRef.current.filterData(values)
        if(location.pathname === '/pages/products')
            productsRef.current.filterData(values)
        if(location.pathname === '/pages/roles')
            rolesRef.current.filterData(values)
        if(location.pathname === '/pages/branch-offices')
            branchOfficesRef.current.filterData(values)
        if(location.pathname === '/pages/users')
            usersRef.current.filterData(values)
        if(location.pathname === '/pages/companies')
            companiesRef.current.filterData(values)
        if(location.pathname === '/pages/orders')
            ordersRef.current.filterData(values)
        if(location.pathname === '/pages/visits')
            visitsRef.current.filterData(values)
        if(location.pathname === '/pages/issues')
            issuesRef.current.filterData(values)
        if(location.pathname === '/pages/sat-unit-measures' || location.pathname === '/pages/sat-products' || location.pathname === '/pages/tax-regimes')
            satCatalogRef.current.filterData(values)
        if(location.pathname === '/pages/invoices')
            invoicesRef.current.filterData(values)
        if(location.pathname === '/pages/unit-measures')
            unitMeasurementsRef.current.filterData(values)
        if(location.pathname === '/pages/credit-notes')
            creditNotesRef.current.filterData(values)
        if(location.pathname === '/pages/payments')
            paymentsRef.current.filterData(values)
    }

    const restoreFilter = () => {
        if(location.pathname === '/pages/client-status')
            clientStatusRef.current.restore()
        if(location.pathname === '/pages/client-types')
            clientTypesRef.current.restore()
        if(location.pathname === '/pages/clients')
            clientsRef.current.restore()
        if(location.pathname === '/pages/services')
            servicesRef.current.restore()
        if(location.pathname === '/pages/products-list')
            productListsRef.current.restore()
        if(location.pathname === '/pages/products')
            productsRef.current.restore()
        if(location.pathname === '/pages/roles')
            rolesRef.current.restore()
        if(location.pathname === '/pages/branch-offices')
            branchOfficesRef.current.restore()
        if(location.pathname === '/pages/users')
            usersRef.current.restore()
        if(location.pathname === '/pages/companies')
            companiesRef.current.restore()
        if(location.pathname === '/pages/orders')
            ordersRef.current.restore()
        if(location.pathname === '/pages/visits')
            visitsRef.current.restore()
        if(location.pathname === '/pages/issues')
            issuesRef.current.restore()
        if(location.pathname === '/pages/sat-unit-measures' || location.pathname === '/pages/sat-products' || location.pathname === '/pages/tax-regimes')
            satCatalogRef.current.restore()
        if(location.pathname === '/pages/invoices')
            invoicesRef.current.restore()
        if(location.pathname === '/pages/unit-measures')
            unitMeasurementsRef.current.restore()
        if(location.pathname === '/pages/credit-notes')
            creditNotesRef.current.restore()
        if(location.pathname === '/pages/payments')
            paymentsRef.current.restore()
    }

    return (
        <>
            <AppFilters applyFilter={ applyFilter } restoreFilter = { restoreFilter }/>
            <AppHeader/>
            <div className="app-main">
                <AppSideBar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Routes>
                            <Route path="/dashboard" element={ <Dashboard/> }
                            />
                            <Route path="/sales-dashboard" element={ <SalesDashboard/> }
                            />

                            <Route path="/companies" element={ <CompanyPage ref={ companiesRef }/> }/>
                            <Route path="/new-company" element={ <NewCompanyPage/> }/>
                            <Route path="/new-company/:idCompany" element={ <NewCompanyPage/> }/>

                            <Route path="/users" element={ <UsersPage ref={ usersRef }/> }/>
                            <Route path="/new-user" element={ <UserPage/> }/>
                            <Route path="/new-user/:idUser" element={ <UserPage/> }/>

                            <Route path="/branch-offices" element={ <BranchOfficesPage ref={ branchOfficesRef }/> }/>
                            <Route path="/branch-office" element={ <BranchOfficePage/> }/>
                            <Route path="/branch-office/:idBranchOffice" element={ <BranchOfficePage/> }/>

                            <Route path="/clients" element={ <ClientsPage ref={ clientsRef }/> }/>
                            <Route path="/new-client" element={ <ClientPage/> }/>
                            <Route path="/new-client/:idCustomer" element={ <ClientPage/> }/>

                            <Route path="/products" element={ <ProductsPage ref={ productsRef }/> }/>
                            <Route path="/new-product" element={ <ProductPage/> }/>
                            <Route path="/new-product/:idMasterProduct" element={ <ProductPage/> }/>

                            <Route path="/roles" element={ <RolesPage ref={ rolesRef }/> }/>
                            <Route path="/services" element={ <ServicesPage ref={ servicesRef }/> }/>
                            <Route path="/client-types" element={ <ClientTypes ref={ clientTypesRef }/> }/>
                            <Route path="/colors" element={ <Colors ref={ colorsRef }/> }/>
                            <Route path="/client-status" element={ <ClientStatus ref={ clientStatusRef }/> }/>
                            <Route path="/sat-unit-measures" element={ <SatGeneralCatalogs
                                pageTitleHeadingText="Unidades de medida SAT"
                                pageTitleSubheadingText="Gestión de unidades de medida de acuerdo con el SAT"
                                modalTitle="Agregar unidad de medida"
                                endpointDataTable="MeasurementUnit/getMeasurementUnitsList?filterMeasurement=Pieza"
                                headers={ { 'Authorization' : 'Bearer ' + localStorage.getItem('sToken') } }
                                pagePath="sat-unit-measures"
                                formDataToStore={ { id: 0, sClaveSAT: '', sMeasurementUnit: '', } }
                                alertMessages={ { addMessage: 'Unidad de medida creada exitosamente', updateMessage: 'Unidad de medida actualizada exitosamente' } }
                                ref={ satCatalogRef }
                            /> }/>
                            <Route path="/tax-regimes" element={ <SatGeneralCatalogs
                                pageTitleHeadingText="Régimen fiscal del SAT"
                                pageTitleSubheadingText="Gestión de régimen fiscal de acuerdo con el SAT"
                                modalTitle="Agregar régimen fiscal"
                                endpointDataTable="TaxRegime/getTaxRegimeList"
                                headers={ { 'Authorization' : 'Bearer ' + localStorage.getItem('sToken') } }
                                pagePath="tax-regimes"
                                formDataToStore={ { id: 0, sClaveSAT: '', sTaxRegime: '', } }
                                alertMessages={ { addMessage: 'Régimen creado exitosamente', updateMessage: 'Régimen actualizado exitosamente' } }
                                ref={ satCatalogRef }
                            /> }/>
                            <Route path="/sat-products" element={ <SatGeneralCatalogs
                                pageTitleHeadingText="Productos del SAT"
                                pageTitleSubheadingText="Gestión de productos y servicios de acuerdo con el SAT"
                                modalTitle="Agregar producto o servicio"
                                endpointDataTable="ServiceProduct/getServicesProductsList?filterProdServ=piezas"
                                headers={ { 'Authorization' : 'Bearer ' + localStorage.getItem('sToken') } }
                                pagePath="sat-products"
                                formDataToStore={ { id: 0, sClaveSAT: '', sProductService: '', } }
                                alertMessages={ { addMessage: 'Producto del SAT creado exitosamente', updateMessage: 'Producto del SAT actualizado exitosamente' } }
                                ref={ satCatalogRef }
                            /> }/>
                            <Route path="/unit-measures" element={ <UnitMeasures ref={ unitMeasurementsRef }/> }/>
                            <Route path="/modules" element={ <Modules ref={ modulesRef }/> }/>

                            <Route path="/products-list" element={ <ProductsListPage ref={ productListsRef }/> }/>
                            <Route path="/new-product-list" element={ <ProductListPage/> }/>
                            <Route path="/new-product-list/:idListProduct" element={ <ProductListPage/> }/>

                            <Route path="/orders" element={ <OrdersPage ref={ ordersRef }/> }/>
                            <Route path="/new-order" element={ <OrderPage/> }/>
                            <Route path="/new-order/:idOrder" element={ <OrderPage/> }/>

                            <Route path="/visits" element={ <VisitsPage ref={ visitsRef }/> }/>
                            <Route path="/issues" element={ <IssuesPage ref={ issuesRef }/> }/>
                            <Route path="/production" element={ <ProductionPage ref={ productionRef }/> }/>
                            <Route path="/edit-production/:idOrder" element={ <EditProductionPage/> }/>

                            <Route path="/invoices" element={ <InvoicesPage ref={ invoicesRef }/> }/>
                            <Route path="/new-invoice" element={ <InvoicePage/> }/>
                            <Route path="/new-invoice/:orders" element={ <InvoicePage/> }/>
                            <Route path="/edit-invoice/:idPreInvoice" element={ <EditInvoicePage/> }/>

                            <Route path="/credit-notes" element={ <CreditNotesPage ref={ creditNotesRef }/> }/>
                            <Route path="/new-credit-note" element={ <CreditNotePage/> }/>
                            <Route path="/edit-credit-note/:idPreCreditNote" element={ <CreditNotePage/> }/>

                            <Route path="/payments" element={ <PaymentsPage ref={ paymentsRef }/> }/>
                            <Route path="/new-payment" element={ <PaymentPage/> }/>
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    )
}

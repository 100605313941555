
import { AppRouter } from './router/AppRouter';

export const LavaKleenApp = () => {
    
    return (
        <AppRouter/>
    )
}

export default LavaKleenApp;

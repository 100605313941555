import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, FormGroup, Label } from "reactstrap";
import { Form, Input } from '@availity/form';
import * as yup from "yup";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import { PageTitle } from '../../layout/components/PageTitle';
import { useEffect, useImperativeHandle, useState } from 'react';
import { lavakleenApi } from '../../api/lavakleenApi';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css'
import { convertArrayOfObjectsToCSV, filterDataFromApi } from '../../api/providers/providers';
import { forwardRef } from 'react';
import { ModalComponent } from '../components/ModalComponent';
import { useSelector } from 'react-redux';

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken')
}

export const UsersPage = forwardRef( (props, ref) => {
    useImperativeHandle( ref, () => ({
        async filterData(values){
            let filter = ''
            if(role !== 1)
                values.filter = `?idCompany=${localStorage.getItem('idCompany')}`
            const result = await filterDataFromApi('User/getUsersList', values, headers);
            setData(result.userList)
        },

        async restore() {
            await getUsers()
        }
    }) )

    const navigate = useNavigate();

    const { role } = useSelector( state => state.auth );

    const [data, setData] = useState([]);
    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)
    const [formDataPassword, setFormDataPassword] = useState({
        idUser: 0,
        newPassword: '',
        userName: '',
    })

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
        saveDataToEndpoint: ''
    })

    const [{isOpen, className, backdrop}, setModalProps] = useState({ isOpen: false, className: 'modal fade', backdrop: false })

    const columns = [
        {
            name: "ID",
            selector: row => row.idUser,
            sortable: true,
        },
        {
            name: "Nombre de usuario",
            id: "sName",
            selector: row => row.sName,
            sortable: true,
        },
        
        {
            name: "Cuenta de usuario",
            selector: row => row.sUser,
            sortable: true,
        },
        {
            name: "Rol de usuario",
            selector: row => row.sRol,
            sortable: true,
        },
        
        {
            name: "Status",
            selector: row => row.sStatusUser,
            sortable: true,
        },
        {
            name: "Opciones",
            cell: (row) => (
                <>
                    <Link to={ `/pages/new-user/${row.idUser}` } className="btn btn-primary">
                        <i className="lnr-pencil"></i>
                    </Link>
                    <Button color="warning" onClick={ () => changePasswordUser(row) }>
                        <FontAwesomeIcon
                            icon={faKey}
                        />
                    </Button>
                    <Button color="danger" onClick={ () => deleteUser(row.idUser) }><i className="lnr-trash"></i></Button>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            button: true,
            width: '150px',
        },
    ];

    const onAddClick = () => {
        navigate('/pages/new-user')
    }

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const getUsers = async () => {
        let filter = ''
        if(role !== 1)
            filter = `?idCompany=${localStorage.getItem('idCompany')}`
        await lavakleenApi.get(`User/getUsersList${filter}`, {
            headers
        }).then( response => {
            setData( response.data.userList );
        } )
    }

    const deleteUser = (idUser) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Usuarios')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                await lavakleenApi.post(`User/deleteUser?idUser=${idUser}`, {}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                        getUsers();
                } )
            }
        })
    }

    const changePasswordUser = (user) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Usuarios')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        setFormDataPassword({
            id: user.idUser,
            newPassword: '',
            userName: '',
        });
        toggleModal(`User/changePassword`)
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        const filename = 'usuarios.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Usuarios')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Usuarios'))
        
        if(userPerm.bList !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })

            navigate(`/pages/dashboard`)
        }
    }

    useEffect(() => {
        checkPermissions();
        getUsers();
    }, [])

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Usuarios"
                                subheading="Gestion de usuarios del sistema"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                {
                                    permission !== null && permission.bCreate === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="primary"
                                                onClick={ onAddClick }
                                            >
                                                Agregar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }

                                {
                                    permission !== null && permission.bExport === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="danger"
                                                onClick={ () => downloadCsv()}
                                            >
                                                Exportar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }
                            </ul>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                        <DataTable data={data}
                                            columns={columns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight="500px"
                                        />
                                        </CardBody>
                                    </Card>
                                    <ModalComponent
                                        isOpen={modalState.isOpen}
                                        toggle={ toggleModal }
                                        backdrop={ modalState.backDrop }
                                        modalTitle="Cambiar contraseña"
                                        size="md"
                                        initialValues={formDataPassword}
                                        validationSchema={ {
                                            newPassword: yup.string().min(6).required(),
                                        } }
                                        saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                        getDataFunction={ getUsers }
                                        alertMessages = { { updateMessage: 'Password actualizado exitosamente'} }
                                    >
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup className="mb-3">
                                                    <Label for="newPassword">Nueva contraseña</Label>
                                                    <Input type="password" name="newPassword" placeholder=""/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </ModalComponent>
                                </Col>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
            {/* <ModalUser isOpen={isOpen} className={className} backdrop={backdrop} /> */}
        </>
    )
})

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css'
import { PageTitle } from '../../layout/components/PageTitle';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { lavakleenApi } from '../../api/lavakleenApi';
import { convertArrayOfObjectsToCSV, filterDataFromApi } from '../../api/providers/providers';

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany'),
};

export const ProductsListPage = forwardRef( (props, ref) => {
    useImperativeHandle( ref, () => ({
        async filterData(values){
            const result = await filterDataFromApi('ProductList/getListProductList', values, headers);
            setData(result.listPorducts)
        },

        async restore() {
            await getProductsList()
        }
    }) )

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)

    const columns = [
        {
            name: "ID",
            selector: row => row.idListProduct,
            sortable: true,
            width: '80px',
        },
        {
            name: "Nombre lista",
            selector: row => row.sListName,
            sortable: true,
        },
        {
            name: "Fecha alta",
            selector: row => row.dRegisterDate,
            sortable: false,
        },
        {
            name: "Usuario alta",
            selector: row => row.sRegisterUser,
            sortable: false,
        },
        {
            name: "Estatus",
            selector: row => row.sStatusProductList,
            sortable: false,
        },
        {
            name: "Opciones",
            cell: (row) => (
                <>
                    <Button onClick={ () => editList(row) } color="primary">
                        <i className="lnr-pencil"></i>
                    </Button>{ "  "}
                    <Button color="success" onClick={ () => activeInactive(row) } title="Bloquear/Desbloquear"><i className="lnr-lock"></i></Button>
                    <Button color="danger" onClick={ () => deleteRow(row.idListProduct) }><i className="lnr-trash"></i></Button>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            button: true,
            width: '150px'
        },
    ];

    const onAddClick = () => {
        navigate('/pages/new-product-list', {})
    }

    const getProductsList = async () => {
        await lavakleenApi.get('ProductList/getListProductList', {
            headers
        }).then( response => {
            setData( response.data.listPorducts );
        } )
    }

    const deleteRow = (idListProduct) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Lista de productos')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }
        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                await lavakleenApi.post(`ProductList/deleteListProduct?idListProduct=${idListProduct}`, {}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                        getProductsList();
                } )
            }
        })
    }

    const editList = (row) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Lista de productos')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        if(row.sStatusProductList == 'Inactiva') {
            Swal.fire({
                title: 'Error',
                text: 'La lista está inactiva, debes activarla para poder editar.',
                icon: 'error',
            })
            return
        }

        navigate(`/pages/new-product-list/${row.idListProduct}`)
    }

    const activeInactive = (listProduct) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Lista de productos')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const idStatusListProduct = listProduct.sStatusProductList === 'Activa' ? 2 : 1
                await lavakleenApi.post(`ProductList/changeListProductStatus`, {idListProduct: listProduct.idListProduct, idStatusListProduct}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                        getProductsList()
                } )
            }
        })
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Lista de productos')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Lista de productos'))
        
        if(userPerm.bList !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })

            navigate(`/pages/dashboard`)
        }
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        const filename = 'lista_productos.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    useEffect(() => {
        checkPermissions();
        getProductsList();
    }, [])
    

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Listas de productos"
                                subheading="Gestion de listas de productos para tu negocio"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                {
                                    permission !== null && permission.bCreate === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="primary"
                                                onClick={ onAddClick }
                                            >
                                                Agregar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }

                                {
                                    permission !== null && permission.bExport === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="danger"
                                                onClick={ () => downloadCsv()}
                                            >
                                                Exportar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }
                            </ul>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                        <DataTable data={data}
                                            columns={columns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight="500px"
                                        />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
})

import { Form } from "@availity/form"
import * as yup from "yup";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import { saveData } from "../../api/providers/providers";
import { startProcessing, stopProcessing } from "../../store/auth/authSlice";
import { useEffect, useState } from "react";

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
};

export const ModalComponent = ({ isOpen, toggle, className, backdrop, modalTitle, size, children, initialValues = {}, validationSchema = {}, saveDataToEndpoint = '', getDataFunction, alertMessages = {}, dropDownsToValidate = [], showSaveButton = true, saveTemporalData, editTemporalData, textSaveButton = 'Guardar' }) => {
    const dispatch = useDispatch();

    const { id, isProcessing } = useSelector( state => state.auth )

    const [finisRegistering, setFinisRegistering] = useState(false);

    const onFinishRegister = () => {
        setFinisRegistering(true)
        getDataFunction()
    }

    const onSubmit = async (values) => {
        dropDownsToValidate.map( dropdown => {
            values[dropdown.field] = dropdown.value
        } )
        dispatch( startProcessing() )
        if(values.id === 0) {
            const alertConfig = {
                title: 'Exito',
                text: alertMessages.addMessage,
                icon: 'success',
                willClose: () => {
                    onFinishRegister()
                    toggle()
                }
            };

            if(saveDataToEndpoint.includes('addTaxRegime'))
                saveDataToEndpoint += `?sClaveSAT=${values.sClaveSAT}&sTaxRegime=${values.sTaxRegime}`
            if(saveDataToEndpoint.includes('addMeasurementUnit'))
                saveDataToEndpoint += `?sClaveSAT=${values.sClaveSAT}&sMeasurementUnits=${values.sMeasurementUnit}`
            if(saveDataToEndpoint.includes('addCustomerStatus'))
                saveDataToEndpoint += `?sCustomerStatus=${values.sCustomerStatus}`
            if(saveDataToEndpoint.includes('addCustomerType'))
                saveDataToEndpoint += `?sCustomerType=${values.sCustomerType}`
            if(saveDataToEndpoint.includes('addProductOrService'))
                saveDataToEndpoint += `?sClaveSAT=${values.sClaveSAT}&sProdServ=${values.sProductService}`
            if(saveDataToEndpoint.includes('addColor'))
                saveDataToEndpoint += `?sColorName=${values.sColorName}`

            if(!saveDataToEndpoint.includes('addOrderDetail') && saveDataToEndpoint !== 'creditNoteDetail' && saveDataToEndpoint !== 'relatedDocument' && saveDataToEndpoint !== 'paymentDetail')
                await saveData( saveDataToEndpoint, values, headers, alertConfig );
            else
                saveTemporalData(values)
        }

        if(values.id > 0) {
            if(saveDataToEndpoint.includes('editServiceType'))
                values.sServiceType = values.sServiceName

            const alertConfig = {
                title: 'Exito',
                text: alertMessages.updateMessage,
                icon: 'success',
                willClose: () => {
                    onFinishRegister()
                    toggle()
                }
            };

            if(saveDataToEndpoint.includes('changePassword'))
                saveDataToEndpoint += `?idUser=${values.id}&newPassword=${values.newPassword}`

            if(!saveDataToEndpoint.includes('editOrderDetail') && saveDataToEndpoint !== 'creditNoteDetail' && saveDataToEndpoint !== 'relatedDocument' && saveDataToEndpoint !== 'paymentDetail')
                await saveData( saveDataToEndpoint, values, headers, alertConfig );
            else
                editTemporalData(values)
        }

        dispatch( stopProcessing() )
        // selectedOption == null ? setInvalid(true) : setInvalid(false)
        // selectedOptionState == null ? setInvalidState(true) : setInvalidState(false)
        // selectedOptionCity == null ? setInvalidCity(true) : setInvalidCity(false)
        // selectedOptionColony == null ? setInvalidColony(true) : setInvalidColony(false)
        // if(invalid || invalidState || invalidCity || invalidColony)
        //     return

        // values.idTaxRegime = selectedOption.value
        // values.idRegisterUser = id;
        // values.sBuinessName = values.sBuinessName.toUpperCase()
        // values.idState = selectedOptionState.value
        // values.idCity = selectedOptionCity.value
        // values.idColony = selectedOptionColony.value
        // if(idCompany !== undefined)
        //     values.idCompany = parseFloat(idCompany);

        // if(idCompany === undefined) {
        //     const alertConfig = {
        //         title: 'Exito',
        //         text: 'Empresa creada exitosamente!!',
        //         icon: 'success',
        //         willClose: () => {
        //             onFinishRegister()
        //         }
        //     };

        //     await saveData( 'Company/addCompany', values, headers, alertConfig );
        // }

        // if(idCompany !== undefined) {
        //     const alertConfig = {
        //         title: 'Exito',
        //         text: 'Empresa actualizada exitosamente!!',
        //         icon: 'success',
        //         willClose: () => {
        //             onFinishRegister()
        //         }
        //     };

        //     await saveData( 'Company/editCompany', values, headers, alertConfig );
        // }
    }

    useEffect(() => {
        if(isProcessing == true) {

            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }

        if(!isProcessing) Swal.close()
    }, [isProcessing])

    return (
        <Modal isOpen={isOpen} toggle={toggle} className={className} backdrop={backdrop} size={ size }>
            <Form
                initialValues={initialValues}
                onSubmit={ (values) => onSubmit(values) }
                validationSchema={
                    yup.object(validationSchema)
                }
                enableReinitialize
            >
                <ModalHeader>{ modalTitle }</ModalHeader>
                <ModalBody>
                    { children }
                </ModalBody>
                <ModalFooter>
                    <Button color="link" onClick={toggle}>
                        Cerrar
                    </Button>
                    {
                        showSaveButton && (
                            <Button color="primary" type="submit">
                                { textSaveButton }
                            </Button>
                        )
                    }
                </ModalFooter>
            </Form>
        </Modal>
    )
}

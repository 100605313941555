import classnames from "classnames";
import { NavItem, NavLink } from "reactstrap";

export const TabHeaderComponent = ({ id, label, state, toggle }) => {

    return (
        <NavItem>
            <NavLink href="#"
                className={classnames({
                    active: state.activeTab === id,
                })}
                onClick={() => {
                    toggle(id);
                }}>
                {label}
            </NavLink>
        </NavItem>
    )
}

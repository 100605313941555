import { useNavigate } from "react-router-dom";
import { Col, Row, Button, FormGroup, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import Select from "react-select";
import AsyncSelect from 'react-select/async';

import { Form, Input } from '@availity/form';

import bg3 from "../../assets/utils/images/originals/citynights.jpg";
import { Link } from "react-router-dom";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import { useEffect, useState } from "react";
import { startRegisterUser } from "../../store/auth/thunks";
import { lavakleenApi } from "../../api/lavakleenApi";

const formData = { 
    sName: '',
    sLastName: '',
    sEmail: '',
    sPassword: '',
    sUser: '',
    sConfirmPassword: '',
    bCheckTerms: false,
    sBuinessName: '',
    sCompanyName: '',
    sRfc: '',
    sBusinessAddress: '',
    sPrincipalPhone: '',
    sExtNumber: '',
    sIntNumber: '',
    idState: null,
    idCity: null,
    idColony: 1,
    sColony: '',
    sCp: '',
    sWebsite: '',
    sCompanyEmail: '',
    sImagePath: '',
    sCsd: '',
    sKey: '',
    sSatpassword: '',
    idTaxRegime: 0,
    idUserRegister: 0,
    idRol: 2,
};

export const RegisterPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isProcessing, finishRegister } = useSelector( state => state.auth );

    const [selectedOptionState, setSelectedOptionState] = useState(null);
    const [selectedOptionCity, setSelectedOptionCity] = useState(null);
    const [selectedOptionColony, setSelectedOptionColony] = useState(null);
    const [selectedOptionTaxRegime, setSelectedOptionTaxRegime] = useState(null);

    const [optionsState, setOptionsState] = useState([]);
    const [optionsCities, setOptionsCities] = useState([]);
    const [optionsColonies, setOptionsColonies] = useState([]);
    const [optionsTaxRegime, setOptionsTaxRegime] = useState([]);

    const [invalidState, setInvalidState] = useState(true);
    const [invalidCity, setInvalidCity] = useState(true);
    const [invalidColony, setInvalidColony] = useState(true);
    const [invalidTaxRegime, setInvalidTaxRegime] = useState(true);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true,
    };

    const onRegister = (values) => {
        if(values.sPassword !== values.sConfirmPassword) {
            return Swal.fire('Información', 'La contraseña no coincide', 'warning');
        }
        values.idTaxRegime = selectedOptionTaxRegime.value
        values.sBuinessName = values.sBuinessName.toUpperCase()
        values.idState = selectedOptionState.value
        values.idCity = selectedOptionCity.value
        values.idColony = 1
        values.sCompanyEmail = values.sEmail

        dispatch( startRegisterUser({
            ...values,
            idRol: 2,
            idUserRegister: 1,
            sAlternatePhone: '',
            sWebsite: '',
            sImagePath: '',
            sCsd: '',
            sKey: '',
            sSatpassword: '',
        }) );
    }

    const getDropdownData = async () => {
        await lavakleenApi.get('CommonMaster/getTaxRegime', {
            // headers
        }).then( response => {
            const optionsArray = []
            response.data.options.map( option => {
                optionsArray.push({value: option.idOpcion, label: option.sOpcion})
            } )
            setOptionsTaxRegime( optionsArray );
        } )

        await lavakleenApi.get('CommonMaster/getStates', {
            // headers
        }).then( response => {
            const optionsArray = []
            response.data.options.map( option => {
                optionsArray.push({value: option.idOpcion, label: option.sOpcion})
            } )
            setOptionsState( optionsArray );
        } )
    }

    const getCities = async (state) => {
        await lavakleenApi.get(`CommonMaster/getCities?idState=${state.value}`, {
        }).then( response => {
            const optionsArray = []
            response.data.options.map( option => {
                optionsArray.push({value: option.idOpcion, label: option.sOpcion})
            } )
            setOptionsCities( optionsArray );
        } )
    }

    const getColonies = async (inputValue) => {
        const optionsArray = []
        if(selectedOptionState && selectedOptionCity) {
            await lavakleenApi.get(`CommonMaster/getColony?idState=${selectedOptionState.value}&idCity=${selectedOptionCity.value}&filter=${inputValue}`, {
            }).then( response => {
                
                response.data.options.map( option => {
                    optionsArray.push({value: option.idOpcion, label: option.sOpcion})
                } )
            } )
        }

        setOptionsColonies(optionsArray)

        return optionsArray;
    }

    const getData = async () => {
        await getDropdownData();
    }

    const loadColonies = (inputValue) => {
        if(inputValue.length > 5 && selectedOptionState && selectedOptionCity) {
            return new Promise((resolve) => {
                resolve(getColonies(inputValue))
            })
        }
    }

    useEffect(() => {
        if(isProcessing == true) {

            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }

        if(!isProcessing) Swal.close()
    }, [isProcessing])

    useEffect(() => {
        if(finishRegister == true) {
            navigate('/auth/login', {
                replace: true
            })
        }
    }, [finishRegister])

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <div className="h-100">
                <Row className="g-0">
                    <Col lg="7" md="12" className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
                        <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                            <br/><br/><br/><br/>
                            {/* <div className="app-logo" /> */}
                            <h4>
                                <div>Bienvenido,</div>
                                <span>
                                    Sólo toma{" "}
                                    <span className="text-success">unos minutos</span> crear tu cuenta, ingresa tus datos personales y los de tu negocio
                                </span>
                            </h4>
                            <div>
                                <Form
                                    initialValues={formData}
                                    onSubmit={ (values) => onRegister(values) }
                                    validationSchema={
                                        yup.object({
                                            sName: yup.string().required(),
                                            sLastName: yup.string().required(),
                                            sEmail: yup.string().email().required(),
                                            sPassword: yup.string().min(6).required(),
                                            sUser: yup.string().min(10).matches(/^[A-Z]/i).required(),
                                            sConfirmPassword: yup.string().min(6).required(),
                                            bCheckTerms: yup.boolean().oneOf([true]),
                                            sBuinessName: yup.string().required(),
                                            sBusinessAddress: yup.string().required(),
                                            sExtNumber: yup.string().required(),
                                            sColony: yup.string().required(),
                                            sCp: yup.string().min(5).required(),
                                            sPrincipalPhone: yup.string().min(10).max(12).required(),
                                            sRfc: yup.string().required(),
                                        })
                                    }
                                >
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="sName">
                                                    <span className="text-danger">*</span> Nombre
                                                </Label>
                                                <Input type="text" name="sName" placeholder="Coloca tu nombre..."/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="sLastName">
                                                    <span className="text-danger">*</span> Apellidos
                                                </Label>
                                                <Input type="text" name="sLastName" placeholder="Coloca tus apellidos..."/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="sUser">
                                                    <span className="text-danger">*</span> Nombre de usuario
                                                </Label>
                                                <Input type="text" name="sUser" placeholder="Coloca tu nombre de usuario..."/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="sEmail">
                                                    <span className="text-danger">*</span> Correco electrónico
                                                </Label>
                                                <Input type="email" name="sEmail" placeholder="Coloca tu correo..."/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="sPassword">
                                                    <span className="text-danger">*</span> Contraseña
                                                </Label>
                                                <Input type="password" name="sPassword" placeholder="Coloca tu contraseña..."/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="sConfirmPassword">
                                                    <span className="text-danger">*</span> Confirmar constraseña
                                                </Label>
                                                <Input type="password" name="sConfirmPassword" placeholder="Confirma tu contraseña..."/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="sBuinessName">
                                                    <span className="text-danger">*</span> Razón social
                                                </Label>
                                                <Input type="text" name="sBuinessName" placeholder="Ingresa la razón social del negocio"/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="sCompanyName">Nombre comercial</Label>
                                                <Input type="text" name="sCompanyName" placeholder="Ingresa el nombre comercial del negocio"/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="sBusinessAddress">
                                                    <span className="text-danger">*</span> Calle
                                                </Label>
                                                <Input type="text" name="sBusinessAddress" placeholder="Ingresa la calle"/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="sExtNumber">
                                                    <span className="text-danger">*</span> No. Exterior
                                                </Label>
                                                <Input type="text" name="sExtNumber" placeholder="Ingresa el número"/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="sIntNumber">No. Interior</Label>
                                                <Input type="text" name="sIntNumber" placeholder="Ingresa el número"/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="idState">
                                                    <span className="text-danger">*</span> Estado
                                                </Label>
                                                <Select
                                                    defaultValue={selectedOptionState}
                                                    onChange={ (option) => {
                                                        option == null ? setInvalidState(true) : setInvalidState(false)
                                                        setSelectedOptionState(option)
                                                        if(option)
                                                            getCities(option)
                                                    }}
                                                    options={optionsState}
                                                    name="idState"
                                                    key={ selectedOptionState }
                                                    placeholder="Selecciona una opción"
                                                    className={ invalidState ? 'is-invalid form-control' : '' }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="idCity">
                                                    <span className="text-danger">*</span> Ciudad
                                                </Label>
                                                <Select
                                                    defaultValue={selectedOptionCity}
                                                    onChange={ (option) => {
                                                        option == null ? setInvalidCity(true) : setInvalidCity(false)
                                                        setSelectedOptionCity(option)
                                                    }}
                                                    options={optionsCities}
                                                    name="idCity"
                                                    key={ selectedOptionCity }
                                                    placeholder="Selecciona una opción"
                                                    className={ invalidCity ? 'is-invalid form-control' : '' }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="sColony">Colonia</Label>
                                                <Input type="text" name="sColony" placeholder="Ingresa la colonia"/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="sCp">
                                                    <span className="text-danger">*</span> Código postal
                                                </Label>
                                                <Input type="text" name="sCp" placeholder="Ingresa el código postal"/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="sRfc">
                                                    <span className="text-danger">*</span> RFC
                                                </Label>
                                                <Input type="text" name="sRfc" placeholder="Ingresa el RFC para facturar"/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="idTaxRegime">
                                                    <span className="text-danger">*</span> Régimen fiscal
                                                </Label>
                                                <Select
                                                    defaultValue={selectedOptionTaxRegime}
                                                    onChange={ (option) => {
                                                        option == null ? setInvalidTaxRegime(true) : setInvalidTaxRegime(false)
                                                        setSelectedOptionTaxRegime(option)
                                                    }}
                                                    options={optionsTaxRegime}
                                                    name="idTaxRegime"
                                                    key={ selectedOptionTaxRegime }
                                                    placeholder="Selecciona una opción"
                                                    className={ invalidTaxRegime ? 'is-invalid form-control' : '' }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup className="mb-3">
                                                <Label for="sPrincipalPhone">Teléfono principal</Label>
                                                <Input type="text" name="sPrincipalPhone" placeholder="Ingresa el teléfono principal del negocio"/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup className="mt-3" check>
                                        <Input type="checkbox" name="bCheckTerms" id="bCheckTerms" />
                                        <Label for="bCheckTerms" check>
                                            Aceptar nuestros{" "}
                                            <a href="#">
                                            Términos y condiciones
                                            </a>
                                            .
                                        </Label>
                                    </FormGroup>
                                    <div className="mt-4 d-flex align-items-center">
                                        <h5 className="mb-0">
                                            ¿Ya estás registrado?{" "}
                                            <Link to="/auth/login" className="text-primary">
                                            Ingresar
                                            </Link>
                                        </h5>
                                        <div className="ms-auto">
                                            <Button color="primary" className="btn-wide btn-pill btn-shadow btn-hover-shine" size="lg" type="submit">
                                            Crear cuenta
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Col>
                    <Col lg="5" className="d-lg-flex d-xs-none">
                        <div className="slider-light">
                            <Slider {...settings}>
                            <div className="h-100 d-flex justify-content-center align-items-center">
                                <div className="slide-img-bg"
                                style={{
                                    // backgroundImage: "url(" + bg3 + ")",
                                }}/>
                                <div className="slider-content">
                                    {/* <h3>LavaKleen</h3>
                                    <p>
                                        La mejor plataforma para administrar tu lavandería!!!
                                    </p> */}
                                    <div className="app-logo" />
                                </div>
                            </div>
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
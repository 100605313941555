import { useSelector } from "react-redux";
import cx from "classnames";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PerfectScrollbar from "react-perfect-scrollbar";
import { VerticalNavWrapper } from "../nav/VerticalNavWrapper";
import { HeaderLogo } from "../main/logo/HeaderLogo";

export const AppSideBar = () => {

    const {backgroundColor, enableSidebarShadow, backgroundImageOpacity, enableBackgroundImage, backgroundImage} = useSelector( state => state.themeOptions);

    const toggleMobileSidebar = () => {

    }

    return (
        <>
            <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar}/>
            <TransitionGroup>
                <CSSTransition component="div"
                    className={cx("app-sidebar", backgroundColor, {
                    "sidebar-shadow": enableSidebarShadow,
                    })}
                    appear={true} enter={false} exit={false} timeout={500}>
                    <div>
                        <HeaderLogo />
                        <PerfectScrollbar>
                            <div className="app-sidebar__inner">
                                <VerticalNavWrapper />
                            </div>
                        </PerfectScrollbar>
                        <div className={cx("app-sidebar-bg", backgroundImageOpacity)}
                            style={{
                            backgroundImage: enableBackgroundImage
                                ? "url(" + backgroundImage + ")"
                                : null,
                            }}>
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

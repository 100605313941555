import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, FormGroup, Label, Input as InputReadonly } from "reactstrap";
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageTitle } from '../../layout/components/PageTitle';
import { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { lavakleenApi } from '../../api/lavakleenApi';
import { ModalComponent } from '../components/ModalComponent';
import { forwardRef } from 'react';
import { convertArrayOfObjectsToCSV, filterDataFromApi, getDataFromApi, saveData, baseUrl } from '../../api/providers/providers';
import { faCheckCircle, faClock, faCloudUpload, faDollarSign, faDolly, faMoneyBill, faTruck, faUpload, faWrench } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { startProcessing, stopProcessing } from '../../store/auth/authSlice';

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany'),
};

export const OrdersPage = forwardRef( (props, ref) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useImperativeHandle( ref, () => ({
        async filterData(values){
            dispatch( startProcessing() )
            const result = await filterDataFromApi('Order/getOrderList', values, headers);
            setData(result.orderList)
            dispatch( stopProcessing() )
        },

        async restore() {
            await getOrders()
        }
    }) )

    const ordersForInvoiceRef = useRef([]);

    const { id, isProcessing } = useSelector( state => state.auth )

    const [data, setData] = useState([]);
    const [closeMessage, setCloseMessage] = useState(false);
    const [dataHistory, setDataHistory] = useState([]);
    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 150);
    const [sumTotal, setSumTotal] = useState(0);
    const [sumPayments, setSumPayments] = useState(0);
    const [sumBalance, setSumBalance] = useState(0);

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
    })

    const paginationOptions = {
        paginationRowsPerPageOptions: [20, 40, 60, 80, 100]
    }

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const columns = [
        {
            name: "Opciones",
            cell: (row) => (
                <>
                    <Link to={ `/pages/new-order/${row.idOrder}` } className="btn btn-primary" title="Editar">
                        <i className="lnr-pencil"></i>
                    </Link>
                    <Button color="success" onClick={ () => activeInactiveOrder(row) } title="Bloquear/Desbloquear"><i className="lnr-lock"></i></Button>
                    <Button color="danger" onClick={ () => deleteOrder(row) } title="Eliminar"><i className="lnr-trash"></i></Button>
                    <Button color="warning" onClick={ () => viewOrderHistory(row.idOrder) } title="Ver historial"><i className="lnr-book"></i></Button>
                    <Button color="secondary" onClick={ () => printTicket(row.idOrder) } title="Imprimir ticket"><i className="lnr-printer"></i></Button>
                    <Button color="info" onClick={ () => printTicketRma(row.idOrder) } title="Imprimir RMA"><i className="lnr-tag"></i></Button>
                    {
                        (row.idOrderProcess == 1)
                        ? <Button color="dark" onClick={ () => changeOrderProcess(row.idOrder, 2) } title="Cambiar a EN ESPERA">
                            <FontAwesomeIcon
                                icon={faCloudUpload}
                            />
                        </Button>
                        : ''
                    }

                    {
                        (row.idOrderProcess == 2)
                        ? <Button color="dark" onClick={ () => changeOrderProcess(row.idOrder, 3) } title="Cambiar a EN PROCESO">
                            <FontAwesomeIcon
                                icon={faWrench}
                            />
                        </Button>
                        : ''
                    }

                    {
                        (row.idOrderProcess == 3)
                        ? <Button color="dark" onClick={ () => changeOrderProcess(row.idOrder, 4) } title="Cambiar a PROCESADO">
                            <FontAwesomeIcon
                                icon={faDolly}
                            />
                        </Button>
                        : ''
                    }
                    {
                        (row.idOrderProcess == 4)
                        ? <Button color="dark" onClick={ () => changeOrderProcess(row.idOrder, 5) } title="Cambiar a PARA ENTREGA">
                            <FontAwesomeIcon
                                icon={faTruck}
                            />
                        </Button>
                        : ''
                    }
                    {
                        (row.idOrderProcess == 5)
                        ? <Button color="dark" onClick={ () => changeOrderProcess(row.idOrder, 6) } title="Cambiar a FINALIZADO">
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                            />
                        </Button>
                        : ''
                    }
                    {
                        (row.idOrderProcess == 6)
                        ? <Button color="dark" onClick={ () => console.log('invoice') } title="Enviar a FACTURACIÓN">
                            <FontAwesomeIcon
                                icon={faMoneyBill}
                            />
                        </Button>
                        : ''
                    }
                    <Button color="light" onClick={ () => sendTicket(row.idOrder) } title="Enviar ticket por mail"><i className="lnr-envelope"></i></Button>
                    {
                        (row.sSignPath !== '')
                        ? <Button color="primary" onClick={ () => openSignature(row) } title="Ver firma del pedido"><i className="lnr-highlight"></i></Button>
                        : ''
                    }
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            button: true,
            width: '350px'
        },
        {
            name: "Facturable",
            cell: (row) => (
                <>
                    {
                        (row.idOrderProcess == 6)
                        ? <>
                            <div className="mb-2 me-2 badge bg-success">Facturable</div> {` `}
                        </>
                        : <div className="mb-2 me-2 badge bg-danger">No Facturable</div>
                    }
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            width: '150px'
        },
        {
            name: "Folio",
            selector: row => row.idOrder,
            sortable: true,
            width: '80px',
        },
        {
            name: "Cliente",
            selector: row => row.sBusinessName,
            sortable: true,
            width: '250px',
            wrap: true,
        },
        {
            name: "Servicio",
            selector: row => row.sServiceName,
            sortable: true,
            width: '160px',
        },
        
        {
            name: "Fecha registro",
            selector: row => row.dRegisterDate.substring(0, 10),
            sortable: true,
            width: '130px',
        },
        {
            name: "Proceso",
            selector: row => row.sOrderProcess,
            cell: (row) => (
                <>
                    {
                        (row.idOrderProcess == 1)
                        ? <FontAwesomeIcon icon={faCloudUpload} size="2x" color='gray'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 2)
                        ? <FontAwesomeIcon icon={faClock} size="2x" color='red'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 3)
                        ? <FontAwesomeIcon icon={faWrench} size="2x" color='black'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 4)
                        ? <FontAwesomeIcon icon={faDolly} size="2x" color='orange'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 5)
                        ? <FontAwesomeIcon icon={faTruck} size="2x" color='blue'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 6)
                        ? <FontAwesomeIcon icon={faCheckCircle} size="2x" color='green'/> 
                        : ''
                    }
                    {row.sOrderProcess}
                </>
            ),
            sortable: false,
            width: '160px',
        },
        {
            name: "Factura",
            cell: (row) => (
                <>
                    {
                        (row.idInvoice == 0)
                        ? <div className="mb-2 me-2 badge bg-secondary">Sin factura</div>
                        : row.idInvoice
                    }
                </>
            ),
            sortable: false,
            width: '150px'
        },
        {
            name: "Total",
            selector: row => row.dTotal.toFixed(2),
            sortable: false,
        },
        {
            name: "Pagado",
            selector: (row) => {
                let payments = 0
                
                row.paymentList.map( payment => {
                    payments += payment.dAmount
                } )

                return payments.toFixed(2)
                
            },
            sortable: false,
        },
        {
            name: "Saldo",
            selector: (row) => {
                let payments = 0

                row.paymentList.map( payment => {
                    payments += payment.dAmount
                } )

                return (row.dTotal - payments).toFixed(2)
            } ,
            sortable: false,
        },
        {
            name: "Fecha entrega",
            selector: row => row.dDeliveryDate.substring(0, 10),
            sortable: true,
            width: '130px',
        },
        {
            name: "Estatus",
            selector: row => row.sStatus,
            sortable: false,
        },
        {
            name: "Peso (KG)",
            selector: row => row.dTotalWeigth,
            sortable: false,
        },
    ];

    const columnsHistory = [
        {
            name: "Estatus",
            selector: row => row.sOrderProcess,
            cell: (row) => (
                <>
                    {
                        (row.idOrderProcess == 1)
                        ? <FontAwesomeIcon icon={faCloudUpload} size="2x" color='gray'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 2)
                        ? <FontAwesomeIcon icon={faClock} size="2x" color='red'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 3)
                        ? <FontAwesomeIcon icon={faWrench} size="2x" color='black'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 4)
                        ? <FontAwesomeIcon icon={faDolly} size="2x" color='orange'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 5)
                        ? <FontAwesomeIcon icon={faTruck} size="2x" color='blue'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 6)
                        ? <FontAwesomeIcon icon={faCheckCircle} size="2x" color='green'/> 
                        : ''
                    }
                    {row.sOrderProcess}
                </>
            ),
            sortable: false,
            width: '200px',
        },
        {
            name: "Usuario",
            selector: row => row.usuario,
            sortable: true,
        },
        {
            name: "Fecha",
            selector: row => row.dRegisterDate,
            sortable: true,
            width: '180px',
        },
    ];

    const onAddClick = () => {
        navigate('/pages/new-order', {})
    }

    const onAddInvoiceClick = () => {
        if(ordersForInvoiceRef.current.length == 0) {
            Swal.fire({
                title: 'Error',
                text: 'Debes seleccionar al menos un pedido',
                icon: 'error',
            })
            return
        }

        navigate(`/pages/new-invoice/${ordersForInvoiceRef.current.join('|')}`, {})
    }

    const getOrders = async () => {
        dispatch( startProcessing() )
        await lavakleenApi.get('Order/getOrderList', {
            headers
        }).then( response => {
            setData( response.data.orderList );
        } )

        dispatch( stopProcessing() )
    }

    const addOrderForInvoice = (order, checkId) => {
        let OrdersId = ordersForInvoiceRef.current
        const check = document.getElementById(checkId)
        
        if(check.checked) {
            const el = OrdersId.find(element => element === order.idOrder)
            if(el === undefined)
                OrdersId.push(order.idOrder)
        } else {
            const el = OrdersId.findIndex(element => element === order.idOrder)
            OrdersId.splice(el, 1)
        }

        ordersForInvoiceRef.current = OrdersId
        console.log(ordersForInvoiceRef.current)
    }

    const deleteOrder = (order) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Pedidos')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        if(order.idInvoice > 0) {
            Swal.fire({
                title: 'Error',
                text: 'No se puede eliminar el pedido porque ya fue facturado',
                icon: 'error',
            })
            return
        }
        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                await lavakleenApi.post(`Order/deleteOrder?idOrder=${order.idOrder}`, {}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                        getOrders()
                    else {
                        Swal.fire({
                            title: 'Error',
                            text: response.data.sErrors[0],
                            icon: 'error',
                        })
                    }
                } )
            }
        })
    }

    const viewOrderHistory = async (idOrder) => {
        const result = await getDataFromApi(`Order/getHistoryList?idOrder=${idOrder}`, idOrder, [], headers)
        if(result.status === 'success') {
            setDataHistory(result.historyList)
            toggleModal('')
        }
    }

    const nothingToDo = () => {
        
    }

    const printTicket = (idOrder) => {
        window.open(`/ticket-order/${idOrder}`, '_blank')
    }

    const printTicketRma = (idOrder) => {
        window.open(`/ticket-order-rma/${idOrder}`, '_blank')
    }

    const sendTicket = async (idOrder) => {
        dispatch( startProcessing() )
        await lavakleenApi.post(`Order/sendEmail?idOrder=${idOrder}`, {}, {
            headers
        }).then( response => {
            if(response.data.bResult == true && response.data.sErrors.length == 0) {
                setTimeout(() => {
                    Swal.fire({
                        title: 'Exito',
                        text: 'Ticket enviado correctamente',
                        icon: 'success',
                    })
                }, 200);
            }
            else {
                setTimeout(() => {
                    Swal.fire({
                        title: 'Error',
                        text: response.data.sErrors[0],
                        icon: 'error',
                    })
                }, 200);
            }
        } )

        dispatch( stopProcessing() )
    }

    const openSignature = (row) => {
        window.open( baseUrl + row.sSignPath.replace(`\\`, `/`), '_blank')
    }

    const changeOrderProcess = async (idOrder, status) => {

        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Pedidos')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const alertConfig = {
                    title: 'Exito',
                    text: 'Estatus actualizado exitosamente.',
                    icon: 'success',
                    willClose: () => {
                        getOrders()
                    }
                }
                const result = await saveData(`Order/changeProcessStatus`, { idOrder, idOrderProcess: status, idRegisterUser: id }, headers, alertConfig)
            }
        })
    }

    const activeInactiveOrder = async (order) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Pedidos')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const bBlock = order.sStatus === 'Activo' ? true : false
                await lavakleenApi.post(`Order/blockUnblockOrder`, {idOrder: order.idOrder, bBlock}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                        getOrders()
                } )
            }
        })
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Pedidos')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Pedidos'))
        
        if(userPerm.bList !== true) {
            setCloseMessage(false)
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })

            navigate(`/pages/dashboard`)
            return
        }
        setCloseMessage(true)
        getOrders();
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        const filename = 'pedidos.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const handleRowChange = useCallback( state => {
        let listToAssign = []
        state.selectedRows.map( row => {
            if(row.idOrderProcess == 6) {
                listToAssign = [...listToAssign, row.idOrder]
            }
        } )
        ordersForInvoiceRef.current = listToAssign
    } )

    const listOfOrdersToInvoice = (rows) => {
        return ordersForInvoiceRef.current.indexOf(rows.idOrder) > -1
    }

    useEffect(() => {
        checkPermissions();
        
    }, [])

    useEffect(() => {
        if(isProcessing == true) {

            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }

        if(closeMessage == true)
            if(!isProcessing) Swal.close()
    }, [isProcessing, closeMessage])
    
    useEffect(() => {
        if(data.length > 0) {
            let sumTotal = 0
            let sumPayment = 0
            data.map( element => {
                if(element.sStatus == 'Activo') {
                    sumTotal += element.dTotal
                    element.paymentList.map( payment => {
                        sumPayment += payment.dAmount
                    } )
                }
            } )
            setSumTotal(sumTotal.toFixed(2))
            setSumPayments(sumPayment.toFixed(2))
            setSumBalance((sumTotal - sumPayment).toFixed(2))
        }
    }, [data])
    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Pedidos"
                                subheading="Gestion de pedidos para tu negocio"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                {
                                    permission !== null && permission.bCreate === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="primary"
                                                onClick={ onAddClick }
                                            >
                                                Agregar
                                            </Button>
                                            <Button className="mb-2 me-2" color="primary"
                                                onClick={ onAddInvoiceClick }
                                            >
                                                Facturar pedidos
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }

                                {
                                    permission !== null && permission.bExport === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="danger"
                                                onClick={ () => downloadCsv()}
                                            >
                                                Exportar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }
                            </ul>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                        <DataTable data={data}
                                            columns={columns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight={ `${windowHeight}px` }
                                            selectableRows
                                            onSelectedRowsChange={handleRowChange}
                                            selectableRowSelected={listOfOrdersToInvoice}
                                            paginationRowsPerPageOptions= { [20, 40, 60, 80, 100] }
                                            paginationPerPage={ 20 }
                                        />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <ModalComponent
                                    isOpen={modalState.isOpen}
                                    toggle={ toggleModal }
                                    backdrop={ modalState.backDrop }
                                    modalTitle={ 'Historial de cambio de status' }
                                    size="lg"
                                    initialValues={ {} }
                                    validationSchema={ {} }
                                    saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                    alertMessages={ { } }
                                    getDataFunction={ nothingToDo }
                                    dropDownsToValidate={ [ ] }
                                    showSaveButton={false}
                                >
                                    <Row>
                                        <Col md={12}>
                                            <DataTable data={dataHistory}
                                                columns={columnsHistory}
                                                pagination
                                                fixedHeader
                                                fixedHeaderScrollHeight="500px"
                                            />
                                        </Col>
                                        
                                    </Row>
                                </ModalComponent>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <FormGroup className="mb-2">
                                        <Label for="idCustomerType">Suma Total</Label>
                                        <InputReadonly type="text" name="sumTotal" placeholder="" value={ sumTotal } readOnly={ true }/>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="mb-2">
                                        <Label for="idCustomerType">Suma Pagado</Label>
                                        <InputReadonly type="text" name="sumPayment" placeholder="" value={ sumPayments } readOnly={ true }/>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="mb-2">
                                        <Label for="idCustomerType">Suma Saldo</Label>
                                        <InputReadonly type="text" name="sumPayment" placeholder="" value={ sumBalance } readOnly={ true }/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
})
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/auth/authSlice";

export const useCheckAuth = () => {
    
    const { status } = useSelector( state => state.auth );
    const dispatch = useDispatch();

    return {
        status
    };
}

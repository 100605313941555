import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, FormGroup, Label } from "reactstrap";
import { Form, Input } from '@availity/form';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import { lavakleenApi } from "../../api/lavakleenApi";
import { PageTitle } from '../../layout/components/PageTitle';
import { convertArrayOfObjectsToCSV, deleteDataFromApi, filterDataFromApi, getDataFromApi } from '../../api/providers/providers';
import { columnsTaxRegimes, columnsMeasurementUnits, columnsSatProducts } from '../providers/adminProviders';
import { ModalComponent } from '../components/ModalComponent';
import { useSelector } from 'react-redux';

export const SatGeneralCatalogs = forwardRef( ({ pageTitleHeadingText, pageTitleSubheadingText, modalTitle, endpointDataTable, headers, pagePath, formDataToStore, alertMessages }, ref) => {
    useImperativeHandle( ref, () => ({
        async filterData(values){
            let endpoint = ''
            if(pagePath === 'sat-unit-measures')
                endpoint = endpointDataTable.replace('?filterMeasurement=Pieza', '')
            if(pagePath === 'sat-products' || pagePath === 'tax-regimes')
                endpoint = endpointDataTable.replace('?filterProdServ=piezas', '')
            const result = await filterDataFromApi(endpoint, values, headers);
            
            if(result.sErrors.length === 0) {
                if(pagePath === 'tax-regimes')
                    setData( result.listTaxRegime );
                if(pagePath === 'sat-unit-measures')
                    setData( result.listMeasurementUnits );
                if(pagePath === 'sat-products')
                    setData( result.serviceProductData );
            } else {
                Swal.fire({
                    title: 'Error',
                    text: result.sErrors[0],
                    icon: 'error',
                })
            }
        },

        async restore() {
            await getInfoDataTable()
        }
    }) )
    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
        saveDataToEndpoint: ''
    })

    const [data, setData] = useState([]);
    const [formData, setFormData] = useState(formDataToStore);
    const [fields, setFields] = useState([]);
    const [permission, setPermission] = useState(null)

    const navigate = useNavigate();
    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))

    let columnsToShow = [];

    if(pagePath === 'tax-regimes')
        columnsToShow = columnsTaxRegimes;
    if(pagePath === 'sat-unit-measures')
        columnsToShow = columnsMeasurementUnits;
        if(pagePath === 'sat-products')
        columnsToShow = columnsSatProducts;

    const [columns, setColumns] = useState([...columnsToShow, {
        name: "Opciones",
        cell: (row) => (
            <>
                <Button onClick={ () => editRow(row) } color="primary">
                    <i className="lnr-pencil"></i>
                </Button>
                <Button color="danger" onClick={ () => deleteRow(row) }><i className="lnr-trash"></i></Button>
            </>
        ),
        sortable: false,
        ignoreRowClick: true,
        button: true,
    }])
    const [searchData, setSearchData] = useState({
        filterBranchOffice: '',
        // filterCompanyName: '',
    })

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const onAddClick = () => {
        setFormData(formDataToStore);
        if(pagePath === 'tax-regimes')
            toggleModal('TaxRegime/addTaxRegime')
        if(pagePath === 'sat-unit-measures')
            toggleModal('MeasurementUnit/addMeasurementUnit')
        if(pagePath === 'sat-products')
            toggleModal('ServiceProduct/addProductOrService')
    }

    const getInfoDataTable = async () => {
        //revisamos si tiene los permisos para ver la pantalla
        await lavakleenApi.get(endpointDataTable, {
            headers
        }).then( response => {
            if(pagePath === 'tax-regimes')
                setData( response.data.listTaxRegime );
            if(pagePath === 'sat-unit-measures')
                setData( response.data.listMeasurementUnits );
            if(pagePath === 'sat-products')
                setData( response.data.serviceProductData );
        } )
    }

    const editRow = async(row) => {
        let getDataUrl = '';
        let idData = 0;
        let userPerm = null

        if(pagePath === 'tax-regimes') {
            getDataUrl = `TaxRegime/findTaxRegime?idTaxRegime=${row.idTaxRegime}`;
            idData = row.idTaxRegime;
            userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Regimen Fiscal')
        }

        if(pagePath === 'sat-unit-measures') {
            getDataUrl = `MeasurementUnit/findMeasurementUnit?idMeasurementUnit=${row.idMeasurementUnit}`;
            idData = row.idMeasurementUnit;
            userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Unidades de medida SAT')
        }

        if(pagePath === 'sat-products') {
            getDataUrl = `ServiceProduct/findProdServ?idProdServ=${row.idProductService}`;
            idData = row.idProductService;
            userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Productos del SAT')
        }

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        const dataToUpdate = await getDataFromApi(getDataUrl, idData, {}, headers);
        if(dataToUpdate.status === 'success'){
            if(pagePath === 'tax-regimes') {
                setFormData( {...dataToUpdate.taxRegimeData, id: dataToUpdate.taxRegimeData.idTaxRegime} );
                toggleModal('TaxRegime/editTaxRegime')
            }

            if(pagePath === 'sat-unit-measures') {
                setFormData( {...dataToUpdate.measurementUnitsData, id: dataToUpdate.measurementUnitsData.idMeasurementUnit} );
                toggleModal('MeasurementUnit/editMeasurementUnit')
            }

            if(pagePath === 'sat-products') {
                setFormData( {...dataToUpdate.prodServ, id: dataToUpdate.prodServ.idProductService} );
                toggleModal('ServiceProduct/editProductOrService')
            }
        }
    }

    const deleteRow = async (row) => {
        let deleteDataUrl = '';
        let userPerm = null
        if(pagePath === 'tax-regimes') {
            userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Regimen Fiscal')
            deleteDataUrl = `TaxRegime/deleteTaxRegime?idTaxRegime=${row.idTaxRegime}`;
        }
        if(pagePath === 'sat-unit-measures') {
            userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Unidades de medida SAT')
            deleteDataUrl = `MeasurementUnit/deleteMeasurementUnit?idMeasurementUnit=${row.idMeasurementUnit}`;
        }
        if(pagePath === 'sat-products') {
            userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Productos del SAT')
            deleteDataUrl = `ServiceProduct/deleteproductOrService?idProdService=${row.idProductService}`;
        }

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const result = await deleteDataFromApi(deleteDataUrl, headers)
                if(result.bResult === true)
                    await getInfoDataTable()
            }
        })
    }

    const filterData = async (values) => {
        // const result = await filterDataFromApi('Rol/getRolList', values, headers);
        // setData(result.branchList)
        alert('Hola desde filterData de productos sat')
    }

    const restore = async () => {
        setSearchData({
            filterBranchOffice: '',
        })

        await getInfoDataTable()
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        let filename = '';

        if(pagePath === 'tax-regimes')
            filename = 'regimen_fiscal.csv';
        if(pagePath === 'sat-unit-measures')
            filename = 'unidades_medida_sat.csv';
        if(pagePath === 'sat-products')
            filename = 'productos_sat.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const checkPermissions = () => {
        let userPerm = null
        if(pagePath === 'sat-products'){
            userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Productos del SAT')
            setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Productos del SAT'))
        }
        if(pagePath === 'tax-regimes') {
            userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Regimen Fiscal')
            setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Regimen Fiscal'))
        }
        if(pagePath === 'sat-unit-measures') {
            userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Unidades de medida SAT')
            setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Unidades de medida SAT'))
        }
        
        if(userPerm.bList !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })

            navigate(`/pages/dashboard`)
        }
    }

    useEffect(() => {
        checkPermissions();
        getInfoDataTable();

        let fields = [];
        for (const key in formDataToStore) {
            if(key !== 'id') {
                fields.push(
                    <FormGroup className="mb-3" key={ key }>
                        <Label for={ key }> { key.includes('ClaveSAT') ? 'Clave' : 'Nombre' }</Label>
                        <Input type="text" name={ key } placeholder=""/>
                    </FormGroup>
                )
            }
        }

        setFields(fields);
    }, [])
    
    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading={ pageTitleHeadingText }
                                subheading={ pageTitleSubheadingText }
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                {
                                    permission !== null && permission.bCreate === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="primary"
                                                onClick={ onAddClick }
                                            >
                                                Agregar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }

                                {
                                    permission !== null && permission.bExport === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="danger"
                                                onClick={ () => downloadCsv()}
                                            >
                                                Exportar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }
                            </ul>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                        <DataTable data={data}
                                            columns={columns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight="500px"
                                        />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <ModalComponent
                                    isOpen={modalState.isOpen}
                                    toggle={ toggleModal }
                                    backdrop={ modalState.backDrop }
                                    modalTitle={ modalTitle }
                                    size="md"
                                    initialValues={formData}
                                    validationSchema={ {
                                        sClaveSAT: (pagePath === 'sat-unit-measures' || pagePath === 'tax-regimes') ? yup.string().max(3).required() : yup.string().max(8).required(),
                                        sTaxRegime: pagePath === 'tax-regimes' ? yup.string().required() : yup.string(),
                                        sMeasurementUnit: pagePath === 'sat-unit-measures' ? yup.string().required() : yup.string(),
                                        sProductService: pagePath === 'sat-products' ? yup.string().required() : yup.string(),
                                    } }
                                    saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                    getDataFunction={ getInfoDataTable }
                                    alertMessages = { alertMessages }
                                >
                                    <Row>
                                        <Col md={12}>
                                            { fields }
                                        </Col>
                                    </Row>
                                </ModalComponent>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
} )

import { lavakleenApi } from "../../api/lavakleenApi";
import { startProcessing, stopProcessing, setFinishRegister, login, permissions } from "./authSlice";
import Swal from "sweetalert2";

export const startRegisterUser = ({ sName, sLastName, sEmail, sPassword, sUser, sConfirmPassword, bCheckTerms, sBuinessName, sCompanyName, sRfc, sBusinessAddress, sPrincipalPhone, sExtNumber, sIntNumber, idState, idCity, idColony, sCp, sWebsite, sCompanyEmail, sImagePath, sCsd, sKey, sSatpassword, idTaxRegime, idRol, idUserRegister, sAlternatePhone, sColony }) => {
    return async (dispatch, getState) => {
        dispatch( startProcessing() );

        const result = await lavakleenApi.post( 'AddUser', { sName, sLastName, sEmail, sPassword, sUser, sConfirmPassword, bCheckTerms, sBuinessName, sCompanyName, sRfc, sBusinessAddress, sPrincipalPhone, sExtNumber, sIntNumber, idState, idCity, idColony, sCp, sWebsite, sCompanyEmail, sImagePath, sCsd, sKey, sSatpassword, idTaxRegime, idRol, idUserRegister, sAlternatePhone, sColony });
        const response = result.data;

        dispatch( stopProcessing() );
        
        if(response.sErrors.length > 0) {
            setTimeout(() => {
                Swal.fire('Error', response.sErrors[0], 'error')
            }, 100);
        }

        if(response.sErrors.length == 0) {
            setTimeout(() => {
                Swal.fire({
                    title: 'Exito',
                    text: 'Cuenta creada con éxito, ingresa al sistema para comenzar a usarlo!!.',
                    icon: 'success',
                    willClose: () => {
                        dispatch( setFinishRegister() )
                    }
                })
            }, 100);
        }
    }
}

export const startLogin = ({ sUserName, sPassword }) => {
    return async (dispatch) => {
        dispatch( startProcessing() );

        const result = await lavakleenApi.post( '/Login', { sUserName, sPassword } )
        const response = result.data;

        if(response.sErrors.length > 0) {
            setTimeout(() => {
                Swal.fire('Error', response.sErrors[0], 'error')
            }, 100);
        }

        if(response.sErrors.length == 0) {
            dispatch( login(response.userData) )
            localStorage.setItem('userData', JSON.stringify(response.userData));
            localStorage.setItem('sToken', response.sToken);
            localStorage.setItem('idCompany', response.idCompany);

            const headers = {
                'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
                'private' : localStorage.getItem('idCompany'),
            };
            const userPermissions = await lavakleenApi.get(`GrantPrivileges/getPermisionList?idUser=${response.userData.idUser}`, { headers })

            Swal.close()

            if(userPermissions.data.sErrors.length === 0) {
                dispatch( permissions(userPermissions.data.permisionsList) )
                localStorage.setItem('userPermissions', JSON.stringify(userPermissions.data.permisionsList));
            }
        }

        dispatch( stopProcessing() );
    }
}
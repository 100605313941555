export const MainNav = [
  {
    icon: "lnr-diamond",
    label: "Administración",
    content: [
      {
        icon: "lnr-users",
        label: "Catálogos",
        content: [
          {
            label: "Colores",
            to: "/pages/colors"
          },
          {
            label: "Productos del SAT",
            to: "/pages/sat-products"
          },
          {
            label: "Régimen fiscal SAT",
            to: "/pages/tax-regimes"
          },
          {
            label: "Status de cliente",
            to: "/pages/client-status"
          },
          {
            label: "Tipos de cliente",
            to: "/pages/client-types"
          },
          {
            label: "Unidades de medida",
            to: "/pages/unit-measures"
          },
          {
            label: "Unidades de medida SAT",
            to: "/pages/sat-unit-measures",
          },
        ]
      },
      {
        icon: "lnr-apartment",
        label: "Empresas",
        to: "/pages/companies",
      },
      {
        icon: "lnr-users",
        label: "Módulos",
        to: "/pages/modules",
        id: "modulesOption"
      },
      {
        icon: "lnr-users",
        label: "Roles",
        to: "/pages/roles"
      },
      {
        icon: "lnr-apartment",
        label: "Sucursales",
        to: "/pages/branch-offices"
      },
      {
        icon: "lnr-users",
        label: "Usuarios",
        to: "/pages/users"
      },
    ]
  },
  {
    icon: "pe-7s-wallet",
    label: "Ventas",
    content: [
      {
        label: "Clientes",
        to: "/pages/clients"
      },
      {
        label: "Incidencias",
        to: "/pages/issues"
      },
      {
        label: "Pedidos",
        to: "/pages/orders"
      },
      // {
      //   label: "Remisiones",
      //   to: "#"
      // },
      {
        label: "Visitas",
        to: "/pages/visits"
      }
    ]
  },
  {
    icon: "pe-7s-note2",
    label: "Inventario",
    content: [
      {
        label: "Lista de productos",
        to: "/pages/products-list"
      },
      {
        label: "Productos",
        to: "/pages/products"
      },
      {
        label: "Servicios",
        to: "/pages/services"
      },
    ]
  },
  {
    icon: "lnr-calendar-full",
    label: "Producción",
    content: [
      {
        label: "Producción",
        to: "/pages/production"
      },
    ]
  },
  {
    icon: "pe-7s-cash",
    label: "Facturación",
    content: [
      {
        label: "Facturas",
        to: "/pages/invoices"
      },
      {
        label: "Notas de crédito",
        to: "/pages/credit-notes"
      },
      {
        label: "Complementos de pago",
        to: "/pages/payments"
      },
    ]
  },
  {
    icon: "pe-7s-graph",
    label: "Reportes",
    content: [
      {
        label: "Dashboard operativo",
        to: "/pages/dashboard"
      },
      {
        label: "Dashboard ventas",
        to: "/pages/sales-dashboard"
      },
    ]
  },

];
  export const ComponentsNav = [
    {
      icon: "pe-7s-diamond",
      label: "Elements",
      content: [
        {
          label: "Buttons",
          content: [
            {
              label: "Standard",
              to: "#/elements/buttons-standard",
            },
            {
              label: "Pills",
              to: "#/elements/buttons-pills",
            },
            {
              label: "Square",
              to: "#/elements/buttons-square",
            },
            {
              label: "Shadow",
              to: "#/elements/buttons-shadow",
            },
            {
              label: "With Icons",
              to: "#/elements/buttons-icons",
            },
          ],
        },
        {
          label: "Dropdowns",
          to: "#/elements/dropdowns",
        },
        {
          label: "Icons",
          to: "#/elements/icons",
        },
        {
          label: "Badges",
          to: "#/elements/badges-labels",
        },
        {
          label: "Cards",
          to: "#/elements/cards",
        },
        {
          label: "Loading Indicators",
          to: "#/elements/loaders",
        },
        {
          label: "List Groups",
          to: "#/elements/list-group",
        },
        {
          label: "Navigation Menus",
          to: "#/elements/navigation",
        },
        {
          label: "Timeline",
          to: "#/elements/timelines",
        },
        {
          label: "Utilities",
          to: "#/elements/utilities",
        },
        {
          label: "Visibility Sensor",
          to: "#/elements/visibility-sensor",
        },
      ],
    },
    {
      icon: "pe-7s-car",
      label: "Components",
      content: [
        {
          label: "Tabs",
          to: "#/components/tabs",
        },
        {
          label: "Accordions",
          to: "#/components/accordions",
        },
        {
          label: "Notifications",
          to: "#/components/notifications",
        },
        {
          label: "Modals",
          to: "#/components/modals",
        },
        {
          label: "Loading Blockers",
          to: "#/components/loading-blocks",
        },
        {
          label: "Progress Bar",
          to: "#/components/progress-bar",
        },
        {
          label: "Tooltips & Popovers",
          to: "#/components/tooltips-popovers",
        },
        {
          label: "Carousel",
          to: "#/components/carousel",
        },
        {
          label: "Calendar",
          to: "#/components/calendar",
        },
        {
          label: "Pagination",
          to: "#/components/pagination",
        },
        {
          label: "Count Up",
          to: "#/components/count-up",
        },
        {
          label: "Sticky Elements",
          to: "#/components/sticky-elements",
        },
        {
          label: "Scrollable",
          to: "#/components/scrollable-elements",
        },
        {
          label: "Maps",
          to: "#/components/maps",
        },
        {
          label: "Ratings",
          to: "#/components/ratings",
        },
        {
          label: "Image Crop",
          to: "#/components/image-crop",
        },
        {
          label: "Guided Tours",
          to: "#/components/guided-tours",
        },
      ],
    },
    {
      icon: "pe-7s-display2",
      label: "Tables",
      content: [
        {
          label: "Data Tables",
          content: [
            {
              label: "Basic",
              to: "#/tables/data-tables",
            },
            {
              label: "Fixed Header",
              to: "#/tables/datatables-fixed-header",
            },
            {
              label: "Selectable Rows",
              to: "#/tables/datatables-selectable-rows",
            },
          ],
        },
        {
          label: "Regular Tables",
          to: "#/tables/regular-tables",
        },
      ],
    },
  ];
  export const FormsNav = [
    {
      icon: "pe-7s-light",
      label: "Elements",
      content: [
        {
          label: "Controls",
          to: "#/forms/controls",
        },
        {
          label: "Layouts",
          to: "#/forms/layouts",
        },
        {
          label: "Validation",
          to: "#/forms/validation",
        },
        {
          label: "Wizards",
          content: [
            {
              label: "Variation 1",
              to: "#/forms/wizard-1",
            },
            {
              label: "Variation 2",
              to: "#/forms/wizard-2",
            },
            {
              label: "Variation 3",
              to: "#/forms/wizard-3",
            },
          ],
        },
        {
          label: "Sticky Form Headers",
          to: "#/forms/sticky-headers",
        },
      ],
    },
    {
      icon: "pe-7s-joy",
      label: "Widgets",
      content: [
        {
          label: "Datepicker",
          to: "#/forms/datepicker",
        },
        {
          label: "Range Slider",
          to: "#/forms/range-slider",
        },
        {
          label: "Input Selects",
          to: "#/forms/input-selects",
        },
        {
          label: "Toggle Switch",
          to: "#/forms/toggle-switch",
        },
        {
          label: "Dropdowns",
          to: "#/forms/dropdown",
        },
        {
          label: "WYSIWYG Editor",
          to: "#/forms/wysiwyg-editor",
        },
        {
          label: "Input Mask",
          to: "#/forms/input-mask",
        },
        {
          label: "Typeahead",
          to: "#/forms/typeahead",
        },
        {
          label: "Clipboard",
          to: "#/forms/clipboard",
        },
        {
          label: "Textarea Autosize",
          to: "#/forms/textarea-autosize",
        },
        {
          label: "Number Spinners",
          to: "#/forms/numberspinners",
        },
        {
          label: "Color Picker",
          to: "#/forms/color-picker",
        },
        {
          label: "Dropzone",
          to: "#/forms/dropzone",
        },
      ],
    },
  ];
  export const WidgetsNav = [
    {
      icon: "pe-7s-graph2",
      label: "Chart Boxes",
      content: [
        {
          label: "Variation 1",
          to: "#/widgets/chart-boxes",
        },
        {
          label: "Variation 2",
          to: "#/widgets/chart-boxes-2",
        },
        {
          label: "Variation 3",
          to: "#/widgets/chart-boxes-3",
        },
      ],
    },
    {
      icon: "pe-7s-id",
      label: "Profile Boxes",
      to: "#/widgets/profile-boxes",
    },
    {
      icon: "pe-7s-display1",
      label: "Content Boxes",
      to: "#/widgets/content-boxes",
    },
  ];
  export const ChartsNav = [
    {
      icon: "pe-7s-graph2",
      label: "ChartJS",
      to: "#/charts/chartjs",
    },
    {
      icon: "pe-7s-graph",
      label: "Apex Charts",
      to: "#/charts/apexcharts",
    },
    {
      icon: "pe-7s-gleam",
      label: "Gauges",
      to: "#/charts/gauges",
    },
    {
      icon: "pe-7s-graph1",
      label: "Chart Sparklines 1",
      to: "#/charts/sparklines-1",
    },
    {
      icon: "pe-7s-edit",
      label: "Chart Sparklines 2",
      to: "#/charts/sparklines-2",
    },
  ];  
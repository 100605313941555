import ResizeDetector from 'react-resize-detector';
import { useSelector } from "react-redux";
import cx from "classnames";
import { AppContent } from './AppContent';
import logo from '../../logo.svg';

export const AppMain = () => {

    const {colorScheme, enableFixedHeader, enableFixedSidebar, enableFixedFooter, enableClosedSidebar, closedSmallerSidebar, enableMobileMenu, enablePageTabsAlt} = useSelector( state => state.themeOptions);
    return (
        <>
            <ResizeDetector
                handleWidth
            >
                {({ width, targetRef }) => (
                    <>
                        <div ref={targetRef}
                            className={cx(
                                "app-container app-theme-" + colorScheme,
                                { "fixed-header": enableFixedHeader },
                                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                                { "fixed-footer": enableFixedFooter },
                                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                                {
                                "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                                },
                                { "sidebar-mobile-open": enableMobileMenu },
                                { "body-tabs-shadow-btn": enablePageTabsAlt }
                        )}
                        >
                            <AppContent />
                        </div>
                    </>
                )}
            </ResizeDetector>
        </>
    )
}

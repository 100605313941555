import cx from "classnames";
import { useSelector } from "react-redux";

export const PageTitle = ({heading = '', icon = '', subheading = ''}) => {

    const {enablePageTitleIcon, enablePageTitleSubheading} = useSelector( state => state.themeOptions);

    return (
        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className={cx("page-title-icon", {
                        "d-none": !enablePageTitleIcon,
                    })}>
                    <i className={icon} />
                    </div>
                    <div>
                    {heading}
                    <div className={cx("page-title-subheading", {
                        "d-none": !enablePageTitleSubheading,
                        })}>
                        {subheading}
                    </div>
                    </div>
                </div>
                {/* <div className="page-title-actions">{this.randomize(arr)}</div> */}
            </div>
        </div>
    )
}

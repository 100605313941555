import { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import { Form, Input } from '@availity/form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Label, TabContent, TabPane, CardHeader, Nav, NavItem, NavLink } from "reactstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import { lavakleenApi } from '../../api/lavakleenApi';
import { deleteDataFromApi, getCompanyById, getDataFromApi, saveData } from '../../api/providers/providers';
import { PageTitle } from '../../layout/components/PageTitle';
import { startProcessing, stopProcessing } from '../../store/auth/authSlice';
import { TabHeaderComponent } from '../components/TabHeaderComponent';
import { columnsAddress } from '../providers/clientsProviders';
import { ModalComponent } from '../components/ModalComponent';
import { columnsProductsListToAssign, Taxes, TaxObjects } from '../providers/inventoryProviders';
import { cfdiRelation, cfdiUse, paymentsForm } from '../providers/salesProviders';

const formData = {
    idInvoiceRelated: [],
    sSerie: '',
    dTotal: 0,
    dSubTotal: 0,
    dTax: 0,
    dDescuento: 0,
    idRegisterUser: 0,
    sTipoRelacion: '',
    totales: {},
    sPayDate: '',
    // montoTotalPagos: '0',
    // totalTrasladosImpuestoIVA16: '0',
    // totalTrasladosBaseIVA16: '0',
    pago: [],
};

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const PaymentPage = () => {

    const {orders} = useParams();
    const {idOrder} = useParams();

    const orderDetailsRef = useRef([]);
    const productsDetailsRef = useRef([]);
    const clientsRef = useRef([]);
    const servicesListRef = useRef([]);
    const measurementUnitsRef = useRef([]);
    const relatedDocumentsRef = useRef([]);
    const montoTotalPagos = useRef(0);
    const totalTrasladosImpuestoIVA16 = useRef(0);
    const totalTrasladosBaseIVA16 = useRef(0);
    const productsOfList = useRef([]);

    const { id, isProcessing } = useSelector( state => state.auth );

    const [state, setState] = useState({
        activeTab: 'items'
    })

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
    })

    const [modalStatePayments, setModalStatePayments] = useState({
        isOpen: false,
        backDrop: true,
    })

    const invoiceDate = new Date(new Date().toLocaleString('en', {timeZone: 'America/Mexico_City'}))
    var minDate = new Date(new Date().toLocaleString('en', {timeZone: 'America/Mexico_City'}))
    minDate.setDate( invoiceDate.getDate() - 2 )

    const [deliveryDate, setDeliveryDate] = useState(new Date(new Date().toLocaleString('en', {timeZone: 'America/Mexico_City'})));
    const [paymentDate, setPaymentDate] = useState(new Date(new Date().toLocaleString('en', {timeZone: 'America/Mexico_City'})));

    const [formItemData, setFormItemData] = useState({
        formaDePagoP: '',
        monedaP: '',
        monto: '',
        tipoCambioP: '1',
        numOperacion: '',
        id: 0,
        doctoRelacionado: []
      });

    const [formRelatedDocument, setFormRelatedDocument] = useState({
        monedaDR: '',
        numParcialidad: '1',
        equivalenciaDR: '',
        impSaldoAnt: '',
        impPagado: '',
        impSaldoInsoluto: '',
        objetoImpDR: '',
        IdDocumento: '',
        id: 0,
    });

    const [columnsAddresses, setColumnsAddresses] = useState([...columnsAddress, {
        name: "Opciones",
        cell: (row) => (
            <>
                <Button onClick={ () => editRow(row.idAddress) } color="primary">
                    <i className="lnr-pencil"></i>
                </Button>
                <Button color="danger" onClick={ () => deleteRow(row.idAddress) }><i className="lnr-trash"></i></Button>
            </>
        ),
        sortable: false,
        ignoreRowClick: true,
        button: true,
    }]);

    const [columnsProductsList, setColumnsProductsList] = useState([
        {
            name: "Fecha pago",
            selector: row => row.sPayDetailDate,
            sortable: true,
            width: '150px',
        },
        {
            name: "Forma de Pago",
            selector: row => row.formaDePagoP,
            sortable: true,
        },
        {
            name: "Moneda Pago",
            selector: row => row.monedaP,
            sortable: true,
        },
        {
            name: "Monto",
            selector: row => row.monto,
            sortable: true,
        },
        {
            name: "Acciones",
            cell: (row) => (
                <>
                    {/* <Button color="primary" onClick={ () => editCreditNoteDetail(row) } title="Editar"><i className="lnr-pencil"></i></Button> */}

                    <Button color="danger" onClick={ () => deletePaymentDetail(row) } title="Eliminar"><i className="lnr-trash"></i></Button>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            width: '100px'
        }
    ]);

    const [columnsPaymentsList, setColumnsPaymentsList] = useState([
        {
            name: "Forma de pago",
            selector: row => row.sPaymentType,
            sortable: false,
        },
        {
            name: "Importe",
            selector: row => row.dAmount,
            sortable: false,
        },
        {
            name: "Acciones",
            cell: (row) => (
                <>
                    <Button color="danger" onClick={ () => deleteOrderPayment(row) } title="Eliminar"><i className="lnr-trash"></i></Button>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            width: '100px'
        }
    ]);

    const [columnsRelatedDocuments, setColumnsRelatedDocuments] = useState([
        {
            name: "Documento",
            selector: row => row.IdDocumento,
            sortable: false,
            width: '150px',
        },
        {
            name: "No. Parcialidad",
            selector: row => row.numParcialidad,
            sortable: false,
        },
        {
            name: "Saldo Anterior",
            selector: row => row.impSaldoAnt,
            sortable: false,
        },
        {
            name: "Pagado",
            selector: row => row.impPagado,
            sortable: false,
        },
        {
            name: "Saldo",
            selector: row => row.impSaldoInsoluto,
            sortable: false,
        },
        {
            name: "Acciones",
            cell: (row) => (
                <>
                    <Button color="danger" onClick={ () => deleteRelatedDocument(row) } title="Eliminar"><i className="lnr-trash"></i></Button>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            width: '100px'
        }
    ]);

    const [columnsProducts, setColumnsProducts] = useState([...columnsProductsListToAssign, {
        name: "Acciones",
        cell: (row) => (
            <Input type="checkbox" name={ `check_${row.idProduct}` } id={ `check_${row.idProduct}` } onChange={ () => addProductToCustomer(row, `check_${row.idProduct}`) }/>
        ),
        sortable: false,
        ignoreRowClick: true,
        width: '100px'
    }]);

    const [columnsProductsListCustomer, setColumnsProductsListCustomer] = useState([
        {
            name: "ID",
            selector: row => row.idProductList,
            sortable: true,
            width: '80px',
        },
        {
            name: "Nombre lista",
            selector: row => row.sListName,
            sortable: true,
        },
    ]);

    const [columnsProductsCustomer, setColumnsProductsCustomer] = useState([...columnsProductsListToAssign]);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedOptionCurrency, setSelectedOptionCurrency] = useState({
        value: 'MXN', label: 'MXN - Pesos Mexicanos'
    });
    const [selectedOptionProductList, setSelectedOptionProductList] = useState(null);
    const [selectedOptionMeasurementUnit, setSelectedOptionMeasurementUnit] = useState(null);
    const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
    const [selectedOptionCfdiUse, setSelectedOptionCfdiUse] = useState(null);
    const [selectedOptionRelatedCfdi, setSelectedOptionRelatedCfdi] = useState(null);
    const [selectedOptionIdDocumento, setSelectedOptionIdDocumento] = useState(null);
    const [selectedOptionTax, setSelectedOptionTax] = useState(null);
    const [invoiceData, setInvoiceData] = useState(formData);
    const [selectedOptionPaymentMethod, setSelectedOptionPaymentMethod] = useState(null);
    const [selectedPaymentForm, setSelectedPaymentForm] = useState(null);
    const [selectedOptionCfdiRelation, setSelectedOptionCfdiRelation] = useState(null);
    const [selectedOptionSatProduct, setSelectedOptionSatProduct] = useState(null);
    const [selectedOptionTaxObject, setSelectedOptionTaxObject] = useState(null);
    const [discount, setDiscount] = useState(0);

    const [optionsProducts, setOptionsProducts] = useState([]);
    const [optionsCfdiUse, setOptionsCfdiUse] = useState(cfdiUse);
    const [optionsCfdiRelation, setOptionsCfdiRelation] = useState(cfdiRelation);
    const [optionsCurrency, setOptionsCurrency] = useState([
        {value: 'MXN', label: 'MXN - Pesos Mexicanos'},
        {value: 'USD', label: 'USD - Dólares americanos'}
    ]);
    const [optionsPaymentsForm, setOptionsPaymentsForm] = useState(paymentsForm);
    const [optionsPaymentMethod, setOptionsPaymentMethod] = useState([
        {value: 'PUE', label: 'PUE - Pago en una sola exhibición'},
        {value: 'PPD', label: 'PPD - Pago en parcialidades o diferido'},
    ])
    const [optionsTaxes, setOptionsTaxes] = useState(Taxes);
    const [optionsProductList, setOptionsProductList] = useState([]);
    const [optionsCustomers, setOptionsCustomers] = useState([]);
    const [optionsMeasurementUnits, setOptionsMeasurementUnits] = useState([]);
    const [finisRegistering, setFinisRegistering] = useState(false);
    const [optionsRelatedCfdis, setOptionsRelatedCfdis] = useState([]);
    const [optionsIdDocuments, setOptionsIdDocuments] = useState([]);
    const [optionsSatProducts, setOptionsSatProducts] = useState([]);
    const [optionsTaxObjects, setOptionsTaxObjects] = useState(TaxObjects);

    //validators
    const [invalidCustomer, setInvalidCustomer] = useState(true);
    const [invalidProduct, setInvalidProduct] = useState(true);
    const [invalidTax, setInvalidTax] = useState(true);
    const [invalidPaymentMethod, setInvalidPaymentMethod] = useState(true);
    const [invalidCfdiUse, setInvalidCfdiUse] = useState(true);
    const [invalidPaymentForm, setInvalidPaymentForm] = useState(true);
    const [invalidCfdiRelation, setInvalidCfdiRelation] = useState(true);
    const [invalidRelatedCfdi, setInvalidRelatedCfdi] = useState(true);
    const [invalidIdDocumento, setInvalidIdDocumento] = useState(true);
    const [invalidMeasurementUnit, setInvalidMeasurementUnit] = useState(true);
    const [itemWithProduct, setItemWithProduct] = useState(false);
    const [invalidSatProduct, setInvalidSatProduct] = useState(true);
    const [invalidTaxObject, setInvalidTaxObject] = useState(true);

    //datatables
    const [dataPaymentsOrder, setDataPaymentsOrder] = useState([]);
    const [dataRelatedDocuments, setDataRelatedDocuments] = useState([]);
    const [dataProductsOrder, setDataProductsOrder] = useState([]);
    const [dataProducts, setDataProducts] = useState([]);
    const [dataProductsListCustomer, setDataProductsListCustomer] = useState([]);
    const [dataProductsCustomer, setDataProductsCustomer] = useState([]);
    const [dataPayments, setDataPayments] = useState([]);

    const [customerProductListsList, setCustomerProductListsList] = useState([]);
    const [customerProducts, setCustomerProducts] = useState([]);

    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async (values) => {

        if(montoTotalPagos.current == 0) {
            Swal.fire({
                title: 'Error',
                text: 'Debes de agregar al menos un pago.',
                icon: 'error',
            })

            return
        }

        const newValues = {...values}
        
        selectedOptionCustomer == null ? setInvalidCustomer(true) : setInvalidCustomer(false)
        selectedOptionPaymentMethod == null ? setInvalidPaymentMethod(true) : setInvalidPaymentMethod(false)
        selectedPaymentForm == null ? setInvalidPaymentForm(true) : setInvalidPaymentForm(false)

        const alertConfig = {
            title: 'Exito',
            text: 'Pre complemento de pago creado exitosamente.',
            icon: 'success',
            willClose: () => {
                onFinishRegister()
            }
        };

        const newDataProductsOrder = [...dataProductsOrder]
        console.log(newDataProductsOrder)

        newDataProductsOrder.map( element => {
            element.doctoRelacionado.map( docto => {
                newValues.idInvoiceRelated = [...newValues.idInvoiceRelated, docto.idInvoice]
                delete docto.id
                delete docto.idInvoice
            } )

            delete element.id
        } )

        // selectedOptionRelatedCfdi.map( element => {
        //     newValues.idInvoiceRelated = [...newValues.idInvoiceRelated, element.value]
        // } )

        newValues.totales = {
            montoTotalPagos: montoTotalPagos.current.toString(),
            totalTrasladosImpuestoIVA16: totalTrasladosImpuestoIVA16.current.toString(),
            totalTrasladosBaseIVA16: totalTrasladosBaseIVA16.current.toString()
        }

        // newValues.conceptoData = orderDetailsRef.current
        // newValues.sMetodoPago = 'PUE'
        // newValues.sFormaPago = selectedPaymentForm.value.toString()
        // newValues.sUsoCFDI = selectedOptionCfdiUse.value
        // newValues.sClaveImpuestoPartida = "002"
        // newValues.sTipoComprobante = "FACTURA"
        // newValues.sClaveTipoComprobante = 'P'
        newValues.idRegisterUser = id
        // newValues.sTipoRelacion = selectedOptionCfdiRelation.value

        newValues.pago = newDataProductsOrder
        newValues.sPayDate = toISOStringWithTimezone(deliveryDate).substring(0, 10)

        delete newValues.dDescuento
        delete newValues.dSubTotal
        delete newValues.dTax
        delete newValues.montoTotalPagos
        delete newValues.totalTrasladosBaseIVA16
        delete newValues.totalTrasladosImpuestoIVA16
        delete newValues.dTotal
        delete newValues.sTipoRelacion

        dispatch( startProcessing() )
        //save the order to get the idOrder
        await lavakleenApi.post('Pay/createPrePayment', newValues, {
            headers
        }).then( response => {
            if(response.data.sErrors.length === 0) {
                setTimeout(() => {
                    Swal.fire(alertConfig)
                }, 200);
            } else {
                setTimeout(() => {
                    Swal.fire({
                        title: 'Error',
                        text: response.data.sErrors[0],
                        icon: 'error',
                        willClose: () => {
                        }
                    })
                }, 200);
            }
        })

        dispatch( stopProcessing() )
    }

    const reloadPage = () => {
        location.href = `/pages/new-client/${idCustomerRegister}`
    }

    const addListToClient = (list, checkId) => {
        let productsId = listAssignedRef.current
        const check = document.getElementById(checkId)
        if(check.checked) {
            const el = productsId.find(element => element === list.idListProduct)
            if(el === undefined)
                productsId.push(list.idListProduct)
        } else {
            const el = productsId.findIndex(element => element === list.idListProduct)
            productsId.splice(el, 1)
        }

        listAssignedRef.current = productsId

        setTimeout(() => {
            productsId.map( element => {
                document.getElementById(`check_list_${element}`).checked = true
            } )
        }, 100);

        setCustomerProductListsList(productsId)
    }

    const setWithProduct = () => {
        const check = document.getElementById('check_with_product')
        if(check.checked) {
            setItemWithProduct(true)
            getProducts()
        } else {
            setItemWithProduct(false)
        }
    }

    const getCustomerLists = async (customer) => {
        const lists = await getDataFromApi(`Customer/getCustomerProdList?idCustomer=${customer.value}`, customer.value, [], headers)
        if(lists.status === 'success') {
            setDataProductsListCustomer(lists.customerListsList)
            lists.customerListsList.map( async (list) => {
                const listOfProducts = await getDataFromApi(`ProductList/geProductsOfList?idListProduct=${list.idProductList}`, list.idProductList, [], headers)

                if(listOfProducts.status === 'success') {
            
                    productsOfList.current = listOfProducts.productosOfList;
                }

            } )
        }
    }

    const getCustomerProducts = async (customer) => {
        const lists = await getDataFromApi(`Customer/getCustomerProducts?idCustomer=${customer.value}`, customer.value, [], headers)
        if(lists.status === 'success') {
            setDataProductsCustomer(lists.customerProductList)
        }
    }

    const onAddListClick = async () => {
        dispatch( startProcessing() )
        const alertConfig = {
            title: 'Exito',
            text: 'Listas asignadas correctamente.',
            icon: 'success',
            willClose: () => {
                onFinishRegister()
            }
        };

        const result = await saveData('Customer/updateListsOfCustomer', { idCustomer, customerProductListsList }, headers, alertConfig)
        if(result.status === 'success') {
            await getCustomerLists()
        }

        dispatch( stopProcessing() )
    }

    const onAddProductClick = async () => {
        dispatch( startProcessing() )
        const alertConfig = {
            title: 'Exito',
            text: 'Producto-servicios asignados correctamente.',
            icon: 'success',
            willClose: () => {
                onFinishRegister()
            }
        };

        const result = await saveData('Customer/updateProductsOfCustomer', { idCustomer, customerProducts }, headers, alertConfig)
        if(result.status === 'success') {
            await getCustomerProducts()
        }

        dispatch( stopProcessing() )
    }

    const getDropdownData = async () => {
        await lavakleenApi.get('Customer/getCustomerList?filterIdStatusCustomer=1', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.customerList.map( option => {
                optionsArray.push({value: option.idCustomer, label: `${option.sCompanyName}`, discount: option.dPercent})
            } )
            setOptionsCustomers( optionsArray );
            clientsRef.current = optionsArray;
        } )

        await lavakleenApi.get('ServiceType/getServiceList', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.serviceTypeList.map( option => {
                if(option.sStatus === 'Activo')
                    optionsArray.push({value: option.idServiceType, label: option.sServiceType})
            } )
            setOptionsProductList( optionsArray );
            servicesListRef.current = optionsArray
        } )

        await lavakleenApi.get('Product/getProductList', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.productList.map( option => {
                optionsArray.push({value: option.idProduct, label: option.sProductService, sServiceType: option.sServiceType})
            } )
            productsDetailsRef.current = optionsArray
        } )

        await lavakleenApi.get('CustomerMeasurement/getCustomerMeasurementList', {
            headers
        }).then( response => {
            let optionsArray = []
            response.data.customerMeasurementList.map( option => {
                optionsArray = [...optionsArray, {value: option.idCustomerMeasurement, label: option.sCustomerMeasurement, sClaveSAT: option.sClaveSAT}]
            } )
            setOptionsMeasurementUnits( optionsArray );
            measurementUnitsRef.current = optionsArray
        } )

        // if(orders !== undefined) {
        //     const arrayOrders = orders.split('|')
        //     arrayOrders.map( async order => {
        //         const details = await getDataFromApi(`/OrderDetail/getOrderDetailList?idOrder=${order}`, order, {}, headers)
        //         if(details.orderDetailList !== null) {
        //             details.orderDetailList.map( element => {
        //                 orderDetailsRef.current = [...orderDetailsRef.current, {iQuantity: element.iQuantity, sProductService: element.sProductService, dSubtotal: element.dTotal, dPrice: element.dPrice, dTaxAmount: (((element.iQuantity * element.dPrice) - ((element.iQuantity * element.dPrice) * (discount / 100))) * (parseFloat(element.dTaxPercent) / 100)).toFixed(2), idOrderDetail: element.idOrderDetail, dDiscount: (element.iQuantity * element.dPrice) * (discount / 100)}]
        //             } )
        //         }
        //         setDataProductsOrder(orderDetailsRef.current)
        //         calculateTotal(orderDetailsRef.current)
        //     } )
        // }
    }

    const getClientInvoices = async (customer) => {
        const invoices = await getDataFromApi(`PreInvoice/getPreInvoiceList?idCustomer=${customer.value}`, customer.value, [], headers)
        if(invoices.status === 'success') {
            let optionsArray = []
            let optionsArrayDocumentos = []
            invoices.preInvoiceList.map( option => {
                if(option.invoiceData !== null) {
                    optionsArray = [ ...optionsArray,  {value: option.invoiceData.idInvoice, label: `Factura: ${option.invoiceData.idInvoice} Estatus: ${option.invoiceData.sEstatus} Total: ${option.invoiceData.dTotal} Folio fiscal: ${option.invoiceData.sFolioFiscal}`, dTotal: option.invoiceData.dTotal}]

                    optionsArrayDocumentos = [...optionsArrayDocumentos, {value: option.invoiceData.sFolioFiscal, label: `Factura: ${option.invoiceData.idInvoice} Estatus: ${option.invoiceData.sEstatus} Total: $${option.invoiceData.dTotal} Folio fiscal: ${option.invoiceData.sFolioFiscal}`, dTotal: option.invoiceData.dTotal, idInvoice: option.invoiceData.idInvoice}]
                }
            } )
            setOptionsRelatedCfdis( optionsArray )
            setOptionsIdDocuments( optionsArrayDocumentos )
            // billingAddressesRef.current = optionsArray
        }
    }

    const getProductsList = async (customer) => {
        const lists = await getDataFromApi(`Customer/getCustomerProdList?idCustomer=${customer.value}`, customer.value, [], headers)
        if(lists.status === 'success') {
            const optionsArray = []
            lists.customerListsList.map( option => {
                optionsArray.push({value: option.idProductList, label: `${option.sListName}`})
            } )
            setOptionsProductList( optionsArray );
        }
    }

    const getProductsDetails = async () => {
        const productsDetails = await getDataFromApi(`Product/getProductList`, 4, [], headers)
        if(productsDetails.status === 'success') {
            setDataProducts(productsDetails.productList)
        }
    }

    const getData = async () => {
        dispatch( startProcessing() )
        await getDropdownData();
        dispatch( stopProcessing() )
    }

    const getOrderDetails = async(orderId) => {
        const details = await getDataFromApi(`/OrderDetail/getOrderDetailList?idOrder=${orderId}`, orderId, {}, headers)
        const optionsArray = []
        if(details.orderDetailList !== null) {
            details.orderDetailList.map( element => {
                optionsArray.push({iQuantity: element.iQuantity, sProductService: element.sProductService, dSubtotal: element.dTotal, dPrice: element.dPrice, dTaxAmount: (((element.iQuantity * element.dPrice) - ((element.iQuantity * element.dPrice) * (discount / 100))) * (parseFloat(element.dTaxPercent) / 100)).toFixed(2), idOrderDetail: element.idOrderDetail, dDiscount: (element.iQuantity * element.dPrice) * (discount / 100)})
            } )
        }

        return optionsArray
    }

    const getOrderPayments = async(orderId) => {
        if(orderId === undefined)
            orderId = idOrder
        const details = await getDataFromApi(`Payment/getPaymentList?idOrder=${orderId}`, orderId, {}, headers)
        if(details.status === 'success') {
            
            
            setDataPaymentsOrder(details.paymentList)
        }
    }

    const getRelatedDocuments = () => {

    }

    const getProducts = async () => {
        const products = productsDetailsRef.current
        let optionsArray = []

        dataProductsCustomer.map( productCustomer => {
            const product = products.find( element => element.value === productCustomer.idProductService )
            if(product !== undefined)
                optionsArray = [...optionsArray, {value: product.value, label: product.label} ]
        } )

        productsOfList.current.map( productCustomer => {
            const product = optionsArray.find(element => element.value == productCustomer.idProduct)
            if( product == undefined)
                optionsArray = [...optionsArray, {value: productCustomer.idProduct, label: productCustomer.sProductName} ]
        } )
        
        setOptionsProducts( optionsArray );
        // const products = await getDataFromApi(`ProductList/geProductsOfList?idListProduct=${list.value}`, list.value, [], headers)
        // if(products.status === 'success') {
        //     const optionsArray = []
        //     products.productosOfList.map( option => {
        //         optionsArray.push({value: option.idProduct, label: option.sProductName})
        //     } )
        //     setOptionsProducts( optionsArray );
        // }
    }

    const editCreditNoteDetail = async(row) => {
        // setSelectedProduct({ value: row.idProduct, label: row.sProductService })
        // setInvalidProduct(false)

        const tax = Taxes.find( element => element.value === row.dTaxPercen )
        setSelectedOptionTax(tax)
        setInvalidTax(false)

        const unit = measurementUnitsRef.current.find( element => element.label === row.sUnidad )
        setSelectedOptionMeasurementUnit(unit)
        setInvalidMeasurementUnit(false)

        const taxObject = optionsTaxObjects.find( element => element.value === row.sObjetoImpuesto )
        setSelectedOptionTaxObject(taxObject)
        setInvalidTaxObject(false)

        await getSatProduct(row.idProductService)

        setFormItemData(row)
        toggleModal('creditNoteDetail')
    }

    const deletePaymentDetail = async (row) => {

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                let tempArray = orderDetailsRef.current
                const el = tempArray.findIndex(element => element.id === row.id)
                tempArray.splice(el, 1)

                setDataProductsOrder(tempArray)
                orderDetailsRef.current = tempArray
                calculateTotal(orderDetailsRef.current)
            }
        })
    }

    const deleteRelatedDocument = (row) => {
        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                let tempArray = relatedDocumentsRef.current
                const el = tempArray.findIndex(element => element.id === row.id)
                tempArray.splice(el, 1)

                setDataRelatedDocuments(tempArray)
                relatedDocumentsRef.current = tempArray
            }
        })
    }

    const deleteOrderPayment = async (row) => {
        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                dispatch( startProcessing() )
                const result = await deleteDataFromApi(`Payment/removePayment?idPayment=${row.idPayment}`, headers)
                if(result.sErrors.length === 0) {
                    setTimeout(() => {
                        Swal.fire({
                            title: 'Exito',
                            text: 'Pago eliminado correctamente.',
                            icon: 'success',
                            willClose: async () => {
                                await getOrderPayments(idOrder)
                            }
                        })
                    }, 200)
                } else {
                    setTimeout(() => {
                        Swal.fire({
                            title: 'Error',
                            text: response.data.sErrors[0],
                            icon: 'error',
                            willClose: async () => {
                                
                            }
                        })
                    }, 200)
                }
                dispatch( stopProcessing() )
            }
        })
    }

    const onFinishRegister = () => {
        setFinisRegistering(true)
    }

    const toggle = (tab) => {
        if(state.activeTab !== tab) {
            setState({
                ...state,
                activeTab: tab
            })
        }
    }

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const toggleModalPayment = (saveDataToEndpoint) => {
        setModalStatePayments({
            ...modalStatePayments,
            isOpen: !modalStatePayments.isOpen,
            saveDataToEndpoint,
        })
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Complementos de pago')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Complementos de pago'))
        
        if(userPerm.bCreate !== true && idCustomer === undefined) {
            navigate(`/pages/payments`)
        }

        if(userPerm.bEdit !== true && idCustomer !== undefined) {
            navigate(`/pages/payments`)
        }
    }

    const onAddItemClick = () => {
        setFormItemData({
            formaDePagoP: '',
            monedaP: '',
            monto: '',
            tipoCambioP: '1',
            numOperacion: '',
            id: 0,
            doctoRelacionado: [],
            sPayDetailDate: '',
        })
        setSelectedProduct(null)
        setInvalidProduct(true)
        setSelectedOptionTax(null)
        setInvalidTax(true)
        setSelectedOptionSatProduct(null)
        setInvalidSatProduct(true)
        setSelectedOptionMeasurementUnit(null)
        setInvalidMeasurementUnit(true)
        setSelectedOptionTaxObject(null)
        setInvalidTaxObject(true)

        setDataRelatedDocuments([])
        toggleModal('paymentDetail')
    }

    const onAddPaymentClick = () => {
        setFormRelatedDocument({
            monedaDR: '',
            numParcialidad: '1',
            equivalenciaDR: '',
            impSaldoAnt: '',
            impPagado: '',
            impSaldoInsoluto: '',
            objetoImpDR: '',
            IdDocumento: '',
            id: 0,
        })
        setSelectedOptionIdDocumento(null)
        setInvalidIdDocumento(true)
        setSelectedOptionTaxObject(null)
        setInvalidTaxObject(true)
        toggleModalPayment('relatedDocument')
    }

    const saveTemporalData = async (values) => {
        values.monedaDR = 'MXN'
        values.objetoImpDR = selectedOptionTaxObject.value
        values.equivalenciaDR = '1'

        const tempArray = dataRelatedDocuments
        const num = relatedDocumentsRef.current.length
    
        setDataRelatedDocuments([...tempArray, {id: num + 1, IdDocumento: values.IdDocumento, numParcialidad: values.numParcialidad.toString(), impSaldoAnt: values.impSaldoAnt.toString(), impPagado: values.impPagado.toString(), impSaldoInsoluto: values.impSaldoInsoluto.toString(), monedaDR: values.monedaDR, objetoImpDR: values.objetoImpDR, idInvoice: values.idInvoice, equivalenciaDR: values.equivalenciaDR}])
        
        relatedDocumentsRef.current = [...relatedDocumentsRef.current, {id: num + 1, IdDocumento: values.IdDocumento, numParcialidad: values.numParcialidad.toString(), impSaldoAnt: values.impSaldoAnt.toString(), impPagado: values.impPagado.toString(), impSaldoInsoluto: values.impSaldoInsoluto.toString(), impSaldoInsoluto: values.impSaldoInsoluto.toString(), monedaDR: values.monedaDR, objetoImpDR: values.objetoImpDR, idInvoice: values.idInvoice, equivalenciaDR: values.equivalenciaDR}]

        calculateTotalPaymentDr(relatedDocumentsRef.current)
        
        toggleModalPayment('')
    }

    const saveTemporalPayment = async (values) => {
        values.formaDePagoP = selectedPaymentForm.value
        values.monedaP = selectedOptionCurrency.value
        values.doctoRelacionado = dataRelatedDocuments
        values.sPayDetailDate = toISOStringWithTimezone(paymentDate).substring(0, 10)
        values.tipoCambioP = values.tipoCambioP.toString()
        values.monto = values.monto.toString()
        values.numOperacion = '1'

        const tempArray = dataProductsOrder
        const num = dataProductsOrder.length

        values = { ...values, id: num + 1 }

        setDataProductsOrder([...tempArray, values])
        orderDetailsRef.current = [...tempArray, values]

        calculateTotalPayments(orderDetailsRef.current)

        relatedDocumentsRef.current = []

        toggleModal('')
    }

    const editTemporalData = async (values) => {
        console.log(values)
        values.dTaxPercen = selectedOptionTax.value
        values.sClaveProdServ = selectedOptionSatProduct.value
        values.idProductService = selectedOptionSatProduct.id
        values.dTasaOcuota = (parseFloat(selectedOptionTax.value) / 100).toFixed(6)
        values.dBase = values.iCantidad * values.dValorUnitario
        values.dImporte = values.dBase
        values.dImpuestoImporte = (values.dBase * values.dTasaOcuota).toFixed(2)
        values.sObjetoImpuesto = selectedOptionTaxObject.value
        values.sClaveUnidad = 'H87'
        values.sUnidad = selectedOptionMeasurementUnit.label
        const tempArray = dataProductsOrder.map( element => {
            if(element.id === values.id)
                return {iCantidad: values.iCantidad, dImporte: values.dImporte, dValorUnitario: values.dValorUnitario, sDescripcion: values.sDescripcion, sUnidad: values.sUnidad, sClaveUnidad: values.sClaveUnidad, sClaveProdServ: values.sClaveProdServ, dImpuestoImporte: values.dImpuestoImporte, dTasaOcuota: values.dTasaOcuota, id: values.id, sObjetoImpuesto: values.sObjetoImpuesto, dBase: values.dBase, dTaxPercen: values.dTaxPercen, idProductService: values.idProductService}

            return element
        } )
        
        setDataProductsOrder(tempArray)
        orderDetailsRef.current = tempArray

        calculateTotal(orderDetailsRef.current)

        toggleModal('creditNoteDetail')
    }

    const editTemporalPayment = async (values) => {

    }

    const calculateTotal = (items) => {
        let subTotal = 0
        let tax = 0
        let total = 0
        let amountDiscount = 0
        items.map( item => {
            subTotal += parseFloat(item.dImporte)
            tax += parseFloat(item.dImpuestoImporte)
            amountDiscount += 0
        } )

        total = subTotal - amountDiscount + tax
        console.log(total)

        invoiceData.dSubTotal = subTotal.toFixed(2)
        invoiceData.dDiscount = amountDiscount.toFixed(2)
        invoiceData.dTax = tax.toFixed(2)
        invoiceData.dTotal = total.toFixed(2)
    }

    const calculateTotalPaymentDr = (items) => {
        let totalPayment = 0

        items.map( item => {
            totalPayment += parseFloat(item.impPagado)
        } )


        setFormItemData({...formItemData, monto: totalPayment })
    }

    const calculateTotalPayments = (items) => {
        let totalPayment = 0

        items.map( item => {
            totalPayment += parseFloat(item.monto)
        } )

        invoiceData.montoTotalPagos = totalPayment.toFixed(2)
        invoiceData.totalTrasladosBaseIVA16 = (totalPayment / 1.16).toFixed(2)
        invoiceData.totalTrasladosImpuestoIVA16 = ((totalPayment / 1.16) * 0.16).toFixed(2)
        montoTotalPagos.current = totalPayment.toFixed(2)
        totalTrasladosBaseIVA16.current = (totalPayment / 1.16).toFixed(2)
        totalTrasladosImpuestoIVA16.current = ((totalPayment / 1.16) * 0.16).toFixed(2)
    }

    const calculateDiscount = (value) => {
        invoiceData.dDiscount = parseFloat(value)
    }

    const setItemData = async (item) => {
        const product = await getDataFromApi(`Product/findProduct?idProduct=${item.value}`, item.value, [], headers)
        if(product.status === 'success'){
            const tax = Taxes.find( element => element.value === product.productData.sTaxId )
            const unit = optionsMeasurementUnits.find( element => element.value == product.productData.idCustomerMeasurement )
            const taxObject = optionsTaxObjects.find( element => element.value == product.productData.sTaxObjectId )
            const prodSat = {value: product.productData.sCalveSAT, label: `${product.productData.sCalveSAT}`, id: product.productData.idProductServiceSAT}
            
            setFormItemData( {
                // idOrder: parseFloat(idOrder),
                // idProduct: product.productData.idProduct,
                // iQuantity: formItemData.iQuantity,
                // sComments: formItemData.sComments,
                // dPrice: product.productData.dMXNPrice,
                // dTaxPercen: product.productData.sTaxId,
                // id: 0,
                // sProductService: product.productData.sProductService

                

                dImporte: 0,
                dValorUnitario: product.productData.dMXNPrice,
                sDescripcion: product.productData.sProductService,
                sUnidad: unit.label,
                sClaveUnidad: unit.sClaveSAT,
                iCantidad: 1,
                sClaveProdServ: product.productData.sCalveSAT,
                dImpuestoImporte: product.productData.dMXNPrice,
                dTasaOcuota: (parseFloat(tax.value) / 100).toFixed(6),
                sObjetoImpuesto: product.productData.sTaxObjectId,
                dBase: product.productData.dMXNPrice,
                id: 0,
                check_with_product: itemWithProduct,
                dTaxPercen: tax.value,
                idProductService: product.productData.idProductServiceSAT
            } );

            setSelectedOptionTax(tax)
            setInvalidTax(false)
            setSelectedOptionTaxObject(taxObject)
            setInvalidTaxObject(false)
            setSelectedOptionMeasurementUnit(unit)
            setInvalidMeasurementUnit(false)
            setSelectedOptionSatProduct(prodSat)
            setInvalidSatProduct(false)
        }
    }

    const getSatProducts = async (inputValue) => {
        let optionsArray = []
        await lavakleenApi.get(`ServiceProduct/getServicesProductsList?filterProdServ=${inputValue}`, {
            headers
        }).then( response => {
            
            response.data.serviceProductData.map( option => {
                optionsArray = [...optionsArray, {value: option.sClaveSAT, label: `${option.sClaveSAT} ${option.sProductService}`, id: option.idProductService}]
            } )
        } )

        setOptionsSatProducts(optionsArray)

        return optionsArray;
    }

    const loadSatProducts = (inputValue) => {
        if(inputValue.length > 4) {
            return new Promise((resolve) => {
                resolve(getSatProducts(inputValue))
            })
        }
    };

    const getSatProduct = async (idProdServ) => {
        const satProduct = await getDataFromApi(`ServiceProduct/findProdServ?idProdServ=${idProdServ}`, idProdServ, {}, headers);

        if(satProduct.status === 'success') {
            setSelectedOptionSatProduct({
                value: satProduct.prodServ.sClaveSAT,
                label: `${satProduct.prodServ.sClaveSAT} ${satProduct.prodServ.sProductService}`,
                id: satProduct.prodServ.idProductService
            })
            setInvalidSatProduct(false)
        }
    }

    const nothingToDo = () => {
        
    }

    const setRelatedDocumentData = (document) => {
        setFormRelatedDocument({ ...formRelatedDocument, impSaldoAnt: document.dTotal, impPagado: document.dTotal, impSaldoInsoluto: 0.00, idInvoice: document.idInvoice })
    }

    const toISOStringWithTimezone = date => {
        const tzOffset = -date.getTimezoneOffset();
        const diff = tzOffset >= 0 ? '+' : '-';
        const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
        return date.getFullYear() +
          '-' + pad(date.getMonth() + 1) +
          '-' + pad(date.getDate()) +
          'T' + pad(date.getHours()) +
          ':' + pad(date.getMinutes()) +
          ':' + pad(date.getSeconds()) +
          diff + pad(tzOffset / 60) +
          ':' + pad(tzOffset % 60);
      };
      

    useEffect(() => {
        checkPermissions();
        getData();
    }, [])

    useEffect(() => {
        if(finisRegistering == true) {
            navigate(`/pages/payments`)
        }
    }, [finisRegistering])

    useEffect(() => {
        if(isProcessing == true) {

            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }

        if(!isProcessing) Swal.close()
    }, [isProcessing])
    

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Crear complemento de pago"
                                subheading="Pantalla para creación/edición de complementos de pago"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <Form
                                initialValues={invoiceData}
                                onSubmit={ (values) => onSubmit(values) }
                                validationSchema={
                                    yup.object({
                                        // montoTotalPagos: yup.string().required(),
                                        // totalTrasladosBaseIVA16: yup.string().required(),
                                        // totalTrasladosImpuestoIVA16: yup.string().required(),
                                    })
                                }
                            >
                                <Row>
                                    <Col md="12">
                                        <Card className="main-card mb-3">
                                            <CardBody>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Cliente</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCustomer}
                                                                options={optionsCustomers}
                                                                isClearable
                                                                name="idCustomerType"
                                                                maxMenuHeight={ 280 }
                                                                key={ selectedOptionCustomer }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidCustomer ? 'is-invalid form-control' : '' }
                                                                onChange={ async (option) => {
                                                                    option == null ? setInvalidCustomer(true) : setInvalidCustomer(false)
                                                                    setSelectedOptionCustomer(option)
                                                                    if(option){
                                                                        dispatch( startProcessing() )
                                                                        setSelectedOptionRelatedCfdi(null)
                                                                        await getClientInvoices(option)
                                                                        await getCustomerProducts(option)
                                                                        await getCustomerLists(option)

                                                                        dispatch( stopProcessing() )
                                                                    }
                                                                }}
                                                                isDisabled={ idOrder !== undefined ? true : false }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="3">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Fecha complemento de pago</Label>
                                                            <DatePicker
                                                                className="form-control"
                                                                name="dDeliveryDate"
                                                                dateFormat="yyyy-MM-dd"
                                                                selected={ deliveryDate }
                                                                onChange={(date) => setDeliveryDate(date)}
                                                                minDate={ minDate }
                                                                maxDate={ invoiceDate }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="3">
                                                        <FormGroup className="mb-3">
                                                            <Label for="sSerie">Serie</Label>
                                                            <Input type="text" name="sSerie" placeholder=""/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Cfdis Relacionados</Label>
                                                            <Select
                                                                defaultValue={selectedOptionRelatedCfdi}
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidRelatedCfdi(true) : setInvalidRelatedCfdi(false)
                                                                    setSelectedOptionRelatedCfdi(option)
                                                                }}
                                                                options={optionsRelatedCfdis}
                                                                name="idState"
                                                                isMulti
                                                                maxMenuHeight={ 200 }
                                                                key={ selectedOptionRelatedCfdi }
                                                                placeholder="Selecciona una opción"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3">
                                                            <Label for="idCustomerType">Tipo de relación</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCfdiRelation}
                                                                options={optionsCfdiRelation}
                                                                isClearable
                                                                name="idProduct"
                                                                key={ selectedOptionCfdiRelation }
                                                                placeholder="Selecciona una opción"
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidCfdiRelation(true) : setInvalidCfdiRelation(false)
                                                                    setSelectedOptionCfdiRelation(option)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Monto total pagos</Label>
                                                            <Input type="number" name="montoTotalPagos" value={montoTotalPagos.current} readOnly/>
                                                        </FormGroup>
                                                        
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Total Traslados Base IVA 16%</Label>
                                                            <Input type="number" name="totalTrasladosBaseIVA16" value={totalTrasladosBaseIVA16.current} readOnly/>
                                                        </FormGroup>
                                                        {/* <FormGroup>
                                                            <Label for="idCustomerType">Método de pago</Label>
                                                            <Select
                                                                defaultValue={selectedOptionPaymentMethod}
                                                                options={optionsPaymentMethod}
                                                                isClearable
                                                                name="idCustomerType"
                                                                key={ selectedOptionPaymentMethod }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidPaymentMethod ? 'is-invalid form-control' : '' }
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidPaymentMethod(true) : setInvalidPaymentMethod(false)
                                                                    setSelectedOptionPaymentMethod(option)
                                                                }}
                                                            />
                                                        </FormGroup> */}
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Total Traslados Impuesto IVA 16%</Label>
                                                            <Input type="number" name="totalTrasladosImpuestoIVA16" value={totalTrasladosImpuestoIVA16.current} readOnly/>
                                                        </FormGroup>
                                                        {/* <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Total Traslados Base IVA 8%</Label>
                                                            <Input type="number" name="totalTrasladosBaseIVA16"/>
                                                        </FormGroup> */}
                                                        {/* <FormGroup className="mb-3">
                                                            <Label for="idCustomerType">Uso de CFDI</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCfdiUse}
                                                                options={optionsCfdiUse}
                                                                isClearable
                                                                name="idCustomerType"
                                                                key={ selectedOptionCfdiUse }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidCfdiUse ? 'is-invalid form-control' : '' }
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidCfdiUse(true) : setInvalidCfdiUse(false)
                                                                    setSelectedOptionCfdiUse(option)
                                                                }}
                                                            />
                                                        </FormGroup> */}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="4">
                                                        
                                                    </Col>
                                                    <Col md="4">
                                                        {/* <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Total Traslados Impuesto IVA 8%</Label>
                                                            <Input type="number" name="dSubTotal"/>
                                                        </FormGroup> */}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <Card className="main-card mb-3 mt-3">
                                                            <CardHeader>
                                                                <Nav justified>
                                                                    <TabHeaderComponent id="items" label="Conceptos de complemento" state={state} toggle={ toggle }/>
                                                                    {
                                                                        idOrder !== undefined
                                                                        ? <TabHeaderComponent id="payments" label="Pagos" state={state} toggle={ toggle }/>
                                                                        : ''
                                                                    }
                                                                    
                                                                </Nav>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <TabContent activeTab={ state.activeTab }>
                                                                    <TabPane tabId="items">
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                                                                    <Button
                                                                                        className="mb-2 me-2"
                                                                                        color="primary"
                                                                                        onClick={ onAddItemClick }
                                                                                    >
                                                                                        Agregar
                                                                                    </Button>
                                                                                </ul>
                                                                            </Col>
                                                                            <Col md="12">
                                                                                <DataTable data={dataProductsOrder}
                                                                                    columns={columnsProductsList}
                                                                                    pagination
                                                                                    fixedHeader
                                                                                    fixedHeaderScrollHeight="500px"
                                                                                />
                                                                            </Col>
                                                                            <ModalComponent
                                                                                isOpen={modalState.isOpen}
                                                                                toggle={ toggleModal }
                                                                                backdrop={ modalState.backDrop } modalTitle="Agregar/Editar pago"
                                                                                size="lg"
                                                                                initialValues={ formItemData }
                                                                                validationSchema={ {
                                                                                    
                                                                                } }
                                                                                saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                                                                alertMessages={ { addMessage: 'Pago agregado exitosamente', updateMessage: 'Pago actualizado exitosamente' } }
                                                                                getDataFunction={ nothingToDo }
                                                                                dropDownsToValidate={ [  ] }
                                                                                saveTemporalData={ saveTemporalPayment }
                                                                                editTemporalData= { editTemporalPayment }
                                                                            >
                                                                                <Row>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <Label for="idCustomerType">Fecha de pago</Label>
                                                                                            <DatePicker
                                                                                                className="form-control"
                                                                                                name="sPayDetailDate"
                                                                                                dateFormat="yyyy-MM-dd"
                                                                                                selected={ paymentDate }
                                                                                                onChange={(date) => setPaymentDate(date)}
                                                                                                maxDate={ invoiceDate }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="idCustomerType">Forma de pago</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedPaymentForm}
                                                                                                options={optionsPaymentsForm}
                                                                                                isClearable
                                                                                                name="idProduct"
                                                                                                key={ selectedPaymentForm }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidPaymentForm ? 'is-invalid form-control' : '' }
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidPaymentForm(true) : setInvalidPaymentForm(false)
                                                                                                    setSelectedPaymentForm(option)
                                                                                                }}
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <FormGroup>
                                                                                            <Label for="idCustomerType">Moneda</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedOptionCurrency}
                                                                                                options={optionsCurrency}
                                                                                                name="idCustomerType"
                                                                                                key={ selectedOptionCurrency }
                                                                                                placeholder="Selecciona una opción"
                                                                                                isDisabled={ idOrder !== undefined ? true : false }
                                                                                                
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={3}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="sBuinessName">Tipo de cambio</Label>
                                                                                            <Input type="number" name="tipoCambioP"/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={3}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="sBuinessName">Monto pago</Label>
                                                                                            <Input type="number" name="monto" readOnly/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md="12">
                                                                                        <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                                                                            <Button
                                                                                                className="mb-2 me-2"
                                                                                                color="primary"
                                                                                                onClick={ onAddPaymentClick }
                                                                                            >
                                                                                                Agregar documento relacionado
                                                                                            </Button>
                                                                                        </ul>
                                                                                    </Col>
                                                                                    <Col md={12}>
                                                                                        <DataTable data={dataRelatedDocuments}
                                                                                            columns={columnsRelatedDocuments}
                                                                                            pagination
                                                                                            fixedHeader
                                                                                            fixedHeaderScrollHeight="500px"
                                                                                        />
                                                                                        {/* <FormGroup className="mb-2">
                                                                                            <Label for="iCantidad">Cantidad</Label>
                                                                                            <Input type="number" name="iCantidad" placeholder=""/>
                                                                                        </FormGroup>
                                                                                        {
                                                                                            itemWithProduct !== true
                                                                                            ? <>
                                                                                                <FormGroup className="mb-2">
                                                                                                    <Label for="sUnit">Unidad</Label>
                                                                                                    <Select
                                                                                                        defaultValue={selectedOptionMeasurementUnit}
                                                                                                        onChange={ (option) => {
                                                                                                            option == null ? setInvalidMeasurementUnit(true) : setInvalidMeasurementUnit(false)
                                                                                                            setSelectedOptionMeasurementUnit(option)
                                                                                                        }}
                                                                                                        options={optionsMeasurementUnits}
                                                                                                        name="idState"
                                                                                                        key={ selectedOptionMeasurementUnit }
                                                                                                        placeholder="Selecciona una opción"
                                                                                                        className={ invalidMeasurementUnit ? 'is-invalid form-control' : '' }
                                                                                                    />
                                                                                                </FormGroup>
                                                                                                <FormGroup className="mb-2">
                                                                                                    <Label>Clave producto SAT</Label>
                                                                                                    <AsyncSelect
                                                                                                        // cacheOptions
                                                                                                        defaultValue={selectedOptionSatProduct}
                                                                                                        defaultOptions
                                                                                                        onChange={ (option) => {
                                                                                                            option == null ? setInvalidSatProduct(true) : setInvalidSatProduct(false)
                                                                                                            setSelectedOptionSatProduct(option)
                                                                                                        }}
                                                                                                        loadOptions={loadSatProducts}
                                                                                                        placeholder="Escribe para buscar..."
                                                                                                        className={ invalidSatProduct ? 'is-invalid form-control' : '' }
                                                                                                        key={ selectedOptionSatProduct }
                                                                                                    />
                                                                                                </FormGroup>
                                                                                                <FormGroup className="mb-2">
                                                                                                    <Label for="idCustomerType">Descripción</Label>
                                                                                                    <Input type="text" name="sDescripcion" placeholder=""/>
                                                                                                </FormGroup>
                                                                                            </>
                                                                                            : ''
                                                                                        }
                                                                                        {
                                                                                            itemWithProduct === true
                                                                                            ? <>
                                                                                                <FormGroup className="mb-2">
                                                                                                    <Label for="idCustomerType">Servicio/Producto</Label>
                                                                                                    <Select
                                                                                                        defaultValue={selectedProduct}
                                                                                                        options={optionsProducts}
                                                                                                        isClearable
                                                                                                        name="idProduct"
                                                                                                        key={ selectedProduct }
                                                                                                        placeholder="Selecciona una opción"
                                                                                                        className={ invalidProduct ? 'is-invalid form-control' : '' }
                                                                                                        onChange={ (option) => {
                                                                                                            option == null ? setInvalidProduct(true) : setInvalidProduct(false)
                                                                                                            setSelectedProduct(option)

                                                                                                            if(option)
                                                                                                                setItemData(option)
                                                                                                        }}
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </>
                                                                                            : ''
                                                                                        }
                                                                                        
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="dValorUnitario">Precio</Label>
                                                                                            <Input type="number" name="dValorUnitario" placeholder=""/>
                                                                                        </FormGroup>
                                                                                        {
                                                                                            itemWithProduct !== true
                                                                                            ? <>
                                                                                                <FormGroup className="mb-2">
                                                                                                    <Label>Objeto de impuesto</Label>
                                                                                                    <Select
                                                                                                        defaultValue={selectedOptionTaxObject}
                                                                                                        onChange={ (option) => {
                                                                                                            option == null ? setInvalidTaxObject(true) : setInvalidTaxObject(false)
                                                                                                            setSelectedOptionTaxObject(option)
                                                                                                        }}
                                                                                                        options={optionsTaxObjects}
                                                                                                        name="idState"
                                                                                                        key={ selectedOptionTaxObject }
                                                                                                        placeholder="Selecciona una opción"
                                                                                                        className={ invalidTaxObject ? 'is-invalid form-control' : '' }
                                                                                                    />
                                                                                                </FormGroup>
                                                                                            </>
                                                                                            : ''
                                                                                        }
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="dTaxPercen">I.V.A</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedOptionTax}
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidTax(true) : setInvalidTax(false)
                                                                                                    setSelectedOptionTax(option)
                                                                                                }}
                                                                                                options={optionsTaxes}
                                                                                                name="dTaxPercen"
                                                                                                key={ selectedOptionTax }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidTax ? 'is-invalid form-control' : '' }
                                                                                            />
                                                                                        </FormGroup> */}
                                                                                    </Col>
                                                                                    {/* <Col md={3}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="sBuinessName">Monto base pago</Label>
                                                                                            <Input type="number" name="dSubTotal"/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="sBuinessName">Tasa Impuesto</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedOptionTax}
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidTax(true) : setInvalidTax(false)
                                                                                                    setSelectedOptionTax(option)
                                                                                                }}
                                                                                                options={optionsTaxes}
                                                                                                name="dTaxPercen"
                                                                                                key={ selectedOptionTax }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidTax ? 'is-invalid form-control' : '' }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={3}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="sBuinessName">Importe impuesto pago</Label>
                                                                                            <Input type="number" name="dSubTotal"/>
                                                                                        </FormGroup>
                                                                                    </Col> */}
                                                                                </Row>
                                                                            </ModalComponent>
                                                                        </Row>
                                                                    </TabPane>
                                                                    <TabPane tabId="payments">
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                                                                    <Button
                                                                                        className="mb-2 me-2"
                                                                                        color="primary"
                                                                                        onClick={ onAddPaymentClick }
                                                                                    >
                                                                                        Agregar
                                                                                    </Button>
                                                                                </ul>
                                                                            </Col>
                                                                            <Col md="12">
                                                                                <DataTable data={dataRelatedDocuments}
                                                                                    columns={columnsRelatedDocuments}
                                                                                    pagination
                                                                                    fixedHeader
                                                                                    fixedHeaderScrollHeight="500px"
                                                                                />
                                                                            </Col>
                                                                            <ModalComponent
                                                                                isOpen={modalStatePayments.isOpen}
                                                                                toggle={ toggleModalPayment }
                                                                                backdrop={ modalStatePayments.backDrop } modalTitle="Agregar Documento Relacionado"
                                                                                size="lg"
                                                                                initialValues={ formRelatedDocument }
                                                                                validationSchema={ {
                                                                                    numParcialidad: yup.string().required(),
                                                                                    impSaldoAnt: yup.string().required(),
                                                                                    impPagado: yup.string().required(),
                                                                                } }
                                                                                saveDataToEndpoint={ modalStatePayments.saveDataToEndpoint }
                                                                                alertMessages={ { addMessage: 'Documento agregado exitosamente', updateMessage: 'Documento actualizado exitosamente' } }
                                                                                getDataFunction={ getRelatedDocuments }
                                                                                dropDownsToValidate={ [ { field: 'IdDocumento', value: selectedOptionIdDocumento?.value}, { field: 'objetoImpDR', value: selectedOptionTaxObject?.value} ] }
                                                                                saveTemporalData={ saveTemporalData }
                                                                                editTemporalData= { editTemporalData }
                                                                            >
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <FormGroup>
                                                                                            <Label for="sBuinessName">Documento Relacionado</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedOptionIdDocumento}
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidIdDocumento(true) : setInvalidIdDocumento(false)
                                                                                                    setSelectedOptionIdDocumento(option)
                                                                                                    setRelatedDocumentData(option)
                                                                                                }}
                                                                                                options={optionsIdDocuments}
                                                                                                name="idState"
                                                                                                key={ selectedOptionIdDocumento }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidIdDocumento ? 'is-invalid form-control' : '' }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={4}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="sBuinessName">Número de parcialidad</Label>
                                                                                            <Input type="number" name="numParcialidad"/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={4}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="sBuinessName">Saldo anterior</Label>
                                                                                            <Input type="number" name="impSaldoAnt"/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={4}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="sBuinessName">Importe pagado</Label>
                                                                                            <Input type="number" name="impPagado"/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={4}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label for="sBuinessName">Saldo final</Label>
                                                                                            <Input type="number" name="impSaldoInsoluto"/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                    <Col md={8}>
                                                                                        <FormGroup className="mb-3">
                                                                                            <Label>Objeto de impuesto</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedOptionTaxObject}
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidTaxObject(true) : setInvalidTaxObject(false)
                                                                                                    setSelectedOptionTaxObject(option)
                                                                                                }}
                                                                                                options={optionsTaxObjects}
                                                                                                name="idState"
                                                                                                key={ selectedOptionTaxObject }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidTaxObject ? 'is-invalid form-control' : '' }
                                                                                            />
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                            </ModalComponent>
                                                                        </Row>
                                                                    </TabPane>
                                                                </TabContent>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="2">
                                                        
                                                    </Col>
                                                    <Col md="6">
                                                        {/* <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Dirección de entrega</Label>
                                                            <Select
                                                                defaultValue={selectedOptionBillingAddress}
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidBillingAddress(true) : setInvalidBillingAddress(false)
                                                                    setSelectedOptionBillingAddress(option)
                                                                }}
                                                                options={optionsBillingAddress}
                                                                name="idState"
                                                                key={ selectedOptionBillingAddress }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidBillingAddress ? 'is-invalid form-control' : '' }
                                                            />
                                                        </FormGroup> 
                                                        <FormGroup>
                                                            <Label for="sCondicionesPago">Condiciones de pago</Label>
                                                            <Input type="textarea" name="sCondicionesPago" />
                                                            </FormGroup> */}
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Subtotal</Label>
                                                            <Input type="number" name="dSubTotal" readOnly/>
                                                        </FormGroup>
                                                        <FormGroup className="mb-3">
                                                            <Label for="dDescuento">Descuento</Label>
                                                            <Input type="number" name="dDescuento" readOnly/>
                                                        </FormGroup>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">I.V.A</Label>
                                                            <Input type="number" name="dTax" readOnly/>
                                                        </FormGroup>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBuinessName">Total</Label>
                                                            <Input type="number" name="dTotal" readOnly/>
                                                        </FormGroup>
                                                        <FormGroup className="mb-3" style={ { 'textAlign': 'end' } }>
                                                            <Link 
                                                                className="mt-2 btn btn-danger"
                                                                to="/pages/payments"
                                                            >
                                                                Cancelar
                                                            </Link>{ "  " }
                                                            <Button color="primary" className="mt-2 mr-2" type="submit">
                                                                {
                                                                    (idOrder === undefined)
                                                                    ? 'Guardar'
                                                                    : 'Actualizar'
                                                                }
                                                            </Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form> 
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

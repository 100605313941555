import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { PageTitle } from '../../layout/components/PageTitle';
import { Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { lavakleenApi } from '../../api/lavakleenApi';
import { getCompanyById, baseUrl } from '../../api/providers/providers';

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const SalesDashboard = () => {
    const [data, setData] = useState({})
    const [showDashboard, setShowDashboard] = useState(false)
    const [companyData, setCompanyData] = useState({})
    const [totalAmountOrders, setTotalAmountOrders] = useState({})
    const getData = async () => {
        await lavakleenApi.get('Report/getReportAdmin', {
            headers
        }).then( response => {
            let amountOrders = 0
            let amountInvoices = 0

            response.data.adminReport.mountSales.map( sales => {
                if(sales.sConcept != 'Descuentos') {
                    amountOrders += sales.dAmount
                }
            } )

            response.data.adminReport.invoiceSales.map( sales => {
                if(sales.sConcept != 'Descuentos') {
                    amountInvoices += sales.dAmount
                }
            } )

            setTotalAmountOrders({...totalAmountOrders, amountOrders, amountInvoices})
            setData( response.data.adminReport );
            setShowDashboard(true)
        } )

        const company = await getCompanyById(localStorage.getItem('idCompany'), {
            'Authorization' : 'Bearer ' + localStorage.getItem('sToken')
        });
        if(company.status === 'success'){
            setCompanyData({...company.companyData, sCertificateNumber: '', sPassword: '', certificateFile: '', keyFile: ''});
        }
    }
    useEffect(() => {
        getData();
    }, [])
    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Dashboard de Ventas - Acquatronix CRM"
                                subheading=""
                                icon="pe-7s-graph icon-gradient bg-ripe-malin"
                            />
                            {
                                showDashboard == true
                                ? (
                                    <>
                                        <Row>
                                            <Col md="2">
                                            {
                                                companyData.sImagePath !== ''
                                                ? (
                                                    <img src={ `${baseUrl}/${companyData.sImagePath}` } width="200"></img>
                                                )
                                                : ''
                                            }
                                            </Col>
                                            <Col md="5">
                                                <Card className="mb-3">
                                                    <CardHeader className="card-header-tab card-header-tab-animation">
                                                        <div className="card-header-title">
                                                            {/* <i className="header-icon lnr-apartment icon-gradient bg-love-kiss"> {" "} </i> */}
                                                            Ventas de pedidos por servicio en el mes
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="scroll-area-sm">
                                                            <ListGroup className="rm-list-borders rm-list-borders-scroll" flush>
                                                                {
                                                                    data.mountSales.map( (sales) => (
                                                                        <ListGroupItem key={ sales.sConcept }>
                                                                            <div className="widget-content p-0">
                                                                                <div className="widget-content-wrapper">
                                                                                    <div className="widget-content-left">
                                                                                        <div className="widget-heading">
                                                                                            { sales.sConcept }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="widget-content-right">
                                                                                        <div className="font-size-xlg text-muted">
                                                                                            { `$${sales.dAmount.toLocaleString('en-US')}` }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ListGroupItem>
                                                                    ))
                                                                }
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    Total
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { `$${totalAmountOrders.amountOrders.toLocaleString('en-US')}` }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                            </ListGroup>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="5">
                                                <Card className="mb-3">
                                                    <CardHeader className="card-header-tab card-header-tab-animation">
                                                        <div className="card-header-title">
                                                            {/* <i className="header-icon lnr-apartment icon-gradient bg-love-kiss"> {" "} </i> */}
                                                            Tickets de soporte
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="scroll-area-sm">
                                                            <ListGroup className="rm-list-borders rm-list-borders-scroll" flush>
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    { data.tickets.abiertos.sOrderProcess }
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { data.tickets.abiertos.iNumberOrders }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    { data.tickets.proceso.sOrderProcess }
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { data.tickets.proceso.iNumberOrders }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    { data.tickets.cerrados.sOrderProcess }
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { data.tickets.cerrados.iNumberOrders }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                            </ListGroup>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                            
                                            </Col>
                                            <Col md="5">
                                                <Card className="mb-3">
                                                    <CardHeader className="card-header-tab card-header-tab-animation">
                                                        <div className="card-header-title">
                                                            {/* <i className="header-icon lnr-apartment icon-gradient bg-love-kiss"> {" "} </i> */}
                                                            Resumen de pedidos
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="scroll-area-sm">
                                                            <ListGroup className="rm-list-borders rm-list-borders-scroll" flush>
                                                                {
                                                                    data.orderResume.map( (sales) => (
                                                                        <ListGroupItem key={ `order_${sales.sOrderProcess}` }>
                                                                            <div className="widget-content p-0">
                                                                                <div className="widget-content-wrapper">
                                                                                    <div className="widget-content-left">
                                                                                        <div className="widget-heading">
                                                                                            { sales.sOrderProcess }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="widget-content-right">
                                                                                        <div className="font-size-xlg text-muted">
                                                                                            { sales.iNumberOrders }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ListGroupItem>
                                                                    ))
                                                                }
                                                            </ListGroup>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="5">
                                                <Card className="mb-3">
                                                    <CardHeader className="card-header-tab card-header-tab-animation">
                                                        <div className="card-header-title">
                                                            {/* <i className="header-icon lnr-apartment icon-gradient bg-love-kiss"> {" "} </i> */}
                                                            Facturado en el mes
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="scroll-area-sm">
                                                            <ListGroup className="rm-list-borders rm-list-borders-scroll" flush>
                                                                {
                                                                    data.invoiceSales.map( (sales) => (
                                                                        <ListGroupItem key={ `invoice_${sales.sConcept}` }>
                                                                            <div className="widget-content p-0">
                                                                                <div className="widget-content-wrapper">
                                                                                    <div className="widget-content-left">
                                                                                        <div className="widget-heading">
                                                                                            { sales.sConcept }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="widget-content-right">
                                                                                        <div className="font-size-xlg text-muted">
                                                                                            { `$${sales.dAmount.toLocaleString('en-US')}` }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ListGroupItem>
                                                                    ))
                                                                }
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    Total
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { `$${totalAmountOrders.amountInvoices.toLocaleString('en-US')}` }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                            </ListGroup>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </>
                                )
                                : ''
                            }
                            
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'not-authenticated',
        id: null,
        email: null,
        displayName: null,
        role: null,
        roleName: null,
        errorMessage: null,
        isProcessing: false,
        finishRegister: false,
        userPermissions: null,
    },
    reducers: {
        login: (state, {payload}) => {
            state.status = 'authenticated';
            state.id = payload.idUser;
            state.email = payload.sEmail;
            state.displayName = payload.sName;
            state.role = payload.idRol;
            state.roleName = payload.sRolName;
            state.errorMessage = null;
        },
        logout: (state) => {
            localStorage.removeItem('userData');
            localStorage.removeItem('sToken');
            localStorage.removeItem('idCompany');
            localStorage.removeItem('userPermissions');
            state.status = 'not-authenticated';
            state.id = null;
            state.email = null;
            state.displayName = null;
            state.role = null;
            state.roleName = null;
            state.errorMessage = null;
            state.isProcessing = false;
            state.finishRegister = false;
            state.userPermissions = null;
        },
        permissions: (state, { payload }) => {
            state.userPermissions = payload
        },
        startProcessing: (state) => {
            state.isProcessing = true;
        },

        stopProcessing: (state) => {
            state.isProcessing = false;
        },
        setFinishRegister: (state) => {
            state.finishRegister = true;
        },
        stopFinishRegister: (state) => {
            state.finishRegister = false;
        }
    }
});


// Action creators are generated for each case reducer function
export const { login, startProcessing, stopProcessing, setFinishRegister, stopFinishRegister, logout, permissions } = authSlice.actions;
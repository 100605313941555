import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageTitle } from '../../layout/components/PageTitle';
import { useEffect, useImperativeHandle, useState } from 'react';
import { lavakleenApi } from '../../api/lavakleenApi';
import { ModalComponent } from '../components/ModalComponent';
import { forwardRef } from 'react';
import { convertArrayOfObjectsToCSV, filterDataFromApi, getDataFromApi, saveData } from '../../api/providers/providers';
import { faCheckCircle, faClock, faCloudUpload, faDolly, faTruck, faUpload, faWrench } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany'),
};

export const ProductionPage = forwardRef( (props, ref) => {

    useImperativeHandle( ref, () => ({
        async filterData(values){
            const result = await filterDataFromApi('Customer/getCustomerList', values, headers);
            setData(result.customerList)
        },

        async restore() {
            await getOrders()
        }
    }) )

    const navigate = useNavigate();

    const { id, isProcessing } = useSelector( state => state.auth )

    const [data, setData] = useState([]);
    const [dataHistory, setDataHistory] = useState([]);
    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
    })

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const columns = [
        {
            name: "Folio",
            selector: row => row.idOrder,
            sortable: true,
            width: '80px',
        },
        {
            name: "Cliente",
            selector: row => row.sBusinessName,
            sortable: true,
            width: '250px',
            wrap: true,
        },
        {
            name: "Fecha registro",
            selector: row => row.dRegisterDate,
            sortable: true,
            width: '190px',
        },
        {
            name: "Servicio",
            selector: row => row.sServiceName,
            sortable: true,
            width: '250px',
        },
        {
            name: "Chofer",
            selector: row => row.sDriverName,
            sortable: true,
            width: '250px',
        },
        {
            name: "Peso",
            selector: row => row.dTotalWeigth,
            sortable: true,
        },
        {
            name: "Proceso",
            selector: row => row.sOrderProcess,
            cell: (row) => (
                <>
                    {
                        (row.idOrderProcess == 1)
                        ? <FontAwesomeIcon icon={faCloudUpload} size="2x" color='gray'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 2)
                        ? <FontAwesomeIcon icon={faClock} size="2x" color='red'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 3)
                        ? <FontAwesomeIcon icon={faWrench} size="2x" color='black'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 4)
                        ? <FontAwesomeIcon icon={faDolly} size="2x" color='orange'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 5)
                        ? <FontAwesomeIcon icon={faTruck} size="2x" color='blue'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 6)
                        ? <FontAwesomeIcon icon={faCheckCircle} size="2x" color='green'/> 
                        : ''
                    }
                    {row.sOrderProcess}
                </>
            ),
            sortable: true,
            width: '250px',
        },
        {
            name: "Opciones",
            cell: (row) => (
                <>
                    {
                        (row.idOrderProcess == 2)
                        ? <Button color="primary" onClick={ () => changeOrderProcess(row.idOrder, 3) } title="Cambiar a EN PROCESO">
                            <FontAwesomeIcon
                                icon={faWrench}
                            />
                        </Button>
                        : ''
                    }
                    {
                        (row.idOrderProcess == 3)
                        ? <Link to={ `/pages/edit-production/${row.idOrder}` } className="btn btn-primary" title="Editar">
                                <i className="lnr-pencil"></i>
                            </Link>
                        : ''
                    }
                    
                    
                    <Button color="success" onClick={ () => printTicket(row.idOrder) } title="Imprimir ticket"><i className="lnr-printer"></i></Button>
                    <Button color="warning" onClick={ () => viewOrderHistory(row.idOrder) } title="Ver historial"><i className="lnr-book"></i></Button>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            button: true,
            width: '150px'
        },
    ];

    const columnsHistory = [
        {
            name: "Estatus",
            selector: row => row.sOrderProcess,
            cell: (row) => (
                <>
                    {
                        (row.idOrderProcess == 1)
                        ? <FontAwesomeIcon icon={faCloudUpload} size="2x" color='gray'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 2)
                        ? <FontAwesomeIcon icon={faClock} size="2x" color='red'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 3)
                        ? <FontAwesomeIcon icon={faWrench} size="2x" color='black'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 4)
                        ? <FontAwesomeIcon icon={faDolly} size="2x" color='orange'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 5)
                        ? <FontAwesomeIcon icon={faTruck} size="2x" color='blue'/> 
                        : ''
                    }
                    {
                        (row.idOrderProcess == 6)
                        ? <FontAwesomeIcon icon={faCheckCircle} size="2x" color='green'/> 
                        : ''
                    }
                    {row.sOrderProcess}
                </>
            ),
            sortable: false,
            width: '200px',
        },
        {
            name: "Usuario",
            selector: row => row.usuario,
            sortable: true,
        },
        {
            name: "Fecha",
            selector: row => row.dRegisterDate,
            sortable: true,
            width: '180px',
        },
    ];

    const onAddClick = () => {
        navigate('/pages/new-order', {})
    }

    const getOrders = async () => {
        const ordersWaiting = await getDataFromApi(`Order/getOrderList?filterProdStatus=true&filerActive=true`, 2, [], headers)
        if(ordersWaiting.status === 'success') {
            setData( ordersWaiting.orderList )
            // const ordersInProcess = await getDataFromApi(`Order/getOrderList?filterIdStatusOrder=3`, 3, [], headers)
            // if(ordersInProcess.status === 'success') {
            //     const ordersProcessed = await getDataFromApi(`Order/getOrderList?filterIdStatusOrder=4`, 4, [], headers)
            //     if(ordersProcessed.status === 'success') {
            //         setData( [...ordersWaiting.orderList, ...ordersInProcess.orderList, ...ordersProcessed.orderList] )
            //     }
            // }
        }
    }

    const deleteOrder = (idOrder) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Producción')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }
        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                await lavakleenApi.post(`Order/deleteOrder?idOrder=${idOrder}`, {}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                        getOrders()
                    else {
                        Swal.fire({
                            title: 'Error',
                            text: response.data.sErrors[0],
                            icon: 'error',
                        })
                    }
                } )
            }
        })
    }

    const viewOrderHistory = async (idOrder) => {
        const result = await getDataFromApi(`Order/getHistoryList?idOrder=${idOrder}`, idOrder, [], headers)
        if(result.status === 'success') {
            setDataHistory(result.historyList)
            toggleModal('')
        }
    }

    const nothingToDo = () => {
        
    }

    const printTicket = (idOrder) => {
        window.open(`/ticket-order/${idOrder}`, '_blank')
    }

    const printTicketRma = (idOrder) => {
        window.open(`/ticket-order-rma/${idOrder}`, '_blank')
    }

    const changeOrderProcess = async (idOrder, status) => {

        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Producción')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const alertConfig = {
                    title: 'Exito',
                    text: 'Estatus actualizado exitosamente.',
                    icon: 'success',
                    willClose: () => {
                        getOrders()
                    }
                }
                const result = await saveData(`Order/changeProcessStatus`, { idOrder, idOrderProcess: status, idRegisterUser: id }, headers, alertConfig)
            }
        })
    }

    const activeInactiveOrder = async (order) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Producción')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const bBlock = order.sStatus === 'Activo' ? true : false
                await lavakleenApi.post(`Order/blockUnblockOrder`, {idOrder: order.idOrder, bBlock}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                        getOrders()
                } )
            }
        })
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Producción')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Producción'))
        
        if(userPerm.bList !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })

            navigate(`/pages/dashboard`)
        }
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        const filename = 'produccion.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    useEffect(() => {
        checkPermissions();
        getOrders();
    }, [])
    

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Producción"
                                subheading="Gestion de producción para tu negocio"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                {
                                    permission !== null && permission.bCreate === true
                                    ? (
                                        <li className="nav-item">
                                            {/* <Button className="mb-2 me-2" color="primary"
                                                onClick={ onAddClick }
                                            >
                                                Agregar
                                            </Button> */}
                                        </li>
                                    )
                                    : ''
                                }

                                {
                                    permission !== null && permission.bExport === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="danger"
                                                onClick={ () => downloadCsv()}
                                            >
                                                Exportar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }
                            </ul>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                        <DataTable data={data}
                                            columns={columns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight="500px"
                                        />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <ModalComponent
                                    isOpen={modalState.isOpen}
                                    toggle={ toggleModal }
                                    backdrop={ modalState.backDrop }
                                    modalTitle={ 'Historial de cambio de status' }
                                    size="lg"
                                    initialValues={ {} }
                                    validationSchema={ {} }
                                    saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                    alertMessages={ { } }
                                    getDataFunction={ nothingToDo }
                                    dropDownsToValidate={ [ ] }
                                    showSaveButton={false}
                                >
                                    <Row>
                                        <Col md={12}>
                                            <DataTable data={dataHistory}
                                                columns={columnsHistory}
                                                pagination
                                                fixedHeader
                                                fixedHeaderScrollHeight="500px"
                                            />
                                        </Col>
                                        
                                    </Row>
                                </ModalComponent>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
})
import { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import { Form, Input } from '@availity/form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Label, TabContent, TabPane, CardHeader, Nav, NavItem, NavLink } from "reactstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import {QRCodeSVG} from 'qrcode.react';
import { lavakleenApi } from '../../api/lavakleenApi';
import { deleteDataFromApi, getCompanyById, getDataFromApi, saveData } from '../../api/providers/providers';
import { PageTitle } from '../../layout/components/PageTitle';
import { startProcessing, stopProcessing } from '../../store/auth/authSlice';
import { TabHeaderComponent } from '../components/TabHeaderComponent';
import { columnsAddress } from '../providers/clientsProviders';
import { ModalComponent } from '../components/ModalComponent';
import { columnsProductsListToAssign, Taxes } from '../providers/inventoryProviders';
import { cfdiRelation, cfdiUse, paymentsForm } from '../providers/salesProviders';

const formData = {
    idOrders: [],
    sMetodoPago: '',
    sCondicionesPago: '',
    sFormaPago: '',
    sSerie: '',
    sUsoCFDI: '',
    idPreInvoice: 0,
    dSubtotal: 0,
    dDiscount: 0,
    dTax: 0,
    dTotal: 0,
    sComments: '',
    bIsInvoiced: false,
};

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const EditInvoicePage = () => {

    const {idPreInvoice} = useParams();

    const orderDetailsRef = useRef([]);
    const orderRef = useRef(formData);
    const productsDetailsRef = useRef([]);
    const clientsRef = useRef([]);
    const servicesListRef = useRef([]);
    const billingAddressesRef = useRef([]);
    const productsOfList = useRef([]);

    const { id, isProcessing } = useSelector( state => state.auth );

    const [state, setState] = useState({
        activeTab: 'items'
    })

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
    })

    const [modalStatePayments, setModalStatePayments] = useState({
        isOpen: false,
        backDrop: true,
    })

    const invoiceDate = new Date()
    var minDate = new Date()
    minDate.setDate( invoiceDate.getDate() - 2 )

    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [paymentDate, setPaymentDate] = useState(new Date());

    const [formItemData, setFormItemData] = useState({});

    const [formPaymentData, setFormPaymentData] = useState({});
    const [closeMessage, setCloseMessage] = useState(false);

    const [columnsAddresses, setColumnsAddresses] = useState([...columnsAddress, {
        name: "Opciones",
        cell: (row) => (
            <>
                <Button onClick={ () => editRow(row.idAddress) } color="primary">
                    <i className="lnr-pencil"></i>
                </Button>
                <Button color="danger" onClick={ () => deleteRow(row.idAddress) }><i className="lnr-trash"></i></Button>
            </>
        ),
        sortable: false,
        ignoreRowClick: true,
        button: true,
    }]);

    const customStyles = {
        head: {
            style: {
                zIndex: 'auto'
            },
        },
    }

    const [columnsProductsList, setColumnsProductsList] = useState([
        {
            name: "Cantidad",
            selector: row => row.iQuantity,
            sortable: true,
            width: '150px',
        },
        {
            name: "Producto",
            selector: row => row.sProductService,
            sortable: true,
        },
        {
            name: "P.Unitario",
            selector: row => row.dPrice,
            sortable: true,
        },
        // {
        //     name: "Tasa IVA",
        //     selector: row => row.dTaxPercent,
        //     sortable: true,
        // },
        {
            name: "Importe",
            selector: row => row.dSubtotal,
            sortable: true,
        },
        {
            name: "IVA",
            selector: row => row.dTaxAmount,
            sortable: true,
        },
        {
            name: "Acciones",
            cell: (row) => (
                <>
                    {/* <Button color="primary" onClick={ () => editOrderDetail(row) } title="Editar"><i className="lnr-pencil"></i></Button>

                    <Button color="danger" onClick={ () => deleteOrderDetail(row) } title="Eliminar"><i className="lnr-trash"></i></Button> */}
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            width: '100px'
        }
    ]);

    const columnsOrders = [
        {
            name: "Folio",
            selector: row => row.idOrder,
            sortable: true,
            width: '80px',
        },
        {
            name: "Cliente",
            selector: row => row.sBusinessName,
            sortable: true,
            width: '250px',
            wrap: true,
        },
        {
            name: "Servicio",
            selector: row => row.sServiceName,
            sortable: true,
            width: '250px',
        },
        
        {
            name: "Fecha registro",
            selector: row => row.dRegisterDate,
            sortable: true,
            width: '190px',
        },
        {
            name: "Fecha entrega",
            selector: row => row.dDeliveryDate,
            sortable: true,
            width: '190px',
        },
        // {
        //     name: "Proceso",
        //     selector: row => row.sOrderProcess,
        //     cell: (row) => (
        //         <>
        //             {
        //                 (row.idOrderProcess == 1)
        //                 ? <FontAwesomeIcon icon={faCloudUpload} size="2x" color='gray'/> 
        //                 : ''
        //             }
        //             {
        //                 (row.idOrderProcess == 2)
        //                 ? <FontAwesomeIcon icon={faClock} size="2x" color='red'/> 
        //                 : ''
        //             }
        //             {
        //                 (row.idOrderProcess == 3)
        //                 ? <FontAwesomeIcon icon={faWrench} size="2x" color='black'/> 
        //                 : ''
        //             }
        //             {
        //                 (row.idOrderProcess == 4)
        //                 ? <FontAwesomeIcon icon={faDolly} size="2x" color='orange'/> 
        //                 : ''
        //             }
        //             {
        //                 (row.idOrderProcess == 5)
        //                 ? <FontAwesomeIcon icon={faTruck} size="2x" color='blue'/> 
        //                 : ''
        //             }
        //             {
        //                 (row.idOrderProcess == 6)
        //                 ? <FontAwesomeIcon icon={faCheckCircle} size="2x" color='green'/> 
        //                 : ''
        //             }
        //             {row.sOrderProcess}
        //         </>
        //     ),
        //     sortable: false,
        //     width: '250px',
        // },
        // {
        //     name: "Estatus",
        //     selector: row => row.sStatus,
        //     sortable: false,
        // },
        // {
        //     name: "Peso",
        //     selector: row => row.dTotalWeigth,
        //     sortable: false,
        // },
        // {
        //     name: "Factura",
        //     cell: (row) => (
        //         <>
        //             {
        //                 (row.idInvoice == 0)
        //                 ? <div className="mb-2 me-2 badge bg-secondary">Sin factura</div>
        //                 : row.idInvoice
        //             }
        //         </>
        //     ),
        //     sortable: false,
        //     width: '150px'
        // },
        // {
        //     name: "Total",
        //     selector: row => row.dTotal.toFixed(2),
        //     sortable: false,
        // },
        // {
        //     name: "Facturable",
        //     cell: (row) => (
        //         <>
        //             {
        //                 (row.idOrderProcess == 6)
        //                 ? <>
        //                     <div className="mb-2 me-2 badge bg-success">Facturable</div> {` `}
        //                     <Input type="checkbox" name={ `check_${row.idOrder}` } id={ `check_${row.idOrder}` } onChange={ () => addOrderForInvoice(row, `check_${row.idOrder}`) }/>
        //                 </>
        //                 : <div className="mb-2 me-2 badge bg-danger">No Facturable</div>
        //             }
        //         </>
        //     ),
        //     sortable: false,
        //     ignoreRowClick: true,
        //     width: '150px'
        // },
        {
            name: "Opciones",
            cell: (row) => (
                <>
                    <Link to={ `/pages/new-order/${row.idOrder}` } className="btn btn-primary" title="Editar">
                        <i className="lnr-pencil"></i>
                    </Link>
                    {/* <Button color="success" onClick={ () => activeInactiveOrder(row) } title="Bloquear/Desbloquear"><i className="lnr-lock"></i></Button>
                    <Button color="danger" onClick={ () => deleteOrder(row) } title="Eliminar"><i className="lnr-trash"></i></Button>
                    <Button color="warning" onClick={ () => viewOrderHistory(row.idOrder) } title="Ver historial"><i className="lnr-book"></i></Button>
                    <Button color="secondary" onClick={ () => printTicket(row.idOrder) } title="Imprimir ticket"><i className="lnr-printer"></i></Button>
                    <Button color="info" onClick={ () => printTicketRma(row.idOrder) } title="Imprimir RMA"><i className="lnr-tag"></i></Button>
                    {
                        (row.idOrderProcess == 1)
                        ? <Button color="dark" onClick={ () => changeOrderProcess(row.idOrder, 2) } title="Cambiar a EN ESPERA">
                            <FontAwesomeIcon
                                icon={faCloudUpload}
                            />
                        </Button>
                        : ''
                    }

                    {
                        (row.idOrderProcess == 2)
                        ? <Button color="dark" onClick={ () => changeOrderProcess(row.idOrder, 3) } title="Cambiar a EN PROCESO">
                            <FontAwesomeIcon
                                icon={faWrench}
                            />
                        </Button>
                        : ''
                    }

                    {
                        (row.idOrderProcess == 3)
                        ? <Button color="dark" onClick={ () => changeOrderProcess(row.idOrder, 4) } title="Cambiar a PROCESADO">
                            <FontAwesomeIcon
                                icon={faDolly}
                            />
                        </Button>
                        : ''
                    }
                    {
                        (row.idOrderProcess == 4)
                        ? <Button color="dark" onClick={ () => changeOrderProcess(row.idOrder, 5) } title="Cambiar a PARA ENTREGA">
                            <FontAwesomeIcon
                                icon={faTruck}
                            />
                        </Button>
                        : ''
                    }
                    {
                        (row.idOrderProcess == 5)
                        ? <Button color="dark" onClick={ () => changeOrderProcess(row.idOrder, 6) } title="Cambiar a FINALIZADO">
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                            />
                        </Button>
                        : ''
                    }
                    {
                        (row.idOrderProcess == 6)
                        ? <Button color="dark" onClick={ () => console.log('invoice') } title="Enviar a FACTURACIÓN">
                            <FontAwesomeIcon
                                icon={faMoneyBill}
                            />
                        </Button>
                        : ''
                    }
                    <Button color="light" onClick={ () => sendTicket(row.idOrder) } title="Enviar ticket por mail"><i className="lnr-envelope"></i></Button> */}
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            button: true,
            width: '150px'
        },
    ];

    const [columnsPaymentsList, setColumnsPaymentsList] = useState([
        {
            name: "Forma de pago",
            selector: row => row.sPaymentType,
            sortable: false,
        },
        {
            name: "Importe",
            selector: row => row.dAmount,
            sortable: false,
        },
        {
            name: "Acciones",
            cell: (row) => (
                <>
                    <Button color="danger" onClick={ () => deleteOrderPayment(row) } title="Eliminar"><i className="lnr-trash"></i></Button>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            width: '100px'
        }
    ]);

    const [columnsProducts, setColumnsProducts] = useState([...columnsProductsListToAssign, {
        name: "Acciones",
        cell: (row) => (
            <Input type="checkbox" name={ `check_${row.idProduct}` } id={ `check_${row.idProduct}` } onChange={ () => addProductToCustomer(row, `check_${row.idProduct}`) }/>
        ),
        sortable: false,
        ignoreRowClick: true,
        width: '100px'
    }]);

    const [columnsProductsListCustomer, setColumnsProductsListCustomer] = useState([
        {
            name: "ID",
            selector: row => row.idProductList,
            sortable: true,
            width: '80px',
        },
        {
            name: "Nombre lista",
            selector: row => row.sListName,
            sortable: true,
        },
    ]);

    const [columnsProductsCustomer, setColumnsProductsCustomer] = useState([...columnsProductsListToAssign]);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedOptionCurrency, setSelectedOptionCurrency] = useState({
        value: 1, label: 'MXN - Pesos Mexicanos'
    });
    const [selectedOptionCfdiRelation, setSelectedOptionCfdiRelation] = useState(null);
    const [selectedOptionRelatedCfdi, setSelectedOptionRelatedCfdi] = useState(null);
    const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
    const [selectedOptionCfdiUse, setSelectedOptionCfdiUse] = useState(null);
    const [selectedOptionBillingAddress, setSelectedOptionBillingAddress] = useState(null);
    const [selectedOptionTax, setSelectedOptionTax] = useState(null);
    const [invoiceData, setInvoiceData] = useState(formData);
    const [selectedOptionPaymentMethod, setSelectedOptionPaymentMethod] = useState(null);
    const [selectedPaymentForm, setSelectedPaymentForm] = useState(null);
    const [selectedOptionInvoiceType, setSelectedOptionInvoiceType] = useState(null);
    const [discount, setDiscount] = useState(0);

    const [optionsProducts, setOptionsProducts] = useState([]);
    const [optionsCfdiUse, setOptionsCfdiUse] = useState(cfdiUse);
    const [optionsInvoiceTypes, setOptionsInvoiceTypes] = useState([
        {value: 'I', label: 'I - Ingreso'},
        {value: 'T', label: 'T - Traslado'},
    ]);
    const [optionsCurrency, setOptionsCurrency] = useState([
        {value: 1, label: 'MXN - Pesos Mexicanos'},
        {value: 2, label: 'USD - Dólares americanos'}
    ]);
    const [optionsPaymentsForm, setOptionsPaymentsForm] = useState(paymentsForm);
    const [optionsPaymentMethod, setOptionsPaymentMethod] = useState([
        {value: 'PUE', label: 'PUE - Pago en una sola exhibición'},
        {value: 'PPD', label: 'PPD - Pago en parcialidades o diferido'},
    ])
    const [optionsCfdiRelation, setOptionsCfdiRelation] = useState(cfdiRelation);
    const [optionsRelatedCfdis, setOptionsRelatedCfdis] = useState([]);
    const [optionsProductList, setOptionsProductList] = useState([]);
    const [optionsCustomers, setOptionsCustomers] = useState([]);
    const [optionsProcess, setOptionsProcess] = useState([
        { value: 1, label: 'RECOLECCION' },
        { value: 2, label: 'EN ESPERA' },
        { value: 3, label: 'EN PROCESO' },
        { value: 4, label: 'PROCESADO' },
        { value: 5, label: 'PARA ENTREGA' },
        { value: 6, label: 'FINALIZADO' },
    ]);
    const [finisRegistering, setFinisRegistering] = useState(false);
    const [optionsBillingAddress, setOptionsBillingAddress] = useState([]);

    //validators
    const [invalidCustomer, setInvalidCustomer] = useState(true);
    const [invalidProduct, setInvalidProduct] = useState(true);
    const [invalidTax, setInvalidTax] = useState(true);
    const [invalidPaymentMethod, setInvalidPaymentMethod] = useState(true);
    const [invalidCfdiUse, setInvalidCfdiUse] = useState(true);
    const [invalidPaymentForm, setInvalidPaymentForm] = useState(true);
    const [invalidInvoiceType, setInvalidInvoiceType] = useState(true);
    const [hasBillingData, setHasBillingData] = useState(false);

    //datatables
    const [dataPaymentsOrder, setDataPaymentsOrder] = useState([]);
    const [dataProductsOrder, setDataProductsOrder] = useState([]);
    const [dataProducts, setDataProducts] = useState([]);
    const [dataProductsListCustomer, setDataProductsListCustomer] = useState([]);
    const [dataProductsCustomer, setDataProductsCustomer] = useState([]);
    const [dataOrders, setDataOrders] = useState([]);

    const [customerProductListsList, setCustomerProductListsList] = useState([]);
    const [customerProducts, setCustomerProducts] = useState([]);

    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        selectedOptionCustomer == null ? setInvalidCustomer(true) : setInvalidCustomer(false)
        selectedOptionPaymentMethod == null ? setInvalidPaymentMethod(true) : setInvalidPaymentMethod(false)
        selectedPaymentForm == null ? setInvalidPaymentForm(true) : setInvalidPaymentForm(false)

        if(idPreInvoice !== undefined) {
            const alertConfig = {
                title: 'Exito',
                text: 'Factura actualizada exitosamente.',
                icon: 'success',
                willClose: () => {
                    onFinishRegister()
                }
            };

            values.sMetodoPago = selectedOptionPaymentMethod.value
            values.sFormaPago = selectedPaymentForm.value.toString()
            values.sUsoCFDI = selectedOptionCfdiUse.value
            
            dispatch( startProcessing() )

            //save the order to get the idOrder
            await lavakleenApi.post('PreInvoice/editPreInvoice', values, {
                headers
            }).then( response => {
                if(response.data.sErrors.length === 0) {
                    setTimeout(() => {
                        Swal.fire(alertConfig)
                    }, 200);
                } else {
                    setTimeout(() => {
                        Swal.fire({
                            title: 'Error',
                            text: response.data.sErrors[0],
                            icon: 'error',
                            willClose: () => {
                            }
                        })
                    }, 200);
                }
            })

            dispatch( stopProcessing() )
        }
    }

    const reloadPage = () => {
        location.href = `/pages/new-client/${idCustomerRegister}`
    }

    const getClientInvoices = async (customer) => {
        const invoices = await getDataFromApi(`PreInvoice/getPreInvoiceList?idCustomer=${customer.value}`, customer.value, [], headers)
        if(invoices.status === 'success') {
            let optionsArray = []
            invoices.preInvoiceList.map( option => {
                if(option.invoiceData !== null) {
                    optionsArray = [ ...optionsArray,  {value: option.invoiceData.idInvoice, label: `Factura: ${option.invoiceData.idInvoice} Estatus: ${option.invoiceData.sEstatus} Total: ${option.invoiceData.dTotal} Folio fiscal: ${option.invoiceData.sFolioFiscal}`}]
                }
            } )
            setOptionsRelatedCfdis( optionsArray );
            // billingAddressesRef.current = optionsArray
        }
    }

    const addProductToCustomer = (product, checkId) => {
        let productsId = productsAssignedRef.current
        const check = document.getElementById(checkId)
        if(check.checked) {
            const el = productsId.find(element => element === product.idProduct)
            if(el === undefined)
                productsId.push(product.idProduct)
        } else {
            const el = productsId.findIndex(element => element === product.idProduct)
            productsId.splice(el, 1)
        }

        productsAssignedRef.current = productsId

        setTimeout(() => {
            productsId.map( element => {
                document.getElementById(`check_${element}`).checked = true
            } )
        }, 100);

        setCustomerProducts(productsId)
    }

    const getCustomerLists = async (customer) => {
        const lists = await getDataFromApi(`Customer/getCustomerProdList?idCustomer=${customer.value}`, customer.value, [], headers)
        if(lists.status === 'success') {
            setDataProductsListCustomer(lists.customerListsList)
            lists.customerListsList.map( async (list) => {
                const listOfProducts = await getDataFromApi(`ProductList/geProductsOfList?idListProduct=${list.idProductList}`, list.idProductList, [], headers)

                if(listOfProducts.status === 'success') {
            
                    productsOfList.current = listOfProducts.productosOfList;
                }

            } )
        }
    }

    const getCustomerProducts = async (customer) => {
        const lists = await getDataFromApi(`Customer/getCustomerProducts?idCustomer=${customer.value}`, customer.value, [], headers)
        if(lists.status === 'success') {
            setDataProductsCustomer(lists.customerProductList)
        }
    }

    const onAddListClick = async () => {
        dispatch( startProcessing() )
        const alertConfig = {
            title: 'Exito',
            text: 'Listas asignadas correctamente.',
            icon: 'success',
            willClose: () => {
                onFinishRegister()
            }
        };

        const result = await saveData('Customer/updateListsOfCustomer', { idCustomer, customerProductListsList }, headers, alertConfig)
        if(result.status === 'success') {
            await getCustomerLists()
        }

        dispatch( stopProcessing() )
    }

    const onAddProductClick = async () => {
        dispatch( startProcessing() )
        const alertConfig = {
            title: 'Exito',
            text: 'Producto-servicios asignados correctamente.',
            icon: 'success',
            willClose: () => {
                onFinishRegister()
            }
        };

        const result = await saveData('Customer/updateProductsOfCustomer', { idCustomer, customerProducts }, headers, alertConfig)
        if(result.status === 'success') {
            await getCustomerProducts()
        }

        dispatch( stopProcessing() )
    }

    const getDropdownData = async () => {
        await lavakleenApi.get('Customer/getCustomerList?filterIdStatusCustomer=1', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.customerList.map( option => {
                optionsArray.push({value: option.idCustomer, label: `${option.sCompanyName}`, discount: option.dPercent})
            } )
            setOptionsCustomers( optionsArray );
            clientsRef.current = optionsArray;
        } )

        await lavakleenApi.get('ServiceType/getServiceList', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.serviceTypeList.map( option => {
                if(option.sStatus === 'Activo')
                    optionsArray.push({value: option.idServiceType, label: option.sServiceType})
            } )
            setOptionsProductList( optionsArray );
            servicesListRef.current = optionsArray
        } )

        await lavakleenApi.get('Product/getProductList', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.productList.map( option => {
                optionsArray.push({value: option.idProduct, label: option.sProductService, sServiceType: option.sServiceType})
            } )
            productsDetailsRef.current = optionsArray
        } )

        if(idPreInvoice !== undefined) {
            const preInvoice = await getDataFromApi(`PreInvoice/findPreInvoice?idPreInvoice=${idPreInvoice}`, idPreInvoice, {}, headers)

            if(preInvoice.status === 'success') {
                setInvoiceData(preInvoice.preInvoiceData)

                setDataOrders(preInvoice.preInvoiceData.orderList)

                await getClientInvoices({ value: preInvoice.preInvoiceData.idCustomer })

                // ordersRef.current = preInvoice.preInvoiceData.idOrders

                preInvoice.preInvoiceData.idOrders.map( async order => {
                    const details = await getDataFromApi(`/OrderDetail/getOrderDetailList?idOrder=${order}`, order, {}, headers)
                    if(details.orderDetailList !== null) {
                        details.orderDetailList.map( element => {
                            orderDetailsRef.current = [...orderDetailsRef.current, {iQuantity: element.iQuantity, sProductService: element.sProductService, dSubtotal: element.dTotal + element.dDiscount, dPrice: element.dPrice, dTaxAmount: (((element.iQuantity * element.dPrice) - (element.dDiscount)) * (parseFloat(element.dTaxPercent) / 100)).toFixed(6), idOrderDetail: element.idOrderDetail, dDiscount: element.dDiscount}]
                        } )
                    }

                    setDataProductsOrder(orderDetailsRef.current)
                })
            }
        }
    }

    const getClientAddresses = async (customer) => {
        const address = await getDataFromApi(`CustomerAddresses/getCustomerAddressesList?idCustomer=${customer.value}`, customer.value, [], headers)
        if(address.status === 'success') {
            const optionsArray = []
            address.listCustomerAddresses.map( option => {
                optionsArray.push({value: option.idAddress, label: `${option.sAddressAlias} ${option.sBusinessAddress} ${option.sExtNumber} ${option.sIntNumber} ${option.sCity} ${option.sState}`})
            } )
            setOptionsBillingAddress( optionsArray );
            billingAddressesRef.current = optionsArray
            setDiscount(customer.discount)
        }
    }

    const getProductsList = async (customer) => {
        const lists = await getDataFromApi(`Customer/getCustomerProdList?idCustomer=${customer.value}`, customer.value, [], headers)
        if(lists.status === 'success') {
            const optionsArray = []
            lists.customerListsList.map( option => {
                optionsArray.push({value: option.idProductList, label: `${option.sListName}`})
            } )
            setOptionsProductList( optionsArray );
        }
    }

    const getProductsDetails = async () => {
        const productsDetails = await getDataFromApi(`Product/getProductList`, 4, [], headers)
        if(productsDetails.status === 'success') {
            setDataProducts(productsDetails.productList)
        }
    }

    const getData = async () => {
        await getDropdownData();

        // await getProductsDetails();
        

        // const order = await getDataFromApi(`Order/findOrder?idOrder=${idOrder}`, idOrder, orderData, headers);
        // if(order.status === 'success'){
        //     const customer = clientsRef.current.find( element => element.value === order.orderData.idCustomer )
        //     setSelectedOptionCustomer(customer)
        //     setDiscount(customer.discount)
        //     setInvalidCustomer(false)

        //     await getClientAddresses(customer)
        //     await getCustomerProducts(customer)
        //     await getCustomerLists(customer)

        //     const address = billingAddressesRef.current.find( element => element.value === order.orderData.idCustomerAddress )

        //     setSelectedOptionBillingAddress(address)
        //     setInvalidBillingAddress(false)

        //     // await getProductsList(customer)
        //     const serviceType = servicesListRef.current.find( element => element.value === order.orderData.idServiceType )
        //     setSelectedOptionProductList(serviceType)

        //     const chofer = chofersRef.current.find(element => element.value === order.orderData.idDriver)
        //     setSelectedOptionChofer(chofer)
        //     setInvalidChofer(false)

        //     const orderStatus = optionsProcess.find(element => element.value === order.orderData.idOrderProcess)
        //     setSelectedOptionProcess(orderStatus)

        //     setDeliveryDate(new Date(order.orderData.dDeliveryDate))

        //     await getOrderDetails(idOrder)
        //     await getOrderPayments(idOrder)
        //     await getProducts(serviceType)

        //     setOrderData(order.orderData)
        //     orderDataRef.current = order.orderData
        //     calculateTotal(orderDetailsRef.current)
        // }
    }

    const getOrderDetails = async(orderId) => {
        const details = await getDataFromApi(`/OrderDetail/getOrderDetailList?idOrder=${orderId}`, orderId, {}, headers)
        const optionsArray = []
        if(details.orderDetailList !== null) {
            details.orderDetailList.map( element => {
                optionsArray = [...optionsArray, {iQuantity: element.iQuantity, sProductService: element.sProductService, dSubtotal: element.dTotal, dPrice: element.dPrice, dTaxAmount: (((element.iQuantity * element.dPrice) - (element.dDiscount)) * (parseFloat(element.dTaxPercent) / 100)).toFixed(6), idOrderDetail: element.idOrderDetail, dDiscount: element.dDiscount}]
            } )
        }

        return optionsArray
    }

    const getOrderPayments = async(orderId) => {
        if(orderId === undefined)
            orderId = idOrder
        const details = await getDataFromApi(`Payment/getPaymentList?idOrder=${orderId}`, orderId, {}, headers)
        if(details.status === 'success') {
            
            
            setDataPaymentsOrder(details.paymentList)
        }
    }

    const getProducts = async (list) => {
        const products = productsDetailsRef.current.filter( element => element.sServiceType === list.label )
        const optionsArray = []

        dataProductsCustomer.map( productCustomer => {
            const product = products.find( element => element.value === productCustomer.idProductService )
            if(product !== undefined)
                optionsArray.push({value: product.value, label: product.label})
        } )

        productsOfList.current.map( productCustomer => {
            const product = optionsArray.find(element => element.value == productCustomer.idProduct)
            if(productCustomer.idServiceType == list.value && product == undefined)
                optionsArray.push({value: productCustomer.idProduct, label: productCustomer.sProductName})
        } )
        
        setOptionsProducts( optionsArray );
        // const products = await getDataFromApi(`ProductList/geProductsOfList?idListProduct=${list.value}`, list.value, [], headers)
        // if(products.status === 'success') {
        //     const optionsArray = []
        //     products.productosOfList.map( option => {
        //         optionsArray.push({value: option.idProduct, label: option.sProductName})
        //     } )
        //     setOptionsProducts( optionsArray );
        // }
    }

    const editOrderDetail = async(row) => {
        if(idOrder === undefined) {
            setSelectedProduct({ value: row.idProduct, label: row.sProductService })
            setInvalidProduct(false)

            const tax = Taxes.find( element => element.value === row.dTaxPercent )
            setSelectedOptionTax(tax)
            setInvalidTax(false)

            setFormItemData(row)
            toggleModal('OrderDetail/editOrderDetail')
        } else {
            const detail = await getDataFromApi(`OrderDetail/findOrderDetail?idOrderDetail=${row.idOrderDetail}`, row.idOrderDetail, {}, headers);
            // orderDetailData
            if(detail.status === 'success') {
                setSelectedProduct({ value: detail.orderDetailData.idProduct, label: detail.orderDetailData.sProduct })
                setInvalidProduct(false)

                const tax = Taxes.find( element => element.value === detail.orderDetailData.dTaxPercen.toString() )
                setSelectedOptionTax(tax)
                setInvalidTax(false)

                setFormItemData({
                    idOrder: idOrder,
                    idProduct: detail.orderDetailData.idProduct,
                    iQuantity: detail.orderDetailData.iQuantity,
                    sComments: '',
                    dPrice: detail.orderDetailData.dPrice,
                    dTaxPercen: detail.orderDetailData.dTaxPercen.toString(),
                    id: detail.orderDetailData.idOrderDetail,
                    idOrderDetail: detail.orderDetailData.idOrderDetail,
                })

                toggleModal('OrderDetail/editOrderDetail')
            }
            
        }
    }

    const deleteOrderDetail = async (row) => {

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                if(idOrder === undefined) {
                    let tempArray = dataProductsOrder
                    const el = tempArray.findIndex(element => element === row.id)
                    tempArray.splice(el, 1)
    
                    setDataProductsOrder(tempArray)
                    orderDetailsRef.current = tempArray
                    calculateTotal(orderDetailsRef.current)
                } else {
                    dispatch( startProcessing() )
                    const result = await deleteDataFromApi(`OrderDetail/deleteOrderDetail?idOrderDetail=${row.idOrderDetail}`, headers)
                    if(result.sErrors.length === 0) {
                        setTimeout(() => {
                            Swal.fire({
                                title: 'Exito',
                                text: 'Concepto eliminado correctamente. Para terminar la actualización por favor da clic en "Actualizar" en la parte inferior derecha.',
                                icon: 'success',
                                willClose: async () => {
                                    await getOrderDetails(idOrder)
                                    calculateTotal(orderDetailsRef.current)
                                }
                            })
                        }, 200)
                    } else {
                        setTimeout(() => {
                            Swal.fire({
                                title: 'Error',
                                text: response.data.sErrors[0],
                                icon: 'error',
                                willClose: async () => {
                                    
                                }
                            })
                        }, 200)
                    }
                    dispatch( stopProcessing() )
                }
            }
        })
    }

    const deleteOrderPayment = async (row) => {
        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                dispatch( startProcessing() )
                const result = await deleteDataFromApi(`Payment/removePayment?idPayment=${row.idPayment}`, headers)
                if(result.sErrors.length === 0) {
                    setTimeout(() => {
                        Swal.fire({
                            title: 'Exito',
                            text: 'Pago eliminado correctamente.',
                            icon: 'success',
                            willClose: async () => {
                                await getOrderPayments(idOrder)
                            }
                        })
                    }, 200)
                } else {
                    setTimeout(() => {
                        Swal.fire({
                            title: 'Error',
                            text: response.data.sErrors[0],
                            icon: 'error',
                            willClose: async () => {
                                
                            }
                        })
                    }, 200)
                }
                dispatch( stopProcessing() )
            }
        })
    }

    const onFinishRegister = () => {
        setFinisRegistering(true)
    }

    const toggle = (tab) => {
        if(state.activeTab !== tab) {
            setState({
                ...state,
                activeTab: tab
            })
        }
    }

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const toggleModalPayment = (saveDataToEndpoint) => {
        setModalStatePayments({
            ...modalStatePayments,
            isOpen: !modalStatePayments.isOpen,
            saveDataToEndpoint,
        })
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Facturas')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Facturas'))
        setCloseMessage(false)
        if(userPerm.bCreate !== true && idPreInvoice === undefined) {
            setCloseMessage(false)
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            navigate(`/pages/dashboard`)
            return
        }

        if(userPerm.bEdit !== true && idPreInvoice !== undefined) {
            setCloseMessage(false)
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            navigate(`/pages/dashboard`)
            return
        }
        setCloseMessage(true)
        getData();
    }

    const onAddItemClick = () => {
        setFormItemData({
            idOrder: parseFloat(idOrder),
            idProduct: null,
            iQuantity: 1,
            sComments: '',
            dPrice: '',
            dTaxPercen: '',
            id: 0,
        })
        setSelectedProduct(null)
        setInvalidProduct(true)
        setSelectedOptionTax(null)
        setInvalidTax(true)
        toggleModal('OrderDetail/addOrderDetail')
    }

    const onAddPaymentClick = () => {
        setFormPaymentData({
            idOrder: parseFloat(idOrder),
            idPaymentType: 0,
            dAmount: 0,
            dPaymentDate: new Date(),
            id: 0,
        })
        toggleModalPayment('Payment/addPayment')
    }

    const saveTemporalData = async (values) => {
        values.dTaxPercen = selectedOptionTax.value
        if(idOrder === undefined) {
            const tempArray = dataProductsOrder
            const num = orderDetailsRef.current.length
    
            setDataProductsOrder([...tempArray, {iQuantity: values.iQuantity, sProductService: values.sProductService, dPrice: values.dPrice, dTaxPercent: values.dTaxPercen, dSubtotal: (values.iQuantity * values.dPrice), dTaxAmount: ((values.iQuantity * values.dPrice) * (parseFloat(values.dTaxPercen) / 100)).toFixed(2), idOrder: 0, idProduct: values.idProduct, sComments: values.sComments, id: num + 1, dTaxPercen: values.dTaxPercen}])
            toggleModal('')
    
            orderDetailsRef.current.push({iQuantity: values.iQuantity, sProductService: values.sProductService, dPrice: values.dPrice, dTaxPercent: values.dTaxPercen, dSubtotal: (values.iQuantity * values.dPrice), dTaxAmount: (((values.iQuantity * values.dPrice) - (values.iQuantity * values.dPrice) * (discount / 100)) * (parseFloat(values.dTaxPercen) / 100)).toFixed(2), idOrder: 0, idProduct: values.idProduct, sComments: values.sComments, id: num + 1, dTaxPercen: values.dTaxPercen, dDiscount: (values.iQuantity * values.dPrice) * (discount / 100)})
    
            //calculate total
            calculateTotal(orderDetailsRef.current)
        } else {
            
            dispatch( startProcessing() )
            const alertConfig = {
                title: 'Exito',
                text: 'Concepto agregado corretamente. Para terminar la actualización por favor da clic en "Actualizar" en la parte inferior derecha.',
                icon: 'success',
                willClose: async () => {
                    await getOrderDetails(idOrder)
                    calculateTotal(orderDetailsRef.current)
                }
            };

            await saveData( 'OrderDetail/addOrderDetail', values, headers, alertConfig );

            dispatch( stopProcessing() )

            toggleModal('')
        }
        
    }

    const editTemporalData = async (values) => {
        if(idOrder === undefined) {
            const tempArray = dataProductsOrder.map( element => {
                if(element.id === values.id)
                    return {iQuantity: values.iQuantity, sProductService: values.sProductService, dPrice: values.dPrice, dTaxPercent: values.dTaxPercent, dSubtotal: (values.iQuantity * values.dPrice), dTaxAmount: ((values.iQuantity * values.dPrice) * (parseFloat(values.dTaxPercent) / 100)).toFixed(2), idOrder: 0, idProduct: values.idProduct, sComments: values.sComments, id: values.id}
    
                return element
            } )
            
            setDataProductsOrder(tempArray)
            orderDetailsRef.current = tempArray
    
            calculateTotal(orderDetailsRef.current)
        } else {
            values.dTaxPercen = selectedOptionTax.value
            dispatch( startProcessing() )
            const alertConfig = {
                title: 'Exito',
                text: 'Concepto actualizado corretamente. Para terminar la actualización por favor da clic en "Actualizar" en la parte inferior derecha.',
                icon: 'success',
                willClose: async () => {
                    await getOrderDetails(idOrder)
                    calculateTotal(orderDetailsRef.current)
                }
            };

            await saveData( 'OrderDetail/editOrderDetail', values, headers, alertConfig );
            dispatch( stopProcessing() )
        }

        toggleModal('')
    }

    const calculateTotal = (items) => {
        let subTotal = 0
        let tax = 0
        let total = 0
        let amountDiscount = 0
        items.map( item => {
            subTotal += parseFloat(item.dSubtotal)
            tax += parseFloat(item.dTaxAmount)
            amountDiscount += parseFloat(item.dDiscount)
        } )

        total = subTotal - amountDiscount + tax

        invoiceData.dSubtotal = subTotal.toFixed(2)
        invoiceData.dDiscount = amountDiscount.toFixed(2)
        invoiceData.dTax = tax.toFixed(2)
        invoiceData.dTotal = total.toFixed(2)
    }

    const setItemData = async (item) => {
        const product = await getDataFromApi(`Product/findProduct?idProduct=${item.value}`, item.value, [], headers)
        if(product.status === 'success'){
            const tax = Taxes.find( element => element.value === product.productData.sTaxId )
            
            setFormItemData( {
                idOrder: parseFloat(idOrder),
                idProduct: product.productData.idProduct,
                iQuantity: formItemData.iQuantity,
                sComments: formItemData.sComments,
                dPrice: product.productData.dMXNPrice,
                dTaxPercen: product.productData.sTaxId,
                id: 0,
                sProductService: product.productData.sProductService
            } );

            setSelectedOptionTax(tax)
            setInvalidTax(false)
        }
    }

    useEffect(() => {
        checkPermissions();
    }, [])

    useEffect(() => {
        if(finisRegistering == true) {
            navigate(`/pages/invoices`)
        }
    }, [finisRegistering])

    useEffect(() => {
        if(isProcessing == true) {

            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }

        if(closeMessage == true)
            if(!isProcessing) Swal.close()
    }, [isProcessing])

    useEffect(() => {
        if(orderDetailsRef.current.length > 0 && idPreInvoice !== undefined && invoiceData.idPreInvoice > 0) {
            const paymentForm = optionsPaymentsForm.find( element => element.value === invoiceData.sFormaPago );
            if(paymentForm !== undefined) {
                setSelectedPaymentForm(paymentForm)
                setInvalidPaymentForm(false)
            }
            
            const paymentMethod = optionsPaymentMethod.find( element => element.value === invoiceData.sMetodoPago );
            if(paymentMethod !== undefined) {
                setSelectedOptionPaymentMethod(paymentMethod)
                setInvalidPaymentMethod(false)
            }

            const cfdiUse = optionsCfdiUse.find( element => element.value === invoiceData.sUsoCFDI );
            if(cfdiUse !== undefined) {
                setSelectedOptionCfdiUse(cfdiUse)
                setInvalidCfdiUse(false)
            }

            const customer = optionsCustomers.find( element => element.value === invoiceData.idCustomer );
            if(customer !== undefined) {
                setSelectedOptionCustomer(customer)
                setInvalidCustomer(false)
            }

            calculateTotal(orderDetailsRef.current)
        }
    }, [orderDetailsRef.current])

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Editar factura"
                                subheading="Pantalla para creación/edición de facturas"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            {
                                invoiceData.idPreInvoice > 0
                                ? <Form
                                initialValues={invoiceData}
                                onSubmit={ (values) => onSubmit(values) }
                            >
                                <Row>
                                    <Col md="12">
                                        <Card className="main-card mb-3">
                                            <CardBody>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Cliente</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCustomer}
                                                                options={optionsCustomers}
                                                                isClearable
                                                                name="idCustomerType"
                                                                key={ selectedOptionCustomer }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidCustomer ? 'is-invalid form-control' : '' }
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidCustomer(true) : setInvalidCustomer(false)
                                                                    setSelectedOptionCustomer(option)
                                                                    if(option){
                                                                        // getProducts(option)
                                                                        // getClientAddresses(option)
                                                                        getCustomerProducts(option)
                                                                        getCustomerLists(option)
                                                                    }
                                                                }}
                                                                isDisabled={ idPreInvoice !== undefined ? true : false }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Fecha factura</Label>
                                                            <DatePicker
                                                                className="form-control"
                                                                name="dDeliveryDate"
                                                                dateFormat="yyyy-MM-dd"
                                                                selected={ deliveryDate }
                                                                onChange={(date) => setDeliveryDate(date)}
                                                                minDate={ minDate }
                                                                maxDate={ invoiceDate }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup className="mb-3">
                                                            <Label for="idCustomerType">Forma de pago</Label>
                                                            <Select
                                                                defaultValue={selectedPaymentForm}
                                                                options={optionsPaymentsForm}
                                                                isClearable
                                                                name="idProduct"
                                                                key={ selectedPaymentForm }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidPaymentForm ? 'is-invalid form-control' : '' }
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidPaymentForm(true) : setInvalidPaymentForm(false)
                                                                    setSelectedPaymentForm(option)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Moneda</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCurrency}
                                                                options={optionsCurrency}
                                                                name="idCustomerType"
                                                                key={ selectedOptionCurrency }
                                                                placeholder="Selecciona una opción"
                                                                isDisabled={ idPreInvoice !== undefined ? true : false }
                                                                
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Método de pago</Label>
                                                            <Select
                                                                defaultValue={selectedOptionPaymentMethod}
                                                                options={optionsPaymentMethod}
                                                                isClearable
                                                                name="idCustomerType"
                                                                key={ selectedOptionPaymentMethod }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidPaymentMethod ? 'is-invalid form-control' : '' }
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidPaymentMethod(true) : setInvalidPaymentMethod(false)
                                                                    setSelectedOptionPaymentMethod(option)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup className="mb-3">
                                                            <Label for="idCustomerType">Uso de CFDI</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCfdiUse}
                                                                options={optionsCfdiUse}
                                                                isClearable
                                                                name="idCustomerType"
                                                                key={ selectedOptionCfdiUse }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidCfdiUse ? 'is-invalid form-control' : '' }
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidCfdiUse(true) : setInvalidCfdiUse(false)
                                                                    setSelectedOptionCfdiUse(option)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="2">
                                                        <FormGroup className="mb-5">
                                                            <Label for="sSerie">Serie</Label>
                                                            <Input type="text" name="sSerie" placeholder=""/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="5">
                                                        <FormGroup>
                                                            <Label for="sBuinessName">Cfdis Relacionados</Label>
                                                            <Select
                                                                defaultValue={selectedOptionRelatedCfdi}
                                                                onChange={ (option) => {
                                                                    setSelectedOptionRelatedCfdi(option)
                                                                }}
                                                                options={optionsRelatedCfdis}
                                                                name="idState"
                                                                isMulti
                                                                key={ selectedOptionRelatedCfdi }
                                                                placeholder="Selecciona una opción"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="5">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Tipo de relación</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCfdiRelation}
                                                                options={optionsCfdiRelation}
                                                                isClearable
                                                                name="idProduct"
                                                                key={ selectedOptionCfdiRelation }
                                                                placeholder="Selecciona una opción"
                                                                onChange={ (option) => {
                                                                    setSelectedOptionCfdiRelation(option)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <Card className="main-card mb-3 mt-3">
                                                            <CardHeader>
                                                                <Nav justified>
                                                                    <TabHeaderComponent id="items" label="Conceptos de factura y pedidos" state={state} toggle={ toggle }/>
                                                                    
                                                                </Nav>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <TabContent activeTab={ state.activeTab }>
                                                                    <TabPane tabId="items">
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <DataTable data={dataProductsOrder}
                                                                                    columns={columnsProductsList}
                                                                                    pagination
                                                                                    fixedHeader
                                                                                    fixedHeaderScrollHeight="500px"
                                                                                    customStyles={ customStyles }
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <DataTable data={dataOrders}
                                                                                    columns={columnsOrders}
                                                                                    pagination
                                                                                    fixedHeader
                                                                                    fixedHeaderScrollHeight="500px"
                                                                                    customStyles={ customStyles }
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </TabPane>
                                                                </TabContent>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="8">
                                                        <FormGroup className="mb-3">
                                                            <Label for="sCondicionesPago">Condiciones de pago</Label>
                                                            <Input type="textarea" name="sCondicionesPago" />
                                                        </FormGroup>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sComments">Comentarios</Label>
                                                            <Input type="textarea" name="sComments" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup className="mb-3">
                                                            <Label for="dSubtotal">Subtotal</Label>
                                                            <Input type="number" name="dSubtotal" readOnly/>
                                                        </FormGroup>
                                                        <FormGroup className="mb-3">
                                                            <Label for="dDiscount">Descuento</Label>
                                                            <Input type="number" name="dDiscount" readOnly/>
                                                        </FormGroup>
                                                        <FormGroup className="mb-3">
                                                            <Label for="dTax">I.V.A</Label>
                                                            <Input type="number" name="dTax" readOnly/>
                                                        </FormGroup>
                                                        <FormGroup className="mb-3">
                                                            <Label for="dTotal">Total</Label>
                                                            <Input type="number" name="dTotal" readOnly/>
                                                        </FormGroup>
                                                        <FormGroup className="mb-3" style={ { 'textAlign': 'end' } }>
                                                            <Link 
                                                                className="mt-2 btn btn-danger"
                                                                to="/pages/invoices"
                                                            >
                                                                Cancelar
                                                            </Link>{ "  " }
                                                            {
                                                                invoiceData.bIsInvoiced == false
                                                                ?
                                                                <Button color="primary" className="mt-2 mr-2" type="submit">
                                                                    {
                                                                        (idPreInvoice === undefined)
                                                                        ? 'Guardar'
                                                                        : 'Actualizar'
                                                                    }
                                                                </Button>
                                                                : " "
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form> 
                                : ' '
                            }
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

import { Form, Input } from '@availity/form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Label } from "reactstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import { getDataFromApi, saveData } from '../../api/providers/providers';
import { PageTitle } from '../../layout/components/PageTitle';
import { startProcessing, stopProcessing } from '../../store/auth/authSlice';

const formData = {
    sBranchOfficeName: '',
    sAddress: '',
    sPhone: ''
};

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const BranchOfficePage = () => {

    const {idBranchOffice} = useParams();
    const [branchOfficeData, setBranchOfficeData] = useState(formData);
    const { id, isProcessing } = useSelector( state => state.auth )
    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)

    const [finisRegistering, setFinisRegistering] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        values.sPhone = values.sPhone.toString()

        dispatch( startProcessing() )

        if(idBranchOffice === undefined) {
            const alertConfig = {
                title: 'Exito',
                text: 'Sucursal creada exitosamente!!',
                icon: 'success',
                willClose: () => {
                    onFinishRegister()
                }
            };

            await saveData( 'BranchOffice/addBranchOffice', values, headers, alertConfig );
        }

        if(idBranchOffice !== undefined) {
            const alertConfig = {
                title: 'Exito',
                text: 'Sucursal actualizada exitosamente!!',
                icon: 'success',
                willClose: () => {
                    onFinishRegister()
                }
            };

            await saveData( 'BranchOffice/editBranchOffice', values, headers, alertConfig );
        }

        dispatch( stopProcessing() )
    }

    const getData = async () => {

        const company = await getDataFromApi(`BranchOffice/findBranchOffice?idBranchOffice=${idBranchOffice}`, idBranchOffice, formData, headers);
        if(company.status === 'success'){
            setBranchOfficeData( company.branchData );
        }
    }

    const onFinishRegister = () => {
        setFinisRegistering(true)
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Sucursales')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Sucursales'))
        
        if(userPerm.bCreate !== true && idBranchOffice === undefined) {
            navigate(`/pages/branch-offices`)
        }

        if(userPerm.bEdit !== true && idBranchOffice !== undefined) {
            navigate(`/pages/branch-offices`)
        }
    }

    useEffect(() => {
        checkPermissions();
        getData();
    }, [])

    useEffect(() => {
        if(isProcessing == true) {

            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }

        if(!isProcessing) Swal.close()
    }, [isProcessing])

    useEffect(() => {
        if(finisRegistering == true) {

            navigate('/pages/branch-offices')
        }
    }, [finisRegistering])
    
    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Crear sucursal"
                                subheading="Pantalla para creación de sucursales nuevas"
                                icon="lnr-apartment icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                
                            </ul>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                            <CardTitle>Datos de sucursal</CardTitle>
                                            <Form
                                                initialValues={branchOfficeData}
                                                onSubmit={ (values) => onSubmit(values) }
                                                validationSchema={
                                                    yup.object({
                                                        sBranchOfficeName: yup.string().required(),
                                                        sAddress: yup.string().required(),
                                                        sPhone: yup.string().min(10).max(12)
                                                    })
                                                }
                                                enableReinitialize
                                            >
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sBranchOfficeName">Nombre</Label>
                                                            <Input type="text" name="sBranchOfficeName" placeholder="Ingresa el nombre de la sucursal"/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sAddress">Dirección</Label>
                                                            <Input type="text" name="sAddress" placeholder="Ingresa la dirección de la sucursal"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup className="mb-3">
                                                            <Label for="sPhone">Teléfono</Label>
                                                            <Input type="number" name="sPhone" placeholder="Ingresa el teléfono de la sucursal"/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Button color="primary" className="mt-2 mr-2" type="submit">
                                                    {
                                                        (idBranchOffice === undefined)
                                                        ? 'Guardar'
                                                        : 'Actualizar'
                                                    }
                                                </Button>{ "  " }
                                                <Link 
                                                    className="mt-2 btn btn-danger"
                                                    to="/pages/branch-offices"
                                                >
                                                    Cancelar
                                                </Link>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css'
import { PageTitle } from '../../layout/components/PageTitle';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { lavakleenApi } from '../../api/lavakleenApi';
import { convertArrayOfObjectsToCSV, filterDataFromApi, saveData } from '../../api/providers/providers';
import { ModalComponent } from '../components/ModalComponent';
import {useDropzone} from 'react-dropzone';
import { useSelector } from 'react-redux';

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany'),
};

export const ProductsPage = forwardRef( (props, ref) => {
    useImperativeHandle( ref, () => ({
        async filterData(values){
            const result = await filterDataFromApi('MasterProduct/getMasterProductList', values, headers);
            setData(result.productList)
        },

        async restore() {
            await getProducts()
        }
    }) )
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)
    const { id, isProcessing } = useSelector( state => state.auth )

    const [modalStateProducts, setModalStateProducts] = useState({
        isOpen: false,
        backDrop: true,
    })

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        saveFiles(acceptedFiles);
    }, [])

    const saveFiles = async (files) => {
        const formData = new FormData() // pass data as a form
        formData.append(`file`, files[0])

        const alertConfig = {
            title: 'Exito',
            text: 'Archivo procesado exitosamente.',
            icon: 'success',
            willClose: () => {
                getProducts()
            }
        };

        const result = await saveData(`Product/ProductsMasiveLoad?idRegisterUser=${id}`, formData, headers, alertConfig)
    }

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: {
          'application/vnd.ms-excel': [],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
        }, onDrop});

    const columns = [
        {
            name: "ID",
            selector: row => row.idMasterProduct,
            sortable: true,
            width: '80px',
        },
        {
            name: "Nombre producto",
            selector: row => row.sMasterProduct,
            sortable: true,
        },
        {
            name: "Estatus",
            selector: row => row.sStatus,
            sortable: false,
            width: '100px',
        },
        {
            name: "Fecha alta",
            selector: row => row.dRegisterDate,
            sortable: true,
            width: '180px',
        },
        {
            name: "Usuario alta",
            selector: row => row.sRegisterUser,
            sortable: true,
            width: '180px',
        },
        {
            name: "Opciones",
            cell: (row) => (
                <>
                    <Button onClick={ () => editProduct(row) } color="primary">
                        <i className="lnr-pencil"></i>
                    </Button>{ "  "}
                    <Button color="success" onClick={ () => activeInactive(row) } title="Bloquear/Desbloquear"><i className="lnr-lock"></i></Button>
                    <Button color="danger" onClick={ () => deleteRow(row.idMasterProduct) }><i className="lnr-trash"></i></Button>
                </>
            ),
            sortable: false,
            ignoreRowClick: true,
            button: true,
            width: '150px'
        },
    ];

    const onAddClick = () => {
        navigate('/pages/new-product', {})
    }

    const toggleModalProducts = (saveDataToEndpoint) => {
        setModalStateProducts({
            ...modalStateProducts,
            isOpen: !modalStateProducts.isOpen,
            saveDataToEndpoint,
        })
    }

    const importProducts = () => {
        toggleModalProducts('')
    }

    const nothingToDo = () => {

    }

    const getProducts = async () => {
        let activeProducts = []
        let allProducts = []
        await lavakleenApi.get('MasterProduct/getMasterProductList?bActive=true', {
            headers
        }).then( response => {
            activeProducts = response.data.productList
        } )

        await lavakleenApi.get('MasterProduct/getMasterProductList?bActive=false', {
            headers
        }).then( response => {
            allProducts = activeProducts.concat(response.data.productList)
            allProducts.sort((a, b) => a.idMasterProduct - b.idMasterProduct)
            setData( allProducts );
        } )
        
    }

    const editProduct = (row) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Productos')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        if(row.sStatus == 'Inactivo') {
            Swal.fire({
                title: 'Error',
                text: 'El producto está inactivo, debes activarlo para poder editar.',
                icon: 'error',
            })
            return
        }

        navigate(`/pages/new-product/${row.idMasterProduct}`)
    }

    const deleteRow = (idMasterProduct) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Productos')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }
        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                await lavakleenApi.post(`MasterProduct/deleteMasterProduct?idMasterProduct=${idMasterProduct}`, {}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                        getProducts();
                } )
            }
        })
    }

    const activeInactive = (masterProduct) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Productos')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const bActive = masterProduct.sStatus === 'Activo' ? false : true
                await lavakleenApi.post(`MasterProduct/activateDeactivateMasterProduct`, {idMasterProduct: masterProduct.idMasterProduct, bActive}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0)
                        getProducts()
                } )
            }
        })
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Productos')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Productos'))
        
        if(userPerm.bList !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })

            navigate(`/pages/dashboard`)
            return
        }

        getProducts();
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        const filename = 'productos.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    useEffect(() => {
        checkPermissions();
    }, [])
    

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Productos"
                                subheading="Gestion de productos para tu negocio"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                {
                                    permission !== null && permission.bCreate === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="primary"
                                                onClick={ onAddClick }
                                            >
                                                Agregar
                                            </Button>
                                            <Button className="mb-2 me-2" color="success"
                                                onClick={ importProducts }
                                            >
                                                Importar de Excel
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }

                                {
                                    permission !== null && permission.bExport === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="danger"
                                                onClick={ () => downloadCsv()}
                                            >
                                                Exportar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }
                            </ul>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                        <DataTable data={data}
                                            columns={columns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight="500px"
                                        />
                                        </CardBody>
                                    </Card>
                                    <ModalComponent
                                        isOpen={modalStateProducts.isOpen}
                                        toggle={ toggleModalProducts }
                                        backdrop={ modalStateProducts.backDrop }
                                        modalTitle="Importar clientes"
                                        size="md"
                                        initialValues={ {} }
                                        validationSchema={ {} }
                                        saveDataToEndpoint={ modalStateProducts.saveDataToEndpoint }
                                        alertMessages={ { } }
                                        getDataFunction={ nothingToDo }
                                        dropDownsToValidate={ [ ] }
                                        showSaveButton={false}
                                    >
                                        <Row>
                                            <Col md={12}>
                                            <div {...getRootProps({className: 'dropzone'})}>
                                                <input {...getInputProps()} />
                                                <p>Da clic aquí para subir tus productos</p>
                                            </div>
                                            </Col>
                                        </Row>
                                    </ModalComponent>
                                </Col>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
})

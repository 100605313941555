import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { PageTitle } from '../../layout/components/PageTitle';
import { Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { lavakleenApi } from '../../api/lavakleenApi';
import { getCompanyById, baseUrl } from '../../api/providers/providers';

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const Dashboard = () => {
    const [data, setData] = useState({})
    const [showDashboard, setShowDashboard] = useState(false)
    const [companyData, setCompanyData] = useState({})
    const getData = async () => {
        await lavakleenApi.get('Report/getReportOperations', {
            headers
        }).then( response => {
            console.log(response.data.operacion)
            setData( response.data.operacion );
            setShowDashboard(true)
        } )

        const company = await getCompanyById(localStorage.getItem('idCompany'), {
            'Authorization' : 'Bearer ' + localStorage.getItem('sToken')
        });
        if(company.status === 'success'){
            setCompanyData({...company.companyData, sCertificateNumber: '', sPassword: '', certificateFile: '', keyFile: ''});
        }
    }
    useEffect(() => {
        getData();
    }, [])
    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Dashboard Operativo - Acquatronix CRM"
                                subheading=""
                                icon="pe-7s-graph icon-gradient bg-ripe-malin"
                            />
                            {
                                showDashboard == true
                                ? (
                                    <>
                                        <Row>
                                            <Col md="2">
                                            {
                                                companyData.sImagePath !== ''
                                                ? (
                                                    <img src={ `${baseUrl}/${companyData.sImagePath}` } width="200"></img>
                                                )
                                                : ''
                                            }
                                            </Col>
                                            <Col md="5">
                                                <Card className="mb-3">
                                                    <CardHeader className="card-header-tab card-header-tab-animation">
                                                        <div className="card-header-title">
                                                            {/* <i className="header-icon lnr-apartment icon-gradient bg-love-kiss"> {" "} </i> */}
                                                            Pedidos pendientes de procesar
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="scroll-area-sm">
                                                            <ListGroup className="rm-list-borders rm-list-borders-scroll" flush>
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    { data.pedidosPendientesProcesar.recoleccion.sOrderProcess }
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { data.pedidosPendientesProcesar.recoleccion.iNumberOrders }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    { data.pedidosPendientesProcesar.espera.sOrderProcess }
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { data.pedidosPendientesProcesar.espera.iNumberOrders }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    { data.pedidosPendientesProcesar.proceso.sOrderProcess }
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { data.pedidosPendientesProcesar.proceso.iNumberOrders }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                            </ListGroup>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="5">
                                                <Card className="mb-3">
                                                    <CardHeader className="card-header-tab card-header-tab-animation">
                                                        <div className="card-header-title">
                                                            {/* <i className="header-icon lnr-apartment icon-gradient bg-love-kiss"> {" "} </i> */}
                                                            Tickets de soporte
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="scroll-area-sm">
                                                            <ListGroup className="rm-list-borders rm-list-borders-scroll" flush>
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    { data.tickets.abiertos.sOrderProcess }
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { data.tickets.abiertos.iNumberOrders }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    { data.tickets.proceso.sOrderProcess }
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { data.tickets.proceso.iNumberOrders }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    { data.tickets.cerrados.sOrderProcess }
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { data.tickets.cerrados.iNumberOrders }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                            </ListGroup>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="2">
                                            
                                            </Col>
                                            <Col md="5">
                                                <Card className="mb-3">
                                                    <CardHeader className="card-header-tab card-header-tab-animation">
                                                        <div className="card-header-title">
                                                            {/* <i className="header-icon lnr-apartment icon-gradient bg-love-kiss"> {" "} </i> */}
                                                            Pedidos procesados hoy
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="scroll-area-sm">
                                                            <ListGroup className="rm-list-borders rm-list-borders-scroll" flush>
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    { data.pedidosProcesadosHoy.procesado.sOrderProcess }
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { data.pedidosProcesadosHoy.procesado.iNumberOrders }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    { data.pedidosProcesadosHoy.entrega.sOrderProcess }
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { data.pedidosProcesadosHoy.entrega.iNumberOrders }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                                <ListGroupItem>
                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left">
                                                                                <div className="widget-heading">
                                                                                    { data.pedidosProcesadosHoy.finalizado.sOrderProcess }
                                                                                </div>
                                                                            </div>
                                                                            <div className="widget-content-right">
                                                                                <div className="font-size-xlg text-muted">
                                                                                    { data.pedidosProcesadosHoy.finalizado.iNumberOrders }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>
                                                            </ListGroup>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col md="5">

                                            </Col>
                                        </Row>
                                    </>
                                )
                                : ''
                            }
                            
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

import { createSlice } from '@reduxjs/toolkit';
import sideBar6 from '../../assets/utils/images/sidebar/city1.jpg';

export const SET_ENABLE_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE';

export const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU';
export const SET_ENABLE_MOBILE_MENU_SMALL = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL';

export const SET_ENABLE_FIXED_HEADER = 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER';
export const SET_ENABLE_HEADER_SHADOW = 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW';
export const SET_ENABLE_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW';
export const SET_ENABLE_FIXED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR';
export const SET_ENABLE_CLOSED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR';
export const SET_ENABLE_FIXED_FOOTER = 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER';

export const SET_ENABLE_PAGETITLE_ICON = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON';
export const SET_ENABLE_PAGETITLE_SUBHEADING = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING';
export const SET_ENABLE_PAGE_TABS_ALT = 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT';

export const SET_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE';
export const SET_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_BACKGROUND_COLOR';
export const SET_COLOR_SCHEME = 'THEME_OPTIONS/SET_COLOR_SCHEME';
export const SET_BACKGROUND_IMAGE_OPACITY = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY';

export const SET_HEADER_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR';

export const themeOptionsSlice = createSlice({
    name: 'themeOptions',
    initialState: {
        backgroundColor: '',
        headerBackgroundColor: '',
        enableMobileMenuSmall: '',
        enableBackgroundImage: false,
        enableClosedSidebar: false,
        enableFixedHeader: true,
        enableHeaderShadow: true,
        enableSidebarShadow: true,
        enableFixedFooter: true,
        enableFixedSidebar: true,
        colorScheme: 'white',
        backgroundImage: sideBar6,
        backgroundImageOpacity: 'opacity-06',
        enablePageTitleIcon: true,
        enablePageTitleSubheading: true,
        enablePageTabsAlt: true,
    },
    reducers: {
        setEnableClosedSidebar: (state, {payload}) => {
            state.enableClosedSidebar = payload;
        }
    }
});


// Action creators are generated for each case reducer function
export const { setEnableClosedSidebar } = themeOptionsSlice.actions;
export const columnsRoles = [
    {
        name: "ID",
        selector: row => row.idRol,
        sortable: true,
    },
    {
        name: "Nombre",
        id: "lastName",
        selector: row => row.sRolName,
        sortable: true,
    },
]

export const columnsTaxRegimes = [
    {
        name: "ID",
        selector: row => row.idTaxRegime,
        sortable: true,
    },
    {
        name: "Clave",
        selector: row => row.sClaveSAT,
        sortable: true,
    },
    {
        name: "Nombre",
        id: "sTaxRegime",
        selector: row => row.sTaxRegime,
        sortable: true,
    },
]

export const columnsMeasurementUnits = [
    {
        name: "ID",
        selector: row => row.idMeasurementUnit,
        sortable: true,
    },
    {
        name: "Clave",
        selector: row => row.sClaveSAT,
        sortable: true,
    },
    {
        name: "Nombre",
        id: "sMeasurementUnit",
        selector: row => row.sMeasurementUnit,
        sortable: true,
    },
]

export const columnsClientStatus = [
    {
        name: "ID",
        selector: row => row.idCustomerStatus,
        sortable: true,
    },
    {
        name: "Nombre",
        id: "sCustomerStatus",
        selector: row => row.sCustomerStatus,
        sortable: true,
    },
]

export const columnsClientTypes = [
    {
        name: "ID",
        selector: row => row.idCustomerType,
        sortable: true,
    },
    {
        name: "Nombre",
        id: "sCustomerType",
        selector: row => row.sCustomerType,
        sortable: true,
    },
]

export const columnsSatProducts = [
    {
        name: "ID",
        selector: row => row.idProductService,
        sortable: true,
    },
    {
        name: "Clave",
        selector: row => row.sClaveSAT,
        sortable: true,
    },
    {
        name: "Nombre",
        id: "sProductService",
        selector: row => row.sProductService,
        sortable: true,
    },
]

export const columnsCustomerMeasurementUnits = [
    {
        name: "ID",
        selector: row => row.idCustomerMeasurement,
        sortable: true,
    },
    {
        name: "Clave",
        selector: row => row.sClave,
        sortable: true,
    },
    {
        name: "Nombre",
        id: "sProductService",
        selector: row => row.sCustomerMeasurement,
        sortable: true,
    },
]

export const columnsModules = [
    {
        name: "ID",
        selector: row => row.idModule,
        sortable: true,
    },
    {
        name: "Categoría",
        selector: row => row.sCategory,
        sortable: true,
    },
    {
        name: "Función",
        selector: row => row.sModuleDetail,
        sortable: true,
    },
]

export const columnsColors = [
    {
        name: "ID",
        selector: row => row.idColor,
        sortable: true,
    },
    {
        name: "Nombre",
        id: "sCustomerType",
        selector: row => row.sColorName,
        sortable: true,
    },
]
import { useState } from "react";
import { Slider } from "react-burgers";
import { useSelector, useDispatch } from "react-redux";
import { setEnableClosedSidebar } from "../../../store/theme/themeOptionsSlice";

export const HeaderLogo = () => {
    const { enableClosedSidebar } = useSelector( state => state.themeOptions );
    const dispatch = useDispatch();

    const [componentState, setComponentState] = useState({
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false,
    });

    const toggleEnableClosedSidebar = () => {
        dispatch( setEnableClosedSidebar(!enableClosedSidebar) );
    }

    return (
        <>
            <div className="app-header__logo">
                <div className="logo-src" />
                <div className="header__pane ms-auto">
                    <div
                        onClick={toggleEnableClosedSidebar}
                    >
                    <Slider
                        width={26}
                        lineHeight={2}
                        lineSpacing={5}
                        color="#6c757d"
                        active={componentState.active}
                        onClick={() => setComponentState({ active: !componentState.active })}
                    />
                    </div>
                </div>
            </div>
            {/* <AppMobileMenu /> */}
        </>
    )
}

import { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import { Form, Input } from '@availity/form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Label, TabContent, TabPane, CardHeader, Nav, NavItem, NavLink } from "reactstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import {QRCodeSVG} from 'qrcode.react';
import { lavakleenApi } from '../../api/lavakleenApi';
import { deleteDataFromApi, getCompanyById, getDataFromApi, saveData } from '../../api/providers/providers';
import { PageTitle } from '../../layout/components/PageTitle';
import { startProcessing, stopProcessing } from '../../store/auth/authSlice';
import { TabHeaderComponent } from '../components/TabHeaderComponent';
import { columnsAddress } from '../providers/clientsProviders';
import { ModalComponent } from '../components/ModalComponent';
import { columnsProductsListToAssign, Taxes } from '../providers/inventoryProviders';

const formData = {
    idCustomer: 0,
    idServiceType: 0,
    idCustomerAddress: 0,
    idCurrency: 0,
    idRegisterUser: 0,
    dDeliveryDate: '',
    sNotificationMail: '',
    sComments: '',
    dSubtotal: 0,
    dDiscount: 0,
    dTax: 0,
    dTotal: 0,
    dTotalWeight: 0,
};

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const EditProductionPage = () => {

    const {idOrder} = useParams();

    const orderDetailsRef = useRef([]);
    const productsDetailsRef = useRef([]);
    const clientsRef = useRef([]);
    const servicesListRef = useRef([]);
    const billingAddressesRef = useRef([]);
    const orderDataRef = useRef(null);
    const chofersRef = useRef([]);

    const { id, isProcessing, role } = useSelector( state => state.auth );

    const [state, setState] = useState({
        activeTab: 'items'
    })

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
    })

    const [deliveryDate, setDeliveryDate] = useState(new Date());

    const [formItemData, setFormItemData] = useState({
        idOrder: parseFloat(idOrder),
        idProduct: null,
        iQuantity: 1,
        sComments: '',
        dPrice: '',
        dTaxPercen: '',
        id: 0,
    });

    const [columnsAddresses, setColumnsAddresses] = useState([...columnsAddress, {
        name: "Opciones",
        cell: (row) => (
            <>
                <Button onClick={ () => editRow(row.idAddress) } color="primary">
                    <i className="lnr-pencil"></i>
                </Button>
                <Button color="danger" onClick={ () => deleteRow(row.idAddress) }><i className="lnr-trash"></i></Button>
            </>
        ),
        sortable: false,
        ignoreRowClick: true,
        button: true,
    }]);

    const [columnsProductsList, setColumnsProductsList] = useState([
        {
            name: "Cantidad",
            selector: row => row.iQuantity,
            sortable: true,
            width: '150px',
        },
        {
            name: "Producto",
            selector: row => row.sProductService,
            sortable: true,
        },
        {
            name: "Comentarios",
            selector: row => row.sComments,
            sortable: true,
        },
        {
            name: "Marcar terminado",
            cell: (row) => (
                <Input type="checkbox" name={ `check_item_${row.idOrderDetail}` } id={ `check_item_${row.idOrderDetail}` }/>
            ),
            sortable: false,
            ignoreRowClick: true,
            width: '150px'
        }
    ]);

    const [columnsProducts, setColumnsProducts] = useState([...columnsProductsListToAssign, {
        name: "Acciones",
        cell: (row) => (
            <Input type="checkbox" name={ `check_${row.idProduct}` } id={ `check_${row.idProduct}` } onChange={ () => addProductToCustomer(row, `check_${row.idProduct}`) }/>
        ),
        sortable: false,
        ignoreRowClick: true,
        width: '100px'
    }]);

    const [columnsProductsListCustomer, setColumnsProductsListCustomer] = useState([
        {
            name: "ID",
            selector: row => row.idProductList,
            sortable: true,
            width: '80px',
        },
        {
            name: "Nombre lista",
            selector: row => row.sListName,
            sortable: true,
        },
    ]);

    const [columnsProductsCustomer, setColumnsProductsCustomer] = useState([...columnsProductsListToAssign]);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedOptionCurrency, setSelectedOptionCurrency] = useState({
        value: 1, label: 'MXN - Pesos Mexicanos'
    });
    const [selectedOptionProductList, setSelectedOptionProductList] = useState(null);
    const [selectedOptionProcess, setSelectedOptionProcess] = useState(null);
    const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
    const [selectedOptionChofer, setSelectedOptionChofer] = useState(null);
    const [selectedOptionBillingAddress, setSelectedOptionBillingAddress] = useState(null);
    const [selectedOptionTax, setSelectedOptionTax] = useState(null);
    const [orderData, setOrderData] = useState(formData);
    const [idOrderRegister, setIdOrderRegister] = useState(idOrder);

    const [optionsProducts, setOptionsProducts] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [optionsCurrency, setOptionsCurrency] = useState([
        {value: 1, label: 'MXN - Pesos Mexicanos'},
        {value: 2, label: 'USD - Dólares americanos'}
    ]);
    const [optionsTaxes, setOptionsTaxes] = useState(Taxes);
    const [optionsProductList, setOptionsProductList] = useState([]);
    const [optionsCustomers, setOptionsCustomers] = useState([]);
    const [optionsProcess, setOptionsProcess] = useState([]);
    const [finisRegistering, setFinisRegistering] = useState(false);
    const [optionsChofer, setOptionsChofer] = useState([]);

    //validators
    const [invalidCustomer, setInvalidCustomer] = useState(true);
    const [invalidProduct, setInvalidProduct] = useState(true);
    const [invalidTax, setInvalidTax] = useState(true);
    const [invalidCity, setInvalidCity] = useState(true);
    const [invalidBillingAddress, setInvalidBillingAddress] = useState(true);
    const [invalidTaxRegime, setInvalidTaxRegime] = useState(true);
    const [hasBillingData, setHasBillingData] = useState(false);

    //datatables
    const [dataAddress, setDataAddress] = useState([]);
    const [dataProductsOrder, setDataProductsOrder] = useState([]);
    const [dataProducts, setDataProducts] = useState([]);
    const [dataProductsListCustomer, setDataProductsListCustomer] = useState([]);
    const [dataProductsCustomer, setDataProductsCustomer] = useState([]);

    const [customerProductListsList, setCustomerProductListsList] = useState([]);
    const [customerProducts, setCustomerProducts] = useState([]);

    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        selectedOptionCustomer == null ? setInvalidCustomer(true) : setInvalidCustomer(false)
        selectedOptionBillingAddress == null ? setInvalidBillingAddress(true) : setInvalidBillingAddress(false)
        // const alertConfig = {
        //     title: 'Exito',
        //     text: 'Pedido actualizado exitosamente.',
        //     icon: 'success',
        //     willClose: () => {
        //         onFinishRegister()
        //     }
        // };

        // values.idCustomer = selectedOptionCustomer.value
        // values.idCurrency = selectedOptionCurrency.value
        // values.idCustomerAddress = selectedOptionBillingAddress.value
        // values.idRegisterUser = id
        // values.dDeliveryDate = deliveryDate
        // values.idServiceType = selectedOptionProductList.value
        // values.idOrder = idOrder
        // values.dSubtotal = orderData.dSubtotal
        // values.dTax = orderData.dTax
        // values.dTotal = orderData.dTotal
        
        // dispatch( startProcessing() )

        // //save the order to get the idOrder
        // await lavakleenApi.post('Order/editOrder', values, {
        //     headers
        // }).then( response => {
        //     if(response.data.sErrors.length === 0) {
        //         setTimeout(() => {
        //             Swal.fire(alertConfig)
        //         }, 200);
        //     } else {
        //         setTimeout(() => {
        //             Swal.fire({
        //                 title: 'Error',
        //                 text: response.data.sErrors[0],
        //                 icon: 'error',
        //                 willClose: () => {
        //                 }
        //             })
        //         }, 200);
        //     }
        // })

        // dispatch( stopProcessing() )
    }

    const reloadPage = () => {
        location.href = `/pages/new-client/${idCustomerRegister}`
    }

    const addListToClient = (list, checkId) => {
        let productsId = listAssignedRef.current
        const check = document.getElementById(checkId)
        if(check.checked) {
            const el = productsId.find(element => element === list.idListProduct)
            if(el === undefined)
                productsId.push(list.idListProduct)
        } else {
            const el = productsId.findIndex(element => element === list.idListProduct)
            productsId.splice(el, 1)
        }

        listAssignedRef.current = productsId

        setTimeout(() => {
            productsId.map( element => {
                document.getElementById(`check_list_${element}`).checked = true
            } )
        }, 100);

        setCustomerProductListsList(productsId)
    }

    const addProductToCustomer = (product, checkId) => {
        let productsId = productsAssignedRef.current
        const check = document.getElementById(checkId)
        if(check.checked) {
            const el = productsId.find(element => element === product.idProduct)
            if(el === undefined)
                productsId.push(product.idProduct)
        } else {
            const el = productsId.findIndex(element => element === product.idProduct)
            productsId.splice(el, 1)
        }

        productsAssignedRef.current = productsId

        setTimeout(() => {
            productsId.map( element => {
                document.getElementById(`check_${element}`).checked = true
            } )
        }, 100);

        setCustomerProducts(productsId)
    }

    const getCustomerLists = async () => {
        const lists = await getDataFromApi(`Customer/getCustomerProdList?idCustomer=${idCustomer}`, idCustomer, [], headers)
        if(lists.status === 'success') {
            setDataProductsListCustomer(lists.customerListsList)
        }
    }

    const getCustomerProducts = async (customer) => {
        const lists = await getDataFromApi(`Customer/getCustomerProducts?idCustomer=${customer.value}`, customer.value, [], headers)
        if(lists.status === 'success') {
            setDataProductsCustomer(lists.customerProductList)
        }
    }

    const onAddListClick = async () => {
        dispatch( startProcessing() )
        const alertConfig = {
            title: 'Exito',
            text: 'Listas asignadas correctamente.',
            icon: 'success',
            willClose: () => {
                onFinishRegister()
            }
        };

        const result = await saveData('Customer/updateListsOfCustomer', { idCustomer, customerProductListsList }, headers, alertConfig)
        if(result.status === 'success') {
            await getCustomerLists()
        }

        dispatch( stopProcessing() )
    }

    const onAddProductClick = async () => {
        dispatch( startProcessing() )
        const alertConfig = {
            title: 'Exito',
            text: 'Producto-servicios asignados correctamente.',
            icon: 'success',
            willClose: () => {
                onFinishRegister()
            }
        };

        const result = await saveData('Customer/updateProductsOfCustomer', { idCustomer, customerProducts }, headers, alertConfig)
        if(result.status === 'success') {
            await getCustomerProducts()
        }

        dispatch( stopProcessing() )
    }

    const getDropdownData = async () => {
        await lavakleenApi.get('Customer/getCustomerList?filterIdStatusCustomer=1', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.customerList.map( option => {
                optionsArray.push({value: option.idCustomer, label: `${option.sCompanyName}`})
            } )
            setOptionsCustomers( optionsArray );
            clientsRef.current = optionsArray;
        } )

        await lavakleenApi.get('ServiceType/getServiceList', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.serviceTypeList.map( option => {
                if(option.sStatus === 'Activo')
                    optionsArray.push({value: option.idServiceType, label: option.sServiceType})
            } )
            setOptionsProductList( optionsArray );
            servicesListRef.current = optionsArray
        } )

        await lavakleenApi.get('Product/getProductList', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.productList.map( option => {
                optionsArray.push({value: option.idProduct, label: option.sProductService, sServiceType: option.sServiceType})
            } )
            productsDetailsRef.current = optionsArray
        } )

        let filter = ''
        if(role !== 1)
            filter = `&idCompany=${localStorage.getItem('idCompany')}`

        const chofers = await getDataFromApi(`User/getUsersList?idRol=6&idStatusUser=1${filter}`, 0, [], headers)
        if(chofers.status === 'success') {
            let optionsArray = []
            chofers.userList.map( option => {
                optionsArray = [...optionsArray, {value: option.idUser, label: `${option.sName}`}]
            } )
            setOptionsChofer( optionsArray )
            chofersRef.current = optionsArray
        }
    }

    const getClientAddresses = async (customer) => {
        const address = await getDataFromApi(`CustomerAddresses/getCustomerAddressesList?idCustomer=${customer.value}`, customer.value, [], headers)
        if(address.status === 'success') {
            const optionsArray = []
            address.listCustomerAddresses.map( option => {
                optionsArray.push({value: option.idAddress, label: `${option.sAddressAlias} ${option.sBusinessAddress} ${option.sExtNumber} ${option.sIntNumber} ${option.sCity} ${option.sState}`})
            } )
            // setOptionsBillingAddress( optionsArray );
            billingAddressesRef.current = optionsArray
        }
    }

    const getProductsList = async (customer) => {
        const lists = await getDataFromApi(`Customer/getCustomerProdList?idCustomer=${customer.value}`, customer.value, [], headers)
        if(lists.status === 'success') {
            const optionsArray = []
            lists.customerListsList.map( option => {
                optionsArray.push({value: option.idProductList, label: `${option.sListName}`})
            } )
            setOptionsProductList( optionsArray );
        }
    }

    const getProductsDetails = async () => {
        const productsDetails = await getDataFromApi(`Product/getProductList`, 4, [], headers)
        if(productsDetails.status === 'success') {
            setDataProducts(productsDetails.productList)
        }
    }

    const getData = async () => {
        await getDropdownData();

        // await getProductsDetails();
        // await getCustomerLists();
        

        const order = await getDataFromApi(`Order/findOrder?idOrder=${idOrder}`, idOrder, orderData, headers);
        if(order.status === 'success'){
            const customer = clientsRef.current.find( element => element.value === order.orderData.idCustomer )
            setSelectedOptionCustomer(customer)
            setInvalidCustomer(false)

            await getClientAddresses(customer)
            await getCustomerProducts(customer)

            const address = billingAddressesRef.current.find( element => element.value === order.orderData.idCustomerAddress )

            setSelectedOptionBillingAddress(address)
            setInvalidBillingAddress(false)

            // await getProductsList(customer)
            const serviceType = servicesListRef.current.find( element => element.value === order.orderData.idServiceType )
            setSelectedOptionProductList(serviceType)

            const chofer = chofersRef.current.find(element => element.value === order.orderData.idDriver)
            setSelectedOptionChofer(chofer)

            await getOrderDetails(idOrder)

            setOrderData(order.orderData)
            setDeliveryDate(new Date(order.orderData.dDeliveryDate.replace("T", " ")))
            orderDataRef.current = order.orderData
            calculateTotal(orderDetailsRef.current)
        }
    }

    const getOrderDetails = async(orderId) => {
        const details = await getDataFromApi(`/OrderDetail/getOrderDetailList?idOrder=${orderId}`, orderId, {}, headers)
        if(details.orderDetailList !== null) {
            const optionsArray = []
            details.orderDetailList.map( element => {
                optionsArray.push({iQuantity: element.iQuantity, sProductService: element.sProductService, dSubtotal: element.dTotal, dPrice: element.dPrice, dTaxAmount: ((element.iQuantity * element.dPrice) * (parseFloat(element.dTaxPercent) / 100)).toFixed(2), idOrderDetail: element.idOrderDetail, sComments: element.sComments})
            } )
            
            setDataProductsOrder(optionsArray)
            orderDetailsRef.current = optionsArray
        }
    }

    const getProducts = async (list) => {
        const products = productsDetailsRef.current.filter( element => element.sServiceType === list.label )
        const optionsArray = []

        dataProductsCustomer.map( productCustomer => {
            const product = products.find( element => element.value === productCustomer.idProductService )
            if(product !== undefined)
                optionsArray.push({value: product.value, label: product.label})
        } )
        
        setOptionsProducts( optionsArray );
        // const products = await getDataFromApi(`ProductList/geProductsOfList?idListProduct=${list.value}`, list.value, [], headers)
        // if(products.status === 'success') {
        //     const optionsArray = []
        //     products.productosOfList.map( option => {
        //         optionsArray.push({value: option.idProduct, label: option.sProductName})
        //     } )
        //     setOptionsProducts( optionsArray );
        // }
    }

    const editOrderDetail = async(row) => {
        if(idOrder === undefined) {
            setSelectedProduct({ value: row.idProduct, label: row.sProductService })
            setInvalidProduct(false)

            const tax = Taxes.find( element => element.value === row.dTaxPercent )
            setSelectedOptionTax(tax)
            setInvalidTax(false)

            setFormItemData(row)
            toggleModal('OrderDetail/editOrderDetail')
        } else {
            const detail = await getDataFromApi(`OrderDetail/findOrderDetail?idOrderDetail=${row.idOrderDetail}`, row.idOrderDetail, {}, headers);
            // orderDetailData
            if(detail.status === 'success') {
                setSelectedProduct({ value: detail.orderDetailData.idProduct, label: detail.orderDetailData.sProduct })
                setInvalidProduct(false)

                const tax = Taxes.find( element => element.value === detail.orderDetailData.dTaxPercen.toString() )
                setSelectedOptionTax(tax)
                setInvalidTax(false)

                setFormItemData({
                    idOrder: idOrder,
                    idProduct: detail.orderDetailData.idProduct,
                    iQuantity: detail.orderDetailData.iQuantity,
                    sComments: '',
                    dPrice: detail.orderDetailData.dPrice,
                    dTaxPercen: detail.orderDetailData.dTaxPercen.toString(),
                    id: detail.orderDetailData.idOrderDetail,
                    idOrderDetail: detail.orderDetailData.idOrderDetail,
                })

                toggleModal('OrderDetail/editOrderDetail')
            }
            
        }
    }

    const deleteOrderDetail = async (row) => {

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                if(idOrder === undefined) {
                    let tempArray = dataProductsOrder
                    const el = tempArray.findIndex(element => element === row.id)
                    tempArray.splice(el, 1)
    
                    setDataProductsOrder(tempArray)
                    orderDetailsRef.current = tempArray
                    calculateTotal(orderDetailsRef.current)
                } else {
                    dispatch( startProcessing() )
                    const result = await deleteDataFromApi(`OrderDetail/deleteOrderDetail?idOrderDetail=${row.idOrderDetail}`, headers)
                    if(result.sErrors.length === 0) {
                        setTimeout(() => {
                            Swal.fire({
                                title: 'Exito',
                                text: 'Concepto eliminado correctamente. Para terminar la actualización por favor da clic en "Actualizar" en la parte inferior derecha.',
                                icon: 'success',
                                willClose: async () => {
                                    await getOrderDetails(idOrder)
                                    calculateTotal(orderDetailsRef.current)
                                }
                            })
                        }, 200)
                    } else {
                        setTimeout(() => {
                            Swal.fire({
                                title: 'Error',
                                text: response.data.sErrors[0],
                                icon: 'error',
                                willClose: async () => {
                                    
                                }
                            })
                        }, 200)
                    }
                    dispatch( stopProcessing() )
                }
            }
        })
    }

    const onFinishRegister = () => {
        setFinisRegistering(true)
    }

    const toggle = (tab) => {
        if(state.activeTab !== tab) {
            setState({
                ...state,
                activeTab: tab
            })
        }
    }

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Producción')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Producción'))
        
        if(userPerm.bCreate !== true) {
            navigate(`/pages/dashboard`)
        }

        if(userPerm.bEdit !== true) {
            navigate(`/pages/dashboard`)
        }
    }

    const onAddItemClick = () => {
        setFormItemData({
            idOrder: parseFloat(idOrder),
            idProduct: null,
            iQuantity: 1,
            sComments: '',
            dPrice: '',
            dTaxPercen: '',
            id: 0,
        })
        setSelectedProduct(null)
        setInvalidProduct(true)
        setSelectedOptionTax(null)
        setInvalidTax(true)
        toggleModal('OrderDetail/addOrderDetail')
    }

    const saveTemporalData = async (values) => {
        values.dTaxPercen = selectedOptionTax.value
        if(idOrder === undefined) {
            const tempArray = dataProductsOrder
            const num = orderDetailsRef.current.length
    
            setDataProductsOrder([...tempArray, {iQuantity: values.iQuantity, sProductService: values.sProductService, dPrice: values.dPrice, dTaxPercent: values.dTaxPercen, dSubtotal: (values.iQuantity * values.dPrice), dTaxAmount: ((values.iQuantity * values.dPrice) * (parseFloat(values.dTaxPercen) / 100)).toFixed(2), idOrder: 0, idProduct: values.idProduct, sComments: values.sComments, id: num + 1, dTaxPercen: values.dTaxPercen}])
            toggleModal('')
    
            orderDetailsRef.current.push({iQuantity: values.iQuantity, sProductService: values.sProductService, dPrice: values.dPrice, dTaxPercent: values.dTaxPercen, dSubtotal: (values.iQuantity * values.dPrice), dTaxAmount: ((values.iQuantity * values.dPrice) * (parseFloat(values.dTaxPercen) / 100)).toFixed(2), idOrder: 0, idProduct: values.idProduct, sComments: values.sComments, id: num + 1, dTaxPercen: values.dTaxPercen})
    
            //calculate total
            calculateTotal(orderDetailsRef.current)
        } else {
            
            dispatch( startProcessing() )
            const alertConfig = {
                title: 'Exito',
                text: 'Concepto agregado corretamente. Para terminar la actualización por favor da clic en "Actualizar" en la parte inferior derecha.',
                icon: 'success',
                willClose: async () => {
                    await getOrderDetails(idOrder)
                    calculateTotal(orderDetailsRef.current)
                }
            };

            await saveData( 'OrderDetail/addOrderDetail', values, headers, alertConfig );

            dispatch( stopProcessing() )

            toggleModal('')
        }
        
    }

    const editTemporalData = async (values) => {
        if(idOrder === undefined) {
            const tempArray = dataProductsOrder.map( element => {
                if(element.id === values.id)
                    return {iQuantity: values.iQuantity, sProductService: values.sProductService, dPrice: values.dPrice, dTaxPercent: values.dTaxPercent, dSubtotal: (values.iQuantity * values.dPrice), dTaxAmount: ((values.iQuantity * values.dPrice) * (parseFloat(values.dTaxPercent) / 100)).toFixed(2), idOrder: 0, idProduct: values.idProduct, sComments: values.sComments, id: values.id}
    
                return element
            } )
            
            setDataProductsOrder(tempArray)
            orderDetailsRef.current = tempArray
    
            calculateTotal(orderDetailsRef.current)
        } else {
            values.dTaxPercen = selectedOptionTax.value
            dispatch( startProcessing() )
            const alertConfig = {
                title: 'Exito',
                text: 'Concepto actualizado corretamente. Para terminar la actualización por favor da clic en "Actualizar" en la parte inferior derecha.',
                icon: 'success',
                willClose: async () => {
                    await getOrderDetails(idOrder)
                    calculateTotal(orderDetailsRef.current)
                }
            };

            await saveData( 'OrderDetail/editOrderDetail', values, headers, alertConfig );
            dispatch( stopProcessing() )
        }

        toggleModal('')
    }

    const calculateTotal = (items) => {
        let subTotal = 0
        let tax = 0
        let total = 0
        items.map( item => {
            subTotal += parseFloat(item.dSubtotal)
            tax += parseFloat(item.dTaxAmount)
        } )

        total = subTotal + tax

        orderData.dSubtotal = subTotal.toFixed(2)
        orderData.dTax = tax.toFixed(2)
        orderData.dTotal = total.toFixed(2)
    }

    const calculateDiscount = (value) => {
        orderData.dDiscount = parseFloat(value)
    }

    const setItemData = async (item) => {
        const product = await getDataFromApi(`Product/findProduct?idProduct=${item.value}`, item.value, [], headers)
        if(product.status === 'success'){
            const tax = Taxes.find( element => element.value === product.productData.sTaxId )
            
            setFormItemData( {
                idOrder: parseFloat(idOrder),
                idProduct: product.productData.idProduct,
                iQuantity: formItemData.iQuantity,
                sComments: formItemData.sComments,
                dPrice: product.productData.dMXNPrice,
                dTaxPercen: product.productData.sTaxId,
                id: 0,
                sProductService: product.productData.sProductService
            } );

            setSelectedOptionTax(tax)
            setInvalidTax(false)
        }
    }

    const changeOrderProcess = async (idOrder, status) => {

        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Producción')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const alertConfig = {
                    title: 'Exito',
                    text: 'Estatus actualizado exitosamente.',
                    icon: 'success',
                    willClose: () => {
                        navigate(`/pages/production`)
                    }
                }
                const result = await saveData(`Order/changeProcessStatus`, { idOrder, idOrderProcess: status, idRegisterUser: id }, headers, alertConfig)
            }
        })
    }

    useEffect(() => {
        checkPermissions();
        getData();
    }, [])

    useEffect(() => {
        if(finisRegistering == true) {
            navigate(`/pages/orders`)
        }
    }, [finisRegistering])

    useEffect(() => {
        if(isProcessing == true) {

            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }

        if(!isProcessing) Swal.close()
    }, [isProcessing])

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading={ `Editar producción Pedido: ${idOrder}` }
                                subheading="Pantalla para informar la producción de pedidos"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <Form
                                initialValues={orderData}
                                onSubmit={ (values) => onSubmit(values) }
                            >
                                <Row>
                                    <Col md="12">
                                        <Card className="main-card mb-3">
                                            <CardBody>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Cliente</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCustomer}
                                                                options={optionsCustomers}
                                                                isClearable
                                                                name="idCustomerType"
                                                                key={ selectedOptionCustomer }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidCustomer ? 'is-invalid form-control' : '' }
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidCustomer(true) : setInvalidCustomer(false)
                                                                    setSelectedOptionCustomer(option)
                                                                    if(option){
                                                                        // getProducts(option)
                                                                        getClientAddresses(option)
                                                                        getCustomerProducts(option)
                                                                    }
                                                                }}
                                                                isDisabled={ idOrder !== undefined ? true : false }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col md="3">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Productos</Label>
                                                            <Select
                                                                defaultValue={selectedOptionListProduct}
                                                                options={optionsListProducts}
                                                                isClearable
                                                                name="idCustomerType"
                                                                key={ selectedOptionListProduct }
                                                                placeholder="Selecciona una opción"
                                                                className={ invalidListProduct ? 'is-invalid form-control' : '' }
                                                                onChange={ (option) => {
                                                                    option == null ? setInvalidListProduct(true) : setInvalidListProduct(false)
                                                                    setSelectedOptionListProduct(option)
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col> */}
                                                    <Col md="3">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Fecha entrega</Label>
                                                            <DatePicker
                                                                className="form-control"
                                                                name="dDeliveryDate"
                                                                dateFormat="yyyy-MM-dd"
                                                                selected={ deliveryDate }
                                                                onChange={(date) => setDeliveryDate(date)}
                                                                minDate={new Date()}
                                                                disabled={ idOrder !== undefined ? true : false }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Moneda</Label>
                                                            <Select
                                                                defaultValue={selectedOptionCurrency}
                                                                options={optionsCurrency}
                                                                name="idCustomerType"
                                                                key={ selectedOptionCurrency }
                                                                placeholder="Selecciona una opción"
                                                                isDisabled={ idOrder !== undefined ? true : false }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Tipo de servicio</Label>
                                                            <Select
                                                                defaultValue={selectedOptionProductList}
                                                                options={optionsProductList}
                                                                name="idCustomerType"
                                                                key={ selectedOptionProductList }
                                                                placeholder="Selecciona una opción"
                                                                onChange={ (option) => {
                                                                    setSelectedOptionProductList(option)
                                                                    if(option){
                                                                        getProducts(option)
                                                                    }
                                                                }}
                                                                isDisabled={ idOrder !== undefined ? true : false }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Proceso</Label>
                                                            <Select
                                                                defaultValue={selectedOptionProcess}
                                                                options={optionsProcess}
                                                                name="idCustomerType"
                                                                key={ selectedOptionProcess }
                                                                placeholder="Selecciona una opción"
                                                                
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="4">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Noficiar a</Label>
                                                            <Input type="email" name="email" placeholder="Email..."/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="2">
                                                        <FormGroup>
                                                            <Label for="idCustomerType">Peso</Label>
                                                            <Input type="text" name="dTotalWeight"/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup>
                                                        <Label for="idCustomerType">Chofer</Label>
                                                        <Select
                                                            defaultValue={selectedOptionChofer}
                                                            options={optionsChofer}
                                                            isDisabled
                                                            name="idCustomerType"
                                                            key={ selectedOptionChofer }
                                                            placeholder="Selecciona una opción"
                                                            // className={ invalidChofer ? 'is-invalid form-control' : '' }
                                                            // onChange={ (option) => {
                                                            //     option == null ? setInvalidChofer(true) : setInvalidChofer(false)
                                                            //     setSelectedOptionChofer(option)
                                                            // }}
                                                        />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <Card className="main-card mb-3 mt-3">
                                                            <CardHeader>
                                                                <Nav justified>
                                                                    <TabHeaderComponent id="items" label="Conceptos del pedido" state={state} toggle={ toggle }/>
                                                                </Nav>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <TabContent activeTab={ state.activeTab }>
                                                                    <TabPane tabId="items">
                                                                        <Row>
                                                                            <Col md="12">
                                                                                <DataTable data={dataProductsOrder}
                                                                                    columns={columnsProductsList}
                                                                                    pagination
                                                                                    fixedHeader
                                                                                    fixedHeaderScrollHeight="500px"
                                                                                />
                                                                            </Col>
                                                                            <ModalComponent
                                                                                isOpen={modalState.isOpen}
                                                                                toggle={ toggleModal }
                                                                                backdrop={ modalState.backDrop } modalTitle="Agregar/Editar concepto"
                                                                                size="md"
                                                                                initialValues={ formItemData }
                                                                                validationSchema={ {
                                                                                    iQuantity: yup.string().required(),
                                                                                    dPrice: yup.string().required()
                                                                                } }
                                                                                saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                                                                alertMessages={ { addMessage: 'Concepto agregado exitosamente', updateMessage: 'Concepto actualizado exitosamente' } }
                                                                                getDataFunction={ getClientAddresses }
                                                                                dropDownsToValidate={ [  ] }
                                                                                saveTemporalData={ saveTemporalData }
                                                                                editTemporalData= { editTemporalData }
                                                                            >
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="iQuantity">Cantidad</Label>
                                                                                            <Input type="number" name="iQuantity" placeholder=""/>
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="sUnit">Unidad</Label>
                                                                                            <Input type="text" name="sUnit" placeholder=""/>
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="idCustomerType">Servicio/Producto</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedProduct}
                                                                                                options={optionsProducts}
                                                                                                isClearable
                                                                                                name="idProduct"
                                                                                                key={ selectedProduct }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidProduct ? 'is-invalid form-control' : '' }
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidProduct(true) : setInvalidProduct(false)
                                                                                                    setSelectedProduct(option)

                                                                                                    if(option)
                                                                                                        setItemData(option)
                                                                                                }}
                                                                                            />
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="dPrice">Precio</Label>
                                                                                            <Input type="number" name="dPrice" placeholder=""/>
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="dTaxPercen">I.V.A</Label>
                                                                                            <Select
                                                                                                defaultValue={selectedOptionTax}
                                                                                                onChange={ (option) => {
                                                                                                    option == null ? setInvalidTax(true) : setInvalidTax(false)
                                                                                                    setSelectedOptionTax(option)
                                                                                                }}
                                                                                                options={optionsTaxes}
                                                                                                name="dTaxPercen"
                                                                                                key={ selectedOptionTax }
                                                                                                placeholder="Selecciona una opción"
                                                                                                className={ invalidTax ? 'is-invalid form-control' : '' }
                                                                                            />
                                                                                        </FormGroup>
                                                                                        <FormGroup className="mb-2">
                                                                                            <Label for="idCustomerType">Comentarios</Label>
                                                                                            <Input type="text" name="sComments" placeholder=""/>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                            </ModalComponent>
                                                                        </Row>
                                                                    </TabPane>
                                                                </TabContent>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="8">
                                                        
                                                    </Col>
                                                    <Col md="4">
                                                        <FormGroup className="mb-3" style={ { 'textAlign': 'end' } }>
                                                            <Link 
                                                                className="mt-2 btn btn-danger"
                                                                to="/pages/production"
                                                            >
                                                                Cancelar
                                                            </Link>{ "  " }
                                                            <Button color="warning" className="mt-2 mr-2" onClick={ () => changeOrderProcess(idOrder, 4) }>Procesado</Button>{ "  " }
                                                            <Button color="primary" className="mt-2 mr-2" type="submit">
                                                                {
                                                                    (idOrder === undefined)
                                                                    ? 'Guardar'
                                                                    : 'Actualizar'
                                                                }
                                                            </Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form> 
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

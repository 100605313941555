import { useState, useEffect } from "react"
import { Row, Col, Button, FormGroup, Label } from "reactstrap"
import { Form, Input } from '@availity/form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faClose, faFilter } from '@fortawesome/free-solid-svg-icons'
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { lavakleenApi } from "../../api/lavakleenApi";

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const AppFilters = ({ applyFilter, restoreFilter }) => {
    const [state, setState] = useState({
        showing: false,
        filter: false,
    })

    const { showing, filter } = state;

    const [searchData, setSearchData] = useState({
        filterCustomerStatus: '',
        filterCustomerType: '',
        filterBusinessName: '',
        filterEmail: '',
        filterIdStatusCustomer: '',
        filterService: '',
        filterListProduct: '',
        filterProduct: '',
        filterRol: '',
        filterBranchOffice: '',
        filterName: '',
        filterEmail: '',
        filterUser: '',
        filterCompanyName: '',
        filterMeasurement: '',
        filterCompany: '',
        filterIdOrder: '',
        filterStartDate: '',
        filterFinishDate: '',
        filterIdCustomer: '',
        filterIdStatusOrder: '',
        filerActive: '',
        filterIdDriver: '',
        dStartDate: '',
        dFinishDate: '',
        idCustomer: '',
        idDriver: '',
        idStatus: '',
        bisVisitOpen: '',
        filterIdinvoice: '',
        filterProdServ: '',
        filterTaxRegime: '',
        bActive: true,
    })

    const [selectedOptionClientStatus, setSelectedOptionClientStatus] = useState(null);
    const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
    const [selectedOptionOrderProcess, setSelectedOptionOrderProcess] = useState(null);
    const [selectedOptionOrderStatus, setSelectedOptionOrderStatus] = useState(null);
    const [selectedOptionChofer, setSelectedOptionChofer] = useState(null);
    const [selectedOptionIncidentStatus, setSelectedOptionIncidentStatus] = useState(null);
    const [selectedOptionVisitStatus, setSelectedOptionVisitStatus] = useState(null);
    const [selectedOptionBActive, setSelectedOptionBActive] = useState(null);
    const [optionsClientStatus, setOptionsClientStatus] = useState([]);
    const [optionsCustomers, setOptionsCustomers] = useState([]);
    const [optionsOrderProcess, setOptionsOrderProcess] = useState([
        { value: 1, label: 'RECOLECCION' },
        { value: 2, label: 'EN ESPERA' },
        { value: 3, label: 'EN PROCESO' },
        { value: 4, label: 'PROCESADO' },
        { value: 5, label: 'PARA ENTREGA' },
        { value: 6, label: 'FINALIZADO' },
    ]);
    const [optionsOrderStatus, setOptionsOrderStatus] = useState([
        { value: true, label: 'Activo' },
        { value: false, label: 'Inactivo' },
    ]);
    const [optionsVisitStatus, setOptionsVisitStatus] = useState([
        { value: true, label: 'Abierta' },
        { value: false, label: 'Cerrada' },
    ]);
    const [optionsChofer, setOptionsChofer] = useState([]);
    const [optionsIncidentStatus, setOptionsIncidentStatus] = useState([
        { value: 1, label: 'Abierta' },
        { value: 2, label: 'Proceso' },
        { value: 3, label: 'Cerrada' },
    ]);

    const location = useLocation();

    const [initialDate, setInitialDate] = useState(null);
    const [finalDate, setFinalDate] = useState(null);

    const apply = (values) => {
        if(initialDate !== null && finalDate !== null) {
            const dateInitial = new Date(initialDate)
            const dateFinal = new Date(finalDate)
            values.filterStartDate = `${dateInitial.getFullYear()}-${dateInitial.getMonth() + 1}-${dateInitial.getDate()} 00:00:00`
            values.filterFinishDate = `${dateFinal.getFullYear()}-${dateFinal.getMonth() + 1}-${dateFinal.getDate()} 23:59:59`

            values.dStartDate = `${dateInitial.getFullYear()}-${dateInitial.getMonth() + 1}-${dateInitial.getDate()} 00:00:00`
            values.dFinishDate = `${dateFinal.getFullYear()}-${dateFinal.getMonth() + 1}-${dateFinal.getDate()} 23:59:59`
        }
        setState({ filter: true, showing: true })
        if(selectedOptionClientStatus)
            values.filterIdStatusCustomer = selectedOptionClientStatus.value
        if(selectedOptionCustomer){
            values.filterIdCustomer = selectedOptionCustomer.value
            values.idCustomer = selectedOptionCustomer.value
        }
        if(selectedOptionOrderProcess)
            values.filterIdStatusOrder = selectedOptionOrderProcess.value
        if(selectedOptionOrderStatus)
            values.filerActive = selectedOptionOrderStatus.value
        if(selectedOptionChofer){
            values.filterIdDriver = selectedOptionChofer.value
            values.idDriver = selectedOptionChofer.value
        }
        if(selectedOptionIncidentStatus)
            values.idStatus = selectedOptionIncidentStatus.value
        if(selectedOptionVisitStatus)
            values.bisVisitOpen = selectedOptionVisitStatus.value
        if(selectedOptionBActive)
            values.bActive = selectedOptionBActive.value
        applyFilter(values)
    }

    const restore = () => {
        setState({ filter: false , showing: true})
        restoreFilter()
    }

    useEffect(() => {
        if(filter === false) {
            setInitialDate(null)
            setFinalDate(null)
            setSelectedOptionCustomer(null)
            setSelectedOptionOrderProcess(null)
            setSelectedOptionOrderStatus(null)
            setSelectedOptionChofer(null)
            setSelectedOptionIncidentStatus(null)
            setSelectedOptionVisitStatus(null)
            setSearchData({
                filterCustomerStatus: '',
                filterCustomerType: '',
                filterBusinessName: '',
                filterEmail: '',
                filterIdStatusCustomer: '',
                filterService: '',
                filterListProduct: '',
                filterProduct: '',
                filterRol: '',
                filterBranchOffice: '',
                filterName: '',
                filterEmail: '',
                filterUser: '',
                filterCompanyName: '',
                filterMeasurement: '',
                filterCompany: '',
                filterIdOrder: '',
                filterStartDate: '',
                filterFinishDate: '',
                filterIdCustomer: '',
                filterIdStatusOrder: '',
                filerActive: '',
                filterIdDriver: '',
                dStartDate: '',
                dFinishDate: '',
                idCustomer: '',
                idDriver: '',
                idStatus: '',
                bisVisitOpen: '',
                filterIdinvoice: '',
                filterProdServ: '',
                filterTaxRegime: '',
                bActive: true,
            })
        }
    }, [filter])

    useEffect(() => {
        getDropdownData()
    }, [])
    

    const getDropdownData = async () => {
        await lavakleenApi.get('CustomerStatus/getCustomerStatusList', {headers})
        .then( response => {
            const optionsArray = []
            response.data.listCustomerStatus.map( option => {
                optionsArray.push({value: option.idCustomerStatus, label: option.sCustomerStatus})
            } )
            setOptionsClientStatus( optionsArray );
        } )

        await lavakleenApi.get('Customer/getCustomerList?filterIdStatusCustomer=1', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.customerList.map( option => {
                optionsArray.push({value: option.idCustomer, label: `${option.sCompanyName}`})
            } )
            setOptionsCustomers( optionsArray )
        } )

        await lavakleenApi.get('User/getUsersList?idRol=6&idStatusUser=1', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.userList.map( option => {
                optionsArray.push({value: option.idUser, label: `${option.sName}`})
            } )
            setOptionsChofer( optionsArray )
        } )
    }
    
    return (
        <div className={"ui-theme-settings " + (showing ? 'settings-open' : '')}>
            <Button className="btn-open-options" id="TooltipDemo" color="primary" onClick={() => setState({showing: !showing})}>
                <FontAwesomeIcon icon={showing ? faClose : faFilter} color="#ffffff" fixedWidth={false} size="2x"
                style={ showing ? {'left' : '60%'} : { 'left' : '50%' }}/>
            </Button>
            <div className="theme-settings__inner">
                <PerfectScrollbar>
                <div className="theme-settings__options-wrapper">
                    <h3 className="themeoptions-heading"> Filtros para aplicar</h3>
                    <div className="p-3">
                        <Form
                            initialValues={searchData}
                            onSubmit={ (values) => apply(values) }
                            enableReinitialize
                        >
                            <Row>
                                <Col md={12}>
                                    {
                                        location.pathname === '/pages/client-status'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterCustomerStatus">Nombre</Label>
                                                    <Input type="text" name="filterCustomerStatus"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }

                                    {
                                        location.pathname === '/pages/client-types'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterCustomerType">Nombre</Label>
                                                    <Input type="text" name="filterCustomerType"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }

                                    {
                                        location.pathname === '/pages/clients'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterBusinessName">Razón social</Label>
                                                    <Input type="text" name="filterBusinessName"/>
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterCompany">Nombre comercial</Label>
                                                    <Input type="text" name="filterCompany"/>
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterEmail">Email</Label>
                                                    <Input type="text" name="filterEmail"/>
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterIdStatusCustomer">Status de cliente</Label>
                                                    <Select
                                                        defaultValue={selectedOptionClientStatus}
                                                        options={optionsClientStatus}
                                                        name="filterIdStatusCustomer"
                                                        key={ selectedOptionClientStatus }
                                                        placeholder="Selecciona una opción"
                                                        isClearable
                                                        onChange={ (option) => {
                                                            setSelectedOptionClientStatus(option)
                                                        }}
                                                    />
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/services'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterService">Nombre</Label>
                                                    <Input type="text" name="filterService"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/products-list'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterListProduct">Nombre</Label>
                                                    <Input type="text" name="filterListProduct"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/products'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterMasterProduct">Nombre</Label>
                                                    <Input type="text" name="filterMasterProduct"/>
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="bActive">Estatus</Label>
                                                    <Select
                                                        defaultValue={selectedOptionBActive}
                                                        options={optionsOrderStatus}
                                                        isClearable
                                                        name="bActive"
                                                        key={ selectedOptionBActive }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionBActive(option)
                                                            if(!option) {
                                                                setSearchData({...searchData, bActive: true})
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/roles'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterRol">Nombre</Label>
                                                    <Input type="text" name="filterRol"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/branch-offices'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterBranchOffice">Nombre</Label>
                                                    <Input type="text" name="filterBranchOffice"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/users'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterName">Nombre</Label>
                                                    <Input type="text" name="filterName"/>
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterEmail">Email</Label>
                                                    <Input type="text" name="filterEmail"/>
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterUser">Cuenta de usuario</Label>
                                                    <Input type="text" name="filterUser"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/companies'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterCompanyName">Razón social</Label>
                                                    <Input type="text" name="filterCompanyName"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/sat-unit-measures'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterMeasurement">Nombre o clave</Label>
                                                    <Input type="text" name="filterMeasurement"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/orders'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterIdOrder">No. Pedido</Label>
                                                    <Input type="text" name="filterIdOrder"/>
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterIdCustomer">Cliente</Label>
                                                    <Select
                                                        defaultValue={selectedOptionCustomer}
                                                        options={optionsCustomers}
                                                        isClearable
                                                        name="filterIdCustomer"
                                                        key={ selectedOptionCustomer }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionCustomer(option)
                                                            if(!option) {
                                                                setSearchData({...searchData, filterIdCustomer: '', idCustomer: ''})
                                                            }                                                            
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterIdStatusOrder">Proceso</Label>
                                                    <Select
                                                        defaultValue={selectedOptionOrderProcess}
                                                        options={optionsOrderProcess}
                                                        isClearable
                                                        name="filterIdStatusOrder"
                                                        key={ selectedOptionOrderProcess }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionOrderProcess(option)
                                                            if(!option) {
                                                                setSearchData({...searchData, filterIdStatusOrder: ''})
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterStartDate">Fecha inicial registro</Label>
                                                    <DatePicker
                                                        className="form-control"
                                                        name="filterStartDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={ initialDate }
                                                        onChange={(date) => setInitialDate(date)}
                                                        maxDate={new Date()}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterFinishDate">Fecha final registro</Label>
                                                    <DatePicker
                                                        className="form-control"
                                                        name="filterFinishDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={ finalDate }
                                                        onChange={(date) => {
                                                            setFinalDate(date)
                                                        }}
                                                        maxDate={new Date()}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filerActive">Estatus</Label>
                                                    <Select
                                                        defaultValue={selectedOptionOrderStatus}
                                                        options={optionsOrderStatus}
                                                        isClearable
                                                        name="filerActive"
                                                        key={ selectedOptionOrderStatus }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionOrderStatus(option)
                                                            if(!option) {
                                                                setSearchData({...searchData, filerActive: ''})
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="filterIdDriver">Chofer</Label>
                                                    <Select
                                                        defaultValue={selectedOptionChofer}
                                                        options={optionsChofer}
                                                        isClearable
                                                        name="filterIdDriver"
                                                        key={ selectedOptionChofer }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionChofer(option)
                                                            if(!option) {
                                                                setSearchData({...searchData, filterIdDriver: ''})
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterIdinvoice">Factura</Label>
                                                    <Input type="text" name="filterIdinvoice"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/issues'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="idCustomer">Cliente</Label>
                                                    <Select
                                                        defaultValue={selectedOptionCustomer}
                                                        options={optionsCustomers}
                                                        isClearable
                                                        name="idCustomer"
                                                        key={ selectedOptionCustomer }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionCustomer(option)
                                                            if(!option) {
                                                                setSearchData({...searchData, filterIdCustomer: '', idCustomer: ''})
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="idDriver">Chofer</Label>
                                                    <Select
                                                        defaultValue={selectedOptionChofer}
                                                        options={optionsChofer}
                                                        isClearable
                                                        name="idDriver"
                                                        key={ selectedOptionChofer }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionChofer(option)
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="dStartDate">Fecha inicial</Label>
                                                    <DatePicker
                                                        className="form-control"
                                                        name="dStartDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={ initialDate }
                                                        onChange={(date) => setInitialDate(date)}
                                                        maxDate={new Date()}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="dFinishDate">Fecha final</Label>
                                                    <DatePicker
                                                        className="form-control"
                                                        name="dFinishDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={ finalDate }
                                                        onChange={(date) => setFinalDate(date)}
                                                        maxDate={new Date()}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="idStatus">Estatus</Label>
                                                    <Select
                                                        defaultValue={selectedOptionIncidentStatus}
                                                        options={optionsIncidentStatus}
                                                        isClearable
                                                        name="idStatus"
                                                        key={ selectedOptionIncidentStatus }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionIncidentStatus(option)
                                                            
                                                        }}
                                                    />
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/visits'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="idCustomer">Cliente</Label>
                                                    <Select
                                                        defaultValue={selectedOptionCustomer}
                                                        options={optionsCustomers}
                                                        isClearable
                                                        name="idCustomer"
                                                        key={ selectedOptionCustomer }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionCustomer(option)
                                                            if(!option) {
                                                                setSearchData({...searchData, filterIdCustomer: '', idCustomer: ''})
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for="idDriver">Chofer</Label>
                                                    <Select
                                                        defaultValue={selectedOptionChofer}
                                                        options={optionsChofer}
                                                        isClearable
                                                        name="idDriver"
                                                        key={ selectedOptionChofer }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionChofer(option)
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="dStartDate">Fecha inicial</Label>
                                                    <DatePicker
                                                        className="form-control"
                                                        name="dStartDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={ initialDate }
                                                        onChange={(date) => setInitialDate(date)}
                                                        maxDate={new Date()}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="dFinishDate">Fecha final</Label>
                                                    <DatePicker
                                                        className="form-control"
                                                        name="dFinishDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={ finalDate }
                                                        onChange={(date) => setFinalDate(date)}
                                                        maxDate={new Date()}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="idStatus">Estatus</Label>
                                                    <Select
                                                        defaultValue={selectedOptionVisitStatus}
                                                        options={optionsVisitStatus}
                                                        isClearable
                                                        name="idStatus"
                                                        key={ selectedOptionVisitStatus }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionVisitStatus(option)
                                                            
                                                        }}
                                                    />
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/invoices'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterIdCustomer">Cliente</Label>
                                                    <Select
                                                        defaultValue={selectedOptionCustomer}
                                                        options={optionsCustomers}
                                                        isClearable
                                                        name="filterIdCustomer"
                                                        key={ selectedOptionCustomer }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionCustomer(option)
                                                            if(!option) {
                                                                setSearchData({...searchData, filterIdCustomer: '', idCustomer: ''})
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterStartDate">Fecha inicial registro</Label>
                                                    <DatePicker
                                                        className="form-control"
                                                        name="filterStartDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={ initialDate }
                                                        onChange={(date) => setInitialDate(date)}
                                                        maxDate={new Date()}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterFinishDate">Fecha final registro</Label>
                                                    <DatePicker
                                                        className="form-control"
                                                        name="filterFinishDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={ finalDate }
                                                        onChange={(date) => setFinalDate(date)}
                                                        maxDate={new Date()}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/sat-products'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterProdServ">Nombre</Label>
                                                    <Input type="text" name="filterProdServ"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/tax-regimes'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterTaxRegime">Nombre</Label>
                                                    <Input type="text" name="filterTaxRegime"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/unit-measures'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="filterMeasurement">Nombre</Label>
                                                    <Input type="text" name="filterMeasurement"/>
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                    {
                                        location.pathname === '/pages/credit-notes' || location.pathname === '/pages/payments'
                                        ? (
                                            <>
                                                <FormGroup className="mb-2">
                                                    <Label for="idCustomer">Cliente</Label>
                                                    <Select
                                                        defaultValue={selectedOptionCustomer}
                                                        options={optionsCustomers}
                                                        isClearable
                                                        name="idCustomer"
                                                        key={ selectedOptionCustomer }
                                                        placeholder="Selecciona una opción"
                                                        onChange={ (option) => {
                                                            setSelectedOptionCustomer(option)
                                                            if(!option) {
                                                                setSearchData({...searchData, filterIdCustomer: '', idCustomer: ''})
                                                            }
                                                        }}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="dStartDate">Fecha inicial registro</Label>
                                                    <DatePicker
                                                        className="form-control"
                                                        name="dStartDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={ initialDate }
                                                        onChange={(date) => setInitialDate(date)}
                                                        maxDate={new Date()}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>
                                                <FormGroup className="mb-2">
                                                    <Label for="dFinishDate">Fecha final registro</Label>
                                                    <DatePicker
                                                        className="form-control"
                                                        name="dFinishDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={ finalDate }
                                                        onChange={(date) => setFinalDate(date)}
                                                        maxDate={new Date()}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>
                                            </>
                                        )
                                        : ''
                                    }
                                </Col>
                                <Col md={12}>
                                    <Button color="primary" className="mt-4 mr-2" type="submit">Buscar</Button>{ "  " }
                                    <Button color="danger" className="mt-4 mr-2" onClick={ restore }>Reestablecer</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                </PerfectScrollbar>
            </div>
        </div>
    )
}

import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useSelector } from "react-redux";
import cx from "classnames";
import { HeaderLogo } from "../main/logo/HeaderLogo";
import { HeaderDots, MegaMenu, SearchBox, UserBox } from "./components";


export const AppHeader = () => {
    const {headerBackgroundColor, enableHeaderShadow, enableMobileMenuSmall} = useSelector( state => state.themeOptions);
    return (
        <>
            <TransitionGroup>
                <CSSTransition component="div"
                className={cx("app-header", headerBackgroundColor, {
                "header-shadow": enableHeaderShadow,
                })}
                appear={true} timeout={1500} enter={false} exit={false}>
                    <div>
                        <HeaderLogo/>
                        <div className={cx("app-header__content", {
                        "header-mobile-open": enableMobileMenuSmall,
                        })}>
                            <div className="app-header-left">
                                {/* <SearchBox /> */}
                                {/* <MegaMenu /> */}
                            </div>
                            <div className="app-header-right">
                                {/* <HeaderDots /> */}
                                <UserBox />
                                <div className="header-btn-lg">
                                    <button className="hamburger hamburger--elastic open-right-drawer" type="button">
                                        <span className="hamburger-box">
                                            <span className="hamburger-inner"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

import { Col, Row, Button, FormGroup, Label } from "reactstrap";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Input } from '@availity/form';
import * as yup from "yup";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'

import { stopFinishRegister } from "../../store/auth/authSlice";

import bg1 from "../../assets/utils/images/originals/city.jpg";
import { startLogin } from "../../store/auth/thunks";

const formData = { sUserName: '', sPassword: ''};

export const LoginPage = () => {

    const dispatch = useDispatch();
    const { isProcessing, finishRegister, status } = useSelector( state => state.auth );
    const navigate = useNavigate();

    if(finishRegister)
        dispatch( stopFinishRegister() );

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true,
    };

    useEffect(() => {
        if(isProcessing === true) {

            Swal.fire({
                title: 'Espere por favor...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        }

        if(!isProcessing) Swal.close()
    }, [isProcessing])

    useEffect(() => {
        if(status === 'authenticated') {
            navigate('/pages/dashboard', {
                replace: true
            })
        }
    }, [status])

    const onLogin = (values) => {
        dispatch( startLogin(values) );
    }

    return (
        <>
            <div className="h-100">
                <Row className="h-100 g-0">
                    <Col lg="4" className="d-none d-lg-block">
                        <div className="slider-light">
                            <Slider {...settings}>
                            <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                                <div className="slide-img-bg"
                                style={{
                                    backgroundImage: "url(" + bg1 + ")",
                                }}/>
                                <div className="slider-content">
                                <h3>Acquatronix CRM</h3>
                                <p>
                                    La mejor plataforma para administrar tu lavandería!!!
                                </p>
                                </div>
                            </div>
                            </Slider>
                        </div>
                    </Col>
                    <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                        <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                            <div className="app-logo" />
                            <h4 className="mb-0">
                            <div>Bienvenido de nuevo,</div>
                            <span>Por favor ingresa tus credenciales.</span>
                            </h4>
                            <h6 className="mt-3">
                            ¿No tienes cuenta?{" "}
                            <Link to="/auth/register" className="text-primary">
                                Regístrate ahora
                            </Link>
                            </h6>
                            <Row className="divider" />
                            <div>
                                <Form
                                    initialValues={formData}
                                    onSubmit={ (values) => onLogin(values) }
                                    validationSchema={
                                        yup.object({
                                            sUserName: yup.string().required(),
                                            sPassword: yup.string().min(6).required()
                                        })
                                    }
                                >
                                    <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                        <Label for="sUserName">Cuenta de usuario</Label>
                                        <Input type="text" name="sUserName" id="sUserName" placeholder="Ingresa con tu cuenta de usuario..."/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                        <Label for="sPassword">Contraseña</Label>
                                        <Input type="password" name="sPassword" id="sPassword" placeholder="Ingresa tu contraseña..."/>
                                        </FormGroup>
                                    </Col>
                                    </Row>
                                    <FormGroup check>
                                        <Input type="checkbox" name="check" id="exampleCheck" />
                                        <Label for="exampleCheck" check>
                                            Recordarme
                                        </Label>
                                    </FormGroup>
                                    <Row className="divider" />
                                        <div className="d-flex align-items-center">
                                        <div className="ms-auto">
                                            {/* <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()} className="btn-lg btn btn-link" >
                                            Recuperar contraseña
                                            </a>{" "} */}
                                            <Button color="primary" size="lg" type="submit">
                                            Ingresar
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
        </>
    )
}

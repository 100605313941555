import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, FormGroup, Label } from "reactstrap";
import { Form, Input } from '@availity/form';
import Select from "react-select";
import DatePicker from "react-datepicker";
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import { lavakleenApi } from "../../api/lavakleenApi";
import { PageTitle } from '../../layout/components/PageTitle';
import { convertArrayOfObjectsToCSV, deleteDataFromApi, filterDataFromApi, getDataFromApi } from '../../api/providers/providers';
import { columnsIssues } from '../providers/salesProviders';
import { ModalComponent } from '../components/ModalComponent';
import { useSelector } from 'react-redux';
import { forwardRef } from 'react';
import { faWrench } from '@fortawesome/free-solid-svg-icons';

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const IssuesPage = forwardRef( (props, ref) => {
    useImperativeHandle( ref, () => ({
        async filterData(values){
            const result = await filterDataFromApi('Incident/getIncidentList', values, headers);
            setData(result.incidentList)
        },

        async restore() {
            await getIssues()
        }
    }) )

    const navigate = useNavigate();

    const usersRef = useRef(null);
    const customersRef = useRef(null);
    const ordersRef = useRef(null);
    const customerRef = useRef(null);

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
        saveDataToEndpoint: ''
    })

    const [data, setData] = useState([]);
    const { id, isProcessing, displayName, role } = useSelector( state => state.auth )
    const [formData, setFormData] = useState({
        id: 0,
        idOrder: 1,
        idCustomer: 0,
        idDriver: 0,
        idUserAssignment: 0,
        sSubject: '',
        sDescription: ''
    });

    const [user, setUser] = useState({
        value: id, label: displayName
    })

    const [selectedOptionStatus, setSelectedOptionStatus] = useState({
        value: 1, label: 'Abierta'
    });
    const [selectedOptionUser, setSelectedOptionUser] = useState(null);
    const [selectedOptionCustomer, setSelectedOptionCustomer] = useState(null);
    const [selectedOptionOrders, setSelectedOptionOrders] = useState(null);
    const [visitDate, setVisitDate] = useState(new Date());
    const [orderDisabled, setOrderDisabled] = useState(false);

    const [optionsStatus, setOptionsStatus] = useState([]);
    const [optionsUsers, setOptionsUsers] = useState([]);
    const [optionsCustomers, setOptionsCustomers] = useState([]);
    const [optionsOrders, setOptionsOrders] = useState([]);

    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)

    //validators
    const [invalidStatus, setInvalidStatus] = useState(false);
    const [invalidUser, setInvalidUser] = useState(true);
    const [invalidCustomer, setInvalidCustomer] = useState(true);
    const [invalidOrder, setInvalidOrder] = useState(true);

    const [columns, setColumns] = useState([...columnsIssues, {
        name: "Opciones",
        cell: (row) => (
            <>
                <Button onClick={ () => editRow(row) } color="primary">
                    <i className="lnr-pencil"></i>
                </Button>
                {
                    row.idIncidentStatus == 1
                    ? <Button color="success" onClick={ () => closeTicketInline(row) } title="Procesar">
                            <FontAwesomeIcon
                                icon={faWrench}
                            />
                        </Button>
                    : ''
                }
                <Button color="danger" onClick={ () => deleteRow(row.idTicket) }><i className="lnr-trash"></i></Button>
            </>
        ),
        sortable: false,
        ignoreRowClick: true,
        button: true,
        width: '150px',
    }])

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const onAddClick = () => {
        const now = new Date()
        const hour = now.toLocaleTimeString('default', {
            // en-US can be set to 'default' to use user's browser settings
            hour: '2-digit',
            minute: '2-digit',
          });
        setFormData({
            id: 0,
            idOrder: 1,
            idCustomer: 0,
            idDriver: 9,
            idUserAssignment: 0,
            sSubject: '',
            sDescription: '',
            sHour: hour,
        });

        setSelectedOptionCustomer(null)
        setSelectedOptionUser(null)
        setSelectedOptionOrders(null)

        setInvalidCustomer(true)
        setInvalidUser(true)
        setInvalidOrder(true)
        setOrderDisabled(false)
        toggleModal('Incident/addIncident?web=1')
    }

    const getIssues = async () => {
        await lavakleenApi.get('Incident/getIncidentList', {
            headers
        }).then( response => {
            setData( response.data.incidentList );
        } )
    }

    const getDropdownData = async () => {
        const issueStatus = await getDataFromApi(`Incident/getStatusIncident`, 0, [], headers)
        if(issueStatus.status === 'success') {
            const optionsArray = []
            issueStatus.options.map( option => {
                optionsArray.push({value: option.idOpcion, label: `${option.sOpcion}`})
            } )
            setOptionsStatus( optionsArray )
        }

        let filter = ''
        if(role !== 1)
            filter = `&idCompany=${localStorage.getItem('idCompany')}`

        const users = await getDataFromApi(`User/getUsersList?idStatusUser=1${filter}`, 0, [], headers)
        if(users.status === 'success') {
            const optionsArray = []
            users.userList.map( option => {
                optionsArray.push({value: option.idUser, label: `${option.sName}`})
            } )
            usersRef.current = optionsArray
            setOptionsUsers( optionsArray )
        }

        const customers = await getDataFromApi(`Customer/getCustomerList?filterIdStatusCustomer=1`, 0, [], headers)
        if(customers.status === 'success') {
            const optionsArray = []
            customers.customerList.map( option => {
                optionsArray.push({value: option.idCustomer, label: `${option.sCompanyName}`})
            } )
            customersRef.current = optionsArray
            setOptionsCustomers( optionsArray )
        }

        const orders = await getDataFromApi(`Order/getOrderListForIncident`, 0, [], headers)
        if(orders.status === 'success') {
            let optionsArray = []
            orders.orderList.map( option => {
                optionsArray = [...optionsArray, {value: option.idOrder, label: `${option.idOrder} | ${option.sBusinessName} | ${option.sServiceName} | ${option.sOrderProcess}`, idCustomer: option.idCustomer}]
            } )
            ordersRef.current = optionsArray
            setOptionsOrders( optionsArray )
        }
    }

    const editRow = async(row) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Incidencias')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        if(row.idIncidentStatus === 3) {
            Swal.fire({
                title: 'Error',
                text: 'La incidencia ya fue cerrada, no es posible editarla.',
                icon: 'error',
            })
            return
        }
        
        const issue = await getDataFromApi(`Incident/findIncident?idTicket=${row.idTicket}`, row.idTicket, {}, headers);
        if(issue.status === 'success'){
            const userAssigned = usersRef.current.find( element => element.value === issue.incidentData.idAssignmentUser )
            setSelectedOptionUser(userAssigned)
            setInvalidUser(false)

            const customer = customersRef.current.find( element => element.value === issue.incidentData.idCustomer )
            setSelectedOptionCustomer(customer)
            setInvalidCustomer(false)

            const order = ordersRef.current.find( element => element.value === issue.incidentData.idOrder )
            setSelectedOptionOrders(order)
            setInvalidOrder(false)

            setOrderDisabled(true)

            const incidentDate = new Date(issue.incidentData.dRegisterDate)

            setVisitDate(incidentDate)

            setFormData( {...issue.incidentData, id: issue.incidentData.idTicket, sHour: `${incidentDate.getHours()}:${incidentDate.getMinutes().toString().padStart(2, '0')}`} );
            toggleModal('Incident/editIncident')
        }
    }

    const deleteRow = async (idTicket) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Incidencias')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }
        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const result = await deleteDataFromApi(`Incident/deleteIncident?idTicket=${idTicket}`, headers)
                if(result.bResult === true)
                    await getIssues()
            }
        })
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        const filename = 'incidencias.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const checkPermissions = async () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Incidencias')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Incidencias'))
        
        if(userPerm.bList !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })

            navigate(`/pages/dashboard`)
            return
        }
        await getIssues()
        await getDropdownData()
    }

    const closeTicket = async () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Incidencias')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const idStatusIncident = 3
                await lavakleenApi.post(`Incident/changeIncidentStatus`, {idTicket: formData.idTicket, idStatusIncident}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0) {
                        toggleModal('')
                        getIssues()
                    }
                } )
            }
        })
    }

    const closeTicketInline = async (row) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Incidencias')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        if(row.idIncidentStatus === 2) {
            Swal.fire({
                title: 'Error',
                text: 'La incidencia ya está siendo procesada.',
                icon: 'error',
            })
            return
        }

        if(row.idIncidentStatus === 3) {
            Swal.fire({
                title: 'Error',
                text: 'La incidencia ya fue cerrada.',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const idStatusIncident = 2
                await lavakleenApi.post(`Incident/changeIncidentStatus`, {idTicket: row.idTicket, idStatusIncident}, {
                    headers
                }).then( response => {
                    if(response.data.bResult == true && response.data.sErrors.length == 0) {
                        getIssues()
                    }
                } )
            }
        })
    }

    useEffect(() => {
        checkPermissions();
        
    }, [])
    
    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Incidencias"
                                subheading="Gestion de incidencias"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                {
                                    permission !== null && permission.bCreate === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="primary"
                                                onClick={ onAddClick }
                                            >
                                                Agregar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }

                                {
                                    permission !== null && permission.bExport === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="danger"
                                                onClick={ () => downloadCsv()}
                                            >
                                                Exportar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }
                            </ul>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                        <DataTable data={data}
                                            columns={columns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight="500px"
                                        />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <ModalComponent
                                    isOpen={modalState.isOpen}
                                    toggle={ toggleModal }
                                    backdrop={ modalState.backDrop }
                                    modalTitle="Agregar/Editar incidencia"
                                    size="lg"
                                    initialValues={formData}
                                    validationSchema={ {
                                        sDescription: yup.string().required(),
                                        sSubject: yup.string().required(),
                                    } }
                                    saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                    getDataFunction={ getIssues }
                                    alertMessages={ { addMessage: 'Incidencia creada exitosamente', updateMessage: 'Incidencia actualizada exitosamente' } }
                                    dropDownsToValidate={ [ { field: 'idUserAssignment', value: selectedOptionUser?.value}, { field: 'idCustomer', value:  selectedOptionOrders?.idCustomer}, { field: 'idDriver', value: id}, { field: 'idOrder', value: selectedOptionOrders?.value} ] }
                                >
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup className="mb-2">
                                                <Label for="idCustomerType">Status</Label>
                                                <Select
                                                    defaultValue={selectedOptionStatus}
                                                    options={optionsStatus}
                                                    isClearable
                                                    name="idCustomerType"
                                                    key={ selectedOptionStatus }
                                                    placeholder="Selecciona una opción"
                                                    className={ invalidStatus ? 'is-invalid form-control' : '' }
                                                    onChange={ (option) => {
                                                        option == null ? setInvalidStatus(true) : setInvalidStatus(false)
                                                        setSelectedOptionStatus(option)
                                                    }}
                                                    isDisabled
                                                />
                                            </FormGroup>
                                            <FormGroup className="mb-2">
                                                <Label for="sSubject">Asunto</Label>
                                                <Input type="text" name="sSubject" placeholder=""/>
                                            </FormGroup>
                                            <FormGroup className="mb-2">
                                                <Label for="idOrder">No. Pedido</Label>
                                                <Select
                                                    defaultValue={selectedOptionOrders}
                                                    options={optionsOrders}
                                                    name="idOrder"
                                                    key={ selectedOptionOrders }
                                                    placeholder="Selecciona una opción"
                                                    className={ invalidOrder ? 'is-invalid form-control' : '' }
                                                    onChange={ (option) => {
                                                        option == null ? setInvalidOrder(true) : setInvalidOrder(false)
                                                        setSelectedOptionOrders(option)
                                                    }}
                                                    isDisabled={ orderDisabled }
                                                />
                                            </FormGroup>
                                            <FormGroup className="mb-2">
                                                <Label for="idUserAssignment">Persona asignada</Label>
                                                <Select
                                                    defaultValue={selectedOptionUser}
                                                    options={optionsUsers}
                                                    name="idUserAssignment"
                                                    key={ selectedOptionUser }
                                                    placeholder="Selecciona una opción"
                                                    className={ invalidUser ? 'is-invalid form-control' : '' }
                                                    onChange={ (option) => {
                                                        option == null ? setInvalidUser(true) : setInvalidUser(false)
                                                        setSelectedOptionUser(option)
                                                    }}
                                                />
                                            </FormGroup>
                                            {/* <FormGroup className="mb-2">
                                                <Label for="idCustomer">Cliente</Label>
                                                <Select
                                                    defaultValue={selectedOptionCustomer}
                                                    options={optionsCustomers}
                                                    name="idCustomer"
                                                    key={ selectedOptionCustomer }
                                                    placeholder="Selecciona una opción"
                                                    className={ invalidCustomer ? 'is-invalid form-control' : '' }
                                                    onChange={ (option) => {
                                                        option == null ? setInvalidCustomer(true) : setInvalidCustomer(false)
                                                        setSelectedOptionCustomer(option)
                                                    }}
                                                    
                                                />
                                            </FormGroup> */}
                                        </Col>
                                        <Col md="6">
                                            <FormGroup className="mb-2">
                                                <Label for="idCustomerType">Fecha</Label>
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={ visitDate }
                                                    onChange={(date) => setVisitDate(date)}
                                                    minDate={new Date()}
                                                    readOnly
                                                />
                                            </FormGroup>
                                            <FormGroup className="mb-2">
                                                <Label for="sHour">Hora</Label>
                                                <Input type="text" name="sHour" placeholder="" readOnly/>
                                            </FormGroup>
                                            <FormGroup className="mb-2">
                                                <Label for="idCustomerType">Usuario</Label>
                                                <Select
                                                    defaultValue={user}
                                                    options={optionsUsers}
                                                    isClearable
                                                    name="idCustomerType"
                                                    key={ user }
                                                    placeholder="Selecciona una opción"
                                                    isDisabled
                                                />
                                            </FormGroup>
                                            {
                                                formData.id > 0
                                                ? (
                                                    <FormGroup className="mb-2">
                                                        <Button color="success" className="mt-2"
                                                            onClick={ closeTicket }
                                                        >
                                                            Cerrar ticket
                                                        </Button>
                                                    </FormGroup>
                                                )
                                                : ''
                                            }
                                        </Col>
                                        <Col md="12">
                                            <FormGroup className="mb-2">
                                                <Label for="sDescription">Descripción</Label>
                                                <Input type="textarea" name="sDescription" placeholder=""/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </ModalComponent>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
})

export const columnsServices = [
    {
        name: "ID",
        selector: row => row.idServiceType,
        sortable: true,
        width: '80px',
    },
    {
        name: "Nombre",
        selector: row => row.sServiceType,
        sortable: true,
    },
    {
        name: "Status",
        selector: row => row.sStatus,
        sortable: true,
        width: '150px',
    },
    {
        name: "Fecha alta",
        selector: row => row.dRegisterDate,
        sortable: true,
        width: '180px',
    },
    {
        name: "Usuario alta",
        selector: row => row.sRegisterUser,
        sortable: true,
        width: '180px',
    },
];

export const columnsProductDetails = [
    {
        name: "ID",
        selector: row => row.idProduct,
        sortable: true,
        width: '80px',
    },
    {
        name: "Producto/Servicio",
        selector: row => row.sProductService,
        sortable: true,
        width: '250px',
    },
    {
        name: "Tipo de Servicio",
        selector: row => row.sServiceType,
        sortable: true,
        width: '250px',
    },
    {
        name: "Precio MXN",
        selector: row => row.dMXNPrice,
        sortable: true,
        width: '120px',
    },
    {
        name: "Precio USD",
        selector: row => row.dUSDPrice,
        sortable: true,
        width: '120px',
    },
    {
        name: "Unidad",
        selector: row => row.sMeasurement,
        sortable: true,
        width: '120px',
    },
    {
        name: "Prod. SAT",
        selector: row => row.sProductSAT,
        sortable: true,
        width: '250px',
    },
    {
        name: "Peso",
        selector: row => row.iKGWeight,
        sortable: true,
        width: '80px',
    },
    {
        name: "Familia",
        selector: row => row.sFamily,
        sortable: true,
    },
    {
        name: "Código barras",
        selector: row => row.sBarCode,
        sortable: true,
        width: '140px',
    },
    {
        name: "Objeto Impuesto",
        selector: row => row.sTaxObjectId == '01' ? '01 No objeto' : ( row.sTaxObjectId == '02' ? '02 Sí objeto' : '03 Sí objeto y no obligado al desglose' ),
        sortable: true,
        width: '140px',
    },
    {
        name: "Impuesto",
        selector: row => row.sTaxId == "8" ? 'IVA 8%' : (row.sTaxId == "16" ? 'IVA 16%' : 'IVA 0%'),
        sortable: true,
        width: '140px',
    },
];

export const TaxObjects = [
    {
        value: '01',
        label: 'No objeto de impuesto.'
    },
    {
        value: '02',
        label: 'Sí objeto de impuesto.'
    },
    {
        value: '03',
        label: 'Sí objeto del impuesto y no obligado al desglose.'
    }
]

export const Taxes = [
    {
        value: '16',
        label: 'IVA 16%'
    },
    {
        value: '8',
        label: 'IVA 8%'
    },
    {
        value: '0',
        label: 'IVA 0%'
    }
]

export const columnsProductsListToAssign = [
    {
        name: "ID",
        selector: row => row.idProduct,
        sortable: true,
        width: '80px'
    },
    {
        name: "Producto/Servicio",
        selector: row => row.sProductService,
        sortable: true,
        width: '250px'
    },
    {
        name: "Unidad",
        selector: row => row.sMeasurement,
    },
    {
        name: "Precio",
        selector: row => row.dMXNPrice,
    },
];

export const columnsProductsListAssigned = [
    {
        name: "ID",
        selector: row => row.idProduct,
        sortable: true,
        width: '80px'
    },
    {
        name: "Producto/Servicio",
        selector: row => row.sProductName,
        sortable: true
    },
    {
        name: "Unidad",
        selector: row => row.sUnidad == undefined ? 'Pieza' : row.sUnidad,
    },
    // {
    //     name: "Precio",
    //     selector: row => row.dMXNPrice,
    // },
];

export const columnsProductsListAssignedClient = [
    {
        name: "ID",
        selector: row => row.idProductService,
        sortable: true,
        width: '80px'
    },
    {
        name: "Producto/Servicio",
        selector: row => row.sProductService,
        sortable: true
    },
    {
        name: "Unidad",
        selector: row => row.sUnidad == undefined ? 'Pieza' : row.sUnidad,
    },
    // {
    //     name: "Precio",
    //     selector: row => row.dMXNPrice,
    // },
];
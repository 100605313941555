import { useCallback, useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Form, Input } from '@availity/form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Label, TabContent, TabPane, CardHeader, Nav, NavItem, NavLink } from "reactstrap";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import { lavakleenApi } from '../../api/lavakleenApi';
import { deleteDataFromApi, getDataFromApi, saveData } from '../../api/providers/providers';
import { PageTitle } from '../../layout/components/PageTitle';
import { startProcessing, stopProcessing } from '../../store/auth/authSlice';
import { TabHeaderComponent } from '../components/TabHeaderComponent';
import { columnsProductsListToAssign, columnsProductsListAssigned } from '../providers/inventoryProviders';
import { ModalComponent } from '../components/ModalComponent';

const formData = {
    sListName: '',
    sRegisterUser: '',
    dRegisterDate: '',
};

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const ProductListPage = () => {
    const {idListProduct} = useParams();

    const descriptorsReference = useRef([]);

    const [state, setState] = useState({
        activeTab: 'details'
    })

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
    })

    const [columnsProducts, setColumnsProducts] = useState([...columnsProductsListToAssign]);

    const [columnsProductsAssigned, setColumnsProductsAssigned] = useState([...columnsProductsListAssigned]);

    const [productListData, setProductListData] = useState(formData);
    const { id, isProcessing } = useSelector( state => state.auth )
    const [idProductRegister, setIdProductRegister] = useState(idListProduct);

    const [finisRegistering, setFinisRegistering] = useState(false);

    //datatables
    const [dataProducts, setDataProducts] = useState([]);
    const [productsOfList, setProductsOfList] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [listOfProducts, setListOfProducts] = useState([]);

    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)

    const onSubmit = async (values) => {

        if(state.activeTab === 'details') {
            dispatch( startProcessing() )

            if(idListProduct === undefined) {
                const alertConfig = {
                    title: 'Exito',
                    text: 'Lista de producto creada exitosamente',
                    icon: 'success',
                    willClose: () => {
                        onFinishRegister()
                    }
                };

                values.idRegisterUser = id

                const result = await saveData( 'ProductList/addListProduct', values, headers, alertConfig );
                console.log(result)
                // if(result.status === 'success') {
                //     setIdProductRegister(result.response.idCustomer)
                //     onFinishRegister()
                // }
            }

            if(idListProduct !== undefined) {
                const alertConfig = {
                    title: 'Exito',
                    text: 'Lista de producto actualizada exitosamente',
                    icon: 'success',
                    willClose: () => {
                        onFinishRegister()
                    }
                };

                await saveData( 'ProductList/editListProduct', values, headers, alertConfig );
            }

            dispatch( stopProcessing() )
        }
    }

    const getDropdownData = async () => {
        await lavakleenApi.get('ServiceType/getServiceList', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.serviceTypeList.map( option => {
                optionsArray.push({value: option.idServiceType, label: option.sServiceType})
            } )
            setOptionsServices( optionsArray );
        } )

        await lavakleenApi.get('CustomerMeasurement/getCustomerMeasurementList', {
            headers
        }).then( response => {
            const optionsArray = []
            response.data.customerMeasurementList.map( option => {
                optionsArray.push({value: option.idCustomerMeasurement, label: option.sCustomerMeasurement})
            } )
            setOptionsUnitMeasurements( optionsArray );
        } )
    }

    const getData = async () => {
        // await getDropdownData();
        await getProductsDetails();

        const productList = await getDataFromApi(`ProductList/findListProduct?idListProduct=${idListProduct}`, idListProduct, productListData, headers);
        if(productList.status === 'success'){
            setProductListData( {...productList.listData} )
        }

        await getProductsOfList()
    }

    const getProductsDetails = async () => {
        const productsDetails = await getDataFromApi(`Product/getProductList`, 4, [], headers)
        if(productsDetails.status === 'success') {
            setDataProducts(productsDetails.productList)
        }
    }

    const getProductsOfList = async () => {
        const productsDetails = await getDataFromApi(`ProductList/geProductsOfList?idListProduct=${idListProduct}`, idListProduct, [], headers)
        if(productsDetails.status === 'success') {
            setProductsOfList(productsDetails.productosOfList)
            productsDetails.productosOfList.map( product => {
                descriptorsReference.current = [...descriptorsReference.current, product.idProduct]
            } )
        }
    }

    const loadSatProducts = (inputValue) => {
        if(inputValue.length > 4) {
            return new Promise((resolve) => {
                resolve(getSatProducts(inputValue))
            })
        }
    };

    const onFinishRegister = () => {
        setFinisRegistering(true)
    }

    const toggle = (tab) => {
        if(state.activeTab !== tab) {
            setState({
                ...state,
                activeTab: tab
            })
        }
    }

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const onAddProductClick = async () => {
        dispatch( startProcessing() )
        const alertConfig = {
            title: 'Exito',
            text: 'Productos asignados correctamente.',
            icon: 'success',
            willClose: () => {
                onFinishRegister()
            }
        };

        const result = await saveData('ProductList/updateproductsOfList', { idListProduct, listOfProducts: descriptorsReference.current }, headers, alertConfig)
        if(result.status === 'success') {
            await getProductsOfList()
        }

        dispatch( stopProcessing() )
    }

    const addProductToList = (product, checkId) => {
        let productsId = descriptorsReference.current
        const check = document.getElementById(checkId)
        if(check.checked) {
            productsId.push(product.idProduct)
        } else {
            const el = productsId.findIndex(element => element === product.idProduct)
            productsId.splice(el, 1)
        }

        descriptorsReference.current = productsId

        setTimeout(() => {
            productsId.map( element => {
                document.getElementById(`check_${element}`).checked = true
            } )
        }, 100);

        setListOfProducts(productsId)
    }

    const editRow = async(idProduct) => {
        setSelectedOptionService(null)
        setInvalidService(true)
        setSelectedOptionMeasurementUnit(null)
        setInvalidMeasurementUnit(true)
        setSelectedOptionSatProduct(null)
        setInvalidSatProduct(true)
        setSelectedOptionTaxObject(null)
        setInvalidTaxObject(true)
        setSelectedOptionTax(null)
        setInvalidTax(true)
        const product = await getDataFromApi(`Product/findProduct?idProduct=${idProduct}`, idProduct, {}, headers);
        if(product.status === 'success'){
            setFormProductDetail( {...product.productData, id: product.productData.idProduct} );
            toggleModal('Product/editProduct')
        }
    }

    const deleteRow = async (idProduct) => {

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const result = await deleteDataFromApi(`Product/deleteProduct?idProduct=${idProduct}`, headers)
                if(result.bResult === true)
                    await getProductsDetails()
            }
        })
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Lista de productos')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Lista de productos'))
        
        if(userPerm.bCreate !== true && idListProduct === undefined) {
            navigate(`/pages/products-list`)
        }

        if(userPerm.bEdit !== true && idListProduct !== undefined) {
            navigate(`/pages/products-list`)
        }

        getData();
    }

    const handleRowChange = useCallback( state => {
        let productsToAssign = []
        state.selectedRows.map( row => {
            productsToAssign = [...productsToAssign, row.idProduct]
        } )
        descriptorsReference.current = productsToAssign
        // setListOfProducts(productsToAssign)
    } )

    const listOfProductsAssigned = (rows) => {
        return descriptorsReference.current.indexOf(rows.idProduct) > -1
    }

    useEffect(() => {
        checkPermissions();
    }, [])

    // const getSatProduct = async (idProdServ) => {
    //     const satProduct = await getDataFromApi(`ServiceProduct/findProdServ?idProdServ=${idProdServ}`, idProdServ, {}, headers);

    //     if(satProduct.status === 'success') {
    //         setSelectedOptionSatProduct({
    //             value: satProduct.prodServ.idProductService,
    //             label: `${satProduct.prodServ.sClaveSAT} ${satProduct.prodServ.sProductService}`
    //         })
    //         setInvalidSatProduct(false)
    //     }
    // }

    useEffect(() => {
        if(finisRegistering == true) {
            navigate(`/pages/products-list`)
        }
    }, [finisRegistering])

    useEffect(() => {
        if(state.activeTab === 'products') {
            // let productsId = []
            // setTimeout(() => {
            //     productsOfList.map( element => {
            //         productsId.push(element.idProduct)
            //     } )
            //     setListOfProducts(productsId)
            //     descriptorsReference.current = productsId
            // }, 200);

            // setTimeout(() => {
            //     productsOfList.map( element => {
            //         document.getElementById(`check_${element.idProduct}`).checked = true
            //     } )
            // }, 500);
        }
    }, [state.activeTab])

    // useEffect(() => {
    //     if(optionsServices.length > 0 && formProductDetail.idProduct !== undefined) {
    //         const serviceType = optionsServices.find( (option) => option.value === formProductDetail.idServiceType )
    //         setSelectedOptionService(serviceType)
    //         setInvalidService(false)

    //         const measurementUnit = optionsUnitMeasurements.find( (option) => option.value === formProductDetail.idCustomerMeasurement )
    //         setSelectedOptionMeasurementUnit(measurementUnit)
    //         setInvalidMeasurementUnit(false)

    //         const taxObject = optionsTaxObjects.find( (option) => option.value === formProductDetail.sTaxObjectId )
    //         setSelectedOptionTaxObject(taxObject)
    //         setInvalidTaxObject(false)

    //         const tax = optionsTaxes.find( (option) => option.value === formProductDetail.sTaxId )
    //         setSelectedOptionTax(tax)
    //         setInvalidTax(false)

    //         getSatProduct(formProductDetail.idProductServiceSAT)
    //     }
    // }, [optionsServices, formProductDetail])

    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Crear lista de producto"
                                subheading="Pantalla para creación de lista de productos nuevas"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardHeader>
                                            <Nav justified>
                                                <TabHeaderComponent id="details" label="Generales" state={state} toggle={ toggle }/>
                                                {
                                                    idListProduct && (
                                                        <TabHeaderComponent id="products" label="Servicio - Productos" state={state} toggle={ toggle }/>
                                                    )
                                                }
                                            </Nav>
                                        </CardHeader>
                                        <CardBody>
                                            <Form
                                                    initialValues={productListData}
                                                    onSubmit={ (values) => onSubmit(values) }
                                                    validationSchema={
                                                        yup.object({
                                                            sListName: yup.string().required()
                                                        })
                                                    }
                                                    enableReinitialize
                                                >
                                                    <TabContent activeTab={ state.activeTab }>
                                                        <TabPane tabId="details">
                                                            <Row>
                                                                <Col md={12}>
                                                                    <FormGroup className="mb-3">
                                                                        <Label for="sListName">Nombre de la lista de producto</Label>
                                                                        <Input type="text" name="sListName" placeholder=""/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={6}>
                                                                    {
                                                                        productListData.dRegisterDate !== ''
                                                                        ? (
                                                                            <FormGroup className="mb-3">
                                                                                <Label for="dRegisterDate">Fecha y hora de alta</Label>
                                                                                <Input type="text" name="dRegisterDate" placeholder="" readOnly/>
                                                                            </FormGroup>
                                                                        )
                                                                        : ''
                                                                    }
                                                                </Col>
                                                                <Col md={6}>
                                                                    {
                                                                        productListData.sRegisterUser !== ''
                                                                        ? (
                                                                            <FormGroup className="mb-3">
                                                                                <Label for="sRegisterUser">Creado por</Label>
                                                                                <Input type="text" name="sRegisterUser" placeholder="" readOnly/>
                                                                            </FormGroup>
                                                                        )
                                                                        : ''
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tabId="products">
                                                            <Row>
                                                                <Col md={6}>
                                                                    <Card className="main-card mb-3">
                                                                        <CardBody>
                                                                            <CardTitle>Servicio - Productos a asignar</CardTitle>
                                                                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                                                                <li className="nav-item">
                                                                                    <Button className="mb-2 me-2" color="success"
                                                                                        onClick={ onAddProductClick }
                                                                                    >
                                                                                        Asignar
                                                                                    </Button>
                                                                                </li>
                                                                            </ul>
                                                                            <Row>
                                                                                <Col md={12}>
                                                                                    <Card className="main-card mb-3">
                                                                                        <CardBody>
                                                                                            <DataTable data={dataProducts}
                                                                                                columns={columnsProducts}
                                                                                                pagination
                                                                                                fixedHeader
                                                                                                fixedHeaderScrollHeight="500px"
                                                                                                selectableRows
                                                                                                onSelectedRowsChange={handleRowChange}
                                                                                                selectableRowSelected={listOfProductsAssigned}
                                                                                            />
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Row>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                                <Col md={6}>
                                                                <Card className="main-card mb-3">
                                                                    <CardBody>
                                                                        <CardTitle>Productos asignados a la lista ({ productListData.sListName })</CardTitle>
                                                                        <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                                                            
                                                                        </ul>
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <Card className="main-card mb-3">
                                                                                    <CardBody>
                                                                                        <DataTable data={productsOfList}
                                                                                            columns={columnsProductsAssigned}
                                                                                            pagination
                                                                                            fixedHeader
                                                                                            fixedHeaderScrollHeight="500px"
                                                                                        />
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card> 
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                    </TabContent>
                                                    {
                                                        state.activeTab === 'details'
                                                        ? (
                                                            <Button color="primary" className="mt-2 mr-2" type="submit">
                                                                {
                                                                    (idListProduct === undefined)
                                                                    ? 'Guardar'
                                                                    : 'Actualizar'
                                                                }
                                                            </Button>
                                                        )
                                                        : ''
                                                    }
                                                    { "  " }
                                                    <Link 
                                                        className="mt-2 btn btn-danger"
                                                        to="/pages/products-list"
                                                    >
                                                        Cancelar
                                                    </Link>
                                                </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
}

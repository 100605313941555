import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, FormGroup, Label } from "reactstrap";
import { Form, Input } from '@availity/form';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import { lavakleenApi } from "../../api/lavakleenApi";
import { PageTitle } from '../../layout/components/PageTitle';
import { convertArrayOfObjectsToCSV, deleteDataFromApi, filterDataFromApi, getDataFromApi } from '../../api/providers/providers';
import { columnsClientStatus } from '../providers/adminProviders';
import { ModalComponent } from '../components/ModalComponent';

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const ClientStatus = forwardRef( (props, ref) => {
    useImperativeHandle( ref, () => ({
        async filterData(values){
            const result = await filterDataFromApi('CustomerStatus/getCustomerStatusList', values, headers);
            setData(result.listCustomerStatus)
        },

        async restore() {
            await getClientStatus()
        }
    }) )
    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
        saveDataToEndpoint: ''
    })

    const [data, setData] = useState([]);
    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: 0,
        sCustomerStatus: '',
    });

    const [columns, setColumns] = useState([...columnsClientStatus, {
        name: "Opciones",
        cell: (row) => (
            <>
                <Button onClick={ () => editRow(row.idCustomerStatus) } color="primary">
                    <i className="lnr-pencil"></i>
                </Button>
                <Button color="danger" onClick={ () => deleteRow(row.idCustomerStatus) }><i className="lnr-trash"></i></Button>
            </>
        ),
        sortable: false,
        ignoreRowClick: true,
        button: true,
    }])
    const [searchData, setSearchData] = useState({
        filterBranchOffice: '',
        // filterCompanyName: '',
    })

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const onAddClick = () => {
        setFormData({
            id: 0,
            sCustomerStatus: '',
            bActive: true,
        });
        toggleModal('CustomerStatus/addCustomerStatus')
    }

    const getClientStatus = async () => {
        await lavakleenApi.get('CustomerStatus/getCustomerStatusList', {
            headers
        }).then( response => {
            setData( response.data.listCustomerStatus );
        } )
    }

    const editRow = async(idCustomerStatus) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Status del Cliente')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        const role = await getDataFromApi(`CustomerStatus/findCustomerStatus?idCustomerStatus=${idCustomerStatus}`, idCustomerStatus, {}, headers);
        if(role.status === 'success'){
            setFormData( {...role.customerStatusData, id: role.customerStatusData.idCustomerStatus} );
            toggleModal('CustomerStatus/editCustomerStatus')
        }
    }

    const deleteRow = async (idCustomerStatus) => {

        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Status del Cliente')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const result = await deleteDataFromApi(`CustomerStatus/deleteCustomerStatus?idCustomerStatus=${idCustomerStatus}`, headers)
                if(result.bResult === true)
                    await getClientStatus()
            }
        })
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        const filename = 'roles.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Status del Cliente')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Status del Cliente'))
        
        if(userPerm.bList !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })

            navigate(`/pages/dashboard`)
        }
    }

    useEffect(() => {
        checkPermissions();
        getClientStatus();
    }, [])
    
    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Status de cliente"
                                subheading="Gestion de status de cliente para tu negocio"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                {
                                    permission !== null && permission.bCreate === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="primary"
                                                onClick={ onAddClick }
                                            >
                                                Agregar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }

                                {
                                    permission !== null && permission.bExport === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="danger"
                                                onClick={ () => downloadCsv()}
                                            >
                                                Exportar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }
                            </ul>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                        <DataTable data={data}
                                            columns={columns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight="500px"
                                        />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <ModalComponent
                                    isOpen={modalState.isOpen}
                                    toggle={ toggleModal }
                                    backdrop={ modalState.backDrop }
                                    modalTitle="Agregar status de cliente"
                                    size="md"
                                    initialValues={formData}
                                    validationSchema={ {
                                        sCustomerStatus: yup.string().required(),
                                    } }
                                    saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                    getDataFunction={ getClientStatus }
                                    alertMessages={ { addMessage: 'Status de cliente creado exitosamente', updateMessage: 'Status de cliente actualizado exitosamente' } }
                                >
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup className="mb-3">
                                                <Label for="sCustomerStatus">Nombre</Label>
                                                <Input type="text" name="sCustomerStatus" placeholder=""/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </ModalComponent>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
})


import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import { lavakleenApi } from "../lavakleenApi"

export const getUserById = async (idUser, headers) => {
    let result = {
        idUserRegister: null,
        idRol: null,
        idCompany: null,
        sName: 'Test',
        sLastName: 'Test',
        sUser: '',
        sPassword: '',
        sEmail: '',
    };

    if(idUser === undefined) return result;

    await lavakleenApi.get(`User/findUser?idUser=${idUser}`, { headers })
    .then( response => {
        result = response.data
        result.status = 'success'
    })
    .catch( error => {
        result = error;
        result.status = 'error'
    } )

    return result;
}

export const getCompanyById = async (idCompany, headers) => {
    let result = {
        idTaxRegime: null,
        idRegisterUser: null,
        sBuinessName: '',
        sCompanyName: '',
        sRfc: '',
        sBusinessAddress: '',
        sPrincipalPhone: '',
        sAlternatePhone: '',
        sWebsite: '',
        sEmail: '',
        sImagePath: '',
        sCsd: '',
        sKey: '',
        sSatpassword: ''
    };

    if(idCompany === undefined) return result;

    await lavakleenApi.get(`Company/findCompany?idCompany=${idCompany}`, { headers })
    .then( response => {
        result = response.data
        result.status = 'success'
    })
    .catch( error => {
        result = error;
        result.status = 'error'
    } )

    return result;
}

export const saveData = async (url, data, headers, alertConfig) => {

    const result = await lavakleenApi.post(url, data, {
        headers
    }).then( response => {
        if(response.data.bResult == false && response.data.sErrors.length > 0) {
            setTimeout(() => {
                return Swal.fire({
                    title: 'Error',
                    text: response.data.sErrors[0],
                    icon: 'error',
                })
            }, 200);

            return {
                status: 'error',
            }
        }

        if(response.data.bResult == true || response.data.sErrors.length == 0) {
            setTimeout(() => {
                Swal.fire(alertConfig)
            }, 200);

            return {
                status: 'success',
                response: response.data
            }
        }
    } )
    .catch(error => {

        return {
            status: 'error',
            error
        }
    })

    return result
}

export const updateData = async (url, data, headers, alertConfig) => {

    await lavakleenApi.put(url, data, {
        headers
    }).then( response => {
        if(response.data.bResult == false && response.data.sErrors.length > 0) {
            setTimeout(() => {
                return Swal.fire({
                    title: 'Error',
                    text: response.data.sErrors[0],
                    icon: 'error',
                })
            }, 200);

            return {
                status: 'error',
            }
        }

        if(response.data.bResult == true && response.data.sErrors.length == 0) {
            setTimeout(() => {
                return Swal.fire(alertConfig)
            }, 200);

            return {
                status: 'success',
            }
        }
    } )
    .catch(error => {

        return {
            status: 'error',
            error
        }
    })
}

export const getDataFromApi = async (url, id, defaultDataToReturn, headers) => {

    if(id === undefined) return defaultDataToReturn;

    await lavakleenApi.get(url, { headers })
    .then( response => {
        defaultDataToReturn = response.data
        defaultDataToReturn.status = 'success'
    })
    .catch( error => {
        defaultDataToReturn = error;
        defaultDataToReturn.status = 'error'
    } )

    return defaultDataToReturn;
}

export const filterDataFromApi = async (url, data = {}, headers) => {
    let dataToSearch = {}
    for (const key in data) {
        if(data[key] !== '' && data[key] !== null) {
            dataToSearch[key] = data[key]
        }
    }

    let dataToReturn = [];
    await lavakleenApi.get(url, {
        params: dataToSearch,
        headers
    })
    .then( response => {
        dataToReturn = response.data
    } )
    .catch( error => {
        console.log(error)
    } )

    return dataToReturn
}

export const convertArrayOfObjectsToCSV = (array = []) => {
    let result; let keys;
    
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    if(array.length > 0) {
        array.map( item => {
            let idOrderProcessProp = 'idOrderProcess' in item
            let idDriverProp = 'idDriver' in item
            let idCustomerProp = 'idCustomer' in item
            let sSignPathProp = 'sSignPath' in item
            let invoiceDataProp = 'invoiceData' in item
            let orderListProp = 'orderList' in item
            let idOrdersProp = 'idOrders' in item
            let payDataProp = 'payData' in item
            let idInvoiceRelatedProp = 'idInvoiceRelated' in item
            let conceptoDataProp = 'conceptoData' in item
            let sFormaPagoProp = 'sFormaPago' in item
            let sClaveImpuestoPartidaProp = 'sClaveImpuestoPartida' in item
            let idRegisterUserProp = 'idRegisterUser' in item
            let paymentListProp = 'paymentList' in item
            
            if(idOrderProcessProp) {
                if(item.idOrderProcess == 6)
                    item.facturable = 'Facturable'
                else
                    item.facturable = 'No facturable'
                delete item.idOrderProcess
            }
            if(idDriverProp) {
                delete item.idDriver
            }
            if(idCustomerProp) {
                delete item.idCustomer
            }
            if(sSignPathProp) {
                delete item.sSignPath
            }
            if(orderListProp) {
                delete item.orderList
            }
            if(invoiceDataProp) {
                item.idInvoice = 0
                item.sSerie = ''
                item.invoiceDate = ''
                item.dSubtotal = 0
                item.dDescuento = 0
                item.dIva = 0
                item.dTotal = 0
                item.sTipoCfdi = ''
                item.sFolioFiscal = ''
                item.sEstatus = ''
                item.pagado = 0
                item.saldo = 0
                let payment = 0
                if(item.invoiceData !== null) {
                    item.idInvoice = item.invoiceData.idInvoice
                    item.sSerie = item.invoiceData.sSerie
                    item.invoiceDate = item.invoiceData.dRegisterDate
                    item.dSubtotal = item.invoiceData.dSubtotal
                    item.dDescuento = item.invoiceData.dDescuento
                    item.dIva = item.invoiceData.dIva
                    item.dTotal = item.invoiceData.dTotal
                    item.sTipoCfdi = item.invoiceData.sTipoCfdi
                    item.sFolioFiscal = item.invoiceData.sFolioFiscal
                    item.sEstatus = item.invoiceData.sEstatus
                    item.sBusinessName = item.invoiceData.sBusinessName
                    if(item.sMetodoPago == 'PUE') {
                        item.pagado = item.invoiceData.dTotal.toFixed(2)
                    }
                    if(item.sMetodoPago == 'PPD') {
                        if(location.pathname !== '/pages/credit-notes') {
                            item.payData.map( payment => {
                                item.pagado += payment.dPayment
                                payment += payment.dPayment
                            } )
    
                            item.saldo = item.dTotal - item.pagado
                        }
                    }
                }
                // if(location.pathname !== '/pages/payments' && location.pathname !== '/pages/invoices')
                //     delete item.invoiceData
                if(location.pathname === '/pages/invoices') {
                    delete item.invoiceData
                    delete item.payData
                }
                if(location.pathname === '/pages/payments') {
                    delete item.invoiceData
                }
            }
            if(idOrdersProp) {
                delete item.idOrders
            }
            if(payDataProp && location.pathname !== '/pages/invoices') {
                delete item.payData
            }
            if(idInvoiceRelatedProp) {
                delete item.idInvoiceRelated
            }
            if(conceptoDataProp) {
                delete item.conceptoData
            }
            if(sFormaPagoProp) {
                delete item.sFormaPago
            }
            if(sClaveImpuestoPartidaProp) {
                delete item.sClaveImpuestoPartida
            }
            if(idRegisterUserProp) {
                delete item.idRegisterUser
            }
            if(paymentListProp) {
                item.pagado = 0
                item.paymentList.map( payment => {
                    item.pagado += payment.dAmount
                } )

                item.saldo = item.dTotal - item.pagado
                delete item.paymentList
            }
        } )
        keys = Object.keys(array[0]);
    }
    if(array.length === 0)
        keys = Object.keys({});
    
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    
    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
            if(key === 'sBusinessName') {
                let value = item[key].replace(',', '');
                value = value.replace(',', '')
                value = value.replace(',', '')
                value = value.replace(',', '')
                result += value;
                ctr++;
            }
            else {
                result += item[key];
                ctr++;
            }
        });
        result += lineDelimiter;
    });

    result = result.replace('idPreInvoice,', 'Prefactura,')
    result = result.replace('sMetodoPago,', 'Metodo de pago,')
    result = result.replace('sUsoCfdi,', 'Uso de CFDI,')
    result = result.replace('sBusinessName,', 'Nombre o razon social,')
    result = result.replace('sRfc,', 'RFC,')
    result = result.replace('dRegisterDate,', 'Fecha pre factura,')
    result = result.replace('sEmail,', 'Correo electronico,')
    result = result.replace('idInvoice,', 'No. Factura,')
    result = result.replace('sSerie,', 'Serie,')
    result = result.replace('invoiceDate,', 'Fecha de factura,')
    result = result.replace('dSubtotal,', 'Subtotal,')
    result = result.replace('dDescuento,', 'Descuento,')
    result = result.replace('dIva,', 'IVA,')
    result = result.replace('dTotal,', 'Total,')
    result = result.replace('sTipoCfdi,', 'Tipo de CFDI,')
    result = result.replace('sFolioFiscal,', 'Folio fiscal,')
    result = result.replace('sEstatus,', 'Estatus,')
    result = result.replace('idOrder,', 'Pedido,')
    result = result.replace('dDeliveryDate,', 'Fecha registro,')
    result = result.replace('sServiceName,', 'Servicio,')
    result = result.replace('sOrderProcess,', 'Proceso,')
    result = result.replace('sDriverName,', 'Chofer,')
    result = result.replace('sStatus,', 'Estatus,')
    result = result.replace('dTotalWeight,', 'Peso,')
    result = result.replace('bActive,', 'Activo,')
    
    return result;
}

export const deleteDataFromApi = async (url, headers) => {
    let result = []
    await lavakleenApi.post(url, {}, {
        headers
    }).then( response => {
        result = response.data
        // if(response.data.bResult == true && response.data.sErrors.length == 0)
        //     getRoles();
    } )

    return result
}

export const baseUrl = 'https://api.acquacrm.app'
// export const baseUrl = 'http://108.175.5.101:8084'
export const columnsVisits = [
    {
        name: "ID",
        selector: row => row.idVisit,
        sortable: true,
        width: '80px',
    },
    {
        name: "Cliente",
        selector: row => row.sCustomer,
        sortable: true,
    },
    {
        name: "Chofer",
        selector: row => row.sDriverName,
        sortable: true,
        width: '180px',
    },
    {
        name: "Fecha registro",
        selector: row => row.dRegisterDate,
        sortable: true,
        width: '180px',
    },
    {
        name: "Comentario",
        selector: row => row.sComments,
        sortable: true,
    },
    {
        name: "Status",
        selector: row => row.sStatus,
        sortable: true,
    },
];

export const columnsIssues = [
    {
        name: "ID",
        selector: row => row.idTicket,
        sortable: true,
        width: '80px',
    },
    {
        name: "Cliente",
        selector: row => row.sCustomer,
        sortable: true,
    },
    {
        name: "Asunto",
        selector: row => row.sSubject,
        sortable: true,
        width: '180px',
    },
    {
        name: "Descripción",
        selector: row => row.sDescription,
        sortable: true,
        width: '180px',
    },
    {
        name: "Asignado a",
        selector: row => row.sAssignmentUser,
        sortable: true,
    },
    {
        name: "Status",
        selector: row => row.sIncidentStatus,
        sortable: false,
    },
];

export const paymentsForm = [
    {value: '01', label: '01 - Efectivo'},
    {value: '02', label: '02 - Cheque nominativo'},
    {value: '03', label: '03 - Transferencia electrónica'},
    {value: '04', label: '04 - Tarjeta de crédito'},
    {value: '05', label: '28 - Tarjeta de débito'},
    {value: '06', label: '05 - Monedero electrónico'},
    {value: '07', label: '06 - Dinero electrónico'},
    {value: '08', label: '08 - Vales de despensa'},
    {value: '12', label: '12 - Dación en pago'},
    {value: '13', label: '13 - Pago por subrogación'},
    {value: '14', label: '14 - Pago por consignación'},
    {value: '15', label: '15 - Condonación'},
    {value: '16', label: '17 - Compensación'},
    {value: '23', label: '23 - Novación'},
    {value: '24', label: '24 - Confusión'},
    {value: '25', label: '25 - Remisión de deuda'},
    {value: '26', label: '26 - Prescripción o caducidad'},
    {value: '27', label: '27 - A satisfacción del acreedor'},
    {value: '29', label: '29 - Tarjeta de servicios'},
    {value: '30', label: '30 - Aplicación de anticipos'},
    {value: '31', label: '31 - Intermediario pagos'},
    {value: '99', label: '99 - Por definir'},
];

export const cfdiUse = [
    { value: 'G01', label: 'G01 - Adquisición de mercancías.' },
    { value: 'G02', label: 'G02 - Devoluciones, descuentos o bonificaciones.' },
    { value: 'G03', label: 'G03 - Gastos en general.' },
    { value: 'I01', label: 'I01 - Construcciones.' },
    { value: 'I02', label: 'I02 - Mobiliario y equipo de oficina por inversiones.' },
    { value: 'I03', label: 'I03 - Equipo de transporte.' },
    { value: 'I04', label: 'I04 - Equipo de computo y accesorios.' },
    { value: 'I05', label: 'I05 - Dados, troqueles, moldes, matrices y herramental.' },
    { value: 'I06', label: 'I06 - Comunicaciones telefónicas.' },
    { value: 'I07', label: 'I07 - Comunicaciones satelitales.' },
    { value: 'I08', label: 'I08 - Otra maquinaria y equipo.' },
    { value: 'D01', label: 'D01 - Honorarios médicos, dentales y gastos hospitalarios.' },
    { value: 'D02', label: 'D02 - Gastos médicos por incapacidad o discapacidad.' },
    { value: 'D03', label: 'D03 - Gastos funerales.' },
    { value: 'D04', label: 'D04 - Donativos.' },
    { value: 'D05', label: 'D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).' },
    { value: 'D06', label: 'D06 - Aportaciones voluntarias al SAR.' },
    { value: 'D07', label: 'D07 - Primas por seguros de gastos médicos.' },
    { value: 'D08', label: 'D08 - Gastos de transportación escolar obligatoria.' },
    { value: 'D09', label: 'D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
    { value: 'D10', label: 'D10 - Pagos por servicios educativos (colegiaturas).' },
    { value: 'S01', label: 'S01 - Sin efectos fiscales.  ' },
    { value: 'CP01', label: 'CP01 - Pagos' },
    { value: 'CN01', label: 'CN01 - Nómina' },
];

export const cfdiRelation = [
    { value: '01', label: 'Nota de crédito de los documentos relacionados.' },
    { value: '02', label: 'Nota de débito de los documentos relacionados.' },
    { value: '03', label: 'Devolución de mercancía sobre facturas o traslados previos.' },
    { value: '04', label: 'Sustitución de los CFDI previos.' },
    { value: '05', label: 'Traslados de mercancías facturados previamente.' },
    { value: '06', label: 'Factura generada por los traslados previos.' },
    { value: '07', label: 'CFDI por aplicación de anticipo.' },
];

export const cfdiCancelationMotives = [
    {value: '01', label: '01 - Comprobante emitido con errores con relación'},
    {value: '02', label: '02 - Comprobante emitido con errores sin relación'},
    {value: '03', label: '03 - No se llevó a cabo la operación'},
    {value: '04', label: '04 - Operación nominativa relacionada con una factura global'}
];
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginPage } from "../auth/pages/LoginPage";
import { RegisterPage } from "../auth/pages/RegisterPage";
import { useCheckAuth } from "../hooks/useCheckAuth";
import { AppMain } from "../layout/main/AppMain";
import { PublicRoute } from "./PublicRoute";
import { login, permissions } from "../store/auth/authSlice";
import { lavakleenApi } from "../api/lavakleenApi";
import { useEffect } from "react";
import { TicketOrder } from "../pages/orders/TicketOrder";
import { TicketOrderRma } from "../pages/orders/TicketOrderRma";

export const AppRouter = () => {

    const { status } = useCheckAuth();
    const dispatch = useDispatch();

    if(status == 'not-authenticated') {

        if(localStorage.getItem('userData')) {
            const userData = JSON.parse( localStorage.getItem('userData') );
            dispatch( login( userData ) );
        }
    }

    const getPermissions = async () => {
        const headers = {
            'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
            'private' : localStorage.getItem('idCompany'),
        };

        const userData = JSON.parse( localStorage.getItem('userData') );

        const userPermissions = await lavakleenApi.get(`GrantPrivileges/getPermisionList?idUser=${userData.idUser}`, { headers })

        localStorage.setItem('userPermissions', JSON.stringify(userPermissions.data.permisionsList));
            dispatch( permissions( userPermissions.data.permisionsList ) );
    }

    useEffect(() => {
        getPermissions();
    }, [])
    

    return (
        <Routes>
            <Route path="/" element={ <Navigate to="/auth/login"/> }/>
            <Route path="/auth/login" element={<LoginPage/>}/>
            <Route path="/auth/register" element={<RegisterPage/>}/>

            <Route path="/pages/*" element={<AppMain/>}/>
            <Route path="/ticket-order/:idOrder" element={ <TicketOrder/> } />
            <Route path="/ticket-order-rma/:idOrder" element={ <TicketOrderRma/> } />
        </Routes>
    )
}


export const columnsAddress = [
    {
        name: "ID",
        selector: row => row.idAddress,
        sortable: true,
    },
    {
        name: "Nombre",
        selector: row => row.sAddressAlias,
        sortable: true,
    },
    {
        name: "Calle",
        selector: row => row.sBusinessAddress,
        sortable: true,
    },
    {
        name: "No Exterior",
        selector: row => row.sExtNumber,
        sortable: true,
    },
    {
        name: "No Interior",
        selector: row => row.sIntNumber,
        sortable: true,
    },
    {
        name: "Ciudad",
        selector: row => row.sCity,
        sortable: true,
    },
    {
        name: "Estado",
        selector: row => row.sState,
        sortable: true,
    },
]
import MetisMenu from "react-metismenu";
import RouterLink from 'react-metismenu-router-link';
import { MainNav } from './NavItems'

export const VerticalNavWrapper = () => {

    const componentState = {};

    const toggleMobileSidebar = () => {
        
    };

    return (
        <>
            <h5 className="app-sidebar__heading">Menu</h5>
            <MetisMenu
                content={MainNav}
                // LinkComponent={RouterLink}
                onSelected={toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
            />
        </>
    )
}

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody, Button, FormGroup, Label } from "reactstrap";
import { Form, Input } from '@availity/form';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import * as yup from "yup";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.css'
import AsyncSelect from 'react-select/async';
import { lavakleenApi } from "../../api/lavakleenApi";
import { PageTitle } from '../../layout/components/PageTitle';
import { convertArrayOfObjectsToCSV, deleteDataFromApi, filterDataFromApi, getDataFromApi } from '../../api/providers/providers';
import { columnsCustomerMeasurementUnits } from '../providers/adminProviders';
import { ModalComponent } from '../components/ModalComponent';

const headers = {
    'Authorization' : 'Bearer ' + localStorage.getItem('sToken'),
    'private' : localStorage.getItem('idCompany')
}

export const UnitMeasures = forwardRef( (props, ref) => {
    useImperativeHandle( ref, () => ({
        async filterData(values){
            const result = await filterDataFromApi('CustomerMeasurement/getCustomerMeasurementList', values, headers);
            setData(result.customerMeasurementList)
        },

        async restore() {
            await getMeasurementUnits()
        }
    }) )

    const [modalState, setModalState] = useState({
        isOpen: false,
        backDrop: true,
        saveDataToEndpoint: ''
    })

    const [data, setData] = useState([]);
    const [userPermissions, setUserPermissions] = useState(JSON.parse( localStorage.getItem('userPermissions') ))
    const [permission, setPermission] = useState(null)
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: 0,
        sClave: '',
        sCustomerMeasurement: '',
        idMeasurementUnits: '',
    });

    const [selectedOptionSatUnit, setSelectedOptionSatUnit] = useState(null);
    const [optionsSatUnits, setOptionsSatUnits] = useState([]);

    //validators
    const [invalidSatUnit, setInvalidSatUnit] = useState(true);

    const [columns, setColumns] = useState([...columnsCustomerMeasurementUnits, {
        name: "Opciones",
        cell: (row) => (
            <>
                <Button onClick={ () => editRow(row.idCustomerMeasurement) } color="primary">
                    <i className="lnr-pencil"></i>
                </Button>
                <Button color="danger" onClick={ () => deleteRow(row.idCustomerMeasurement) }><i className="lnr-trash"></i></Button>
            </>
        ),
        sortable: false,
        ignoreRowClick: true,
        button: true,
    }])
    const [searchData, setSearchData] = useState({
        filterBranchOffice: '',
        // filterCompanyName: '',
    })

    const toggleModal = (saveDataToEndpoint) => {
        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            saveDataToEndpoint,
        })
    }

    const onAddClick = () => {
        setFormData({
            id: 0,
            sClave: '',
            sCustomerMeasurement: '',
            idMeasurementUnits: '',
        });
        setInvalidSatUnit(true)
        setSelectedOptionSatUnit(null)
        toggleModal('CustomerMeasurement/addCustomerMeasurement')
    }

    const getMeasurementUnits = async () => {
        await lavakleenApi.get('CustomerMeasurement/getCustomerMeasurementList', {
            headers
        }).then( response => {
            setData( response.data.customerMeasurementList );
        } )
    }

    const editRow = async(idCustomerMeasurement) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Unidades de Medida')

        if(userPerm.bEdit !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }
        const unit = await getDataFromApi(`CustomerMeasurement/findCustomerMeasurement?idCustomerMeasurement=${idCustomerMeasurement}`, idCustomerMeasurement, {}, headers);
        if(unit.status === 'success'){
            setFormData( {...unit.customerMeasurementData, id: unit.customerMeasurementData.idCustomerMeasurement} );
            toggleModal('CustomerMeasurement/editCustomerMeasurement')
        }
    }

    const deleteRow = async (idCustomerMeasurement) => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Unidades de Medida')

        if(userPerm.bDelete !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })
            return
        }

        Swal.fire({
            title: 'Aviso',
            text: "¿Está seguro de realizar esta acción?.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, realizarla!'
        })
        .then(async (result) => {
            if(result.isConfirmed) {
                const result = await deleteDataFromApi(`CustomerMeasurement/deleteCustomerMeasurement?idCustomerMeasurement=${idCustomerMeasurement}`, headers)
                if(result.bResult === true)
                    await getMeasurementUnits()
            }
        })
    }

    const filterData = async (values) => {
        const result = await filterDataFromApi('Rol/getRolList', values, headers);
        setData(result.branchList)
    }

    const getSatMeasurementUnits = async (inputValue) => {
        const optionsArray = []
        await lavakleenApi.get(`MeasurementUnit/getMeasurementUnitsList?filterMeasurement=${inputValue}`, {
            headers
        }).then( response => {
            
            response.data.listMeasurementUnits.map( option => {
                optionsArray.push({value: option.idMeasurementUnit, label: `${option.sClaveSAT} ${option.sMeasurementUnit}`})
            } )
        } )
        setOptionsSatUnits(optionsArray)

        return optionsArray;
    };

    const loadSatMeasurementUnits = (inputValue) => {
        if(inputValue.length > 2) {
            return new Promise((resolve) => {
                resolve(getSatMeasurementUnits(inputValue))
            })
        }
    };

    const restore = async () => {
        setSearchData({
            filterBranchOffice: '',
        })

        await getMeasurementUnits()
    }

    const downloadCsv = () => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data);
        const BOM = "\uFEFF";
        if (csv == null) return;
        
        const filename = 'unidades_medida.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${BOM + csv}`;
        }
        
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const getSatMeasurementUnit = async (idMeasurementUnits) => {
        const unit = await getDataFromApi(`MeasurementUnit/findMeasurementUnit?idMeasurementUnit=${idMeasurementUnits}`, idMeasurementUnits, {}, headers);

        if(unit.status === 'success') {
            setSelectedOptionSatUnit({
                value: unit.measurementUnitsData.idMeasurementUnit,
                label: `${unit.measurementUnitsData.sClaveSAT} ${unit.measurementUnitsData.sMeasurementUnit}`
            })
            setInvalidSatUnit(false)
        }

    }

    const checkPermissions = () => {
        const userPerm = userPermissions.find( userPermission => userPermission.sModuleDetail === 'Unidades de Medida')
        setPermission(userPermissions.find( userPermission => userPermission.sModuleDetail === 'Unidades de Medida'))
        
        if(userPerm.bList !== true) {
            Swal.fire({
                title: 'Error',
                text: 'No tienes permisos para usar esta función',
                icon: 'error',
            })

            navigate(`/pages/dashboard`)
        }
    }

    useEffect(() => {
        checkPermissions();
        getMeasurementUnits();
    }, [])

    useEffect(() => {
        if(formData.id > 0 && formData.idCustomerMeasurement !== undefined) {
            getSatMeasurementUnit(formData.idMeasurementUnits)
        }
    }, [formData])

    
    return (
        <>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    classNames="TabsAnimation"
                    appear={true}
                    timeout={1500} enter={false} exit={false}>
                        <div>
                            <PageTitle
                                heading="Unidades de medida"
                                subheading="Gestión de unidades de medida para tu negocio"
                                icon="lnr-users icon-gradient bg-ripe-malin"
                            />
                            <ul className="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav">
                                {
                                    permission !== null && permission.bCreate === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="primary"
                                                onClick={ onAddClick }
                                            >
                                                Agregar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }

                                {
                                    permission !== null && permission.bExport === true
                                    ? (
                                        <li className="nav-item">
                                            <Button className="mb-2 me-2" color="danger"
                                                onClick={ () => downloadCsv()}
                                            >
                                                Exportar
                                            </Button>
                                        </li>
                                    )
                                    : ''
                                }
                            </ul>
                            {/* <div className="row">
                                <h5>Buscar por</h5>
                                <Form
                                    initialValues={searchData}
                                    onSubmit={ (values) => filterData(values) }
                                    enableReinitialize
                                >
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup className="mb-2">
                                                <Label for="filterBranchOffice">Sucursal</Label>
                                                <Input type="text" name="filterBranchOffice"/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup className="mb-2">
                                                <Label for="filterCompanyName">Nombre comercial</Label>
                                                <Input type="text" name="filterCompanyName"/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <Button color="primary" className="mt-4 mr-2" type="submit">Buscar</Button>{ "  " }
                                            <Button color="danger" className="mt-4 mr-2" onClick={ restore }>Reestablecer</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div> */}
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                        <DataTable data={data}
                                            columns={columns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight="500px"
                                        />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <ModalComponent
                                    isOpen={modalState.isOpen}
                                    toggle={ toggleModal }
                                    backdrop={ modalState.backDrop }
                                    modalTitle="Agregar unidad de medida"
                                    size="md"
                                    initialValues={formData}
                                    validationSchema={ {
                                        sClave: yup.string().max(5).required(),
                                        sCustomerMeasurement: yup.string().required(),
                                    } }
                                    saveDataToEndpoint={ modalState.saveDataToEndpoint }
                                    getDataFunction={ getMeasurementUnits }
                                    alertMessages={ { addMessage: 'Unidad de medida creada exitosamente', updateMessage: 'Unidad de medida actualizada exitosamente' } }
                                    dropDownsToValidate={ [ { field: 'idMeasurementUnits', value: selectedOptionSatUnit?.value}] }
                                >
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup className="mb-3">
                                                <Label for="sClave">Clave</Label>
                                                <Input type="text" name="sClave" placeholder=""/>
                                            </FormGroup>
                                            <FormGroup className="mb-3">
                                                <Label for="sCustomerMeasurement">Nombre</Label>
                                                <Input type="text" name="sCustomerMeasurement" placeholder=""/>
                                            </FormGroup>
                                            <FormGroup className="mb-3">
                                                <Label for="idState">Unidad de medida SAT</Label>
                                                <AsyncSelect
                                                    // cacheOptions
                                                    defaultValue={selectedOptionSatUnit}
                                                    defaultOptions
                                                    onChange={ (option) => {
                                                        option == null ? setInvalidSatUnit(true) : setInvalidSatUnit(false)
                                                        setSelectedOptionSatUnit(option)
                                                    }}
                                                    loadOptions={loadSatMeasurementUnits}
                                                    placeholder="Escribe para buscar..."
                                                    className={ invalidSatUnit ? 'is-invalid form-control' : '' }
                                                    key={ selectedOptionSatUnit }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </ModalComponent>
                            </Row>
                        </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    )
})

import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, Button } from "reactstrap";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import city3 from "../../../assets/utils/images/dropdown-header/city3.jpg";
import avatar1 from "../../../assets/utils/images/logo-inverse.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/auth/authSlice";


export const UserBox = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { displayName, roleName } = useSelector( state => state.auth );

    const onLogout = () => {
        dispatch( logout() );
        navigate('/auth/login', {
            replace: true
        })
    }

    return (
        <>
            <div className="header-btn-lg pe-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="link" className="p-0">
                                    <img width={42} className="rounded-circle" src={avatar1} alt=""/>
                                    <FontAwesomeIcon
                                    className="ms-2 opacity-8"
                                    icon={faAngleDown}
                                    />
                                </DropdownToggle>
                                <DropdownMenu
                                    className="rm-pointers dropdown-menu-lg"
                                    end="true"
                                >
                                    <div className="dropdown-menu-header">
                                        <div className="dropdown-menu-header-inner bg-info">
                                            <div className="menu-header-image opacity-2"
                                            style={{
                                                backgroundImage: "url(" + city3 + ")",
                                            }}/>
                                            <div className="menu-header-content text-start">
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left me-3">
                                                            <img width={42} className="rounded-circle" src={avatar1} alt=""/>
                                                        </div>
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                            {displayName}
                                                            </div>
                                                            <div className="widget-subheading opacity-8">
                                                            {roleName}
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right me-2">
                                                            <Button className="btn-pill btn-shadow btn-shine" color="focus"
                                                                onClick={onLogout}
                                                            >
                                                                Salir
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <div className="widget-content-left  ms-3 header-user-info">
                            <div className="widget-heading">{displayName}</div>
                            <div className="widget-subheading">{roleName}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
